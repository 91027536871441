<template>
    <div class="wp-detail">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :span="24">
                <h5>Quản lý tài chính</h5>
                <div class="breadcrumb mb-0">
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Quản lý tài chính</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Doanh thu cá nhân</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết giao dịch</span>
                </div>
            </el-col>
        </el-row>

        <div class="wp-content">
            <div style="margin-bottom: 20px;">
                <router-link to="/cong-viec/tai-chinh" class="rout">
                    <img src="@/assets/icons/arrow.png" alt="">
                </router-link>
                <span class="ttl">Chi tiết giao dịch</span>
                <p class="ttl">Bất động sản liên quan</p>
                <router-link :to="'/quan-ly-bds/danh-sach/chi-tiet/' + encode(dataDetail.transaction_detail.real_estate_id)" class="wp-bds" target="_blank">
                    <img :src="$storage + dataDetail.transaction_detail.thumbnail" alt="" @error="handleError">
                    <div class="wp-txt">
                        <p>{{ dataDetail.transaction_detail.real_estate_name }}</p>
                        <p class="txt--yl">#{{ dataDetail.transaction_detail.real_estate_code }}</p>
                    </div>
                </router-link>
                <div class="wp-money">
                    <p>Hoa hồng của bất động sản</p>
                    <p class="txt--red">{{ parseInt(dataDetail.transaction_detail.commission).toLocaleString('vi') }} VNĐ</p>
                </div>
                <div class="wp-money" v-if="dataDetail.transaction_detail.deposits">
                    <p>Tiền thu trong giao dịch</p>
                    <p class="txt--green">{{ parseInt(dataDetail.transaction_detail.deposits).toLocaleString('vi') }} VNĐ</p>
                </div>
                <p class="price-txt" v-if="money_pay > 0">Số tiền còn phải thu: 
                    <span>{{ (money_pay).toLocaleString('vi') }} VNĐ</span>
                </p>
                <p style="font-size: 15px; margin-bottom: 5px;">Đầu việc liên quan</p>
                <router-link :to="'/cong-viec/quan-ly-cong-viec/chi-tiet-cong-viec/' + encode(dataDetail.transaction_detail.id)" class="wp-job" target="_blank">
                    <div class="d-flex align-items-center justify-content-between">
                        <p style="font-size: 18px;">{{ dataDetail.transaction_detail.title }}</p>
                        <p style="font-size: 16px;" class="txt--yl" v-if="dataDetail.transaction_detail.job_status == 0">Đang xử lý</p>
                        <p style="font-size: 16px;" class="txt--yl" v-else-if="dataDetail.transaction_detail.job_status == 1">Đã hoàn thành - chờ xác nhận</p>
                        <p style="font-size: 16px;" class="txt--green" v-else-if="dataDetail.transaction_detail.job_status == 2">Hoàn thành</p>
                        <p style="font-size: 16px;" class="txt--red" v-else-if="dataDetail.transaction_detail.job_status == 3">Không hoàn thành</p>
                        <p style="font-size: 16px;" class="txt--red" v-else-if="dataDetail.transaction_detail.job_status == 4">Quá hạn</p>
                    </div>
                    <p>
                        <img src="@/assets/icons/clock.png" alt="">
                        {{ $moment(dataDetail.transaction_detail.start_date).format('HH[h]mm') }} - {{ $moment(dataDetail.transaction_detail.end_date).format('HH[h]mm') }}
                    </p>
                    <p>
                        <img src="@/assets/icons/task.png" alt="">
                        {{ dataDetail.transaction_detail.active_name }}
                    </p>
                    <p>
                        <img src="@/assets/icons/user.png" alt="">
                        {{ dataDetail.transaction_detail.full_name }}
                    </p>
                    <p v-if="dataDetail.transaction_detail.customer_name">
                        <img src="@/assets/icons/group.png" alt="">
                        {{ dataDetail.transaction_detail.customer_name }}
                    </p>
                    <p>
                        <img src="@/assets/icons/address.png" alt="">
                        {{ dataDetail.transaction_detail.job_address }}
                    </p>
                    <div class="d-flex">
                        <p class="txt--red" style="margin-right: 15px;" v-if="dataDetail.transaction_detail.reminder_status == 1">
                            <img src="@/assets/icons/alarm.png" alt="">
                            {{ $moment.utc(dataDetail.transaction_detail.reminder_time).format('HH[h]mm') }}
                        </p>
                        <p :class="{'txt--green' : dataDetail.transaction_detail.level == 1,
                            'txt--green' : dataDetail.transaction_detail.level == 2,
                            'txt--yl' : dataDetail.transaction_detail.level == 3,
                            'txt--red' : dataDetail.transaction_detail.level == 4
                        }">
                            <img src="@/assets/icons/bookmark_ico.png" alt="">
                            <span v-if="dataDetail.transaction_detail.level == 1">Không quan trọng</span>
                            <span v-else-if="dataDetail.transaction_detail.level == 2">Bình thường</span>
                            <span v-else-if="dataDetail.transaction_detail.level == 3">Quan trọng</span>
                            <span v-else-if="dataDetail.transaction_detail.level == 4">Rất quan trọng</span>
                        </p>
                    </div>
                </router-link>
                <p style="font-size: 15px; margin-bottom: 5px;">File đính kèm:</p>
                <ul>
                    <li v-for="(item, index) in dataDetail.file" :key="index">
                        <Fancybox>
                            <a v-if="item" data-fancybox="gallery" :href="$storage + item.file_path">
                                <img src="@/assets/icons/Note.png" alt="">
                                {{ item.file_path.substring(item.file_path.lastIndexOf('-') + 1) }}
                            </a>
                        </Fancybox>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Fancybox from '../../components/fancy.vue'
export default {
    components: {
        Fancybox
    },
    data() {
        return {
            dataDetail: {
                transaction_detail: [],
                file: [],
            },
            money_pay: 0
        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/finance/getDetailJobTransaction',{
                params: {
                    id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                this.money_pay = parseInt(this.dataDetail.transaction_detail.commission) - parseInt(this.dataDetail.transaction_detail.deposits)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-content a.rout img {
        rotate: 180deg;
        margin-right: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
    }

    p.ttl {
        color: #515151;
        font-size: 16px;
        font-weight: 600;
        line-height: 180%;
        margin: 24px 0 0 0;
    }

    .wp-bds {
        border-radius: 20px;
        background: #F6F6F6;
        padding: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        text-decoration: none;
    }

    .wp-bds img {
        width: 70px;
        height: 70px;
        border-radius: 15px;
        object-fit: cover;
        margin-right: 10px;
    }

    .wp-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        min-height: 70px;
    }

    .wp-txt p {
        margin-bottom: 0;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        width: 100%;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .wp-money {
        border-radius: 15px;
        background:#F6F6F6;
        padding: 10px 16px;
        margin-bottom: 10px;
    }

    .wp-money p {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;
    }

    .wp-money p:last-child {
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 0;
    }

    .txt--red {
        color: #F13A3A !important;
    }

    .txt--green {
        color: #00B569 !important;
    }

    .txt--yl {
        color: #F58E16 !important;
    }

    .price-txt {
        color: #515151;
        font-size: 14px;
        line-height: 180%;
        margin: 0 0 10px 15px;
    }

    .price-txt span {
        font-size: 15px;
        font-weight: 500;
    }

    .wp-job {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 10px 15px;
        margin-bottom: 10px;
        display: block;
        text-decoration: none;
    }

    .wp-job p {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    .wp-job p img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul li {
        border-radius: 20px;
        background: #FCE3C5;
        padding: 7.5px 15px;
        margin-bottom: 10px;
    }

    ul li:last-child {
        margin-bottom: 0;
    }

    ul li a {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        display: block;
        overflow: hidden;
    }

    ul li a img {
        margin-right: 11px;
    }
</style>