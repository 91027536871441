<template>
    <div class="wp-ck">
        <ckeditor :editor="editor" v-model="data" :config="editorConfig" @input="handleEditorChange"></ckeditor>
    </div>
</template>
<script>
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { UploadAdapter } from '@ckeditor/ckeditor5-adapter-ckfinder';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Autosave } from '@ckeditor/ckeditor5-autosave';
import {
	Bold,
	Code,
	Italic,
	Strikethrough,
	Subscript,
	Superscript,
	Underline
} from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CloudServices } from '@ckeditor/ckeditor5-cloud-services';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace';
import { FontBackgroundColor, FontColor, FontFamily, FontSize } from '@ckeditor/ckeditor5-font';
import { Heading, Title } from '@ckeditor/ckeditor5-heading';
import { Highlight } from '@ckeditor/ckeditor5-highlight';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed';
import {
	DataFilter,
	DataSchema,
	GeneralHtmlSupport,
	HtmlComment
} from '@ckeditor/ckeditor5-html-support';
import {
	AutoImage,
	Image,
	ImageCaption,
	ImageInsert,
	ImageResize,
	ImageStyle,
	ImageToolbar,
	ImageUpload
} from '@ckeditor/ckeditor5-image';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { TextPartLanguage } from '@ckeditor/ckeditor5-language';
import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { List, ListProperties, TodoList } from '@ckeditor/ckeditor5-list';
import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Mention } from '@ckeditor/ckeditor5-mention';
import { PageBreak } from '@ckeditor/ckeditor5-page-break';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { StandardEditingMode } from '@ckeditor/ckeditor5-restricted-editing';
import { SelectAll } from '@ckeditor/ckeditor5-select-all';
import { ShowBlocks } from '@ckeditor/ckeditor5-show-blocks';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import {
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText
} from '@ckeditor/ckeditor5-special-characters';
import { Style } from '@ckeditor/ckeditor5-style';
import {
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar
} from '@ckeditor/ckeditor5-table';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';
import { WordCount } from '@ckeditor/ckeditor5-word-count';
import axios from 'axios'
export default {
    props: ["editorData"],
    data() {
        function uploader(editor) {
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return uploadImage( loader );
            };
        }

        function uploadImage(loader) {
            return {
                upload: () => {
                    return new Promise((resolve, reject) => {
                        loader.file.then(async (file) => {
                            try {
                                if(!file) return

                                var data = {
                                    'file': file,
                                    'path': 'asset/image/'
                                }

                                await axios.post('https://api.rakhoi.vn/api/common/uploadFile', data, {
                                    headers: {
                                        'x-access-token': localStorage.getItem('ad_tk'),
                                        'Content-Type': 'multipart/form-data'
                                    } 
                                }).then(res => {
                                    const url = 'https://api.rakhoi.vn/storage/' + res.data.list_result[0].path_tam
                                    resolve({default: url})
                                })
                            } catch (error) {
                                reject(error)
                            }
                        })
                    })
                }
            }
        }
        return {
            data: '',
            editor: ClassicEditor,
            editorConfig: {
                extraPlugins: [uploader],
                plugins: [
                    Alignment,
                    AutoImage,
                    AutoLink,
                    Autoformat,
                    Autosave,
                    BlockQuote,
                    Bold,
                    CloudServices,
                    Code,
                    CodeBlock,
                    DataFilter,
                    DataSchema,
                    FontSize,
                    Essentials,
                    FindAndReplace,
                    FontBackgroundColor,
                    FontColor,
                    FontFamily,
                    GeneralHtmlSupport,
                    Heading,
                    Highlight,
                    HorizontalLine,
                    HtmlComment,
                    HtmlEmbed,
                    Image,
                    ImageCaption,
                    ImageInsert,
                    ImageResize,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    LinkImage,
                    List,
                    ListProperties,
                    // Markdown,
                    MediaEmbed,
                    // MediaEmbedToolbar,
                    Mention,
                    PageBreak,
                    Paragraph,
                    PasteFromOffice,
                    RemoveFormat,
                    SelectAll,
                    ShowBlocks,
                    SourceEditing,
                    SpecialCharacters,
                    SpecialCharactersArrows,
                    SpecialCharactersCurrency,
                    SpecialCharactersEssentials,
                    SpecialCharactersLatin,
                    SpecialCharactersMathematical,
                    SpecialCharactersText,
                    StandardEditingMode,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TableToolbar,
                    TextPartLanguage,
                    TextTransformation,
                    // Title,
                    TodoList,
                    Underline,
                    UploadAdapter,
                    WordCount
                ],

                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontBackgroundColor',
                        'fontColor',
                        'fontFamily',
                        'fontSize',
                        // 'style',
                        // 'textPartLanguage',
                        '|',
                        'selectAll',
                        'findAndReplace',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'todoList',
                        '|',
                        'subscript',
                        'superscript',
                        'underline',
                        // '|',
                        'outdent',
                        'indent',
                        'horizontalLine',
                        '|',
                        'imageInsert',
                        // 'imageUpload',
                        'mediaEmbed',
                        '|',
                        'alignment',
                        'blockQuote',
                        'insertTable',
                        'undo',
                        'redo',
                        'codeBlock',
                        'code',
                        'highlight',
                        'htmlEmbed',
                        'pageBreak',
                        'removeFormat',
                        'showBlocks',
                        'sourceEditing',
                        'restrictedEditingException',
                        'specialCharacters',
                        'strikethrough'
                    ],
                    shouldNotGroupWhenFull: true
                },
                language: 'vi',
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        'toggleImageCaption',
                        'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        'linkImage'
                    ]
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
            }
        }
    },
    watch: {
        editorData() {
            this.data = this.editorData;
        },
    },
    mounted() {
        this.data = this.editorData
    },
    methods: {
        handleEditorChange() {
            this.$emit("getData", this.data);
        }
    }
}
</script>
<style>
    .wp-ck .ck-content {
        min-height: 600px;
        max-height: 700px;
        overflow: auto;
    }
</style>