<template>
    <div :class="{'admin-layout' : !$route.path.includes('/cong-viec/')}">
        <el-button type="primary" class="side--mb" @click="drawer = true" v-if="$route.path.includes('/cong-viec/')">
            >>
        </el-button>
        <el-container>
            <div v-if="$route.path.includes('/cong-viec/')">
                <el-drawer v-model="drawer" direction="ltr" :with-header="false">
                    <el-aside width="277px"><Sidebar></Sidebar></el-aside>
                </el-drawer>
                <el-aside class="side--pc" width="277px" style="height: 100vh; position: sticky; top: 0;"><Sidebar></Sidebar></el-aside>
            </div>
            <el-aside width="277px" style="height: 100vh; position: sticky; top: 0;" v-else><Sidebar></Sidebar></el-aside>
            <el-container style="background: #f4f4f4; height: 100vh;">
                <el-header style="margin-top: 25px;"><Header></Header></el-header>
                <el-main><router-view></router-view></el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Sidebar from '../pages/components/sidebar.vue'
import Header from '../pages/components/header.vue'
export default {
    components: {
        Sidebar,
        Header
    },
    data() {
        return {
            drawer: false
        }
    },
    name: "App",
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || "Admin";
                this.drawer = false
            }
        },
    },
};
</script>

<style>
    .admin-layout {
        min-width: 1380px;
    }

    body {
        overflow-x: hidden;
        font-family: Roboto !important;
    }

    button {
        background: none;
        outline: none;
        border: none;
    }

    .el-drawer {
        width: unset !important;
    }

    .el-drawer__body {
        padding: 0 !important;
    }

    .side--pc {
        display: none;
    }

    .el-main::-webkit-scrollbar {
        width: 5px;
    }

    .el-main::-webkit-scrollbar-thumb {
        background: #F58E16;
        border-radius: 20px;
    }

    button.side--mb {
        position: fixed;
        top: 50%;
        z-index: 99;
        margin-left: 5px;
    }

    @media (min-width: 1280px) {
        .side--pc {
            display: block;
        }

        .side--mb {
            display: none !important;
        }
    }
</style>