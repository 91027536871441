<template>
    <div class="wp-add-cate">
        <h5>Chỉnh sửa danh mục</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh mục bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chỉnh sửa danh mục</span>
        </div>
        <div class="text-end">
            <button class="btn_cancel" @click="$router.go(-1)"><img src="@/assets/icons/cross.png" alt=""> Hủy bỏ</button>
            <button class="btn_add" @click="submitForm('formDetail')"><img src="@/assets/icons/plus.png" alt=""> Lưu chỉnh sửa</button>
        </div>
        <el-row style="margin-top: 18px;">
            <el-col :span="10">
                <div class="basic_info">
                    <h5>Thông tin cơ bản</h5>
                    <p>Chọn loại</p>
                    <div class="wp-btn d-flex align-items-center">
                        <button :class="{'active' : formDetail.type == ''}" @click="activeBtn('')">Tất cả</button>
                        <button :class="{'active' : formDetail.type == 1}" @click="activeBtn('1')">Rao bán</button>
                        <button :class="{'active' : formDetail.type == 2}" @click="activeBtn('2')">Cho thuê</button>
                    </div>
                    <el-form
                        ref="formDetail"
                        :model="formDetail"
                        :rules="rules"
                        label-width="120px"
                        class="demo-ruleForm"
                        status-icon
                    >
                        <label for="name">Tên danh mục</label>
                        <el-form-item prop="category_name">
                            <el-input id="name" type="text" v-model="formDetail.category_name" placeholder="Nhập tên danh mục"/>
                        </el-form-item>
                        <!-- <p>Icon</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile">
                        <div class="ico_upload" @click="$refs.inputFile.click()">
                            <div v-if="!iconUpload">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else :src="$storage + iconUpload" alt="" style="max-height: 55px; max-width: 55px;" @error="handleError">
                        </div>
                        <span v-if="!iconUpload && showErr" class="error">Vui lòng thêm icon danh mục</span> -->
                    </el-form>
                </div>

                <div class="required_info">
                    <h5>Thông tin pháp lý yêu cầu</h5>
                    <ul class="list_required">
                        <li v-for="(item, index) in list_file_required" :key="index">{{ item.name }}</li>
                    </ul>
                    <button @click="addRequired"><img src="@/assets/icons/plus.png" alt=""> Thêm yêu cầu pháp lý</button>
                    <span v-if="!list_file_required && showErr" class="error">Vui lòng thêm icon danh mục</span>
                </div>
            </el-col>
            <el-col :span="14">
                <div class="describe">
                    <h5>Mô tả bổ sung</h5>
                    <p>Chọn các trường yêu cầu cho danh mục</p>
                    <span v-if="formDetail.describe.length == 0 && showErr" class="error">Vui lòng thêm mô tả bổ sung</span>
                    <ul>
                        <li v-for="(item, index) in list_des" :key="index" @click="addItem(item.id)" :class="{'active' : formDetail.describe.includes(item.id)}">
                            <img :src="$storage + item.icon" alt="" @error="handleError" style="max-width: 32px; max-height: 32px;">
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </el-col>
        </el-row>
        <listRequired 
            :dialogVisible="dialogVisible"
            :list_juridical="all_juridical"
            @handleCloseDialog="listenDialog"
            @confirm="getDataDialog"
        />
    </div>
</template>

<script>
import listRequired from '../modal/list_required.vue'
export default {
    components: {
        listRequired
    },
    data() {
        return {
            formDetail: {
                category_name: '',
                icon: '',
                type: '',
                juridical: [],
                describe: []
            },
            list_des: [],
            rules: {
                category_name: [{required: true, message: "Vui lòng nhập tên danh mục", trigger: ["blur", "change"]}],
                // icon: [{required: true, message: "Vui lòng thêm icon danh mục", trigger: ["blur", "change"]}],
            },
            dialogVisible: false,
            list_file_required: [],
            showErr: false,
            list_describe: [],
            list_juridical: [],
            all_juridical: [],
            iconUpload: ''
        }
    },
    mounted() {
        this.getListDescribe()
        this.getDetail()
    },
    methods: {
        activeBtn(btn) {
            this.formDetail.type = btn
        },
        setPage(val) {
            this.page.currentPage = val;
        },
        addItem(key) {
            const index = this.formDetail.describe.indexOf(key);
            if (index === -1) {
                this.formDetail.describe.push(key);
            } else {
                this.formDetail.describe.splice(index, 1);
            }
        },
        addRequired() {
            this.dialogVisible = true
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        getDataDialog(data) {
            this.list_file_required = []
            if(data.length !== 0) {
                data.forEach(el => {
                    const index = this.formDetail.juridical.indexOf(el.id);
                    if (index === -1) {
                        this.formDetail.juridical.push(el.id);
                    } else {
                        this.formDetail.juridical.splice(el.id, 1);
                    }
                    let item = {
                        id: el.id,
                        name: el.name,
                    };
                    this.list_file_required.push(item)
                });
            }else {
                this.list_file_required = this.formDetail.juridical = []
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if(this.formDetail.describe.length == 0 || !this.list_file_required) {
                        this.showErr = true
                        this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                    } else {
                        this.startLoading();
                        const res = await this.$axios.put('/real_estate/updateCategory',this.formDetail,{
                            headers: {
                                'x-access-token': this.getToken()
                            }
                        }).then(res => {
                            this.showNotify("success", "Thành công", "Chỉnh sửa thành công!");
                            this.$router.push('/quan-ly-bds/danh-muc')
                        }).catch(err => {
                            if(err.response) {
                                if(err.response.status === 401) {
                                    this.handleCheckTokenAuth();
                                } else {
                                    this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                                }
                            }
                        })
                        this.endLoading();
                    }
                } else {
                    return false;
                }
            });
        },
        async getListDescribe() {
            this.startLoading();
            await this.$axios.get('/real_estate/getListDescribe',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_des = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        // async changeFile(event) {
        //     let dataUpload = await this.uploadFile('category/icon/', event.target.files[0])
        //     this.formDetail.icon = dataUpload.path
        //     this.iconUpload = dataUpload.path_tam
        // },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/real_estate/getCategoryDetail?category_id=' + this.decode(this.$route.params.id),{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.formDetail = res.data
                document.title = `Chỉnh sửa danh mục - ${this.formDetail.category_name}`;
                this.iconUpload = res.data.icon
                res.data.describe.forEach(element => {
                    this.list_describe.push(element.describe_id)
                });
                res.data.juridical.forEach(element => {
                    this.list_juridical.push(element.juridical_id)
                    let item = {
                        id: element.juridical_id,
                        name: element.juridical_name
                    };
                    let itemProp = {
                        id: element.juridical_id,
                        name: element.juridical_name,
                        status: 1,
                        is_delete: 0
                    };
                    this.list_file_required.push(item)
                    this.all_juridical.push(itemProp)
                });
                this.formDetail.describe = this.list_describe
                this.formDetail.juridical = this.list_juridical
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn {
        width: 320px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        margin: 15px 0 18px;
    }

    .wp-btn button {
        width: 33%;
        font-size: 15px;
        font-weight: 600;
        color: #515151;
        height: 32px;
    }

    .wp-btn button.active {
        border-radius: 13px;
        background: rgba(245, 142, 22, 0.25);
        color: #F58E16;
    }

    .btn_cancel, .btn_add {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        line-height: 18px;
        padding: 10px 14px;
        border-radius: 15px;
    }

    .btn_cancel {
        border: 1px solid #515151;
        margin-right: 16px;
    }

    .btn_cancel img {
        filter: brightness(0.5);
        margin-right: 4px;
    }

    .btn_add {
        color: #FFF;
        background: #F58E16;
        line-height: 20px;
    }

    .btn_add img {
        margin-right: 4px;
        filter: brightness(10);
    }

    .basic_info, .required_info, .describe {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .describe {
        height: 100%;
        margin-left: 20px;
    }

    .basic_info h5, .required_info h5, .describe h5 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .basic_info label, .basic_info p {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .required_info {
        margin-top: 26px;
        min-height: 179px;
    }

    .required_info button {
        font-family: 'Public Sans', sans-serif;
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 14px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        line-height: 18px;
        margin-top: 15px;
    }

    .required_info button img {
        margin-right: 5px;
    }

    .describe p {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .describe ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;
    }

    .describe ul li {
        color: #A1A1A1;
        font-size: 15px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid #515151;
        min-width: 26%;
        height: 40px;
        padding-left: 32px;
        margin-bottom: 25px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .describe ul li.active {
        background: #FCE3C5;
        color: #515151;
        border: none;
    }

    .describe ul li img {
        margin-right: 20px;
    }

    .el-icon--upload {
        border-radius: 8px;
        background: #F58E16;
        padding: 8px;
        width: 44px;
        height: 44px;
        margin-bottom: 0 !important;
    }

    .el-upload__text {
        margin: 16px 0;
    }

    .add_img {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        border-radius: 8px;
        background: #FFF;
        padding: 8px;
    }

    .list_required {
        list-style: none;
        padding: 0;
    }

    .list_required li {
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 14px;
        border-radius: 15px;
        background: #FCE3C5;
        margin-bottom: 10px;
        width: max-content;
    }

    .ico_upload {
        border-radius: 20px;
        border: 1px dashed var(--neutral-gray-gray-100, #E0E2E7);
        background: #EBEBEB;
        padding: 20px 10px;
        box-shadow: none;
        text-align: center;
        cursor: pointer;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }
</style>
<style>
    .wp-add-cate .el-form-item__content > div {
        width: 100%;
    }
</style>