<template>
    <div class="wp-detail">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :span="14">
                <h5>Chi tiết thiết lập thu nhập</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý tài chính</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Thiết lập lương thưởng</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết thu nhập</span>
                </div>
            </el-col>
            <el-col :span="10" class="text-end">
                <button class="btn_del">Xóa thiết lập</button>
                <button class="btn_confirm" @click="$router.push('/quan-ly-tai-chinh/thiet-lap-luong-thuong/chinh-sua-thu-nhap/' + $route.params.id)">Chỉnh sửa thiết lập</button>
            </el-col>
        </el-row>

        <div class="wp-content">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <router-link to="/quan-ly-tai-chinh/thiet-lap-luong-thuong/thiet-lap-thu-nhap" class="rout">
                        <img src="@/assets/icons/arrow.png" alt="">
                    </router-link>
                    <span class="ttl">Chi tiết thiết lập thu nhập</span>
                </div>
                <!-- <div class="wp-btn">
                    <button>Dán thiết lập</button>
                    <button>Sao chép thiết lập</button>
                </div> -->
            </div>
            <div class="d-flex align-items-center justify-content-between" style="margin: 20px 0;">
                <p >Số nhân viên được gán thiết lập: <span>{{ dataDetail.count_user }}</span></p>
                <router-link :to="'/quan-ly-tai-chinh/thiet-lap-luong-thuong/danh-sach-nhan-vien/' + encode(dataDetail.id)">
                    <button class="btn_confirm">Danh sách nhân viên >></button>
                </router-link>
            </div>
            <h6>{{ dataDetail.name }}</h6>
            <p>Số ngày công trong tháng: <span>{{ dataDetail.working_day }}</span></p>
            <p class="title">Các khoản thu nhập</p>
            <div class="salary-item">
                <p class="ttl">Lương cứng</p>
                <div v-if="dataDetail.base_salary && dataDetail.base_salary.type == 1">
                    <p>Tính lương theo: <span>KPI Doanh số</span></p>
                    <p>Mức lương cơ bản: <span class="txt--red">{{ parseInt(dataDetail.base_salary.base_salary).toLocaleString('vi') }}</span> <span>VNĐ</span></p>
                    <p>Số phần trăm tối thiểu hưởng lương: <span class="txt--red">{{ dataDetail.base_salary.min_percent_KPI }}%</span> <span>KPI Doanh số</span></p>
                </div>
                <div v-else-if="dataDetail.base_salary && dataDetail.base_salary.type == 2">
                    <p>Tính lương theo: <span>Mức doanh số</span></p>
                    <div class="item-input" v-for="(item, index) in dataDetail.base_salary.moc_doanh_so" :key="index">
                        <el-form-item label="Doanh số đạt" style="flex-basis: 48%;">
                            <el-input type="text" placeholder="Nhập doanh số" v-model="item.sales" disabled/>
                            <span class="tag">VNĐ</span>
                        </el-form-item>
                        <el-form-item label="Mức thưởng" style="flex-basis: 48%;">
                            <el-input type="text" placeholder="Nhập mức thưởng" v-model="item.bonus" disabled/>
                            <span class="tag">VNĐ</span>
                        </el-form-item>
                    </div>
                </div>
                <div v-else-if="dataDetail.base_salary && dataDetail.base_salary.type == 3">
                    <p>Tính lương theo: <span>Tổng KPI</span></p>
                    <p>Mức lương cơ bản: <span class="txt--red">{{ parseInt(dataDetail.base_salary.base_salary).toLocaleString('vi') }}</span> <span>VNĐ</span></p>
                    <p>Số phần trăm tối thiểu hưởng lương: <span class="txt--red">{{ dataDetail.base_salary.min_percent_KPI }}%</span> <span>Tổng KPI</span></p>
                </div>
            </div>
            <div class="salary-item">
                <p class="ttl">Hoa hồng doanh số</p>
                <div v-if="dataDetail.commission && dataDetail.commission.type == 1">
                    <p>Tính hoa hồng theo: <span>Tổng doanh số</span></p>
                    <p>Số phần trăm hưởng hoa hồng: <span class="txt--red">{{ dataDetail.commission.commission_sales_person }}%</span> <span>Tổng doanh số team</span></p>
                    <p>Hoa hồng doanh số theo team: <span class="txt--red">{{ dataDetail.commission.commission_sales_team }}%</span> <span>Tổng doanh số team</span></p>
                </div>
                <div v-else-if="dataDetail.commission && dataDetail.commission.type == 2">
                    <p>Tính hoa hồng theo: <span>Giao dịch bất động sản</span></p>
                    <p>Số phần trăm hưởng hoa hồng: <span>Theo % hoa hồng được chia từng bất động sản</span></p>
                    <p>Hoa hồng doanh số theo team: <span class="txt--red">{{ dataDetail.commission.commission_sales_team }}%</span> <span>Tổng doanh số team</span></p>
                </div>
            </div>
            <div class="salary-item">
                <p class="ttl">Thưởng doanh số</p>
                <p>Thưởng đạt đủ KPI doanh số: <span class="txt--red"> {{ parseInt(dataDetail.bonus_sales).toLocaleString('vi') }}</span> <span>VNĐ</span></p>
                <p>Thưởng theo mức doanh số: <span>Thưởng theo các mốc doanh thu quy định</span></p>
                <p>Thưởng team đạt đủ KPI: <span class="txt--red">{{ parseInt(dataDetail.bonus_KPI).toLocaleString('vi') }}</span> <span>VNĐ</span></p>
            </div>
            <div class="salary-item">
                <p class="ttl">Phụ cấp</p>
                <div class="item_ext" v-for="(item, index) in dataDetail.allowance" :key="index">
                    <p class="name">Tiêu đề: <span>{{ item.title }}</span></p>
                    <p>Số công trong tháng: <span class="txt--red">{{ item.working_day }}</span></p>
                    <p class="price">Mức tiền: <span class="txt--red">{{ parseInt(item.amount).toLocaleString('vi') }}</span> <span>VNĐ / Ngày</span></p>
                </div>
            </div>
            <div class="salary-item">
                <p class="ttl">Phạt</p>
                <div class="item_ext" v-for="(item, index) in dataDetail.fine" :key="index">
                    <p class="name">Tiêu đề: <span>{{ item.title }}</span></p>
                    <p class="price">Mức tiền: <span class="txt--red">{{ parseInt(item.amount).toLocaleString('vi') }}</span> <span>VNĐ / Ngày</span></p>
                </div>
            </div>
            <p class="title">Các khoản khấu trừ cố định</p>
            <div class="wp-money wp-detail-settings">
                <el-form-item label="Đóng BHXH">
                    <el-radio v-model="dataDetail.social_insurance_status" disabled :label="1">Đóng BHXH</el-radio>
                    <el-radio v-model="dataDetail.social_insurance_status" disabled :label="2">Không đóng BHXH</el-radio>
                </el-form-item>
                <div v-if="dataDetail.social_insurance_status == 1">
                    <p>Mức đóng BHXH: <span class="txt--red">{{ parseInt(dataDetail.total_social_insurance).toLocaleString('vi') }}</span> <span>VNĐ</span></p>
                    <p>Số tiền đóng bảo hiểm của nhân viên: <span class="txt--red">{{ parseInt(dataDetail.amount_social_insurance).toLocaleString('vi') }}</span> <span>VNĐ</span></p>
                </div>

                <el-form-item label="Thuế TNCN" style="margin-top: 20px;">
                    <el-radio v-model="dataDetail.tax_status" disabled :label="1">Đóng thuế TNCN</el-radio>
                    <el-radio v-model="dataDetail.tax_status" disabled :label="2">Không đóng thuế TNCN</el-radio>
                </el-form-item>
                <div v-if="dataDetail.tax_status == 1 && dataDetail.social_insurance_status == 1">
                    <p>Thuế TNCN tính theo công thức tính ở phần<span class="txt--red"> Thuế Thu nhập cá nhân</span></p>
                </div>
                <div v-else-if="dataDetail.tax_status == 1 && dataDetail.social_insurance_status == 2">
                    <p>Mức đóng thuế TNCN: <span class="txt--red">{{ dataDetail.tax_amount }}%</span></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
           bhxh: 0,
           tncn: 0,
           dataDetail: []
        }
    },
    mounted() {
        this.getSalarySettingDetail()
    },
    methods: {
        async getSalarySettingDetail() {
            this.startLoading();
            await this.$axios.get('/finance/getSalarySettingDetail',{
                params: {
                    id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                if(res.data.base_salary.type == 2) {
                    for (let index = 0; index < this.dataDetail.base_salary.moc_doanh_so.length; index++) {
                        const element = this.dataDetail.base_salary.moc_doanh_so[index];
                        element.sales = parseInt(element.sales).toLocaleString('vi')
                        element.bonus = parseInt(element.bonus).toLocaleString('vi')
                    }
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    h6 {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
        /* margin: 0; */
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-content a.rout img {
        rotate: 180deg;
        margin-right: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
    }

    button.btn_confirm {
        color:#FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #F58E16;
        min-width: 200px;
        height: 40px;
    }

    button.btn_del {
        min-width: 200px;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #F13A3A;
        background-color: #FFF;
        border-radius: 15px;
        margin-right: 10px;
    }

    .wp-content p {
        font-size: 15px;
        color: #515151;
        margin-bottom: 0
    }

    .salary-item {
        border-bottom: 1px solid #DFE3E7;
        padding: 20px 0;
    }

    .salary-item p {
        margin-bottom: 10px;
    }

    .salary-item p.ttl, p span {
        font-weight: 500;
    }

    .salary-item p span {
        margin-left: 5px
    }

    .item_ext {
        border-radius: 20px;
        border: 1px solid #ABABAB;
        padding: 10px 20px;
        margin-bottom: 10px;
    }
    
    p.title {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        margin: 20px 0;
        text-align: center;
    }

    .wp-btn button {
        color:#FFF;
        font-size: 14px;
        font-weight: 500;
        width: 160px;
        height: 28px;
        border-radius: 15px;
        background:#F58E16;
    }
    
    .wp-btn button:first-child {
        color: #F58E16;
        background: #FFF;
        margin-right: 10px;
        border: 1px solid #F58E16;
    }

    .item-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    span.tag {
        font-weight: 500;
        position: absolute;
        right: 10px;
    }

    .txt--red {
        color: #F13A3A !important;
        font-weight: 500;
    }
</style>