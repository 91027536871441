<template>
    <div class="wp-personal">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Thông tin cá nhân của bạn</h5>
                <div class="breadcrumb">
                    <span class="active">Thông tin cá nhân</span>
                </div>
            </div>
            <div class="wp-button">
                <button class="reset-pass" v-if="!changePassPage" @click="changePassPage = true"> Đổi mật khẩu</button>
                <button class="reset-pass" v-else @click="changePassPage = false"> Thông tin cá nhân</button>
            </div>
        </div>

        <div class="wp-detail">
            <div class="detail" v-if="!changePassPage">
                <el-row class="justify-content-between">
                    <el-col :span="7" class="avatar text-center">
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="updateAvatar">
                        <img :src="$storage + dataDetail.avatar" alt="" class="avatar" @error="handleError" @click="$refs.inputFile.click()" v-if="dataDetail.avatar">
                        <p>Mã nhân viên:</p>
                        <p>{{ dataDetail.user_code }}</p>
                    </el-col>
                    <el-col :span="16" class="info">
                        <div class="d-flex justify-content-between">
                            <p>Tên nhân viên:</p>
                            <p>{{ dataDetail.full_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Giới tính:</p>
                            <p v-if="dataDetail.gender == 1">Nam</p>
                            <p v-else>Nữ</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ngày sinh:</p>
                            <p>{{ formateDate(dataDetail.birthday) }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Số điện thoại liên hệ:</p>
                            <p>{{ dataDetail.phone }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Email:</p>
                            <p>{{ dataDetail.email }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Địa chỉ:</p>
                            <p>{{ dataDetail.address }}</p>
                        </div>
                        <!-- <div class="d-flex justify-content-between">
                            <p>Mã giới thiệu:</p>
                            <p>{{ dataDetail.referral_code }}</p>
                        </div> -->
                        <div class="d-flex justify-content-between">
                            <p>Chức danh trong công ty:</p>
                            <p>{{ dataDetail.position }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Phân quyền chức năng:</p>
                            <p>{{ dataDetail.role_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Chi nhánh làm việc:</p>
                            <p>{{ dataDetail.agency_name }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <el-form
                class="change_pass"
                ref="changePass"
                :model="formChangePass"
                :rules="rules"
                @submit.prevent="changePass('changePass')"
                v-else
            >
                <h6 style="font-size: 20px; font-weight: 600; color: #515151; margin-bottom: 20px;">Đổi mật khẩu</h6>
                <div class="input-item">
                    <el-form-item prop="oldpassword" label="Mật khẩu cũ">
                        <el-input v-model.trim="formChangePass.oldpassword" type="password" ref="oldPass"/>
                        <img src="@/assets/icons/seyes.png" alt="" class="pass--ico" @click="showPass('oldPass', 'iconOldPass')" ref="iconOldPass">
                    </el-form-item>
                </div>
                <div class="input-item">
                    <el-form-item prop="newpassword" label="Mật khẩu mới" class="password">
                        <el-input v-model.trim="formChangePass.newpassword" type="password" ref="newPass"/>
                        <img src="@/assets/icons/seyes.png" alt="" class="pass--ico" @click="showPass('newPass', 'iconNewPass')" ref="iconNewPass">
                    </el-form-item>
                </div>
                <div class="input-item">
                    <el-form-item prop="re_newpass" label="Nhập lại mật khẩu" class="password">
                        <el-input v-model.trim="formChangePass.re_newpass" type="password" ref="rePass"/>
                        <img src="@/assets/icons/seyes.png" alt="" class="pass--ico" @click="showPass('rePass', 'iconRePass')" ref="iconRePass">
                    </el-form-item>
                </div>
                <span class="error" v-if="error">{{ error }}</span>
                <div class="text-end">
                    <button class="btn_save" type="button" @click="changePass('changePass')">Lưu mật khẩu mới</button>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        const matchPassword = (rule, value, callback) => {
            if (value === "") {
                callback(
                    new Error("Vui lòng nhập lại mật khẩu!")
                );
            } else if (value !== this.formChangePass.newpassword) {
                callback(new Error("Vui lòng nhập lại đúng mật khẩu!"));
            } else {
                callback();
            }
        };
        return {
            dataDetail: [],
            formChangePass: {
                oldpassword: '',
                newpassword: '',
                re_newpass: '',
            },
            rules: {
                oldpassword: [
                    {required: true, message: "Vui lòng nhập mật khẩu cũ!", trigger: ["blur", "change"]},
                ],
                newpassword: [{required: true, message: "Vui lòng nhập mật khẩu mới!", trigger: ["blur", "change"]}],
                re_newpass: [{validator: matchPassword,}],
            },
            error: '',
            changePassPage: false
        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        showPass(type, icon) {
            if(this.$refs[type].input.type == 'password') {
                this.$refs[type].input.type = 'text'
            } else {
                this.$refs[type].input.type = 'password'
            }

            if(this.$refs[icon].src == require('@/assets/icons/seyes.png')) {
                this.$refs[icon].src = require('@/assets/icons/eyes.png')
            } else {
                this.$refs[icon].src = require('@/assets/icons/seyes.png')
            }

        },
        changePass(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    await this.$axios.put('/auth/changePass', this.formChangePass,{
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Thay đổi mật khẩu thành công!");
                        this.error = ''
                        this.turnOffErrorMessage('changePass')
                    }).catch(err => {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        this.error = err.response.data.error
                    })
                    this.endLoading();
                } else {
                   return true
                }
            });
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/auth/getUserProfile',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                this.formAdd = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateAvatar() {
            let file = event.target.files[0]
            this.startLoading();
            await this.$axios.put('/auth/updateUserAvatar', {
                file: file,
                path: 'user/avatar/',
            },{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi avatar thành công!");
                location.reload()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Public Sans', sans-serif;
        padding: 10px 14px;
        height: 40px;
        border-radius: 15px;
    }

    .reset-pass {
        color: #F58E16;
        background-color: #fff;
        margin-right: 10px;
        width: 200px;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail, .change_pass {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .info>div p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
    }

    .info>div p:last-child {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 50%;
        text-align: right;
    }

    .img_show {
        width: 227px;
        height: 227px;
        border-radius: 113px;
        cursor: pointer;
        object-fit: cover;
    }

    img.avatar {
        margin-bottom: 20px;
        width: 227px;
        height: 227px;
        cursor: pointer;
        border-radius: 114px;
        object-fit: cover;
    }

    .input-item {
        position: relative;
    }

    .pass--ico {
        position: absolute;
        right: 15px;
        left: unset;
        cursor: pointer;
        z-index: 2;
        width: 20px;
        height: 20px;
    }

    .change_pass .el-form, .information .el-form {
        margin: 20px 0;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 16px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    .btn_save {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        margin-top: 20px;
        border-radius: 61px;
        background: #F58E16;
        padding: 9px 53px;
    }
</style>

<style>
    .change_pass .el-form-item, .information .el-form-item{
        display: block;
    }

    .change_pass .el-form-item__label, .information .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
    }

    .change_pass .el-input__wrapper, .information .el-input__wrapper {
        border-radius: 16px;
        background: #F6F6F6;
        padding: 7.5px 15px;
    }
</style>