<template>
    <div class="wp-form-kpi">
        <el-row>
            <el-col :xs="24" :sm="12">
                <h5>Tạo KPI</h5>
                <div class="breadcrumb">
                    <span>Quản lý công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Quản lý KPI</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Tạo KPI</span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12">
                <div class="wp-btn text-end">
                    <button @click="$router.go(-1)">Hủy bỏ</button>
                    <button @click="addKpi('ruleForm')">+ Add KPI</button>
                </div>
            </el-col>
        </el-row>
        <div class="wp-content">
            <h6 class="ttl">Tạo KPI cho nhân viên</h6>
            <p class="ttl">Cài đặt</p>
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                class="form-kpi"
                status-icon
            >
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <el-form-item prop="start_date" label="Ngày bắt đầu tính KPI" class="select_date">
                        <el-date-picker
                            v-model="ruleForm.start_date"
                            type="date"
                            placeholder="Chọn"
                            format="DD-MM-YYYY"
                            value-format="YYYY-MM-DD"
                            class="formInput"
                        />
                    </el-form-item>
                    <el-form-item prop="end_date" label="Ngày kết thúc KPI" class="select_date">
                        <el-date-picker
                            v-model="ruleForm.end_date"
                            type="date"
                            placeholder="Chọn"
                            format="DD-MM-YYYY"
                            value-format="YYYY-MM-DD"
                            class="formInput"
                        />
                    </el-form-item>
                </div>
                <p class="label">Chọn nhân viên được thêm KPI</p>
                <div class="dropdown">
                    <button :disabled="ruleForm.team_type == 0" class="btn btn-secondary dropdown-toggle" type="button" id="broker" data-bs-toggle="dropdown" aria-expanded="false" style="height: 100%; box-shadow: none; margin-bottom: 0">
                        Chọn
                    </button>
                    <ul class="dropdown-menu add_user" aria-labelledby="broker">
                        <h6>Chọn nhân viên phụ trách</h6>
                        <el-form
                            ref="formLogin"
                            style="margin-bottom: 20px;"
                        >
                            <el-form-item>
                                <el-input v-model="keySearch" type="text" placeholder="Search" />
                            </el-form-item>
                        </el-form>
                        <div class="wp-user">
                            <li v-for="(item, index) in filteredItems" :key="index" @click.stop="addItem(item)">
                                <a class="dropdown-item" href="#" style="justify-content: flex-start;" :class="{'active' : listUserSelected.includes(item)}" v-if="item.kpi_status == 0">
                                    <img :src="$storage + item.avatar" alt="" @error="handleError">
                                    <div class="d-flex align-items-center flex-wrap">
                                        <span>{{ item.full_name }}</span>
                                        <span style="margin: 0 8px;">-</span>
                                        <span>{{ item.user_code }}</span>
                                        <span v-if="item.group_name"> - {{ item.group_name }}</span>
                                        <span v-if="item.agency_name"> - {{ item.agency_name }}</span>
                                    </div>
                                </a>
                            </li>
                        </div>
                        <div class="text-end mt-2">
                            <button type="button" @click="listUserSelected = []">Hủy bỏ</button>
                            <button type="button">Xác nhận</button>
                        </div>
                    </ul>
                </div>
                <div class="wp-list-user">
                    <div class="user-item" v-for="(item, index) in listUserSelected" :key="index">
                        <img :src="$storage + item.avatar" alt="" @error="handleError">
                        <div class="user-info">
                            <p>{{ item.full_name }}</p>
                            <p>{{ item.position }}</p>
                        </div>
                        <button type="button" @click="listUserSelected.splice(index, 1);"><img src="@/assets/icons/cross.png" alt=""></button>
                    </div>
                </div>
                <p class="ttl ttl--space">Tạo trường tính KPI <span>100%</span></p>
                <div class="item-kpi" v-for="(input, index) in ruleForm.kpi">
                    <p class="label--ico">Chọn loại KPI <span style="cursor: pointer;" @click="removeKPI(index)"><img src="@/assets/icons/cross.png" alt=""></span></p>
                    <div class="wp-input">
                        <el-form-item prop="kpi.type">
                            <el-select clearable v-model="input.type" placeholder="Chọn loại KPI">
                                <el-option v-for="item in listKPI"
                                    :key="item.id"
                                    :label="item.txt"
                                    :value="item.id"
                                    :disabled="isOptionSelected(item.id, index)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="">
                            <el-input type="text" v-model="input.percent" maxlength="3" @input="input.percent = input.percent.replace(/[^\d]/g, '')"></el-input>
                            <span style="position: absolute; right: 10px; font-size: 15px; font-weight: 500;">%</span>
                        </el-form-item>
                    </div>
                    <el-form-item prop="" label="Mục tiêu" v-if="input.type && input.type == 1" style="padding-left: 30px;">
                        <el-input type="text" placeholder="Nhập số tiền" v-model="input.target" @input="formatInputPrice(input.target, index)" maxlength="15"></el-input>
                        <span style="position: absolute; right: 10px; font-size: 15px; font-weight: 500;">VNĐ</span>
                    </el-form-item>
                    <div class="wp-job" v-else-if="input.type && input.type != 1">
                        <div class="item-job" v-for="(item, indexJob) in ruleForm.kpi[index].job">
                            <div style="flex-basis: 93%">
                                <el-form-item prop="item.active_id" label="Chọn loại công việc KPI">
                                    <el-select clearable v-model="item.active_id" placeholder="Chọn loại công việc KPI" style="width: 100%;">
                                        <el-option v-for="item in listActive"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="item.target" label="Mục tiêu" style="margin-left: 15px;">
                                    <el-input-number placeholder="Nhập mục tiêu" v-model="item.target"></el-input-number>
                                </el-form-item>
                            </div>
                            <button type="button" @click="removeJob(index, indexJob)"><img src="@/assets/icons/cross.png" alt=""></button>
                        </div>
                        <button type="button" class="add-job" @click="addItemJob(index)">+ Thêm đầu việc KPI</button>
                    </div>
                </div>
                <button class="add-kpi" type="button" @click="addItemKpi">+ Thêm loại KPI</button>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ruleForm: {
                start_date: '',
                end_date: '',
                user: [],
                kpi: [],
                team_id: null,
                team_type: 0
            },
            rules: {
                start_date: [{required: true, message: "Vui lòng chọn ngày bắt đầu KPI", trigger: ["blur", "change"]}],
                end_date: [{required: true, message: "Vui lòng chọn ngày kết thúc KPI", trigger: ["blur", "change"]}],
            },
            listEmploy: [],
            keySearch: '',
            listKPI: [],
            listUserSelected: [],
            listActive: []
        }
    },
    computed: {
        filteredItems() {
            return this.listEmploy.filter(employ => employ.full_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    mounted() {
        this.getKPIType()
        this.getListActive()
        if(this.$route.params.teamId) {
            this.ruleForm.team_id = this.decode(this.$route.params.teamId)
        }
        if(this.$route.path.includes('tao-moi-kpi-tong')) {
            this.ruleForm.team_type = 0
        } else if(this.$route.path.includes('tao-moi-kpi-chi-nhanh')) {
            this.ruleForm.team_type = 2
        } else if(this.$route.path.includes('tao-moi-kpi-mang')) {
            this.ruleForm.team_type = 3
        } else if(this.$route.path.includes('tao-moi-kpi-team')) {
            this.ruleForm.team_type = 4
        } else if(this.$route.path.includes('tao-moi-kpi-nhan-vien')) {
            this.ruleForm.team_type = 5
        } else {
            this.ruleForm.team_type = 1
        }
        this.getEmploy()
    },
    methods: {
        async getEmploy() {
            this.startLoading();
            await this.$axios.get('/job/getListPersonForTeam',{
                params: {
                    type: this.ruleForm.team_type,
                    id: this.ruleForm.team_id
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listEmploy = res.data
                if(this.ruleForm.team_type == 5) {
                    this.listUserSelected.push(res.data[0])
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getKPIType() {
            await this.$axios.get('/job/getKPIType',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listKPI = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
        },
        async getListActive() {
            await this.$axios.get('/job/getListActive', {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listActive = res.data
            })
        },
        addItem(key) {
            event.preventDefault()
            const index = this.listUserSelected.indexOf(key);
            if (index === -1) {
                this.listUserSelected.push(key);
            } else {
                this.listUserSelected.splice(index, 1);
            }
        },
        addItemKpi() {
            if(this.ruleForm.kpi.length < this.listKPI.length) {
                this.ruleForm.kpi.push({
                    type: '',
                    percent: 0,
                    target: null,
                    job: [ {active_id: '',target: ''}]
                })
            } else {
                this.showNotify("warning", "Lưu ý", "Chỉ tạo được tối đa " + this.listKPI.length + " loại KPI!");
            }
        },
        removeKPI(index) {
            this.ruleForm.kpi.splice(index, 1)
        },
        isOptionSelected(option, currentIndex) {
            return this.ruleForm.kpi.some((item, index) => index !== currentIndex && item.type === option);
        },
        addItemJob(index) {
            this.ruleForm.kpi[index].job.push({
                active_id: '',
                target: ''
            })
        },
        removeJob(index, indexJob) {
            this.ruleForm.kpi[index].job.splice(indexJob, 1)
        },
        addKpi(formName) {
            //this.ruleForm.user = this.listUserSelected.map(item => item.id)
            this.ruleForm.user = this.listUserSelected
            this.ruleForm.kpi.forEach(kpi => {
                if(kpi.target) {
                    kpi.target = kpi.target.replace(/[^\d]/g, '')
                }
            });
            this.$refs[formName].validate(async (valid) => {
                if (valid && this.validateForm()) {
                    this.startLoading()
                    await this.$axios.post('/job/createKPI', this.ruleForm, {
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Tạo KPI thành công!");
                        this.$router.go(-1)
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            })
        },
        validateForm() {
            let totalPercent = 0
            this.ruleForm.kpi.forEach(item => {
                totalPercent += parseInt(item.percent)
            });
            if(this.ruleForm.start_date > this.ruleForm.end_date) {
                this.showNotify("warning", "Lưu ý", "Ngày bắt đầu không thể lớn hơn ngày kết thúc!");
                return false
            } else if(this.isEmptyCustom(this.ruleForm.user) && this.ruleForm.team_type != 0) {
                this.showNotify("warning", "Lưu ý", "Vui lòng chọn nhân viên được thêm KPI");
                return false
            } else if (this.isEmptyCustom(this.ruleForm.kpi)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng thêm KPI");
                return false
            } else if(totalPercent != 100 && !this.isEmptyCustom(this.ruleForm.kpi)) {
                this.showNotify("warning", "Lưu ý", "Tổng các KPI phải bằng 100%");
                return false
            }
            for (let i = 0; i < this.ruleForm.kpi.length; i++) {
                if(this.isEmptyCustom(this.ruleForm.kpi[i].percent)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập % KPI");
                    return false
                } else if(this.isEmptyCustom(this.ruleForm.kpi[i].type)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng chọn loại KPI");
                    return false
                } else if(this.ruleForm.kpi[i].type == 1 && this.isEmptyCustom(this.ruleForm.kpi[i].target)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập mục tiêu cho KPI");
                    return false
                } else if(this.ruleForm.kpi[i].type != 1) {
                    this.ruleForm.kpi[i].job.forEach(job => {
                        if(this.isEmptyCustom(job.active_id)) {
                            this.showNotify("warning", "Lưu ý", "Vui lòng chọn công việc!");
                            return false
                        } else if(this.isEmptyCustom(job.target)) {
                            this.showNotify("warning", "Lưu ý", "Vui lòng nhập mục tiêu công việc!");
                            return false
                        }
                    });
                }
            }
            return true
        },
        formatInputPrice(value, index) {
            const cleanedValue = value.replace(/[^\d]/g, '');
            const number = parseInt(cleanedValue, 10);
            this.ruleForm.kpi[index].target = number.toLocaleString('vi');
            if(this.ruleForm.kpi[index].target == 'NaN') {
                this.ruleForm.kpi[index].target = ''
            }
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin: 15px auto 0;
    }

    .wp-btn button {
        border-radius: 15px;
        background: #F58E16;
        color: #FFF;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        width: 48%;
        max-width: 193px;
        height: 40px;
    }

    .wp-btn button:first-child {
        border: 1px solid #EE3439;
        color: #EE3439;
        margin-right: 10px;
        background-color: transparent;
    }

    h6.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 25px;
    }

    p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 15px;
    }

    p.ttl--space {
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;
    }

    p.label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 5px;
    }

    .dropdown {
        margin-bottom: 20px;
    }

    .dropdown button.btn {
        border-radius: 15px;
        background: #F6F6F6 !important;
        color: #B4B4B4 !important;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        width: 100%;
        text-align: left;
        padding: 6.5px 20px;
    }

    .dropdown button.btn::after {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    ul.add_user {
        width: 100%;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px
    }

    ul.add_user h6 {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 20px;
    }

    .add_user img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        object-fit: cover;
        margin-right: 8px
    }

    .add_user button {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        width: 48%;
        max-width: 200px;
        height: 38px
    }

    .add_user button:first-child {
        color: #F58E16;
        background: transparent;
        border: 1px solid #F58E16;
        margin-right: 10px;
    }

    .wp-user {
        max-height: 400px;
        overflow: auto;
    }

    .wp-user::-webkit-scrollbar {
        width: 0;
    }

    .wp-user li .dropdown-item.active {
        background: #F58E16;
    }

    .wp-user li .dropdown-item.active span {
        color: #FFF;
    }

    .wp-user li .dropdown-item {
        display: flex;
        align-items: center;
        border-radius: 20px;
        background: #F3F3F3;
        margin-bottom: 10px;
        padding: 8px 20px;
    }

    .wp-user li .dropdown-item span {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-list-user {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    .user-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 55px;
        border: 1px solid #B4B4B4;
        padding: 4px 5px;
        margin: 15px 10px 0 0;
        margin-bottom: 10px;
    }

    .wp-list-user > .user-item:nth-child(3n) {
        margin-right: 0;
    }

    .wp-list-user > .user-item:nth-child(-n+3) {
        margin-top: 0;
    }

    .user-item img {
        width: 50px;
        height: 50px;
        border-radius: 30px;
        object-fit: cover;
        margin-right: 5px;
    }

    .user-info {
        flex-basis: 65%;
    }

    .user-info p {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 23.821px;
        letter-spacing: 0.15px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .user-info p:last-child {
        font-size: 13px;
        letter-spacing: 0.13px;
    }

    .user-item button img {
        width: 15px;
        height: 15px;
    }

    p.label--ico {
        position: relative;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 10px;
        padding-left: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    p.label--ico::before {
        content: '•';
        position: absolute;
        left: 0;
        font-size: 30px;
    }

    .wp-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 30px;
    }

    .wp-input .el-form-item {
        flex-basis: 20%;
    }

    .wp-input .el-form-item:first-child {
        flex-basis: 80%;
    }

    .wp-job {
        padding-left: 30px;
    }

    .add-kpi {
        border-radius: 15px;
        background: #F58E16;
        color: #FFF;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        padding: 10px 45px;
    }

    .item-job {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .item-job > div {
        border-radius: 20px;
        border: 1px solid #B4B4B4;
        padding: 20px 20px 0;
    }

    .add-job {
        color: #EE3439;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
        text-align: end;
        width: 100%;
        margin-bottom: 15px;
    }

    .select_date {
        flex-basis: 100%;
    }

    @media (min-width: 992px) {
        .user-item {
            width: calc(1/3*100% - (1 - 1/3)*10px);  
        }
    }
    @media (min-width: 575px) {
        .select_date {
            flex-basis: 49%;
        }

        .wp-input .el-form-item {
            flex-basis: 10%;
        }

        .wp-input .el-form-item:first-child {
            flex-basis: 90%;
        }
    }
</style>

<style>
    .wp-form-kpi .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
    }

    .wp-form-kpi ul.add_user .el-input__wrapper {
        border-radius: 15px;
        padding: 5px 12px;
        background: #FFF;
    }

    .wp-form-kpi .el-select {
        width: 95%;
    }

    .wp-form-kpi .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 12px;
    }

    .wp-form-kpi .el-input-number .el-input-number__decrease,
    .wp-form-kpi .el-input-number .el-input-number__increase {
        display: none;
    }

    .wp-form-kpi .el-input-number {
        width: 100%;
    }

    .wp-form-kpi .el-input-number .el-input__wrapper {
        padding-left: 10px;
    }

    .wp-form-kpi .el-input-number .el-input__inner {
        text-align: left;
    }
</style>