import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index";
import axios from 'axios';
import store from './store';
import fireBase from './firebase'; 
import common from './common';
import ElementPlus from 'element-plus'
import locale from '../node_modules/element-plus/lib/locale/lang/vi'
import 'element-plus/dist/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/ckeditor.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import CKEditor from '@ckeditor/ckeditor5-vue';
import moment from 'moment'
import "qalendar/dist/style.css";

moment.locale('vi')

const app = createApp(App);
app.config.globalProperties.$axios = axios;
axios.defaults.baseURL = "https://api.rakhoi.vn/api";
app.config.globalProperties.$storage = "https://api.rakhoi.vn/storage/";
app.config.globalProperties.$API = 'https://api.rakhoi.vn/api'

// Server DEV
// axios.defaults.baseURL = "http://192.168.1.12:3000/api";
// app.config.globalProperties.$API = 'http://192.168.1.12:3000/api';
// app.config.globalProperties.$storage = "http://192.168.1.12:3000/storage/";

app.config.globalProperties.$notification = fireBase;
app.config.globalProperties.$moment = moment;
router.afterEach((to) => {
    const metaTag = document.querySelector('meta[name="viewport"]');
    if (to.path.includes('/cong-viec/')) {
        if (!metaTag) {
            const meta = document.createElement('meta');
            meta.name = 'viewport';
            meta.content = 'width=device-width, initial-scale=1, user-scalable=no';
            document.head.appendChild(meta);
        }
    } else {
        if (metaTag) {
            metaTag.remove();
        }
    }
});
app.use(router);
app.use(store);
app.mixin(common);
app.use(ElementPlus, {locale})
app.use(CKEditor )
app.mount('#app')
