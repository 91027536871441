<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="428"
        class="dialog_reject dialog_withdraw"
        @close="emitDialogVisible(false)"
        align-center
    >
        <img src="@/assets/icons/notice.png" alt="">
        <p class="ttl">Yêu cầu rút tiền</p>
        <p>Vì lý do bảo mật, hãy nhập mật khẩu tài khoản nhân viên của bạn để gửi yêu cầu rút tiền đến Admin</p>
        <el-form class="form-withdraw" :rules="rules" :model="ruleForm" ref="ruleForm">
            <el-form-item prop="password" label="Mật khẩu">
                <img src="@/assets/icons/pass_ico.png" alt="" class="icon">
                <el-input :type="showPass ? 'text' : 'password'" v-model="ruleForm.password" placeholder="Nhập mật khẩu"></el-input>
                <img :src="showPass ? path_see : path_img" alt="" class="icon icon--last" @click="showPass = !showPass">
            </el-form-item>
        </el-form>
        <div class="wp-btn" style="margin-top: 20px;">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm('ruleForm')">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['dialogStatus'],
    data() {
        return {
            dialogVisibleStatus: false,
            ruleForm: {
                password: ''
            },
            rules: {
                password: [{required: true, message: "Vui lòng nhập mật khẩu", trigger: ["blur", "change"]}],
            },
            path_img: require('@/assets/icons/seyes.png'),
            path_see: require('@/assets/icons/eyes.png'),
            showPass: false,
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.dialogStatus
    },
    watch: {
        dialogStatus() {
            this.dialogVisibleStatus = this.dialogStatus;
        },
    },
    emits: ['handleCloseDialog', 'confirm'],
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
            this.showPass = false
            this.$refs.ruleForm.clearValidate()
            this.$refs.ruleForm.resetFields()
        },
        confirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit("confirm", this.ruleForm.password);
                } else {
                    return true
                }
            });
        },
    },
}
</script>
<style lang="css" scoped>
    p.ttl {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        color: #444;
    }

    button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 45%;
        max-width: 179px;
        height: 50px;
        border-radius: 20px;
        background: #EEEFF4;
    }

    button.btn_add {
        color: #FFF;
        background: #F58E16;
        margin-left: 30px;
    }

    img.icon {
        position: absolute;
        z-index: 2;
        left: 10px;
    }

    img.icon--last {
        left: unset;
        right: 15px;
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
</style>

<style>
    .dialog_withdraw {
        width: 428px !important;
    }

    .form-withdraw .el-form-item__label {
        text-align: left;
    }

    .form-withdraw .el-input__wrapper {
        padding: 12px 55px !important;
        background: transparent !important;
        border-radius: 54px !important;
    }
</style>