<template>
    <div class="wp-confirm">
        <h5>Dự án bất động sản</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Dự án bất động sản</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <div class="d-flex align-items-center">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ status_txt }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="agency">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn trạng thái</p>
                            <li>
                                <a class="dropdown-item" href="#" @click="status_id = '', setPage(1), status_txt = 'Tất cả trạng thái'">
                                    Tất cả trạng thái
                                </a>
                            </li>
                            <li v-for="item in list_status">
                                <a class="dropdown-item" href="#" @click="status_id = item.id, setPage(1), status_txt = item.txt">
                                    {{ item.txt }}
                                </a>
                            </li>
                        </ul>
                    </div>
    
                    <el-form-item style="margin: 0 10px;" class="select_filter">
                        <el-select v-model="province_id" filterable placeholder="Tỉnh/Thành phố"
                        @change="changeCity(), setPage(1)" clearable id="city">
                            <el-option v-for="item in dataCity"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item style="margin-bottom: 0;" class="select_filter">
                        <el-select v-model="district_id" filterable placeholder="Quận/Huyện"
                        @change="setPage(1)" clearable id="district">
                            <el-option v-for="item in dataDistricts"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <button class="add-new" @click="$router.push('du-an/them-moi')"><img src="@/assets/icons/plus.png" alt=""> Thêm dự án mới</button>
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700"  @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed label="Hình ảnh" width="102">
                        <template #default="scope">
                            <img :src="$storage + scope.row.thumbnail" alt="" @error="handleError" style="width: 60px; height: 60px; border-radius: 15px; object-fit: cover;">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="name" label="Tên dự án bất động sản" width="466" />
                    <el-table-column header-align="center" align="center" label="Mã dự án" width="129">
                        <template #default="scope">
                            <div class="table_code">#{{ scope.row.code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="owner" label="Chủ đầu tư" min-width="276"/>
                    <el-table-column header-align="center" align="center" prop="province_name" label="Vị trí" min-width="240" />
                    <el-table-column header-align="center" align="center" prop="count_real_estate" label="Số bđs" width="129"/>
                    <el-table-column header-align="center" align="center" prop="project_status" label="Trạng thái dự án" min-width="159">
                        <template #default="scope">
                            <span v-if="scope.row.project_status == 5" style="color: #EE3439;">Bán hết</span>
                            <span v-if="scope.row.project_status == 4">Đang cập nhật</span>
                            <span v-if="scope.row.project_status == 3" style="color: #00B569;">Mở bán</span>
                            <span v-if="scope.row.project_status == 2" style="color: #F58E16;">Sắp mở bán</span>
                            <span v-if="scope.row.project_status == 1" style="color: #F58E16;">Chưa mở bán</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            list_status: [
                {id: '1', txt: 'Chưa mở bán'},
                {id: '2', txt: 'Sắp mở bán'},
                {id: '3', txt: 'Mở bán'},
                {id: '4', txt: 'Đang cập nhật'},
                {id: '5', txt: 'Bán hết'},
            ],
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            showFilter: false,
            showList: false,
            status_txt: 'Trạng thái',
            status_id: '',
            province_id: '',
            district_id: '',
            dataCity: [],
            dataDistricts: [],
        }
    },
    mounted() {
        this.getListProject()
        this.getDataCity()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListProject()
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListProject()
        },
        async changeCity() {
            this.dataDistricts = await this.callApiDistrict(this.province_id)
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-bds/du-an/chi-tiet/' + this.encode(row.project_id))
        },
        async getListProject() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/project/getListProjectForAdmin',{
                params: {
                    name: this.keySearch,
                    project_status: this.status_id,
                    province_id: this.province_id,
                    district_id: this.district_id,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin-left: 16px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .add-new {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        background: #F58E16;
        color: #FFF;
        padding: 10px 14px;
        height: 40px;
    }

    .add-new img {
        filter: brightness(10);
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #F58E16;
        font-weight: 600;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
        margin-left: 10px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }
</style>

<style>
    .select_filter .el-input__wrapper {
        padding-left: 20px !important;
    }
</style>