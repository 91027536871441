<template>
    <div class="wp-rank-settings">
        <h5>Thiết lập tin đăng</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Thiết lập tin đăng</span>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="wp-rank">
                    <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 15px;">
                        <p class="ttl">Thiết lập hạng tin đăng</p>
                        <button @click="dialogVisible = true, id_rank= ''"><img src="@/assets/icons/plus.png" alt="">Tạo hạng mới</button>
                    </div>
                    <el-row>
                        <el-col :span="2">Vị trí</el-col>
                        <el-col :span="7">Hiển thị</el-col>
                        <el-col :span="5">Tên</el-col>
                        <el-col :span="5">Đơn giá</el-col>
                        <el-col :span="5">Action</el-col>
                    </el-row>
                    <div class="item-rank" v-for="(item, index) in rank" :key="index">
                        <el-row>
                            <el-col :span="2">{{ item.rank_order }}</el-col>
                            <el-col :span="7" class="wp-show_ico">
                                <div class="show_ico" :style="{ 'background': item.background_color }">
                                    <p>{{ item.name }}</p>
                                    <p>Từ {{ parseInt(item.price).toLocaleString('vi') }}đ/ngày</p>
                                    <p v-if="item.rank_order == '1'">Hiển thị trên cùng</p>
                                    <p v-else>Hiển thị top {{ item.rank_order }}</p>
                                </div>
                            </el-col>
                            <el-col :span="5">{{ item.name }}</el-col>
                            <el-col :span="5">{{ parseInt(item.price).toLocaleString('vi') }}đ/ngày</el-col>
                            <el-col :span="5">
                                <button @click="dialogVisible = true, id_rank = item.id"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button @click="dialogDelete = true, id_rank = item.id"><img src="@/assets/icons/trash.png" alt=""></button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="wp-rank-detail">
                    <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 15px;">
                        <p class="ttl">Thiết lập gói ngày đăng</p>
                        <button @click="dialogRankDetail = true, id_package = ''"><img src="@/assets/icons/plus.png" alt="">Tạo gói đăng mới</button>
                    </div>
                    <el-row>
                        <el-col :span="9">Hiển thị</el-col>
                        <el-col :span="5">Số ngày</el-col>
                        <el-col :span="5">Khuyến mại</el-col>
                        <el-col :span="5">Action</el-col>
                    </el-row>
                    <div class="item-detail-rank">
                        <el-row v-for="(item, index) in rank_detail" :key="index">
                            <el-col :span="9" class="wp-show">
                                <div class="show">
                                    <p>{{ item.day }} ngày</p>
                                    <p>Đơn giá/ngày</p>
                                    <div class="discount" v-if="item.discount_percent != 0">
                                        -{{ item.discount_percent }}%
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="5">{{ item.day }} ngày</el-col>
                            <el-col :span="5">{{ item.discount_percent }}%</el-col>
                            <el-col :span="5">
                                <div class="d-flex align-items-center justify-content-center">
                                    <button style="padding: 0 12px;" @click="id_package = item.id, dialogRankDetail = true"><img src="@/assets/icons/pencil.png" alt=""></button>
                                    <button @click="id_package = item.id, dialogDeletePackage = true"><img src="@/assets/icons/trash.png" alt=""></button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
        </el-row>
        <dialogRank
            :status="dialogVisible"
            :id="id_rank"
            @handleCloseDialog="listenDialog"
            @confirm="addRank"
        />
        <dialogRankDetail
            :status="dialogRankDetail"
            :id="id_package"
            @handleCloseDialog="listenRankDetail"
            @confirm="addRankPackage"
        />
        <Dialog 
            :dialogVisible="dialogDelete"
            :success="false"
            @handleCloseDialog="listenDialogDelete"
            @confirm="deleteRank"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ hạng tin đăng"
            content_success="Bạn đã xóa hạng tin thành công"
        />
        <DialogPackage 
            :dialogVisible="dialogDeletePackage"
            :success="false"
            @handleCloseDialog="listenDialogDeletePackage"
            @confirm="deletePackage"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ gói ngày đăng"
            content_success="Bạn đã xóa gói ngày đăng thành công"
        />
    </div>
</template>

<script>
import dialogRank from './dialog_rank.vue'
import dialogRankDetail from './dialog_rank_detail.vue'
import Dialog from '../../components/dialog.vue'
import DialogPackage from '../../components/dialog.vue'
export default {
    components: {dialogRank, dialogRankDetail, Dialog, DialogPackage},
    data() {
        return {
            rank: [],
            rank_detail: [],
            dialogVisible: false,
            dialogRankDetail: false,
            dialogDelete: false,
            dialogDeletePackage: false,
            id_rank: '',
            id_package: ''
        }
    },
    mounted() {
        this.getRank()
        this.getRankPackage()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        listenDialogDelete(dialogStatus) {
            this.dialogDelete = dialogStatus;
        },
        listenDialogDeletePackage(dialogStatus) {
            this.dialogDeletePackage = dialogStatus;
        },
        listenRankDetail(dialogStatus) {
            this.dialogRankDetail = dialogStatus;
        },
        async getRank() {
            this.rank = await this.getListRank()
        },
        async addRank(data, type, status) {
            this.startLoading();
            data.price = data.price.replace(/[^\d]/g, '')
            if(type == 'add') {
                await this.$axios.post('/real_estate/createRank',data,{
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Thêm hạng tin thành công!");
                    this.dialogVisible = status
                    this.getRank()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            } else {
                data.id = this.id_rank
                await this.$axios.put('/real_estate/updateRank',data,{
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Chỉnh sửa hạng tin thành công!");
                    this.dialogVisible = status
                    this.id_rank = ''
                    this.getRank()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            }
            this.endLoading();
        },
        async deleteRank() {
            this.startLoading();
            await this.$axios.put('/real_estate/deleteRank',{id: this.id_rank},{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa hạng tin thành công!");
                this.getRank()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getRankPackage() {
            await this.$axios.get('/real_estate/getListRankPackage',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.rank_detail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
        },
        async addRankPackage(data, type, status) {
            this.startLoading();
            if(type == 'add') {
                await this.$axios.post('/real_estate/createRankPackage',data,{
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Thêm gói ngày đăng thành công!");
                    this.dialogRankDetail = status
                    this.getRankPackage()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            } else {
                data.id = this.id_package
                await this.$axios.put('/real_estate/updateRankPackage',data,{
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Chỉnh sửa gói ngày đăng thành công!");
                    this.dialogRankDetail = status
                    this.id_package = ''
                    this.getRankPackage()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            }
            this.endLoading();
        },
        async deletePackage() {
            this.startLoading();
            await this.$axios.put('/real_estate/deleteRankPackage',{id: this.id_package},{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa gói ngày đăng thành công!");
                this.getRankPackage()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-rank > div > button,
    .wp-rank-detail > div > button {
        border-radius: 15px;
        font-family: 'Public Sans', sans-serif;
        border: 1px solid #F58E16;
        padding: 8.5px 14.5px;
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
    }

    .wp-rank > div > button img,
    .wp-rank-detail > div > button img {
        margin-right: 6px;
    }


    .wp-rank, .wp-rank-detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-rank-detail {
        margin-left: 20px;
    }

    .wp-rank .el-row > div,
    .wp-rank-detail .el-row > div {
        text-align: center;
    }

    .wp-rank > .el-row > div,
    .wp-rank-detail > .el-row > div {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .item-rank {
        margin-bottom: 15px;
    }

    .item-rank:last-child {
        margin-bottom: 0;
    }

    .item-rank .el-row {
        align-items: center;
        border-radius: 40px;
        background: #F4F4F4;
        padding: 9px 0 8.5px;
    }

    .item-rank .el-row > div,
    .item-detail-rank .el-row > div {
        font-weight: 500;
        color: #515151;
    }

    .item-detail-rank .el-row:last-child {
        margin-bottom: 0;
    }
    .item-detail-rank .el-row {
        border-radius: 20px;
        background: #F4F4F4;
        align-items: center;
        padding: 7px 0;
        margin-bottom: 15px;
    }

    .wp-show_ico, .wp-show {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .show_ico {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 139px;
        border-radius: 11px;
        padding: 8px 0 6px;
    }

    .show_ico p {
        margin-bottom: 5px;
        color: #515151;
    }

    .show_ico p:first-child {
        font-size: 14.594px;
        font-weight: 600;
    }

    .show_ico p:nth-child(2) {
        font-size: 11.675px;
        font-weight: 500;
    }

    .show_ico p:nth-child(3) {
        font-size: 9.486px;
        margin-bottom: 0;
    }

    .item-detail-rank .show {
        border-radius: 11.213px;
        border: 0.748px solid #B4B4B4;
        background: #FFF;
        width: 139px;
        padding-top: 5px;
        position: relative;
    }

    .item-detail-rank .show p {
        margin-bottom: 2px;
        color: #515151;
        font-size: 11.96px;
        font-weight: 600;
    }

    .discount {
        background-image: url('@/assets/icons/bookmark.svg');
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        color: #FFF;
        font-size: 9.718px;
        font-weight: 500;
        width: 24px;
        height: 32px;
        top: 0;
        left: 7px;
        padding-top: 6px;
    }
</style>