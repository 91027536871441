<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Thêm nhân viên mới</h5>
                <div class="breadcrumb">
                    <span>Quản lý nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Thêm nhân viên mới</span>
                </div>
            </div>
            <div class="wp-button">
                <button class="add-new" @click="submitForm('formAdd')"> Xác nhận thêm</button>
            </div>
        </div>

        <div class="wp-detail">
            <div class="detail">
                <el-row class="justify-content-between">
                    <el-col :span="7" class="avatar text-center">
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile">
                        <img src="@/assets/images/add_employ.png" alt="" style="margin-bottom: 20px; cursor: pointer;" @click="$refs.inputFile.click()" v-if="!img_url">
                        <img :src="$storage + img_url" alt="" class="img_show" @click="$refs.inputFile.click()" v-else>
                        <span class="error" v-if="showErr && !this.formAdd.avatar">Vui lòng thêm hình ảnh nhân viên</span>
                    </el-col>
                    <el-col :span="16" class="info">
                        <el-form
                            ref="formAdd"
                            :model="formAdd"
                            :rules="rules"
                            label-width="120px"
                            class="demo-ruleForm"
                            status-icon
                        >
                            <div class="d-flex justify-content-between">
                                <p>Tên nhân viên:</p>
                                <el-form-item prop="full_name" class="formInput">
                                    <el-input v-model="formAdd.full_name" placeholder="Nhập họ và tên" />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Giới tính:</p>
                                <el-radio-group v-model="formAdd.gender" class="formInput">
                                    <el-radio :label="1" size="large">Nam</el-radio>
                                    <el-radio :label="2" size="large">Nữ</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="d-flex justify-content-between" style="margin-top: 10px;">
                                <p>Ngày sinh:</p>
                                <el-form-item prop="birthday" class="formInput">
                                    <el-date-picker
                                        v-model="formAdd.birthday"
                                        type="date"
                                        placeholder="Chọn ngày sinh"
                                        format="DD-MM-YYYY"
                                        value-format="YYYY-MM-DD"
                                        class="formInput"
                                        :disabled-date="disabledDate"
                                    />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Số điện thoại liên hệ:</p>
                                <el-form-item prop="phone" class="formInput">
                                    <el-input v-model="formAdd.phone" placeholder="Nhập số điện thoại" maxlength="10" @input="formAdd.phone = formAdd.phone.replace(/[^0-9]/g, '')"/>
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Email:</p>
                                <el-form-item prop="email" class="formInput">
                                    <el-input v-model="formAdd.email" type="email" placeholder="Nhập email" />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Địa chỉ:</p>
                                <el-form-item prop="address" class="formInput">
                                    <el-input v-model="formAdd.address" type="textarea" placeholder="Nhập địa chỉ" />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Chức danh trong công ty:</p>
                                <el-form-item prop="position" class="formInput">
                                    <el-input v-model="formAdd.position" placeholder="Nhập chức danh" />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Phân quyền chức năng:</p>
                                <el-form-item prop="role_id" class="formInput">
                                    <el-select v-model="formAdd.role_id" filterable placeholder="Chọn phân quyền" class="formInput" style="width: 100%;">
                                        <el-option
                                            v-for="item in list_role"
                                            :key="item.role_id"
                                            :label="item.role_name"
                                            :value="item.role_id"
                                        />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Chi nhánh làm việc:</p>
                                <el-form-item prop="agency_id" class="formInput">
                                    <el-select v-model="formAdd.agency_id" clearable filterable placeholder="Chọn chi nhánh" class="formInput" style="width: 100%;">
                                        <el-option
                                            v-for="item in list_agency.result"
                                            :key="item.id"
                                            :label="item.agency_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Thiết lập thu nhập:</p>
                                <el-form-item prop="salary_setting_id" class="formInput">
                                    <el-select v-model="formAdd.salary_setting_id" clearable filterable placeholder="Chọn thiết lập thu nhập" class="formInput" style="width: 100%;">
                                        <el-option
                                            v-for="item in listSalarySetting"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const disabledDate = (time) => {
            return time.getTime() > Date.now()
        }
        return {
            disabledDate
        };
    },
    data() {
        return {
            formAdd: {
                avatar: '',
                full_name: '',
                gender: 1,
                birthday: '',
                phone: '',
                email: '',
                address: '',
                position: '',
                role_id: '',
                agency_id: '',
                salary_setting_id: ''
            },
            rules: {
                full_name: [{required: true, message: "Vui lòng nhập họ và tên", trigger: ["blur", "change"]}],
                birthday: [{required: true, message: "Vui lòng chọn ngày sinh", trigger: ["blur", "change"]}],
                phone: [
                    {required: true, message: "Vui lòng nhập số điện thoại", trigger: ["blur", "change"]},
                    {min: 10, message: "Số điện thoại tối thiểu 10 ký tự", trigger: ["blur", "change"]},
                ],
                email: [
                    {required: true, message: "Vui lòng nhập email", trigger: ["blur", "change"]},
                    { type: "email", message: "Vui lòng nhập email đúng định dạng", trigger: ["blur", "change"] },
                ],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
                position: [{required: true, message: "Vui lòng nhập chức danh", trigger: ["blur", "change"]}],
                role_id: [{required: true, message: "Vui lòng chọn phân quyền", trigger: ["blur", "change"]}],
                agency_id: [{required: true, message: "Vui lòng chọn chi nhánh", trigger: ["blur", "change"]}],
                salary_setting_id: [{required: true, message: "Vui lòng chọn thiết lập thu nhập", trigger: ["blur", "change"]}],
            },
            list_agency: [],
            list_role: [],
            listSalarySetting: [],
            img_url: '',
            showErr: false
        }
    },
    mounted() {
        this.getAgency()
        this.getListRole()
        this.getListSalarySetting()
    },
    methods: {
        async changeFile(event) {
            if(event.target.files[0]) {
                let url_img = await this.uploadFile('user/avatar/', event.target.files[0])
                this.img_url = url_img.path_tam
                this.formAdd.avatar = url_img.path
            }
        },
        async getAgency() {
            this.list_agency = await this.getListAgency()
        },
        async getListSalarySetting() {
            await this.$axios.get('/finance/getListSalarySetting',{
                params: {
                    key: '',
                    page: 1,
                    per_page: 200
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listSalarySetting = res.data.result
            })
        },
        async getListRole() {
            this.startLoading();
            await this.$axios.get('/permission/getListRole',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_role = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || !this.formAdd.avatar) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading()
                    await this.$axios.post('/auth/createUser', this.formAdd, {
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Thêm mới nhân viên thành công!");
                        this.turnOffErrorMessage('formAdd')
                        this.img_url = ''
                        this.formAdd.avatar = ''
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Public Sans', sans-serif;
        padding: 10px 14px;
        height: 40px;
        border-radius: 15px;
    }

    .add-new {
        background: #F58E16;
        color: #FFF;
        width: 200px;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .info p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
    }

    .formInput {
        width: 60%;
    }

    .img_show {
        width: 227px;
        height: 227px;
        border-radius: 113px;
        cursor: pointer;
        object-fit: cover;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }
</style>

<style>
    .el-form-item__content {
        margin-left: 0 !important;
    }

    .formInput.el-date-editor.el-input {
        width: 100%;
        min-height: 40px;
    }

    .formInput .el-input__wrapper{
        border-radius: 15px;
        background: var(--neutral-gray-gray-25, #F9F9FC);
        padding: 5px 12px;
        border: none;
        box-shadow: none;
    }

    .formInput .el-input__wrapper:hover,
    .formInput .el-input__wrapper.is-focus,
    .formInput .el-textarea__inner:focus,
    .formInput .el-textarea__inner:hover,
    .formInput.el-select:hover:not(.el-select--disabled) .el-input__wrapper,
    .formInput.el-select .el-input.is-focus .el-input__wrapper,
    .formInput.el-select .el-input__wrapper.is-focus {
        box-shadow: none !important;
    }

    .formInput.el-form-item.is-error .el-input__wrapper:hover,
    .formInput.el-form-item.is-error .el-input__wrapper.is-focus,
    .formInput.el-form-item.is-error .el-textarea__inner:focus,
    .formInput.el-form-item.is-error .el-textarea__inner:hover {
        box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
    }

    .formInput .el-textarea__inner {
        border-radius: 15px;
        background: var(--neutral-gray-gray-25, #F9F9FC);
        resize: none;
        box-shadow: none;
        min-height: 71px !important;
    }
    .formInput .el-radio__input.is-checked .el-radio__inner {
        background-color: #515151;
        border: #515151;
    }

    .formInput .el-radio__inner:hover {
        border-color: #dcdfe6;
    }

    .formInput .el-radio__input.is-checked .el-radio__inner::after {
        background-color: #515151;
    }

    .formInput .el-radio.el-radio--large .el-radio__label {
        font-size: 16px;
        font-weight: 400;
        color: #515151;
    }
</style>