<template>
    <div class="wp-job-detail">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div>
                <h5>Quản lý công việc</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Quản lý công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết đầu việc</span>
                </div>
            </div>
            <div class="wp-btn">
                <button @click="dialogVisible = true">Xóa đầu việc</button>
                <button @click.stop="$router.push('/cong-viec/quan-ly-cong-viec/chinh-sua-cong-viec/' + $route.params.jobId)" v-if="dataDetail.job_status == 0">Chỉnh sửa đầu việc</button>
            </div>
        </div>

        <div class="wp-detail">
            <div class="d-flex align-items-center justify-content-between flex-wrap">
                <div>
                    <img src="@/assets/icons/arrow.png" alt="" style="rotate: 180deg; cursor: pointer;" @click="$router.go(-1)">
                    <span class="ttl">Chi tiết đầu việc</span>
                </div>
                <p class="stt">Trạng thái đầu việc:<span v-for="status in listStatus" v-show="status.id == dataDetail.job_status" :style="{ color: status.color }" style="font-weight: 600;">{{ status.text }}</span></p>
            </div>
            <div class="request_confirm" v-if="dataDetail.job_status == 1 && dataDetail.is_leader == 1">
                <p>Đã hoàn thành đầu việc - Yêu cầu bạn xác nhận hoàn thành công việc</p>
                <button class="fail" @click="confirmFailDialog = true, typeConfirm = 'leader'">Xác nhận không hoàn thành</button>
                <button class="success" @click="normalForm = true, typeConfirm = 'leader'">Xác nhận hoàn thành</button>
            </div>
            <div class="text-center" style="margin: 20px 0;">
                <div v-if="dataDetail.job_status == 3">
                    <img src="@/assets/icons/close.png" alt="">
                    <p class="txt--red mb-0">Không hoàn thành đầu việc</p>
                </div>
                <div v-else-if="dataDetail.job_status == 2">
                    <img src="@/assets/icons/ok.png" alt="" style="width: 63px; height: 63px;">
                    <p class="txt--green mb-0">Hoàn thành đầu việc</p>
                </div>
            </div>
            <div class="reason" v-if="dataDetail.job_status == 3">
                <p v-if="dataDetail.approve_status == 0">Lý do không hoàn thành:</p>
                <p v-else>Phản hồi của cấp trên:</p>
                <p>{{ dataDetail.note }}</p>
            </div>
            <div class="d-flex align-items-center" style="margin: 20px 0 15px;" v-if="dataDetail.active && dataDetail.active.id != 1">
                <div class="level">
                    <img src="@/assets/icons/bookmark_ico.png" alt="">
                    <span v-for="level in listLevel" v-show="level.id == dataDetail.level" :style="{ color: level.color }">{{ level.text }}</span>
                </div>
                <div class="level" v-if="dataDetail.add_kpi == 1">
                    <img src="@/assets/icons/flag.png" alt="">
                    <span>Đầu việc tính KPI</span>
                </div>
            </div>
            <!-- <div class="wp-time" v-if="dataDetail.active && dataDetail.active.id == 1">
                <p>Thời gian hoàn thành</p>
                <div class="item">
                    <span class="time">{{ $moment.utc(dataDetail.finish_at).format('HH:mm') }}</span>
                    <span class="date">{{ $moment.utc(dataDetail.finish_at).format('DD/MM/YYYY') }}</span>
                </div>
            </div> -->
            <div class="d-flex align-items-center justify-content-between flex-wrap" style="margin-bottom: 15px;" v-if="dataDetail.active && dataDetail.active.id != 1">
                <div class="wp-time">
                    <p>Thời gian bắt đầu</p>
                    <div class="item">
                        <span class="time">{{ $moment.utc(dataDetail.start_date).format('HH:mm') }}</span>
                        <span class="date">{{ $moment.utc(dataDetail.start_date).format('DD/MM/YYYY') }}</span>
                    </div>
                </div>
                <div class="wp-time">
                    <p>Thời gian kết thúc</p>
                    <div class="item">
                        <span class="time">{{ $moment.utc(dataDetail.end_date).format('HH:mm') }}</span>
                        <span class="date">{{ $moment.utc(dataDetail.end_date).format('DD/MM/YYYY') }}</span>
                    </div>
                </div>
            </div>
            <div class="info">
                <p>Tiêu đề</p>
                <p>{{ dataDetail.title }}</p>
            </div>
            <div class="info">
                <p>Đầu việc</p>
                <p v-if="dataDetail.active">{{ dataDetail.active.name }}</p>
            </div>
            <div class="info">
                <p>Nội dung</p>
                <p>{{ dataDetail.content }}</p>
            </div>
            <div class="info">
                <p>Người phụ trách</p>
                <img :src="$storage + dataDetail.user_avatar" alt="" @error="handleError">
                <span>{{ dataDetail.user_name }}</span>
                <span>-</span>
                <span>{{ dataDetail.user_code }}</span>
            </div>
            <div class="info" v-if="dataDetail.customer_id">
                <p>Khách hàng</p>
                <img :src="$storage + dataDetail.customer_avatar" alt="" @error="handleError">
                <span>{{ dataDetail.customer_name }}</span>
                <span>-</span>
                <span>{{ dataDetail.customer_phone }}</span>
            </div>
            <div class="info">
                <p>Địa chỉ</p>
                <p>{{ dataDetail.address }}</p>
            </div>
            <div v-if="dataDetail.active && dataDetail.active.id != 1">
                <span class="remind">Nhắc hẹn</span>
                <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    v-model="dataDetail.reminder_status"
                    class="ml-2"
                    style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                    disabled
                />
                <div class="wp-time-remind" v-if="dataDetail.reminder_status == 1">
                    <p>Thời gian nhắc hẹn</p>
                    <div class="time-remind">
                        <span class="time">{{ $moment.utc(dataDetail.reminder_time).format('HH:mm') }}</span>
                        <span class="date">{{ $moment.utc(dataDetail.reminder_time).format('DD/MM/YYYY') }}</span>
                    </div>
                </div>
            </div>
            <div class="contract" v-if="!isEmptyCustom(dataDetail.job_files) && dataDetail.success_type == 2">
                <p style="color: #515151; font-weight: 500; margin-bottom: 0;">File hợp đồng đính kèm:</p>
                <ul class="list_file">
                    <li v-for="item_file in dataDetail.job_files">
                        <Fancybox>
                            <a data-fancybox="gallery" :href="$storage + item_file.path">
                                <img src="@/assets/icons/Note.png" alt="">
                                {{ item_file.path.substring(item_file.path.lastIndexOf('-') + 1) }}
                            </a>
                        </Fancybox>
                    </li>
                </ul>
            </div>
            <div class="pay mt-3">
                <div v-if="!isEmptyCustom(dataDetail.real_estate)">
                    <p style="font-size: 16px; font-weight: 500; color: #515151; text-align: left; margin-bottom: 5px;">Bất động sản liên quan</p>
                    <div class="item" @click="$router.push('/quan-ly-bds/danh-sach/chi-tiet/' + encode(dataDetail.real_estate.id))">
                        <img class="thumbnail" :src="$storage + dataDetail.real_estate.thumbnail" alt="" @error="handleError">
                        <div>
                            <p>{{ dataDetail.real_estate.real_estate_name }}</p>
                            <span v-if="dataDetail.real_estate.real_estate_code">#{{ dataDetail.real_estate.real_estate_code }}</span>
                        </div>
                    </div>
                </div>
                <el-form-item prop="social_insurance_status" label="Kết quả bán bất động sản" v-if="dataDetail.active && dataDetail.active.id == 1">
                    <el-radio v-model="dataDetail.real_estate_finish_status" disabled :label="1">BĐS RaKhoi bán</el-radio>
                    <el-radio v-model="dataDetail.real_estate_finish_status" disabled :label="2">BĐS không phải RaKhoi bán</el-radio>
                </el-form-item>
                <div v-if="!isEmptyCustom(dataDetail.job_files) && dataDetail.success_type == 3">
                    <p style="color: #515151; font-weight: 500; margin: 10px 0 0;">File tài liệu:</p>
                    <ul class="list_file">
                        <li v-for="item_file in dataDetail.job_files">
                            <Fancybox>
                                <a data-fancybox="gallery" :href="$storage + item_file.path">
                                    <img src="@/assets/icons/Note.png" alt="">
                                    {{ item_file.path.substring(item_file.path.lastIndexOf('-') + 1) }}
                                </a>
                            </Fancybox>
                        </li>
                    </ul>
                </div>
                <div v-if="!isEmptyCustom(dataDetail.deposits)">
                    <p style="color: #515151; font-weight: 500; margin: 10px 0;">Thanh toán</p>
                    <div class="reason">
                        <p v-if="dataDetail.active && dataDetail.active.id != 1">Số tiền nhận:</p>
                        <p v-else="">Tổng số tiền hoa hồng đã nhận :</p>
                        <p style="position: relative; margin-bottom: 10px;">{{ parseInt(dataDetail.deposits).toLocaleString('vi') }} <span style="position: absolute; right: 10px;">VNĐ</span></p>
                    </div>
                </div>
                <div class="info" v-if="dataDetail.note">
                    <p>Lý do</p>
                    <p>{{ dataDetail.note }}</p>
                </div>
                <p style="color: #515151; font-weight: 500; margin: 10px 0;" v-if="dataDetail.active && dataDetail.active.id == 1">File đính kèm</p>
                <ul class="list_file" v-if="!isEmptyCustom(dataDetail.bill_pay)">
                    <li v-for="item_bill in dataDetail.bill_pay">
                        <Fancybox>
                            <a data-fancybox="gallery" :href="$storage + item_bill.path">
                                <img src="@/assets/icons/Note.png" alt="">
                                {{ item_bill.path.substring(item_bill.path.lastIndexOf('-') + 1) }}
                            </a>
                        </Fancybox>
                    </li>
                </ul>
            </div>
            <div v-if="dataDetail.job_status == 0 || dataDetail.job_status == 4">
                <p class="confirm">Xác nhận kết quả đầu việc</p>
                <div class="group-btn">
                    <button @click="confirmFailDialog = true, typeConfirm = 'user'">Không hoàn thành</button>
                    <button @click="confirmSuccessDialog = true, typeConfirm = 'user'">Hoàn thành đầu việc</button>
                </div>
            </div>
        </div>
    </div>
    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="deleteJob"
        title="Xóa"
        content="Bạn đang yêu cầu xóa đầu việc"
        content_success="Bạn đã xóa đầu việc thành công"
    />
    <confirmFail 
        :confirmFailDialog="confirmFailDialog"
        @handleCloseDialog="listenDialogFail"
        @confirm="confirmFail"
    />
    <confirmSuccess 
        :confirmSuccessDialog="confirmSuccessDialog"
        :formNormal="normalForm"
        @handleCloseDialog="listenDialogSuccess"
        @confirm="confirmSuccess"
    />
</template>

<script>
import Dialog from '@/pages/components/dialog.vue'
import confirmFail from './dialog/confirmFail.vue'
import confirmSuccess from './dialog/confirmSuccess.vue'
import Fancybox from '@/pages/components/fancy.vue'
export default {
    components: {
        Dialog,
        confirmFail,
        confirmSuccess,
        Fancybox
    },
    data() {
        return {
            dialogVisible: false,
            confirmFailDialog: false,
            confirmSuccessDialog: false,
            normalForm: false,
            jobId: this.decode(this.$route.params.jobId),
            dataDetail: [],
            listStatus: [
                {id: 0, text: 'Đang xử lý', color: '#F58E16'},
                {id: 1, text: 'Đã hoàn thành - chờ xác nhận', color: '#F58E16'},
                {id: 2, text: 'Hoàn thành', color: '#00B569'},
                {id: 3, text: 'Không hoàn thành', color: '#F13A3A'},
                {id: 4, text: 'Quá hạn', color: '#F13A3A'},
            ],
            listLevel: [
                {id: 1, text: 'Không quan trọng', color: '#515151'},
                {id: 2, text: 'Bình thường', color: '#00B569'},
                {id: 3, text: 'Quan trọng', color: '#F58E16'},
                {id: 4, text: 'Rất quan trọng', color: '#EE3439'},
            ],
            typeConfirm: ''
        }
    },
 
    mounted() {
        this.getDetailJob()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        listenDialogFail(dialogStatus) {
            this.confirmFailDialog = dialogStatus;
        },
        listenDialogSuccess(dialogStatus) {
            this.confirmSuccessDialog = dialogStatus;
            this.normalForm = dialogStatus;
        },
        async getDetailJob() {
            this.startLoading()
            await this.$axios.get('/job/getDetailJob', {
                params: {
                    id: this.jobId
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
            })
            this.endLoading()
        },
        async deleteJob() {
            this.startLoading();
            await this.$axios.put('/job/deleteJob', {id: this.jobId}, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa đầu việc thành công!");
                this.$router.go(-1)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmFail(reason) {
            this.startLoading();
            const data = {
                id: this.jobId,
                note: reason,
                job_status: 3
            }
            if(this.typeConfirm == 'user') {
                var api = 'finishJob'
            } else {
                var api = 'approveJob'
            }
            await this.$axios.put('/job/' + api, data, {
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Xác nhận trạng thái thành công!");
                    this.confirmFailDialog = false
                    this.getDetailJob()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            this.endLoading();
        },
        async confirmSuccess(dataProps) {
            this.startLoading();
            if(this.typeConfirm == 'user') {
                await this.$axios.put('/job/finishJob', dataProps, {
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Xác nhận trạng thái thành công!");
                    this.confirmSuccessDialog = false
                    this.getDetailJob()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            } else {
                const data = {
                    id: this.jobId,
                    job_status: 2
                }
                await this.$axios.put('/job/approveJob', data, {
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Xác nhận trạng thái thành công!");
                    this.confirmSuccessDialog = false
                    this.getDetailJob()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            }
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped src="./assets/detail.css"></style>
<style>
    .wp-job-detail .el-radio__input.is-checked+.el-radio__label {
        color: #515151;
    }

    .wp-job-detail .el-radio__input.is-checked .el-radio__inner::after {
        width: 0;
    }

    .wp-job-detail .el-radio__input.is-checked .el-radio__inner {
        background: #F13A3A !important;
        border-color: #515151 !important;
    }

    .wp-job-detail .el-radio {
        display: block;
        width: 100%;
        margin: 0;
        border-radius: 15px;
        background: #F6F6F6;
        padding: 10px 15px;
        height: auto;
    }

    .wp-job-detail .el-radio:last-child {
        margin-top: 15px;
    }

    .wp-job-detail .el-radio.is-checked {
       background: #FEECEC !important;
    }

    .wp-job-detail .el-radio {
        width: 30% !important;
    }

    .wp-job-detail .el-radio:last-child {
        margin: 0 0 0 10px !important;
    }
</style>