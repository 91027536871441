<template>
    <div class="wp-detail-em">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Chi tiết nhân viên</h5>
                <div class="breadcrumb">
                    <span>Quản lý nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span :class="{'active' : detailPage}">Chi tiết nhân viên</span>
                    <span style="margin: 0 8px;" v-if="!detailPage"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active" v-if="!detailPage">Chỉnh sửa thông tin nhân viên</span>
                </div>
            </div>
            <div class="wp-button">
                <button class="reset-pass" @click="resetPass" v-if="detailPage"> Reset default password</button>
                <button class="add-new" v-if="detailPage" @click="$router.push('/quan-ly-nhan-vien/danh-sach/chinh-sua/' + $route.params.id)"> Chỉnh sửa nhân viên</button>
                <button class="add-new" v-else @click="updateUser"> Lưu chỉnh sửa</button>
            </div>
        </div>

        <div class="">
            <div class="wp-tab" v-if="detailPage">
                <el-tabs>
                    <el-tab-pane>
                        <template #label>
                        <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/user.png" alt="">
                            </div>
                            <p>Thông tin cá nhân</p>
                        </span>
                        </template>
                        <div class="wp-detail">
                            <div class="detail">
                                <el-row class="justify-content-between">
                                    <el-col :span="7" class="avatar text-center">
                                        <Fancybox>
                                            <a v-if="dataDetail.avatar" data-fancybox="gallery" :href="$storage + dataDetail.avatar">
                                                <img :src="$storage + dataDetail.avatar" alt="" class="img_avatar" @error="handleError">
                                            </a>
                                        </Fancybox>
                                        <p>Mã nhân viên:</p>
                                        <p>{{ dataDetail.user_code }}</p>
                                    </el-col>
                                    <el-col :span="16" class="info">
                                        <div class="d-flex justify-content-between">
                                            <p>Tên nhân viên:</p>
                                            <p>{{ dataDetail.full_name }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Giới tính:</p>
                                            <p v-if="dataDetail.gender == 1">Nam</p>
                                            <p v-else>Nữ</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Ngày sinh:</p>
                                            <p>{{ formateDate(dataDetail.birthday) }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Số điện thoại liên hệ:</p>
                                            <p>{{ dataDetail.phone }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Email:</p>
                                            <p>{{ dataDetail.email }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Địa chỉ:</p>
                                            <p>{{ dataDetail.address }}</p>
                                        </div>
                                        <!-- <div class="d-flex justify-content-between">
                                            <p>Mã giới thiệu:</p>
                                            <p>{{ dataDetail.referral_code }}</p>
                                        </div> -->
                                        <div class="d-flex justify-content-between">
                                            <p>Chức danh trong công ty:</p>
                                            <p>{{ dataDetail.position }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Phân quyền chức năng:</p>
                                            <p>{{ dataDetail.role_name }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Chi nhánh làm việc:</p>
                                            <p>{{ dataDetail.agency_name }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>Thiết lập lương thưởng:</p>
                                            <p>{{ dataDetail.salary_setting_name }}</p>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="text-end">
                                <button class="btn-del" @click="dialogDel = true, getListUser()" v-if="dataDetail.is_delete != 1" :disabled="dataDetail.user_id == 1">Vô hiệu hóa tài khoản</button>
                                <button class="btn-del" disabled v-else>Tài khoản đã bị vô hiệu hóa</button>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane>
                        <template #label>
                        <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/cus_ico.png" alt="">
                            </div>
                            <p>List khách hàng</p>
                        </span>
                        </template>
                        <ListCustomer />
                    </el-tab-pane>
                    <el-tab-pane>
                        <template #label>
                        <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/list_ico.png" alt="">
                            </div>
                            <p>Bất động sản quản lý</p>
                        </span>
                        </template>
                        <bdsManager/>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="wp-detail" v-if="!detailPage">
                <div class="detail">
                    <el-row class="justify-content-between">
                        <el-col :span="7" class="avatar text-center">
                            <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile">
                            <img src="@/assets/images/add_employ.png" alt="" style="margin-bottom: 20px; cursor: pointer;" @click="$refs.inputFile.click()" v-if="!img_url">
                            <img :src="$storage + img_url" alt="" class="img_show" @click="$refs.inputFile.click()" v-else>
                            <span class="error" v-if="showErr && !this.formAdd.avatar">Vui lòng thêm hình ảnh nhân viên</span>
                        </el-col>
                        <el-col :span="16" class="info">
                            <el-form
                                ref="formAdd"
                                :model="formAdd"
                                :rules="rules"
                                label-width="120px"
                                class="demo-ruleForm"
                                status-icon
                            >
                                <div class="d-flex justify-content-between">
                                    <p>Tên nhân viên:</p>
                                    <el-form-item prop="full_name" class="formInput">
                                        <el-input v-model="formAdd.full_name" placeholder="Nhập họ và tên" />
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Giới tính:</p>
                                    <el-radio-group v-model="formAdd.gender" class="formInput">
                                        <el-radio :label="1" size="large">Nam</el-radio>
                                        <el-radio :label="2" size="large">Nữ</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="d-flex justify-content-between" style="margin-top: 10px;">
                                    <p>Ngày sinh:</p>
                                    <el-form-item prop="birthday" class="formInput">
                                        <el-date-picker
                                            v-model="formAdd.birthday"
                                            type="date"
                                            placeholder="Chọn ngày sinh"
                                            format="DD-MM-YYYY"
                                            value-format="YYYY-MM-DD"
                                            class="formInput"
                                            :disabled-date="disabledDate"
                                        />
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Số điện thoại liên hệ:</p>
                                    <el-form-item prop="phone" class="formInput">
                                        <el-input v-model="formAdd.phone" placeholder="Nhập số điện thoại" maxlength="10" @input="formAdd.phone = formAdd.phone.replace(/[^0-9]/g, '')"/>
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Email:</p>
                                    <el-form-item prop="email" class="formInput">
                                        <el-input v-model="formAdd.email" type="email" placeholder="Nhập email" />
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Địa chỉ:</p>
                                    <el-form-item prop="address" class="formInput">
                                        <el-input v-model="formAdd.address" type="textarea" placeholder="Nhập địa chỉ" />
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Chức danh trong công ty:</p>
                                    <el-form-item prop="position" class="formInput">
                                        <el-input v-model="formAdd.position" placeholder="Nhập chức danh" />
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Phân quyền chức năng:</p>
                                    <el-form-item prop="role_id" class="formInput">
                                        <el-select v-model="formAdd.role_id" filterable placeholder="Chọn phân quyền" class="formInput" style="width: 100%;">
                                            <el-option
                                                v-for="item in list_role"
                                                :key="item.role_id"
                                                :label="item.role_name"
                                                :value="item.role_id"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Chi nhánh làm việc:</p>
                                    <el-form-item prop="agency_id" class="formInput">
                                        <el-select v-model="formAdd.agency_id" clearable filterable placeholder="Chọn chi nhánh" class="formInput" style="width: 100%;">
                                            <el-option
                                                v-for="item in list_agency.result"
                                                :key="item.id"
                                                :label="item.agency_name"
                                                :value="item.id"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Thiết lập lương thưởng:</p>
                                    <el-form-item prop="salary_setting_id" class="formInput">
                                        <el-select v-model="formAdd.salary_setting_id" clearable filterable placeholder="Chọn thiết lập" class="formInput" style="width: 100%;">
                                            <el-option
                                                v-for="item in listSalarySetting"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <el-dialog
            v-model="dialogDel"
            width="515"
            class="disable_user"
            @close="dialogDel = false"
            align-center
        >
            <img src="@/assets/icons/notice.png" alt="">
            <p class="ttl">Vô hiệu hóa tài khoản </p>
            <p>Bạn đang yêu cầu vô hiệu hóa tài khoản nhân viên này <br>Vui lòng chọn nhân viên khác sẽ tiếp tục quản lý các tin đăng và khách hàng.</p>
            <el-form :rules="rulesChangeUser" :model="formChangeUser" ref="formChangeUser">
                <el-form-item prop="user_id" label="Chọn nhân viên">
                    <el-select clearable v-model="formChangeUser.user_id" filterable placeholder="Chọn nhân viên">
                        <el-option v-for="item in listUser" :key="item.user_id" :label="item.full_name + ' - ' + item.user_code" :value="item.user_id" :disabled="item.user_id == id || item.is_delete == 1"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 20px;">
                <button class="btn_cancel" @click="dialogDel = false">Hủy bỏ</button>
                <button class="btn_add" @click="confirm('formChangeUser')">Xác nhận</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Fancybox from '../components/fancy.vue'
import ListCustomer from './components/listCustomer.vue'
import bdsManager from './components/bdsManager.vue'
export default {
    components: {
        Fancybox,
        ListCustomer,
        bdsManager
    },
    setup() {
        const disabledDate = (time) => {
            return time.getTime() > Date.now()
        }
        return {
            disabledDate
        };
    },
    data() {
        return {
            dataDetail: [],
            formAdd: {
                avatar: '',
                full_name: '',
                gender: 1,
                birthday: '',
                phone: '',
                email: '',
                address: '',
                position: '',
                role_id: '',
                agency_id: '',
                salary_setting_id: ''
            },
            rules: {
                full_name: [{required: true, message: "Vui lòng nhập họ và tên", trigger: ["blur", "change"]}],
                birthday: [{required: true, message: "Vui lòng chọn ngày sinh", trigger: ["blur", "change"]}],
                phone: [
                    {required: true, message: "Vui lòng nhập số điện thoại", trigger: ["blur", "change"]},
                    {min: 10, message: "Số điện thoại tối thiểu 10 ký tự", trigger: ["blur", "change"]},
                ],
                email: [
                    {required: true, message: "Vui lòng nhập email", trigger: ["blur", "change"]},
                    { type: "email", message: "Vui lòng nhập email đúng định dạng", trigger: ["blur", "change"] },
                ],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
                position: [{required: true, message: "Vui lòng nhập chức danh", trigger: ["blur", "change"]}],
                role_id: [{required: true, message: "Vui lòng chọn phân quyền", trigger: ["blur", "change"]}],
                agency_id: [{required: true, message: "Vui lòng chọn chi nhánh", trigger: ["blur", "change"]}],
                salary_setting_id: [{required: true, message: "Vui lòng chọn thiết lập", trigger: ["blur", "change"]}],
            },
            list_agency: [],
            list_role: [],
            img_url: '',
            showErr: false,
            detailPage: '',
            dialogDel: false,
            formChangeUser: {
                user_id: ''
            },
            rulesChangeUser: {
                user_id: [{required: true, message: "Vui lòng chọn nhân viên", trigger: ["blur", "change"]}],
            },
            listUser: [],
            listSalarySetting: [],
            id: ''
        }
    },
    watch: {
        "$route.path": function(newUrl, oldUrl) {
            if(newUrl.includes('/quan-ly-nhan-vien/danh-sach/chi-tiet')) {
                this.detailPage = true
            } else {
                this.detailPage = false
                this.getAgency()
                this.getListRole()
                this.getListSalarySetting()
            }
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        this.getDetail()
        if(this.$route.path.includes('/quan-ly-nhan-vien/danh-sach/chi-tiet')) {
            this.detailPage = true
        } else {
            this.detailPage = false
            this.getAgency()
            this.getListRole()
            this.getListSalarySetting()
        }
    },
    methods: {
        async changeFile(event) {
            let url_img = await this.uploadFile('user/avatar/', event.target.files[0])
            this.img_url = url_img.path_tam
            this.formAdd.avatar = url_img.path
        },
        async getAgency() {
            this.list_agency = await this.getListAgency()
        },
        async getListRole() {
            await this.$axios.get('/permission/getListRole',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_role = res.data
            })
        },
        async getListSalarySetting() {
            await this.$axios.get('/finance/getListSalarySetting',{
                params: {
                    key: '',
                    page: 1,
                    per_page: 200
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listSalarySetting = res.data.result
            })
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/auth/getUserDetail',{
                params: {
                    user_id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                this.formAdd = res.data
                this.img_url = res.data.avatar
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListUser() {
            this.startLoading();
            await this.$axios.get('/auth/getListUser', {
                params: {
                    user_name: '',
                    agency_id: this.dataDetail.agency_id,
                    page: 1,
                    per_page: 2000,
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listUser = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirm(formName) {
            const data = {
                'user_id': this.decode(this.$route.params.id),
                'new_id': this.formChangeUser.user_id
            }
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    await this.$axios.put('/auth/deleteUser', data, {
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Xóa nhân viên thành công!");
                        this.$router.push('/quan-ly-nhan-vien/danh-sach')
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading();
                }
            });
        },
        async resetPass() {
            const data = {
                'user_id': this.decode(this.$route.params.id),
            }
            this.startLoading();
            await this.$axios.put('/auth/resetPass', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Đặt lại mật khẩu thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateUser() {
            this.startLoading();
            await this.$axios.put('/auth/updateUser', this.formAdd, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi thông tin thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Public Sans', sans-serif;
        padding: 10px 14px;
        height: 40px;
        border-radius: 15px;
    }

    .reset-pass {
        color: #F58E16;
        background-color: #fff;
        margin-right: 10px;
    }

    .add-new {
        background: #F58E16;
        color: #FFF;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .info>div p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
    }

    .info>div p:last-child {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 50%;
        text-align: right;
    }

    .btn-del {
        margin-top: 20px;
        color: #F13A3A;
        font-size: 14px;
        font-weight: 500;
        width: 200px;
        height: 40px;
        border-radius: 15px;
        border: 1px solid #EE3439;
    }

    .formInput {
        width: 60%;
    }

    .img_show {
        width: 227px;
        height: 227px;
        border-radius: 113px;
        cursor: pointer;
        object-fit: cover;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    .img_avatar {
        margin-bottom: 20px;
        width: 227px;
        height: 227px;
        border-radius: 114px;
    }

    .custom-tabs-label {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .custom-tabs-label .wp-img {
        width: 46px;
        height: 46px;
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
    }

    .custom-tabs-label img {
        width: 30px;
        height: 30px;
    }

    .custom-tabs-label p {
        margin: 0;
        color: #515151;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .btn_cancel, .btn_add {
        border-radius: 15px;
        text-align: center;
        font-size: 14.47px;
        font-weight: 700;
        text-transform: capitalize;
        width: 40%;
        height: 40px;
    }

    .btn_cancel {
        border: 1px solid #F13A3A;
        background: #FFF;
        color: #F13A3A;
        margin-right: 15px;
    }

    .btn_add {
        background: #F13A3A;
        color: #FFF;
    }

    p.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }
</style>

<style>
    .wp-detail-em .el-tabs__active-bar {
        background: none;
    }

    .wp-detail-em .el-tabs__nav-wrap::after {
        background: none;
    }

    .wp-detail-em .el-tabs {
        --el-tabs-header-height: 72px;
    }

    .wp-detail-em .el-tabs__header {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        width: 842px;
        margin: 0 auto 20px;
    }

    .wp-detail-em .el-tabs__nav {
        float: unset;
        justify-content: space-evenly;
    }

    .wp-detail-em .el-tabs__item.is-active .custom-tabs-label .wp-img {
        background: #F58E16;
        border: none;
    }

    .wp-detail-em .el-tabs__item.is-active .custom-tabs-label img {
        filter: brightness(20);
    }

    .wp-detail-em .el-tabs__item.is-active .custom-tabs-label p {
        color: #F58E16;
    }

    .disable_user {
        border-radius: 20px !important;
        text-align: center;
    }

    .disable_user .el-dialog__header {
        display: none;
    }

    .disable_user .el-form-item__label {
        text-align: left;
    }

    .disable_user .el-select {
        width: 100%;
    }

    .disable_user .el-select .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        border: none;
        padding: 5px 15px;
    }
</style>