<template>
    <div>
        <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div>
                <h5 v-if="!confirm_page">Chi tiết khách hàng</h5>
                <h5 v-else>Chi tiết khách hàng gửi phê duyệt</h5>
                <div class="breadcrumb">
                    <span>Quản lý khách hàng</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span v-if="!confirm_page">Danh sách khách hàng</span>
                    <span v-else>Danh sách khách hàng gửi phê duyệt</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active" v-if="!confirm_page">Chi tiết khách hàng</span>
                    <span class="active" v-else>Chi tiết khách hàng gửi phê duyệt</span>
                </div>
            </div>
            <div class="wp-button">
                <button class="btn-del" v-if="!confirm_page" @click="dialogVisible = true"> Xóa khách hàng</button>
                <div v-else>
                    <button class="btn-del" @click="rejectVisible = true"> Từ chối xác minh</button>
                    <button class="btn-add" @click="dialogVisible = true"> Xác minh tài khoản</button>
                </div>
            </div>
        </div>

        <div class="wp-detail">
            <div class="detail">
                <el-row class="justify-content-between">
                    <el-col :span="7" class="avatar text-center">
                        <Fancybox>
                            <a v-if="dataDetail.avatar" data-fancybox="gallery" :href="$storage + dataDetail.avatar">
                                <img v-if="dataDetail.avatar" :src="$storage + dataDetail.avatar" alt="" style="margin-bottom: 20px; max-height: 227px; max-width: 227px;" @error="handleError">
                            </a>
                        </Fancybox>
                        <p>Mã khách hàng:</p>
                        <p>{{ dataDetail.member_code }}</p>
                        <div class="mt-3" v-if="!confirm_page">
                            <p style="color: #515151; font-size: 13px; font-weight: 400;">Trạng thái tài khoản</p>
                            <p style="color: #F58E16; font-size: 16px; font-weight: 500;" v-if="dataDetail.approve_status == 1">Chờ xác minh tài khoản</p>
                            <p style="color: #00B569; font-size: 16px; font-weight: 500;" v-else-if="dataDetail.approve_status == 2">Đã xác minh tài khoản</p>
                            <p style="color: #EE3439; font-size: 16px; font-weight: 500;" v-else>Chưa xác minh tài khoản</p>
                        </div>
                    </el-col>
                    <el-col :span="16" class="info">
                        <div class="d-flex justify-content-between">
                            <p>Tên khách hàng:</p>
                            <p>{{ dataDetail.full_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Tên đăng nhập:</p>
                            <p>{{ dataDetail.user_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Giới tính:</p>
                            <p v-if="dataDetail.gender == 1">Nam</p>
                            <p v-else>Nữ</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ngày sinh:</p>
                            <p v-show="dataDetail.birthday">{{ formateDate(dataDetail.birthday) }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Số điện thoại liên hệ:</p>
                            <p>{{ dataDetail.phone }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Email:</p>
                            <p>{{ dataDetail.email }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Địa chỉ:</p>
                            <p>{{ dataDetail.address }}, {{ dataDetail.ward_name }}, {{ dataDetail.district_name }}, {{ dataDetail.province_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ngày đăng ký tài khoản:</p>
                            <p>{{ formateDate(dataDetail.create_at) }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Mã số thuế:</p>
                            <p>{{ dataDetail.tax_code }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Số CMT / CCCD:</p>
                            <p>{{ dataDetail.id_number }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ngày cấp:</p>
                            <p v-show="dataDetail.id_date">{{ formateDate(dataDetail.id_date) }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Nơi cấp:</p>
                            <p>{{ dataDetail.id_place }}</p>
                        </div>
                        <div>
                            <p>Ảnh 2 mặt CMT/CCCD:</p>
                            <div class="wp-images">
                                <div class="text-center" style="flex-basis: 50%;">
                                    <Fancybox>
                                        <a v-if="dataDetail.id_front" data-fancybox="gallery" :href="$storage + dataDetail.id_front">
                                            <img v-if="dataDetail.id_front" :src="$storage + dataDetail.id_front" alt="" @error="handleError">
                                        </a>
                                        <img v-else src="@/assets/images/error_img.png" alt="">
                                    </Fancybox>
                                    <span>Mặt trước</span>
                                </div>
                                <div class="text-center" style="flex-basis: 50%;">
                                    <Fancybox>
                                        <a v-if="dataDetail.id_back" data-fancybox="gallery" :href="$storage + dataDetail.id_back">
                                            <img v-if="dataDetail.id_back" :src="$storage + dataDetail.id_back" alt="" @error="handleError">
                                        </a>
                                        <img v-else src="@/assets/images/error_img.png" alt="">
                                    </Fancybox>
                                    <span>Mặt sau</span>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="confirm_page ? approveMember() : deleteMember()"
            :title="titleDialog"
            :content="contentDialog"
            :content_success="contentSuccess"
        />

        <RejectDialog 
            :rejectVisible="rejectVisible"
            @handleCloseDialog="rejectDialog"
            @confirm="rejectMember"
            :title="'Từ chối yêu cầu đăng tin'"
            :content="'Bạn đã từ chối yêu cầu đăng tin bất động sản mới'"
            :customer="'người đăng'"
        />
    </div>
</template>

<script>
import Dialog from '../components/dialog.vue'
import RejectDialog from '../components/rejectDialog.vue'
import Fancybox from '../components/fancy.vue'
export default {
    components: {
        Dialog,
        RejectDialog,
        Fancybox
    },
    data() {
        return {
            confirm_page: false,
            dialogVisible: false,
            successDialog: false,
            rejectVisible: false,
            titleDialog: '',
            contentDialog: '',
            contentSuccess: '',
            id: '',
            dataDetail: ''
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        if(this.$route.path.includes('/quan-ly-khach-hang/danh-sach/chi-tiet')) {
            this.confirm_page = false
            this.titleDialog = 'Xóa'
            this.contentDialog = 'Bạn đã yêu cầu xóa bỏ khách hàng'
            this.contentSuccess = 'Bạn đã xóa khách hàng thành công'
        } else {
            this.confirm_page = true
            this.titleDialog = 'Xác minh'
            this.contentDialog = 'Bạn đã yêu cầu xác minh khách hàng'
            this.contentSuccess = 'Bạn đã xác minh khách hàng thành công'
        }
        this.getDetail()
    },
    watch: {
        "$route": function (url) {
            if(url.path.includes('/quan-ly-khach-hang/xac-minh-khach-hang/chi-tiet')) {
                this.getDetail();
            }
        },
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async approveMember() {
            const data = {
                "member_id": this.id
            }
            this.startLoading();
            await this.$axios.put('/member/approveMember',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xác minh tài khoản thành công!");
                this.$router.push('/quan-ly-khach-hang/xac-minh-khach-hang')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
            
        },
        rejectDialog(rejectStatus) {
            this.rejectVisible = rejectStatus;
        },
        async rejectMember(reason) {
            const data = {
                "member_id": this.id,
                "reason": reason
            }
            this.startLoading();
            await this.$axios.put('/member/rejectMember',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Từ chối xác minh thành công!");
                this.$router.push('/quan-ly-khach-hang/xac-minh-khach-hang')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async deleteMember() {
            this.startLoading();
            await this.$axios.put('/member/deleteMemberForAdmin',{member_id: this.id},{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tài khoản thành công!");
                this.$router.push('/quan-ly-khach-hang/danh-sach')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/member/getMemberDetail',{
                params: {
                    member_id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-size: 14px;
        font-weight: 500;
        padding: 10px 14px;
        height: 40px;
        width: 200px;
        border-radius: 15px;
    }

    .btn-del {
        color: #F13A3A;
        border: 1px solid #EE3439;
    }

    .btn-add {
        background: #F58E16;
        color: #FFF;
        margin-left: 16px;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .info>div p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
    }

    .info>div p:last-child {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 50%;
        text-align: right;
    }

    .wp-images {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-images img {
        max-width: 255px;
        height: 164px;
        border-radius: 15px;
    }

    .wp-images span {
        color: #757575;
        font-size: 14px;
        font-weight: 500;
        display: block;
    }
</style>