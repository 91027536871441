<template>
    <div class="wp-year">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="17">
                <div class="wp-bar" :class="{'wp-team' : type == 'team'}">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <h5 class="ttl" v-if="type == 'team'">Tổng doanh thu mảng bất động sản năm</h5>
                        <h5 class="ttl" v-else-if="type == 'income'">Thu nhập cá nhân năm</h5>
                        <h5 class="ttl" v-else>Doanh thu cá nhân năm</h5>
                        <div class="nav-bar">
                            <button @click="changeDate('prev')"><img src="@/assets/icons/arrow-left.png" alt="" style="rotate: 180deg;"></button>
                            <span>{{ formattedDate }}</span>
                            <button @click="changeDate('next')"><img src="@/assets/icons/arrow-left.png" alt=""></button>
                            <button @click="changeType">Năm</button>
                        </div>
                    </div>
                    <div class="wp-filter mt-4" v-if="type == 'team'">
                        <el-select filterable placeholder="Team" style="flex-basis: 32%;">
                            <el-option
                                :key="1"
                                label="Tất cả"
                                :value="1"
                            />
                        </el-select>
                        <el-select filterable placeholder="Mảng" style="flex-basis: 32%;">
                            <el-option
                                :key="1"
                                label="Đất nền, thổ cư"
                                :value="1"
                            />
                        </el-select>
                        <el-select filterable placeholder="Chi nhánh" style="flex-basis: 32%;">
                            <el-option
                                :key="1"
                                label="Hà Nội"
                                :value="1"
                            />
                        </el-select>
                    </div>
                    <div class="total">
                        <p>Doanh thu năm</p>
                        <p>{{ parseInt(dataYear.total_deposits).toLocaleString('vi') }} VNĐ</p>
                    </div>
                    <BarChart :color="colorBar" :dataValue="dataValue"/>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7">
                <div class="wp-list">
                    <h5 class="ttl text-center">Doanh thu cá nhân năm</h5>
                    <ul>
                        <li>
                            <p>Tháng</p>
                            <p>Doanh thu</p>
                        </li>
                        <li v-for="(item, index) in dataYear.list_finance" :key="index">
                            <p>Tháng {{ item.months }}</p>
                            <p class="price">{{ parseInt(item.total_deposits_month).toLocaleString('vi') }} VNĐ</p>
                        </li>
                    </ul>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import BarChart from '../../../components/barChart'
export default {
    emits: ['changeTypeView', 'changeYear'],
    props: ['colorBar', 'type', 'dataValue', 'dataYear', 'currentDate'],
    components: {
        BarChart,
    },
    data() {
        return {
            date: this.currentDate
        }
    },
    computed: {
        formattedDate() {
            return this.date.format('YYYY');
        },
    },
    methods: {
        changeDate(type) {
            if(type == 'prev') {
                this.date = this.date.clone().subtract(1, 'year');
            } else {
                this.date = this.date.clone().add(1, 'year');
            }
            this.$emit('changeYear', this.date)
        },
        changeType() {
            this.$emit('changeTypeView', 'month')
        }
    },
}
</script>
<style lang="css" scoped>
    .wp-bar, .wp-list {
        border-radius: 20px;
        background:#FFF;
        padding: 20px;
        height: 100%;
    }

    .wp-team {
        border-radius: 0 20px 20px 20px !important;
    }

    h5.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 0;
    }

    .nav-bar button {
        border-radius: 10px;
        border: 1px solid#999;
        padding: 2px 7.5px;
        margin: 0 20px;
    }

    .nav-bar button img {
        width: 13px;
        height: 10px;
    }

    .nav-bar button:last-child {
        color: #515151;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        width: 94px;
        height: 40px;
        border-radius: 15px;
        margin: 0 0 0 40px;
        position: relative;
    }

    .nav-bar button:last-child::before {
        content: '';
        content: '';
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 1px;
        background: #999;
    }

    .nav-bar span {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
    }

    .total {
        border-radius: 15px;
        border: 1px solid #DFE3E7;
        padding: 10px;
        width: 100%;
        max-width: 338px;
        text-align: center;
        margin: 20px 0;
    }

    .total p {
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .total p:last-child {
        color:#F13A3A;
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 0;
    }

    ul {
        padding: 0;
        margin: 38px 0 0 0;
        list-style: none;
    }

    ul li {
        display: flex;
        align-items: center;
        border-radius: 75px;
        background: #F3F7FA;
        margin-bottom: 5px;
        height: 40px;
    }

    ul li:first-child {
        background: none;
        padding-bottom: 18px;
        border-bottom: 1px solid #F0F1F3;
    }

    ul li p {
        flex-basis: 40%;
        text-align: center;
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.075px;
    }

    ul li p:last-child {
        flex-basis: 60%;
    }

    ul li p.price {
        color:#F13A3A;
        line-height: 117%;
    }

    .wp-filter {
        width: 100%;
        max-width: 600px;
    }

    @media (max-width: 992px) {
        .wp-list {
            margin-top: 20px;
        }
    }
</style>