<template>
    <div class="detail">
        <h5>Thông tin cá nhân</h5>
        <el-row :gutter="20" class="justify-content-between">
            <el-col :xs="24" :sm="7" class="avatar text-center">
                <Fancybox>
                    <a v-if="data.avatar" data-fancybox="gallery" :href="$storage + data.avatar">
                        <img :src="$storage + data.avatar" alt="" class="img_avatar" @error="handleError">
                    </a>
                </Fancybox>
                <p>Mã nhân viên:</p>
                <p>{{ data.user_code }}</p>
            </el-col>
            <el-col :xs="24" :sm="17" class="info">
                <div class="d-flex justify-content-between">
                    <p>Tên nhân viên:</p>
                    <p>{{ data.full_name }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Giới tính:</p>
                    <p v-if="data.gender == 1">Nam</p>
                    <p v-else>Nữ</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Ngày sinh:</p>
                    <p>{{ formateDate(data.birthday) }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Số điện thoại liên hệ:</p>
                    <p>{{ data.phone }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Email:</p>
                    <p>{{ data.email }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Địa chỉ:</p>
                    <p>{{ data.address }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Chức danh trong công ty:</p>
                    <p>{{ data.position }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Chi nhánh làm việc:</p>
                    <p>{{ data.agency_name }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p>Phân quyền chức năng:</p>
                    <p>{{ data.role_name }}</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Fancybox from '../../../components/fancy.vue'
export default {
    components: {
        Fancybox
    },
    props:['data']
}
</script>
<style lang="css" scoped>
    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .info>div p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
    }

    .info>div p:last-child {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 60%;
        text-align: right;
    }

    .img_avatar {
        margin-bottom: 20px;
        width: 227px;
        height: 227px;
        border-radius: 114px;
    }
</style>