<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="802"
        class="dialog_list_user"
        @close="emitDialogVisible(false)"
    >
        <h5 class="ttl" v-if="typeDialog == 'customer'">Chọn khách hàng</h5>
        <h5 class="ttl" v-else>Chọn nhân viên phụ trách</h5>
        <el-form-item>
            <el-input v-model="keySearch" type="text" placeholder="Search" />
        </el-form-item>
        <div class="list">
            <div class="item" v-for="item in filteredItems" :class="{'active' : keyActiveUser.includes(item) && typeDialog == 'user' || keyActive == item.id && typeDialog == 'customer'}" @click="checkActive(item)">
                <img :src="$storage + item.avatar" alt="" @error="handleError">
                <span>{{ item.full_name }} </span>
                <span>-</span>
                <span v-if="typeDialog == 'user'">{{ item.user_code }}</span>
                <span v-else>{{ item.phone }}</span>
            </div>
        </div>
        <div class="wp-btn">
            <button @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button @click="getItem">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['status', 'type', 'data', 'active'],
    data() {
        return {
            dialogVisibleStatus: false,
            keySearch: '',
            keyActive: 0,
            keyActiveUser: [],
            typeDialog: '',
            userChoose: [],
            customerChoose: [],
            job: []
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.status
        this.typeDialog = this.type
    },
    computed: {
        filteredItems() {
            return this.data.filter(item => item.full_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
        type() {
            this.typeDialog = this.type;
        },
        active() {
            this.job = this.active
            this.userChoose = []
            this.keyActiveUser = []
        }
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        getItem() {
            if(JSON.parse(this.job).validate_customer == 1 && this.userChoose.length > 1 && this.typeDialog == 'user') {
                this.showNotify("warning", "Lưu ý", "Công việc này chỉ được phép chọn 1 người phụ trách!");
            } else {
                if(this.typeDialog == 'user') {
                    this.$emit('pushData', 'user', this.userChoose)
                } else {
                    this.$emit('pushData', 'customer', this.customerChoose)
                }
                this.dialogVisibleStatus = false
            }
        },
        checkActive(data) {
            if(this.typeDialog == 'user') {
                const index = this.userChoose.indexOf(data);
                if (index === -1) {
                    this.userChoose.push(data);
                } else {
                    this.userChoose.splice(index, 1);
                }
                this.keyActiveUser = this.userChoose
            } else {
                this.keyActive = data.id
                this.customerChoose = data
            }
        }
    }
}
</script>
<style lang="css" scoped>
    button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        width: 45%;
        max-width: 200px;
        height: 40px;
        color: #FFF;
        background: #F58E16;
        outline: none;
    }

    .wp-btn {
        text-align: end;
        margin-top: 20px;
    }

    .wp-btn button:first-child {
        color: #F58E16;
        border: 1px solid #F58E16;
        margin-right: 15px;
        background: transparent
    }

    h5.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.22px;
        text-align: center;
        margin-bottom: 20px;
        text-align: left;
    }

    .list {
        margin-top: 20px;
        max-height: 400px;
        overflow: auto;
    }

    .list::-webkit-scrollbar {
        width: 0;
    }

    .list .item {
        display: flex;
        align-items: center;
        border-radius: 20px;
        background: #F3F3F3;
        padding: 11px 20px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .list .item.active {
        background: #F58E16;
    }

    .list .item.active span {
        color: #FFF
    }

    .list .item img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 8px;
        object-fit: cover;
    }

    .list .item span {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin-right: 8px;
    }

</style>

<style>
    .dialog_list_user {
        border-radius: 20px !important;
    }

    .dialog_list_user .el-dialog__title {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
    }

    .dialog_list_user .el-dialog__header {
       display: none !important;
    }

    .dialog_list_user .el-input__wrapper {
        border-radius: 15px;
        background: #FFF;
        padding: 5px 12px;
    }

    @media (max-width: 802px) {
        .dialog_list_user {
            width: 95% !important;
        }
    }
</style>