<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_confirm_sell"
        @close="emitDialogVisible(false)"
    >
        <div class="wp-form">
            <img src="@/assets/icons/notice_org.png" alt="">
            <h5>Xác nhận bán bất động sản</h5>
            <p class="note"> Hãy tải lên ảnh chụp hợp đồng đặt cọc, văn bản công chứng và tài liệu liên quan. <br>
                Tải lên bill chuyển khoản hoặc tin nhắn có kèm bill chuyển khoản của khách
            </p>
            <p class="file">File tài liệu</p>
            <input type="file" ref="file_input" multiple accept="image/jpeg,image/jpg,image/png,application/pdf" style="display: none;" @change="upLoad">
            <div class="btn_file" @click="$refs.file_input.click()">
                <button>Chọn file</button>
                <span>Tải lên file giấy tờ</span>
            </div>
            <ul>
                <li v-for="(item, index) in listFile" :key="index">
                    <Fancybox>
                        <a v-if="item" data-fancybox="gallery" :href="$storage + item.path_tam">
                            <img src="@/assets/icons/Note.png" alt="">
                            {{ item.path_tam.substring(item.path_tam.lastIndexOf('-') + 1) }}
                        </a>
                    </Fancybox>
                    <img src="@/assets/icons/cross.png" alt="" class="remove_item" @click="removeFile(index)">
                </li>
            </ul>
            <div class="wp-btn" style="margin-top: 20px;">
                <button class="btn_cancel" @click="emitDialogVisible(false)">Hủy bỏ</button>
                <button class="btn_send" @click="updateStatus">Gửi xác nhận</button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import Fancybox from '../../../components/fancy.vue'
export default {
    props: ['status'],
    components: {
        Fancybox
    },
    data() {
        return {
            dialogVisibleStatus: false,
            listFile: []
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.status
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        async upLoad(event) {
            if(event.target.files) {
                for (let index = 0; index < event.target.files.length; index++) {
                    let item = await this.uploadFile('real_estate/documents/', event.target.files[index])
                    this.listFile.push(item)
                }
            }
        },
        removeFile(key) {
            this.listFile.splice(key, 1);
        },
        async updateStatus() {
            var data_file = []
            if(this.listFile) {
                this.listFile.forEach(el => {
                    data_file.push(el.path)
                });
            }
            const data = {
                id: this.decode(this.$route.params.id),
                file: data_file
            }
            this.startLoading();
            await this.$axios.post('/real_estate/updateRealEstateStatusInStorage', data,
                {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.$emit("success");
                this.emitDialogVisible(false)
                this.showNotify("success", "Thành công", "Xác nhận bán thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-form {
        text-align: center;
    }

    h5 {
        color: #444;
        font-size: 20px;
        font-weight: 600;
        line-height: 180%;
        margin: 5px 0 20px;
    }

    p.note {
        color: #444;
        line-height: 119.5%;
        max-width: 338px;
        margin: 0 auto;
        font-size: 16px;
    }

    p.file {
        color: #515151;
        font-size: 16px;
        font-weight: 600;
        line-height: 180%;
        margin: 10px 0 0;
        text-align: left;
    }

   .btn_file {
        cursor: pointer;
        margin: 3px 0 12px;
        text-align: left;
    }

    .btn_file button {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 180%;
        border-radius: 15px;
        background: #444;
        padding: 3.5px 10px;
        margin-right: 7px;
    }

    .btn_file span {
        color: #515151;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        border-radius: 15px;
        background: #F6F6F6;
        padding: 3.5px 10px;
        display: inline-block;
        width: 100%;
        max-width: 83%;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 200px;
        overflow: auto;
    }

    ul::-webkit-scrollbar {
        width: 0;
    }

    ul li {
        border-radius: 20px;
        background: #FCE3C5;
        padding: 7.5px 15px;
        margin-bottom: 10px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ul li > div {
        flex-basis: 90%;
    }

    ul li:last-child {
        margin-bottom: 0;
    }

    ul li a {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        display: block;
        overflow: hidden;
    }

    ul li a img {
        margin-right: 11px;
    }

    .wp-btn button {
        width: 48%;
        height: 42px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #FFF;
        color: #F58E16;
        text-align: center;
        font-size: 14.47px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .wp-btn button.btn_send {
        background: #F58E16;
        border: none;
        color: #fff;
        margin-left: 6px;
    }

    img.remove_item {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
</style>

<style>
    .el-dialog.dialog_confirm_sell {
        border-radius: 20px !important;
    }

    .dialog_confirm_sell .el-dialog__header {
        display: none;
    }

    .fancybox__container {
        z-index: 2500;
    }
</style>