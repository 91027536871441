<template>
    <div class="wp-confirm wp-tran">
        <h5 class="ttl">List doanh thu về</h5>
        <div class="breadcrumb">
            <span>Quản lý tài chính</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">List doanh thu về</span>
        </div>
        <el-row class="align-items-center mt-3">
            <el-col :xs="24" :sm="12">
                <p class="total">Số giao dịch: <span>{{ page.totalData }}</span></p>
            </el-col>
            <el-col :xs="24" :sm="12">
                <div class="d-flex align-items-center justify-content-end">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ agency_txt }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="agency">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn chi nhánh</p>
                            <li><a class="dropdown-item" href="#" @click="agency_id = '', setPage(1), agency_txt = 'Tất cả'">Tất cả</a></li>
                            <li v-for="(agency, index) in listAgency.result" :key="index">
                                <a class="dropdown-item" href="#" @click="agency_id = agency.id, setPage(1), agency_txt = agency.agency_name">{{ agency.agency_name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ status_txt }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="agency">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Trạng thái</p>
                            <li><a class="dropdown-item" href="#" @click="accept_status = '', setPage(1), status_txt = 'Tất cả'">Tất cả</a></li>
                            <li><a class="dropdown-item txt--yl" href="#" @click="accept_status = 0, setPage(1), status_txt = 'Chưa xác nhận'">Chưa xác nhận</a></li>
                            <li><a class="dropdown-item txt--green" href="#" @click="accept_status = 1, setPage(1), status_txt = 'Đã xác nhận'">Đã xác nhận</a></li>
                            <li><a class="dropdown-item txt--red" href="#" @click="accept_status = 2, setPage(1), status_txt = 'Bị từ chối'">Bị từ chối</a></li>
                        </ul>
                    </div>
                    <el-form
                        ref="ruleFormRef"
                        status-icon
                        class="demo-ruleForm"
                        @submit.prevent="setPage(1)"
                    >
                        <img src="@/assets/icons/search.png" alt="">
                        <el-form-item>
                            <el-input type="text" v-model="keySearch" placeholder="Search"/>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <el-row class="mt-4">
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="getDetail">
                    <el-table-column header-align="center" align="center" label="Bất động sản" width="465">
                        <template #default="scope">
                            <div class="wp-item">
                                <img :src="$storage + scope.row.thumbnail" alt="" style="width: 70px; height: 70px; border-radius: 15px; object-fit: cover;" @error="handleError">
                                <div class="d-flex align-items-center flex-column justify-content-between text-start ms-3">
                                    <p class="name">{{ scope.row.real_estate_name }}</p>
                                    <p>{{ scope.row.real_estate_code }}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="transaction_code" label="Mã giao dịch" min-width="116">
                        <template #default="scope">
                            <span class="code">{{ scope.row.transaction_code }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="agency_name" label="Chi nhánh" min-width="162"/>
                    <el-table-column header-align="center" align="center" prop="finish_at" label="Thời gian" min-width="162">
                        <template #default="scope">
                            <span>{{ $moment(scope.row.finish_at).format('hh:mm DD/MM/YYYY') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="full_name" label="Người phụ trách" min-width="203"/>
                    <el-table-column header-align="center" align="center" prop="deposits" label="Tiền thu" min-width="206">
                        <template #default="scope">
                            <span class="price">{{ parseInt(scope.row.deposits).toLocaleString('vi') }} VNĐ</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="accept_status" label="Trạng thái" min-width="206">
                        <template #default="scope">
                            <span class="txt--yl" v-if="scope.row.accept_status == 0">Chưa xác nhận</span>
                            <span class="txt--green" v-else-if="scope.row.accept_status == 1">Đã xác nhận</span>
                            <span class="txt--red" v-else-if="scope.row.accept_status == 2">Bị từ chối</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
        <Detail
            :dialogVisible="dialogVisible"
            :dataDetail="dataDetail"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            @reject="reject"
        />
    </div>
</template>
<script>
import Pagination from '../components/pagination.vue'
import Detail from './dialog/detail_revenue'
export default {
    components: {
        Pagination,
        Detail
    },
    data() {
        return {
            keySearch: '',
            agency_txt: 'Chi nhánh',
            agency_id: '',
            status_txt: 'Tất cả',
            accept_status: '',
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            tableData: [],
            dialogVisible: false,
            listAgency: [],
            dataDetail: [],
        }
    },
    mounted() {
        this.getListFinanceTransaction()
        this.getAgency()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListFinanceTransaction()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListFinanceTransaction()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        listenDialog(status) {
            this.dialogVisible = status
        },
        async getDetail(row) {
            this.startLoading();
            await this.$axios.get('/finance/getDetailJobTransaction',{
                params: {
                    id: row.id,
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                this.dialogVisible = true
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirm() {
            const data = {
                accept_status: 1,
                id: this.dataDetail.transaction_detail.id
            }
            this.startLoading();
            await this.$axios.put('/finance/approveJobTransaction', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Xác nhận tiền về thành công!');
                this.dialogVisible = false
                this.getListFinanceTransaction()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async reject(reason) {
            const data = {
                accept_status: 2,
                reason: reason,
                id: this.dataDetail.transaction_detail.id
            }
            this.startLoading();
            await this.$axios.put('/finance/approveJobTransaction', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Từ chối xác nhận tiền về thành công!');
                this.dialogVisible = false
                this.getListFinanceTransaction()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async getListFinanceTransaction() {
            this.startLoading();
            await this.$axios.get('/finance/getListFinanceTransaction',{
                params: {
                    accept_status: this.accept_status,
                    key: this.keySearch,
                    agency_id: this.agency_id,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getAgency() {
            this.listAgency = await this.getListAgency()
        }
    }
}
</script>
<style lang="css" scoped>
    h5.ttl {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 8px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    p.total {
        color: #515151;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin-bottom: 0;
    }

    p.total span {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.18px;
        margin-left: 5px;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
        margin-right: 10px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .wp-item {
        display: flex;
        align-items: center;
    }

    .wp-item p {
        margin: 0;
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        width: 100%;
    }

    .wp-item p.name {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    span.code {
        color: #3FA5FF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    span.price {
        color: #F13A3A;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .txt--yl {
        color: #F58E16 !important;
    }

    .txt--green {
        color: #00B569 !important;
    }

    .txt--red {
        color: #F13A3A !important;
    }
</style>