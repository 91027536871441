<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="850"
        class="dialog_interac"
        @close="emitDialogVisible(false)"
    >
        <h5 class="ttl">Danh sách tương tác</h5>
        <div class="text-end">
            <button class="btn_add" @click="openFormAdd">+ Thêm tương tác mới</button>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in listActive" :key="index">
                <p>{{ item.name }}</p>
                <div>
                    <button @click="dialogAdd = true, editPage = true, formAdd.name = item.name, formAdd.validate_customer = item.validate_customer, id = item.id"><img src="@/assets/icons/pencil.png" alt=""></button>
                    <button @click="statusDelete = true, id = item.id"><img src="@/assets/icons/trash.png" alt=""></button>
                </div>
            </div>
        </div>

        <el-dialog
            v-model="dialogAdd"
            width="650"
            class="dialog_add_interac"
            @close="dialogAdd = false, formAdd.name = ''"
            align-center
        >
            <p class="ttl" v-if="editPage">Chỉnh sửa tương tác đầu việc</p>
            <p class="ttl" v-else>Thêm tương tác đầu việc mới</p>
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                class="demo-ruleForm"
                status-icon
                @submit.prevent="addActive('formAdd')"
            >
                <el-form-item label="Nhập tên tương tác" prop="name">
                    <el-input v-model="formAdd.name" type="text" placeholder="Nhập tên tương tác" />
                </el-form-item>
                <el-form-item label="Khách hàng" prop="" style="display: flex !important;">
                    <el-switch
                        v-model="formAdd.validate_customer"
                        :active-value="1"
                        :inactive-value="0"
                        class="ml-2"
                        style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                    />
                </el-form-item>
            </el-form>
            <div class="wp-btn-uti" style="margin-top: 22px;">
                <button class="btn_cancel" @click="dialogAdd = false">Hủy bỏ</button>
                <button class="btn_add" @click="addActive('formAdd')">
                    <span v-if="editPage">Lưu chỉnh sửa</span>
                    <span v-else>Thêm mới</span>    
                </button>
            </div>
        </el-dialog>

        <Dialog 
            :dialogVisible="statusDelete"
            @handleCloseDialog="listenDialog"
            @confirm="confirmDelete"
            title="Xóa tương tác"
            content="Bạn đang yêu cầu xóa bỏ tương tác"
            content_success="Bạn đã xóa tương tác thành công"
        />
    </el-dialog>
</template>
<script>
import Dialog from '../../../components/dialog.vue'
export default {
    props: ['status'],
    components: {Dialog},
    data() {
        return {
            formAdd: {
                name: '',
                validate_customer: ''
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên tương tác", trigger: ["blur", "change"]}],
            },
            dialogVisibleStatus: false,
            list: [],
            statusDelete: false,
            dialogAdd: false,
            listActive: '',
            id: '',
            editPage: false
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.status
        this.getListActive()
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
    },
    methods: {
        openFormAdd() {
            this.dialogAdd = true
            this.editPage = false
            if(this.$refs.formAdd) {
                this.$refs.formAdd.clearValidate()
            }
        },
        listenDialog(dialogStatus) {
            this.statusDelete = dialogStatus;
        },
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        async confirmDelete() {
            this.startLoading();
            await this.$axios.put('/job/deleteActive',{id: this.id},{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tương tác thành công!");
                this.getListActive()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListActive() {
            this.startLoading()
            await this.$axios.get('/job/getListActive', {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listActive = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading()
        },
        addActive(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading()
                    if(this.editPage) {
                        const data = {
                            id: this.id,
                            name: this.formAdd.name,
                            validate_customer: this.formAdd.validate_customer
                        }
                        await this.$axios.put('/job/updateActive',data,{
                            headers: {
                                'x-access-token': this.getToken()
                            }
                        }).then(res => {
                            this.showNotify("success", "Thành công", "Chỉnh sửa tương tác thành công!");
                            this.turnOffErrorMessage('formAdd')
                            this.dialogAdd = false
                            this.getListActive()
                        }).catch(err => {
                            if(err.response) {
                                if(err.response.status === 401) {
                                    this.handleCheckTokenAuth();
                                } else {
                                    this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                                }
                            }
                        })
                    } else {
                        await this.$axios.post('/job/createActive', this.formAdd, {
                            headers: {
                                'x-access-token': this.getToken()
                            }
                        }).then(res => {
                            this.showNotify("success", "Thành công", "Thên tương tác đầu việc thành công!");
                            this.turnOffErrorMessage('formAdd')
                            this.dialogAdd = false
                            this.getListActive()
                        }).catch(err => {
                            if(err.response) {
                                if(err.response.status === 401) {
                                    this.handleCheckTokenAuth();
                                } else {
                                    this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                                }
                            }
                        })
                    }
                    this.endLoading()
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
    }

    button {
        outline: none;
    }

    button.btn_add {
        color: #F58E16;
        border: 1px solid #F58E16;
        padding: 8.5px 27px;
    }

    h5.ttl {
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.22px;
        text-align: center;
        margin-bottom: 20px;
    }

    .list {
        margin-top: 20px;
        max-height: 400px;
        overflow: auto;
    }

    .list::-webkit-scrollbar {
        width: 0;
    }

    .list .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        background: #F3F3F3;
        padding: 11px 20px;
        margin-bottom: 10px
    }

    .list .item p {
        margin: 0;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    button.btn_confirm {
        font-family: 'Public Sans', sans-serif;
        color: #FFF;
        background: #F58E16;
        border: none;
        font-size: 14px;
        font-weight: 600;
        padding: 9.5px 15px;
        border-radius: 15px;
        margin-top: 18px;
    }

    p.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .icon {
        cursor: pointer;
        max-width: 70px;
        max-height: 70px;
        border-radius: 15px;
    }

    .wp-btn-uti {
        text-align: end;
    }

    .wp-btn-uti button {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 0.905px solid #F58E16;
        background-color: #fff;
        width: 48%;
        height: 40px;
        max-width: 200px;
    }

    .wp-btn-uti button.btn_add {
        margin-left: 13px;
        color: #FFF;
        background-color: #F58E16;
    }
</style>

<style>
    .dialog_interac {
        border-radius: 20px !important;
    }

    .dialog_interac .el-dialog__title {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
    }

    .dialog_interac .el-dialog__header {
       display: none !important;
    }

    .dialog_interac .el-dialog__footer {
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 20px 20px;
    }

    .dialog_add_interac .el-dialog__header {
        display: none;
    }

    .dialog_add_interac {
        border-radius: 20px !important;
    }

    .dialog_add_interac .el-dialog__body {
        padding: 11px 16px 16px;
    }

    .dialog_add_interac .el-input__wrapper {
        height: 70px !important;
        border-radius: 15px;
        background: #F6F6F6;
    }

    @media (max-width: 850px) {
        .dialog_interac, .dialog_add_interac {
            width: 95% !important;
        }
    }
</style>