<template>
    <div class="regulation">
        <h5>Thiết lập lương thưởng</h5>
        <div class="breadcrumb">
            <span>Quản lý tài chính</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Thiết lập lương thưởng</span>
        </div>
        <el-row>
            <el-col :span="7">
                <div class="wp-cate">
                    <p class="ttl text-center">Hệ thống</p>
                    <ul class="list-cate">
                        <li 
                            :class="{'active' : $route.path.includes(item.route)}"
                            v-for="(item, index) in listItem" :key="index"
                            @click="$router.push(item.route)"
                        >
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="17" v-if="$route.path != '/quan-ly-tai-chinh/thiet-lap-luong-thuong'">
                <div class="wp-detail">
                    <div v-if="$route.path == '/quan-ly-tai-chinh/thiet-lap-luong-thuong/che-do-luong-thuong'">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <p class="ttl">Chế độ lương thưởng</p>
                            <div class="d-flex">
                                <button class="add-cate" @click="$router.push('/quan-ly-tai-chinh/thiet-lap-luong-thuong')">Hủy bỏ</button>
                                <button class="btn_save" @click="updateSetting">Lưu chỉnh sửa</button>
                            </div>
                        </div>
                        <p class="lb">Mô tả chi tiết <span style="color: #FF5A4F;">*</span></p>
                        <CKeditor
                            :editorData="dataDetail"
                            @getData="getData"
                        />
                    </div>
                    <div v-else-if="$route.path == '/quan-ly-tai-chinh/thiet-lap-luong-thuong/thiet-lap-thu-nhap'">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <p class="ttl">Thiết lập thu nhập nhân viên</p>
                            <div class="d-flex">
                                <button class="add-cate" @click="$router.push('/quan-ly-tai-chinh/thiet-lap-luong-thuong')">Hủy bỏ</button>
                                <button class="btn_save" @click="dialogAddNew = true">Thêm thiết lập</button>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between" style="margin: 20px 0;">
                            <p class="total">Số thiết lập: <span>{{ page.totalData }}</span></p>
                            <el-form
                                ref="ruleFormRef"
                                status-icon
                                @submit.prevent="setPage(1)"
                            >
                                <img src="@/assets/icons/search.png" alt="">
                                <el-form-item>
                                    <el-input type="text" v-model="keySearch" placeholder="Search"/>
                                </el-form-item>
                            </el-form>
                        </div>
                        <ul class="list-set">
                            <li v-for="(item, index) in listSalarySetting" :key="index">
                                <div class="wp-item-setting">
                                    <router-link :to="'/quan-ly-tai-chinh/thiet-lap-luong-thuong/chi-tiet-thu-nhap/' + encode(item.id)" class="info">
                                        <p>{{ item.name }}</p>
                                        <p class="total">Số nhân viên: <span style="font-size: 14px;">{{ item.count_user }}</span></p>
                                    </router-link>
                                    <div class="action">
                                        <button @click="$router.push('/quan-ly-tai-chinh/thiet-lap-luong-thuong/chinh-sua-thu-nhap/' + encode(item.id))"><img src="@/assets/icons/pencil.png" alt=""></button>
                                        <button @click="dialogVisible = true"><img src="@/assets/icons/trash.png" alt=""></button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="wp-paginate">
                            <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                            <Pagination
                                :page="page"
                                @set-page="setPage"
                                @setPerPage="setPerPage"
                            />
                        </div>
                    </div>
                    <div v-else-if="$route.path == '/quan-ly-tai-chinh/thiet-lap-luong-thuong/thuong-doanh-so'">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <p class="ttl">Thiết lập thưởng đạt doanh số</p>
                            <div class="d-flex align-items-center">
                                <span style="font-weight: 500;">Bật thưởng</span>
                                <el-switch
                                    v-model="dataMocDoanhSo.bonus_sales_status"
                                    active-value="1"
                                    inactive-value="0"
                                    class="ml-2"
                                    style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9; margin: 0 10px 0 5px;"
                                />
                                <button class="add-cate" @click="$router.push('/quan-ly-tai-chinh/thiet-lap-luong-thuong')">Hủy bỏ</button>
                                <button class="btn_save" @click="updateMocDoanhSo">Lưu chỉnh sửa</button>
                            </div>
                        </div>
                        <p class="reward_txt">Tính thưởng theo: <span>Tổng doanh số</span></p>
                        <p class="reward_txt">Thời gian tính: <span>Theo thời gian của KPI hiện tại</span></p>
                        <p class="reward_txt">Áp dụng: <span>Cá nhân</span></p>
                        <el-form
                            class="reward"
                            ref="ruleFormRef"
                            status-icon
                            @submit.prevent="setPage(1)"
                        >
                            <div class="item-input" v-for="(item, index) in dataMocDoanhSo.list" :key="index">
                                <el-form-item label="Doanh số đạt">
                                    <el-input type="text" placeholder="Nhập doanh số" v-model="item.sales" @input="formatPrice(index, 'sales')"/>
                                    <span class="tag">VNĐ</span>
                                </el-form-item>
                                <el-form-item label="Mức thưởng">
                                    <el-input type="text" placeholder="Nhập mức thưởng" v-model="item.bonus" @input="formatPrice(index, 'bonus')"/>
                                    <span class="tag">VNĐ</span>
                                </el-form-item>
                                <button type="button" class="p-0" @click="dataMocDoanhSo.list.splice(index, 1)"><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                            <div class="text-end">
                                <button class="add-cate mt-3" type="button" style="display: inline !important; width: 90px;" @click="addRow">+ Thêm</button>
                            </div>
                        </el-form>
                    </div>
                    <div v-else-if="$route.path == '/quan-ly-tai-chinh/thiet-lap-luong-thuong/thiet-lap-chung'">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <p class="ttl">Thiết lập chung</p>
                            <button class="btn_save" @click="updateGeneralSetting">Lưu chỉnh sửa</button>
                        </div>
                        <el-form
                            class="reward setting_regular"
                            ref="ruleFormRef"
                            status-icon
                        >
                            <el-form-item prop="propose_date" label="Ngày đối soát">
                                <el-input-number :max="31" :min="1" v-model="formRegular.propose_date" placeholder="Nhập ngày đối soát" />
                                <span class="tag">Hàng tháng</span>
                            </el-form-item>
                            <el-form-item prop="payday" label="Ngày trả lương">
                                <el-input-number :max="31" :min="1" v-model="formRegular.payday" placeholder="Nhập ngày trả lương" />
                                <span class="tag">Hàng tháng</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-dialog
            v-model="dialogAddNew"
            width="553"
            class="dialog_add_cate"
            @close="dialogAddNew = false"
        >
            <p class="ttl">Tạo thiết lập lương mới</p>
            <el-form
                class="reward"
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                status-icon
                @submit.prevent="createSalarySetting('formAdd')"
            >
                <el-form-item label="Tên thiết lập lương mới" prop="name" style="width: 100%;">
                    <el-input v-model="formAdd.name" type="text" placeholder="Nhập tên thiết lập lương" />
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 40px;">
                <button class="btn_cancel" @click="dialogAddNew = false">Hủy bỏ</button>
                <button class="btn_add" @click="createSalarySetting('formAdd')">Xác nhận</button>
            </div>
        </el-dialog>

        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            title="Xóa thiết lập"
            content="Bạn đang yêu cầu xóa thiết lập"
        />

    </div>
</template>

<script>
import CKeditor from '../components/ckeditor.vue'
import Pagination from '../components/pagination.vue'
import Dialog from '../components/dialog.vue'
export default {
    components: { CKeditor, Pagination, Dialog },
    data() {
        return {
            typeDialog: '',
            dataEdit: [],
            dataDetail: '',
            listItem: [
                {name: 'Chế độ lương thưởng', route: '/quan-ly-tai-chinh/thiet-lap-luong-thuong/che-do-luong-thuong'},
                {name: 'Thiết lập thu nhập nhân viên', route: '/quan-ly-tai-chinh/thiet-lap-luong-thuong/thiet-lap-thu-nhap'},
                {name: 'Thưởng đạt doanh số', route: '/quan-ly-tai-chinh/thiet-lap-luong-thuong/thuong-doanh-so'},
                {name: 'Thuế thu nhập cá nhân', route: '/quan-ly-tai-chinh/thiet-lap-luong-thuong/thue-thu-nhap'},
                {name: 'Thiết lập chung', route: '/quan-ly-tai-chinh/thiet-lap-luong-thuong/thiet-lap-chung'},
            ],
            id: '',
            keySearch: '',
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            dataMocDoanhSo: {
                bonus_sales_status: '',
                list: []
            },
            listSalarySetting: [],
            dialogAddNew: false,
            formAdd: {
                name: ''
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên thiết lập", trigger: ["blur", "change"]}],
            },
            dialogVisible: false,
            formRegular: {
                propose_date: null,
                payday: null
            }
        }
    },
    mounted() {
        this.getSalarySetting()
        this.getListSalarySetting()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        getData(data) {
            this.dataDetail = data
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getListSalarySetting()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListSalarySetting()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        addRow() {
            const item = {
                sales: '',
                bonus: ''
            }
            this.dataMocDoanhSo.list.push(item)
        },
        async getSalarySetting() {
            this.startLoading();
            await this.$axios.get('/finance/getSalarySetting',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data.description
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
            this.getMocDoanhSo()
            this.getGeneralSetting()
        },
        async updateSetting() {
            this.startLoading();
            await this.$axios.put('/finance/updateSetting', {description: this.dataDetail}, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Chỉnh sửa chế độ lương thành công!");
                this.getSalarySetting()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getMocDoanhSo() {
            this.startLoading();
            await this.$axios.get('/finance/getMocDoanhSo',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataMocDoanhSo.bonus_sales_status = res.data.bonus_sales_status
                this.dataMocDoanhSo.list = []
                for (let index = 0; index < res.data.list.length; index++) {
                    const element = res.data.list[index];
                    element.sales = parseInt(element.sales).toLocaleString('vi')
                    element.bonus = parseInt(element.bonus).toLocaleString('vi')
                    this.dataMocDoanhSo.list.push(element)
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateMocDoanhSo() {
            for (let index = 0; index < this.dataMocDoanhSo.list.length; index++) {
                const element = this.dataMocDoanhSo.list[index];
                element.sales = element.sales.replace(/[^\d]/g, '')
                element.bonus = element.bonus.replace(/[^\d]/g, '')
            }
            this.startLoading();
            await this.$axios.put('/finance/updateMocDoanhSo', this.dataMocDoanhSo, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Chỉnh sửa thiết lập thành công!");
                this.getMocDoanhSo()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        formatPrice(index, type) {
            const cleanedValue = this.dataMocDoanhSo.list[index][type].replace(/[^\d]/g, '');
            this.number = parseInt(cleanedValue, 10);
            this.dataMocDoanhSo.list[index][type] = this.number.toLocaleString('vi');
            if(this.dataMocDoanhSo.list[index][type] == 'NaN') {
                this.dataMocDoanhSo.list[index][type] = ''
            }
        },
        async getListSalarySetting() {
            this.startLoading();
            await this.$axios.get('/finance/getListSalarySetting',{
                params: {
                    key: this.keySearch,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listSalarySetting = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async createSalarySetting(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    const res = await this.$axios.post('/finance/createSalarySetting',this.formAdd,{
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Thêm thiết lập lương mới thành công!");
                        this.dialogAddNew = false
                        this.turnOffErrorMessage('formAdd')
                        this.getListSalarySetting()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                } else {
                    return false;
                }
            });
        },
        async confirm() {
            // this.startLoading();
            // await this.$axios.put('/job/deleteKPI', {id: this.KPI_id}, {
            //     headers: {
            //         'x-access-token': this.getToken()
            //     }
            // }).then(res => {
            //     this.showNotify("success", "Thành công", "Xóa KPI thành công!");
            //     if(this.type == 'all') {
            //         this.getKPI()
            //     } else {
            //         this.getListKpiForTeam()
            //     }
            // }).catch(err => {
            //     if(err.response) {
            //         if(err.response.status === 401) {
            //             this.handleCheckTokenAuth();
            //         } else {
            //             this.showNotify("error", "Thất bại", err.response.data.error);
            //         }
            //     }
            // })
            // this.endLoading();
        },
        async getGeneralSetting() {
            this.startLoading();
            await this.$axios.get('/finance/getGeneralSetting',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.formRegular = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateGeneralSetting() {
            this.startLoading();
            await this.$axios.put('/finance/updateGeneralSetting', this.formRegular, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Chỉnh sửa thành công!");
                this.getGeneralSetting()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .regulation {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .el-form:not(.reward) {
        position: relative;
        width: 200px;
        margin: 0 16px;
    }

    .el-form:not(.reward) img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-cate {
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-detail p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .wp-detail p.ttl {
        margin-bottom: 10px;
    }

    .wp-detail p.lb {
        font-weight: 500;
        margin-bottom: 5px;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
    }

    .wp-cate .wp-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-cate .wp-btn button {
        padding: 0;
    }

    .wp-cate .wp-btn button:last-child {
        margin-left: 10px;
    }

    .wp-detail {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    ul.list-cate, ul.list-set {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
    }

    ul.list-cate li.active {
        background: #FCE3C5;
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-detail button.btn_save {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        margin-left: 10px;
    }

    .wp-detail button.btn_save, .wp-detail button.add-cate {
        width: 160px;
        display: flex;
        justify-content: center;
    }

    p.total {
        color: #515151;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin: 0
    }

    p.total span {
        font-size: 18px;
        font-weight: 600;
    }

    ul.list-set li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 12px;
        margin-bottom: 10px;
    }

    ul.list-set li .wp-item-setting {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
    }

    ul.list-set li .info {
        text-decoration: none;
        flex-basis: 90%;
    }

    ul.list-set li p {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 117%;
        margin-bottom: 10px
    }

    ul.list-set li p:last-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 0;
    }

    ul.list-set li button {
        display: inline-block;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .item-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    p.reward_txt {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 30px;
    }

    p.reward_txt span {
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.075px;
    }

    span.tag {
        font-weight: 500;
        position: absolute;
        right: 10px;
    }
</style>

<style>
    .regulation .el-input__wrapper,
    .regulation .el-input__wrapper:hover,
    .regulation .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .regulation .item-input .el-form-item {
        flex-basis: 47%;
        display: flex !important;
        align-items: center;
    }

    .regulation .item-input .el-form-item .el-input__wrapper{
        padding: 0 40px 0 10px;
        background:#F6F6F6;
    }

    .regulation .item-input .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
    }

    .setting_regular .el-input-number {
        width: 100%;
    }

    .setting_regular .el-input-number .el-input-number__decrease,
    .setting_regular .el-input-number .el-input-number__increase {
        display: none;
    }

    .setting_regular .el-input-number .el-input__wrapper {
        padding-left: 15px !important;
        padding-right: 15px !important;
        background:#F6F6F6;
    }

    .setting_regular .el-input-number .el-input__inner {
        text-align: left;
    }
</style>