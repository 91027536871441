<template>
    <div class="wp-au">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Phân quyền nhân viên</h5>
                <div class="breadcrumb">
                    <span>Quản lý nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Phân quyền nhân viên</span>
                </div>
            </div>
            <button class="list-func" @click="$router.push('/quan-ly-nhan-vien/phan-quyen/danh-sach-chuc-nang')">Danh sách chức năng</button>
        </div>
        <el-row>
            <el-col :span="9">
                <div class="wp-cate">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="ttl">Danh sách phân quyền</p>
                        <button class="add-cate" @click="showDialog('add')">+ Tạo phân quyền mới</button>
                    </div>
                    <ul class="list-cate">
                        <li 
                            :class="{'active' : id == item.role_id}" 
                            v-for="(item, index) in list_role" :key="index" 
                            @click="getDetail(item.role_id), id = item.role_id"
                        >{{ item.role_name }}</li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="15" v-if="showDetail">
                <div class="wp-list">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="ttl">Chi tiết chức năng của phân quyền</p>
                        <div class="d-flex align-items-center justify-content-between">
                            <button class="btn-edit" @click="showDialog('edit')"><img src="@/assets/icons/pencil.png" alt=""> Chỉnh sửa</button>
                            <button class="btn-del" @click="dialogVisible = true"><img src="@/assets/icons/trash_redd.png" alt=""> Xóa phân quyền</button>
                        </div>
                    </div>

                    <div class="wp-detail">
                        <div class="item" v-for="(item, index) in roleDetail" :key="index">
                            <span>{{ item.resource_name }}</span>
                            <ul class="list">
                                <li :class="{'active' : act.status == 1}" v-for="(act, index) in item.action" :key="index">{{ act.action_name }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <AddAu
            :data="dataDialog"
            @handleCloseDialog="listenDialog"
            @confirm="addRole"
        />
        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialogDel"
            @confirm="deleteRole"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ phân quyền"
            content_success="Bạn đã xóa phân quyền thành công"
        />
    </div>
</template>

<script>
import AddAu from './dialog/form_authen'
import Dialog from '../components/dialog.vue'
export default {
    components: {
        AddAu,
        Dialog
    },
    data() {
        return {
            dialogVisible: false,
            successDialog: false,
            list_role: [],
            dataDialog: {
                dialogVisible: false,
                type: '',
                id: ''
            },
            showDetail: false,
            roleDetail: [],
            id: ''
        }
    },
    mounted() {
        this.getListRole()
    },
    methods: {
        showDialog(type) {
            if(type == 'add') {
                this.dataDialog = {
                    dialogVisible: true,
                    type: 'add',
                    id: ''
                }
            } else {
                this.dataDialog = {
                    dialogVisible: true,
                    type: 'edit',
                    id: this.id
                }
            }
        },
        listenDialog(dialogStatus) {
            this.dataDialog.dialogVisible = dialogStatus;
        },
        listenDialogDel(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async addRole(form, type) {
            this.startLoading();
            if(type == 'add') {
                await this.$axios.post('/permission/createRole', form, {
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Thêm phân quyền thành công!");
                    this.getListRole()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            } else {
                await this.$axios.put('/permission/updateRole', form, {
                    headers: {
                        'x-access-token': this.getToken()
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Sửa phân quyền thành công!");
                    this.getDetail(this.id)
                    this.getListRole()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            }
            this.endLoading();
        },
        async deleteRole() {
            this.startLoading();
            await this.$axios.post('/permission/destroyRole', {role_id: this.id}, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa phân quyền thành công!");
                this.getListRole()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else if (err.response.status === 403){
                        this.showNotify("error", "Thất bại", err.response.data.message);
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListRole() {
            this.startLoading();
            await this.$axios.get('/permission/getListRole',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_role = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail(id) {
            this.showDetail = true
            this.startLoading();
            await this.$axios.get('/permission/getRoleDetail',{
                params: {
                    role_id: id
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.roleDetail = res.data.resource
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-au {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate{
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-cate {
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-list p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
    }

    ul.list-cate {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    ul.list-cate li.active {
        background: #FCE3C5;
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-list {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .list-func {
        background: #F58E16;
        color: #fff;
        font-weight: 500;
    }

    .btn-edit {
        border-radius: 15px;
        border: 1px solid #515151;
        background-color: #fff;
        color: #515151;
    }

    .btn-del {
        border-radius: 15px;
        border: 1px solid #EE3439;
        color: #EE3439;
        margin-left: 16px;
    }

    .btn-edit img, .btn-del img {
       margin-right: 4px; 
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 19px;
        font-weight: 500;
    }

    .item:first-child {
        margin-top: 24px;
    }

    .item:last-child {
        margin-bottom: 0;
    }

    ul.list {
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
    }

    ul.list li {
        position: relative;
        min-width: 65px;
        font-size: 15px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-weight: 400;
    }

    ul.list li::before {
        content: '';
        position: absolute;
        border: 1px solid #B4B4B4;
        width: 10px;
        height: 10px;
        border-radius: 4px;
        left: 0;
    }

    ul.list li.active::before {
        background-color: #F58E16;
        border: none;
    }
</style>