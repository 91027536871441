<template>
    <div class="wp-add-cate">
        <h5>Chi tiết danh mục</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh mục bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết danh mục </span>
        </div>
        <div class="text-end">
            <button class="btn_add" @click="$router.push('/quan-ly-bds/danh-muc/chinh-sua/' + encode(dataDetail.category_id))">Chỉnh sửa danh mục</button>
            <button class="btn_delete" @click="dialogVisible = true">Xóa danh mục</button>
        </div>
        <el-row style="margin-top: 18px;">
            <el-col :span="10">
                <div class="basic_info">
                    <h5>Thông tin cơ bản</h5>
                    <div class="wp-btn d-flex align-items-center">
                        <button :class="{'active' : dataDetail.type == ''}">Tất cả</button>
                        <button :class="{'active' : dataDetail.type == 1}">Rao bán</button>
                        <button :class="{'active' : dataDetail.type == 2}">Cho thuê</button>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <!-- <div class="wp-ico">
                            <p>Icon</p>
                            <img :src="$storage + dataDetail.icon" alt="" style="max-width: 40px; max-height: 40px;">
                        </div> -->
                        <div class="wp-name">
                            <p style="font-weight: 400;">Tên danh mục</p>
                            <p>{{ dataDetail.category_name }}</p>
                        </div>
                    </div>
                    <p style="font-weight: 400; margin: 19px 0;">Id danh mục: 
                        <span style="color: #F58E16; font-size: 16px; font-weight: 600; margin-left: 30px;">{{ dataDetail.category_id }}</span>
                    </p>
                    <!-- <p style="font-weight: 400;">Số lượng bất động sản: 
                        <span style="color: #F58E16; font-size: 16px; font-weight: 600; margin-left: 30px;">239</span>
                    </p> -->
                </div>

                <div class="required_info">
                    <h5>Thông tin pháp lý yêu cầu</h5>
                    <ul class="list_required">
                        <li v-for="(item, index) in dataDetail.juridical" :key="index">{{ item.juridical_name }}</li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="14">
                <div class="describe">
                    <h5>Mô tả bổ sung</h5>
                    <p>Chọn các trường yêu cầu cho danh mục</p>
                    <ul>
                        <li v-for="(item, index) in dataDetail.describe" :key="index" class="active">
                            <img :src="$storage + item.icon" alt="" @error="handleError" style="max-width: 32px; max-height: 32px;">
                            {{ item.describe_name }}
                        </li>
                    </ul>
                </div>
            </el-col>
        </el-row>
        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ danh mục"
            content_success="Bạn đã xóa danh mục thành công"
        />
    </div>
</template>

<script>
import Dialog from '../../components/dialog.vue'
export default {
    components: {
        Dialog
    },
    data() {
        return {
            dataDetail: [],
            dialogVisible: false,
            successDialog: false,
            cate_id: ''
        }
    },
    mounted() {
        this.cate_id = this.decode(this.$route.params.id);
        this.getDetail()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async confirm(successDialog) {
            const data = {
                'category_id': this.cate_id
            }
            this.startLoading();
            await this.$axios.put('/real_estate/deleteCategory',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                // this.successDialog = successDialog;
                this.$router.push('/quan-ly-bds/danh-muc')
                this.showNotify("success", "Thành công", "Xóa danh mục thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/real_estate/getCategoryDetail',{
                params: {
                    category_id: this.cate_id,
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                document.title = `Chi tiết danh mục - ${this.dataDetail.category_name}`;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn {
        width: 320px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        margin: 15px 0 18px;
    }

    .wp-btn button {
        width: 33%;
        font-size: 15px;
        font-weight: 600;
        color: #515151;
        height: 32px;
    }

    .wp-btn button.active {
        border-radius: 13px;
        background: rgba(245, 142, 22, 0.25);
        color: #F58E16;
    }

    .btn_delete, .btn_add {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        line-height: 18px;
        padding: 10px 14px;
        border-radius: 15px;
    }

    .btn_add {
        color: #FFF;
        background: #F58E16;
        line-height: 20px;
    }

    .btn_delete {
        color: #EE3439;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #EE3439;
        margin-left: 16px;
    }

    .basic_info, .required_info, .describe {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .describe {
        height: 100%;
        margin-left: 20px;
    }

    .basic_info h5, .required_info h5, .describe h5 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .basic_info label, .basic_info p {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .required_info {
        margin-top: 26px;
        min-height: 179px;
    }

    .required_info button {
        font-family: 'Public Sans', sans-serif;
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 14px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        line-height: 18px;
        margin-top: 15px;
    }

    .required_info button img {
        margin-right: 5px;
    }

    .describe p {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .describe ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;
    }

    .describe ul li {
        color: #A1A1A1;
        font-size: 15px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid #515151;
        width: 220px;
        height: 40px;
        padding-left: 32px;
        margin-bottom: 25px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .describe ul li.active {
        background: #FCE3C5;
        color: #515151;
        border: none;
    }

    .describe ul li img {
        margin-right: 20px;
    }

    .list_required {
        list-style: none;
        padding: 0;
    }

    .list_required li {
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 14px;
        border-radius: 15px;
        background: #FCE3C5;
        margin-bottom: 10px;
        width: max-content;
    }

    .wp-ico {
        text-align: center;
        flex-basis: 20%;
    }

    .wp-ico p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
    }

    .wp-ico img {
        border-radius: 10.769px;
        background: #B4B4B4;
        padding: 5px;
    }

    .wp-name {
        flex-basis: 70%;
    }

    .wp-name p:last-child {
        font-weight: 600;
        border-radius: 8px;
        border: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
        background: var(--neutral-gray-gray-25, #F9F9FC);
        padding: 8px 12px;
    }
</style>