<template>
    <div class="wp-confirm wp-support">
        <h5>Quản lý thu nhập nhân viên</h5>
        <div class="breadcrumb">
            <span>Quản lý tài chính</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Quản lý thu nhập nhân viên</span>
        </div>
        <div class="nav-bar">
            <p>Ngày hiện tại: <span>{{ currentDay }}</span></p>
            <button @click="changeDate('prev')"><img src="@/assets/icons/arrow-left.png" alt="" style="rotate: 180deg;"></button>
                <span>Tháng {{ formattedDate }}</span>
            <button @click="changeDate('next')"><img src="@/assets/icons/arrow-left.png" alt="" style="margin-bottom: 1px;"></button>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <p style="margin: 0;">Số nhân viên: <span style="font-size: 18px; font-weight: 600;">{{ page.totalData }}</span></p>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ agency_name }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn chi nhánh</p>
                        <li><a class="dropdown-item" href="#" @click="agency_id = '', setPage(1), agency_name = 'Tất cả chi nhánh'">Tất cả</a></li>
                        <li v-for="item in listAgency">
                            <a class="dropdown-item" href="#" @click="agency_id = item.id, setPage(1), agency_name = item.agency_name">{{ item.agency_name }}</a>
                        </li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ setting_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn thiết lập lương</p>
                        <li><a class="dropdown-item" href="#" @click="setting_key = '', setPage(1), setting_txt = 'Tất cả thiết lập lương'">Tất cả thiết lập</a></li>
                        <li v-for="item in listSalarySetting">
                            <a class="dropdown-item" href="#" @click="setting_key = item.id, setPage(1), setting_txt = item.name">{{ item.name }}</a>
                        </li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ position_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn chức vụ</p>
                        <li><a class="dropdown-item" href="#" @click="position_key = '', setPage(1), position_txt = 'Tất cả chức vụ'">Tất cả chức vụ</a></li>
                        <li v-for="item in listRole">
                            <a class="dropdown-item" href="#" @click="position_key = item.role_id, setPage(1), position_txt = item.role_name">{{ item.role_name }}</a>
                        </li>
                    </ul>
                </div>

                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" class="sp_tbl" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" type="index" fixed label="Stt" width="46"/>
                    <el-table-column header-align="center" align="center" prop="full_name" label="Tên nhân viên" width="238" />
                    <el-table-column header-align="center" align="center" prop="user_code" label="Mã nhân viên" min-width="101">
                        <template #default="scope">
                            <span class="code" v-if="scope.row.user_code">{{ scope.row.user_code }}</span>
                            <span class="code" v-else>---</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="salary_setting_name" label="Thiết lập thu nhập" min-width="198"/>
                    <el-table-column header-align="center" align="center" prop="role_name" label="Chức vụ" min-width="212"/>
                    <el-table-column header-align="center" align="center" prop="agency_name" label="Chi nhánh" width="133"/>
                    <el-table-column header-align="center" align="center" prop="base_salary" label="Lương cơ bản" min-width="178">
                        <template #default="scope">
                            <span class="price">{{ parseInt(scope.row.base_salary).toLocaleString('vi') }} VNĐ</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="income" label="Thu nhập hiện tại" min-width="178">
                        <template #default="scope">
                            <span class="price">{{ parseInt(scope.row.income).toLocaleString('vi') }} VNĐ</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="salary_status" label="Trạng thái" width="133">
                        <template #default="scope">
                            <span v-if="scope.row.salary_status == 0" style="color: #F58E16;">Chưa trả lương</span>
                            <span v-else-if="scope.row.salary_status == 1" style="color: #00B569;">Đã trả lương</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            agency_name: 'Chi nhánh',
            agency_id: '',
            setting_txt: 'Thiết lập',
            setting_key: '',
            position_txt: 'Chức vụ',
            position_key: '',
            id: '',
            listAgency: [],
            listSalarySetting: [],
            listRole: [],
            currentDate: this.$moment(),
        }
    },
    computed: {
        formattedDate() {
            return this.currentDate.format('MM - YYYY');
        },
        currentDay() {
            return this.$moment().format('DD/MM/YYYY');
        },
    },
    mounted() {
        this.getListSalaryUser()
        this.getAgency()
        this.getListSalarySetting()
        this.getListRole()
    },
    methods: {
        changeDate(type) {
            if(type == 'prev') {
                this.currentDate = this.currentDate.clone().subtract(1, 'month');
            } else {
                this.currentDate = this.currentDate.clone().add(1, 'month');
            }
            this.getListSalaryUser()
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getListSalaryUser()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListSalaryUser()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async getListSalaryUser() {
            this.startLoading();
            await this.$axios.get('/finance/getListSalaryUser',{
                params: {
                    key: this.keySearch,
                    agency_id: this.agency_id,
                    role_id: this.position_key,
                    salary_setting_id: this.setting_key,
                    month: this.currentDate.format('M'),
                    year: this.currentDate.format('YYYY'),
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getAgency() {
            var data = await this.getListAgency()
            this.listAgency = data.result
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-tai-chinh/quan-ly-thu-nhap-nhan-vien/chi-tiet/' + this.encode(row.id))
        },
        async getListSalarySetting() {
            await this.$axios.get('/finance/getListSalarySetting',{
                params: {
                    key: '',
                    page: 1,
                    per_page: 200
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listSalarySetting = res.data.result
            })
        },
        async getListRole() {
            await this.$axios.get('/permission/getListRole',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listRole = res.data
            })
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown {
        margin-right: 16px;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown:last-child button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    span.code {
        color: #2B97E9;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    span.price {
        color:#F13A3A;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .btn_add {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        width: 200px;
        height: 40px;
    }

    .btn_cancel {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #FFF;
        width: 200px;
        height: 40px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        margin-right: 15px;
    }

    .dialog_add_sup p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
        text-align: center;
    }

    textarea.form-control {
        resize: none;
        box-shadow: none !important;
        padding: 12px 10px !important;
        border: none !important;
        border-radius: 15px;
        background: #F6F6F6;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }
    
    .nav-bar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .nav-bar p {
        margin: 0 20px 0 0;
        color: #515151;
    }

    .nav-bar button {
        border-radius: 10px;
        border: 1px solid#999;
        padding: 2px 7.5px;
        margin: 0 10px;
    }

    .nav-bar button img {
        width: 13px;
        height: 10px;
    }

    .nav-bar button:first-child {
        margin-left: 0;
    }

    .nav-bar span {
        color: #515151;
        font-size: 20px;
        font-weight: 700;
        line-height: 100%;
    }

    .nav-bar p span {
        color:#F13A3A;
    }
</style>