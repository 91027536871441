<template>
    <div class="wp-money">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :xs="24" :sm="12">
                <h5>Quản lý tài chính</h5>
                <div class="breadcrumb mb-0">
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Quản lý tài chính</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Doanh thu cá nhân</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Rút tiền</span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" class="text-end">
                <router-link to="/cong-viec/tai-chinh/rut-tien/lich-su" class="btn-regime">Lịch sử giao dịch</router-link>
            </el-col>
        </el-row>

        <div class="wp-content">
            <router-link to="/cong-viec/tai-chinh">
                <img src="@/assets/icons/arrow.png" alt="">
            </router-link>
            <span class="ttl">Rút tiền</span>
            <div class="money_can">
                <img src="@/assets/icons/wallet.png" alt="">
                <div>
                    <p>Số tiền có thể rút</p>
                    <p class="price" v-if="adInfo">{{ (parseInt(adInfo.balance) * 0.7).toLocaleString('vi') }} VNĐ</p>
                </div>
            </div>
            <p class="note">Bạn chỉ có thể rút: <span>70%</span> trên tổng hoa hồng và thưởng</p>
            <p class="note">Mỗi tháng bạn chỉ có thể rút tiền <span>1 lần</span></p>
            <el-form
               ref="formAdd"
               :model="formAdd"
               :rules="rules"
               @submit.prevent="submitForm('formAdd')"
           >
                <el-form-item prop="amount" label="Số tiền rút">
                    <el-input type="text" v-model="formAdd.amount" @input="formatInputPrice(formAdd.amount)" maxlength="16" placeholder="Nhập số tiền"/>
                    <span class="tag">VNĐ</span>
                </el-form-item>
                <el-form-item prop="content" label="Nội dung (không bắt buộc)">
                    <el-input type="textarea" v-model="formAdd.content" placeholder="Nhập nội dung"/>
                </el-form-item>
                <el-form-item prop="type" label="Tài khoản nhận tiền">
                    <el-radio v-model="formAdd.type" :label="0">Tài khoản nhận lương</el-radio>
                    <el-radio v-model="formAdd.type" :label="1">Tài khoản khác</el-radio>
                </el-form-item>
                <div style="margin-left: 30px;" v-if="formAdd.type == 1">
                    <el-form-item prop="unit_id">
                        <el-select filterable  clearable v-model="formAdd.bank_id" placeholder="Chọn ngân hàng">
                            <el-option v-for="item in listBank"
                                :key="item.id"
                                :label="item.name + ' - ' + item.pre_name"
                                :value="parseInt(item.id)">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="bank_number">
                        <el-input type="text" v-model="formAdd.bank_number" placeholder="Nhập số tài khoản"/>
                    </el-form-item>
                    <el-form-item prop="owner_name">
                        <el-input type="text" v-model="formAdd.owner_name" placeholder="Nhập tên chủ tài khoản"/>
                    </el-form-item>
                </div>
                <div class="text-end">
                    <button class="btn-send" :disabled="formAdd.amount && checkDisabled">Yêu cầu rút tiền</button>
                </div>
            </el-form>
        </div>
        <Withdraw 
            :dialogStatus="dialogStatus"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Withdraw from './dialog/withdraw'
export default {
    components: {
        Withdraw
    },
    data() {
        return {
            formAdd: {
                amount: '',
                content: '',
                type: 0,
                bank_id: '',
                bank_number: '',
                owner_name: '',
                password: ''
            },
            rules: {
                amount: [{required: true, message: "Vui lòng nhập số tiền", trigger: ["blur", "change"]}],
                type: [{required: true, message: "Vui lòng chọn tài khoản", trigger: ["blur", "change"]}],
            },
            dialogStatus: false,
            listBank: []
        }
    },
    computed: {
        ...mapGetters(['adInfo']),
        checkDisabled() {
            if(this.formAdd.type == 1) {
                if (!this.isEmptyCustom(this.formAdd.bank_id) && !this.isEmptyCustom(this.formAdd.bank_number) && !this.isEmptyCustom(this.formAdd.owner_name)) {
                    return false
                } else {
                    return true
                }
            }
        },
    },
    mounted() {
        this.getBank()
    },
    methods: {
        formatInputPrice(value) {
            const cleanedValue = value.replace(/[^\d]/g, '');
            const number = parseInt(cleanedValue, 10);
            this.formAdd.amount = number.toLocaleString('vi');
            if(this.formAdd.amount == 'NaN') {
                this.formAdd.amount = ''
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.dialogStatus = true
                }
            });
        },
        listenDialog(dialogStatus) {
            this.dialogStatus = dialogStatus;
        },
        async confirm(value) {
            this.formAdd.password = value
            if(this.formAdd.type == 0) {
                this.formAdd.bank_id = ''
            }
            this.formAdd.amount = this.formAdd.amount.replace(/[^\d]/g, '')
            this.startLoading()
            await this.$axios.post('/finance/sendRequestWithdrawUser', this.formAdd, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Tạo yêu cầu rút tiền thành công!");
                location.reload()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading()
        },
        async getBank() {
            this.listBank = await this.getListBank()
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .btn-regime {
        color: #F13A3A;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        background: #FFF;
        padding: 10px 25px;
        margin-left: 10px;
        text-decoration: none;
        display: inline-block;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-content a img {
        rotate: 180deg;
        margin-right: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
    }

    .money_can {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        border-radius: 15px;
        background: #F6F6F6;
        margin: 20px 0 10px;
    }

    .money_can img {
        width: 30px;
        height: 30px;
        filter: brightness(0.5);
        margin-right: 15px;
    }

    .money_can p {
        margin-bottom: 8px;
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        line-height: 110%;
    }

    .money_can p.price {
        color: #F13A3A;
        font-size: 20px;
        font-weight: 700;
    }

    p.note {
        color:#515151;
        font-size: 14px;
        font-weight: 400;
        line-height: 180%;
        margin-bottom: 5px;
    }

    p.note span {
        font-weight: 700;
    }

    span.tag {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.075px;
        position: absolute;
        right: 10px;
    }

    button.btn-send {
        color: #FFF;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        background: #F58E16;
        width: 193px;
        height: 40px;
    }

    button:disabled {
        opacity: 0.7;
    }
</style>

<style>
    .wp-money .el-input__wrapper, .wp-money .el-textarea__inner {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 7.5px 15px;
    }

    .wp-money .el-select {
        width: 100%;
    }

    .wp-money .el-textarea__inner {
        resize: none;
        min-height: 100px !important;
    }

    .wp-money .el-radio__input.is-checked+.el-radio__label {
        color: #515151;
    }

    .wp-money .el-radio__input.is-checked .el-radio__inner::after {
        width: 0;
    }

    .wp-money .el-radio__input.is-checked .el-radio__inner {
        background: #F13A3A !important;
        border-color: #515151 !important;
    }

    .wp-money .el-radio {
        display: block;
        width: 100%;
        margin: 0;
        border-radius: 15px;
        background: #F6F6F6;
        padding: 10px 15px;
        height: auto;
    }

    .wp-money .el-radio:last-child {
        margin-top: 15px;
    }
</style>