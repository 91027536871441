<template>
    <div v-if="type == 'month'">
        <div class="wp-income">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="12">
                    <h5 class="ttl">Thu nhập cá nhân</h5>
                    <div class="wp-item">
                        <p>Tổng thu nhập</p>
                        <p v-if="dataIncome.income">{{ parseInt(dataIncome.income).toLocaleString('vi') }} VNĐ</p>
                        <p v-else>0 VNĐ</p>
                    </div>
                    <div class="wp-item">
                        <p>Thực lĩnh</p>
                        <p class="txt--red" v-if="dataIncome.real_income">{{ parseInt(dataIncome.real_income).toLocaleString('vi') }} VNĐ</p>
                        <p class="txt--red" v-else>0 VNĐ</p>
                    </div>
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="12">
                            <div class="wp-date">
                                <p>Bắt đầu tính</p>
                                <p v-if="dataIncome.start_date">{{ $moment(dataIncome.start_date).format('DD/MM/YYYY') }}</p>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="12">
                            <div class="wp-date">
                                <p>Kết thúc tính</p>
                                <p v-if="dataIncome.end_date">{{ $moment(dataIncome.end_date).format('DD/MM/YYYY') }}</p>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="12">
                            <div class="wp-date">
                                <p>Ngày đối soát</p>
                                <p v-if="dataIncome.propose_date">{{ $moment(dataIncome.propose_date).format('DD/MM/YYYY') }}</p>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="12">
                            <div class="wp-date">
                                <p>Ngày trả lương</p>
                                <p v-if="dataIncome.payday">{{ $moment(dataIncome.payday).format('DD/MM/YYYY') }}</p>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12">
                    <div class="nav-bar text-end">
                        <button @click="changeDate('prev')"><img src="@/assets/icons/arrow-left.png" alt="" style="rotate: 180deg;"></button>
                        <span>Tháng {{ formattedDate }}</span>
                        <button @click="changeDate('next')"><img src="@/assets/icons/arrow-left.png" alt="" style="margin-bottom: 1px;"></button>
                        <button @click="getSalaryPersonYear">Tháng</button>
                    </div>
                    <Doughnut
                        :txt="'Phân bố thu nhập'"
                        :dataValue="dataBar"
                    />
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="14">
                <div class="wp-detail" v-if="dataIncome.salary_detail">
                    <h5 class="ttl">Chi tiết thu nhập</h5>
                    <div class="item-clauses" style="background: #F3F7FA;">
                        <p>Hoa hồng doanh số</p>
                        <p class="txt--green">{{ parseInt(dataIncome.salary_detail.total_commission_sales).toLocaleString('vi') }} VNĐ</p>
                    </div>
                    <el-collapse v-model="activeName" accordion class="income-col">
                        <el-collapse-item>
                            <template #title>
                                <div class="col-ttl">
                                    <div>
                                        <p style="font-weight: 400;">Thưởng doanh số</p>
                                        <p class="txt--green" v-if="dataIncome.salary_detail.total_bonus">{{ parseInt(dataIncome.salary_detail.total_bonus).toLocaleString('vi') }} VNĐ</p>
                                        <p class="txt--green" v-else>0 VNĐ</p>
                                    </div>
                                    <!-- <p>55%</p> -->
                                </div>
                            </template>
                            <ul class="list-income">
                                <li v-for="(item, index) in dataIncome.salary_detail.bonus" :key="index">
                                    <p>{{ item.text }}</p>
                                    <p>{{ parseInt(item.amount).toLocaleString('vi') }} VNĐ</p>
                                </li>
                            </ul>
                        </el-collapse-item>
                    </el-collapse>
                    <div class="item-clauses" style="background: #F3F7FA;">
                        <p>Lương cứng</p>
                        <p class="txt--green">{{ parseInt(dataIncome.salary_detail.base_salary).toLocaleString('vi') }} VNĐ</p>
                    </div>
                    <div class="item-clauses" style="background: #F3F7FA;">
                        <p>Phụ cấp</p>
                        <p class="txt--green">{{ parseInt(dataIncome.salary_detail.allowance).toLocaleString('vi') }} VNĐ</p>
                    </div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="10">
                <div class="wp-clauses" v-if="dataIncome.salary_detail">
                    <h5 class="ttl">Các khoản khấu trừ</h5>
                    <div class="item-clauses">
                        <p>Khấu trừ BHXH</p>
                        <p class="txt--red">{{ dataIncome.salary_detail.BHXH.toLocaleString('vi') }} VNĐ</p>
                    </div>
                    <div class="item-clauses">
                        <p>Khấu trừ thuế TNCN</p>
                        <p class="txt--red">{{ dataIncome.salary_detail.TNCN.toLocaleString('vi') }} VNĐ</p>
                    </div>
                    <div class="item-clauses">
                        <p>Phạt</p>
                        <p class="txt--red">{{ dataIncome.salary_detail.fine.toLocaleString('vi') }} VNĐ</p>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
    <ViewYear v-else
        :colorBar="'#00B569'"
        :type="'income'"
        :dataValue="dataBar"
        :dataYear="dataYear"
        :currentDate="currentDate"
        @changeTypeView="changeType"
        @changeYear="changeYear"
    />
</template>
<script>
import ViewYear from './view_year'
import Doughnut from './doughnut'
export default {
    components: {
        ViewYear,
        Doughnut
    },
    data() {
        return {
            currentDate: this.$moment(),
            type: 'month',
            activeName: '',
            dataIncome: [],
            dataBar: {
                label: [],
                data: []
            },
            dataYear: [],
        }
    },
    computed: {
        formattedDate() {
            return this.currentDate.format('MM - YYYY');
        },
    },
    mounted() {
        this.getSalaryPerson()
    },
    methods: {
        changeDate(type) {
            if(type == 'prev') {
                this.currentDate = this.currentDate.clone().subtract(1, 'month');
            } else {
                this.currentDate = this.currentDate.clone().add(1, 'month');
            }
            this.getSalaryPerson()
        },
        changeType(value) {
            this.type = value
            this.getSalaryPerson()
        },
        changeYear(value) {
            this.currentDate = value
            this.getSalaryPersonYear()
        },
        async getSalaryPerson() {
            this.startLoading();
            await this.$axios.get('/finance/getSalaryPerson', {
                params: {
                    month: this.currentDate.format('M'),
                    year: this.currentDate.format('YYYY')
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataIncome = res.data
                if(res.data.income_ratio) {
                    this.dataBar.label = res.data.income_ratio.map(item => item.text + ' (' + item.percent + '%)')
                    this.dataBar.data = res.data.income_ratio.map(item => item.percent)
                } else {
                    this.dataBar.label = this.dataBar.data = []
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async getSalaryPersonYear() {
            this.type = 'year'
            this.startLoading();
            await this.$axios.get('/finance/getSalaryPersonYear', {
                params: {
                    year: this.currentDate.format('YYYY')
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataYear = res.data
                this.dataBar.label = res.data.list_finance.map(item => item.months)
                this.dataBar.data = res.data.list_finance.map(item => item.total_deposits_month)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        }
    },
}
</script>
<style lang="css" scoped>
    .wp-income {
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin-bottom: 20px;
    }

    .wp-detail, .wp-clauses {
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    h5.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
    }

    .wp-detail h5.ttl, .wp-clauses h5.ttl {
        margin-bottom: 10px;
        text-align: center;
    }

    .wp-item {
        border-radius: 20px;
        border: 1px solid #DFE3E7;
        padding: 10px;
        margin-bottom: 15px;
        text-align: center;
    }

    .wp-item p {
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .wp-item p:last-child {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
    }

    .txt--red {
        color: #F13A3A !important;
    }

    .txt--green {
        color: #00B569 !important;
    }

    .wp-date {
        border-radius: 15px;
        border: 1px solid#DFE3E7;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .wp-date p {
        margin-bottom: 0;
        color: #999;
        font-size: 15px;
        line-height: 110%;
    }

    .wp-date p:last-child {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
    }

    .nav-bar {
        margin-bottom: 20px;
    }

    .nav-bar button {
        border-radius: 10px;
        border: 1px solid#999;
        padding: 2px 7.5px;
        margin: 0 10px;
    }

    .nav-bar button img {
        width: 13px;
        height: 10px;
    }

    .nav-bar button:first-child {
        margin-left: 0;
    }

    .nav-bar button:last-child {
        color: #515151;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        width: 94px;
        height: 40px;
        border-radius: 15px;
        margin: 0 0 0 10px;
        position: relative;
    }

    .nav-bar button:last-child::before {
        content: '';
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 0px;
        background: #999;
    }

    .nav-bar span {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
    }

    .col-ttl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        height: 100%;
    }

    .col-ttl p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 110%;
    }

    .col-ttl > div p:last-child {
        font-size: 16px;
        line-height: 120%;
        margin-top: 6px;
    }

    .item-clauses {
        padding: 10px 16px;
        border-radius: 15px;
        background:#FEECEC;
        margin-bottom: 10px;
    }

    .item-clauses p {
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .item-clauses p:last-child {
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 0;
    }

    .list-income {
        list-style: none;
        margin: 0;
    }

    .list-income li {
        border-radius: 15px;
        background:#F3F7FA;
        padding: 10px 16px;
        margin: 0 0 5px 20px;
        position: relative;
    }

    .list-income li::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 33%;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #515151;
    }

    .list-income li p:last-child {
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 0;
    }

    .list-income li p {
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    @media (min-width: 992px) {
        .wp-gift {
            margin-bottom: 0;
        }
    }

    @media (max-width: 768px) {
        .wp-clauses {
            margin-top: 20px;
        }
    }

    @media (min-width: 575px) {
        .nav-bar button {
            margin: 0 20px;
        }

        .nav-bar button:last-child::before {
            width: 1px;
        }
        
        .nav-bar button:last-child {
            margin: 0 0 0 40px;
        }
    }
</style>

<style>
    .income-col {
        border: none !important;
    }

    .income-col .el-collapse-item__header {
        border-radius: 15px;
        background: #F3F7FA;
        padding: 10px 16px;
        border: none !important;
        margin-bottom: 10px;
        height: unset;
    }

    .income-col .el-collapse-item__content {
        padding-bottom: 10px;
    }
</style>