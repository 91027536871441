<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="469"
        class="dialog_rank"
        align-center
        @close="emitDialogVisible(false)"
    >
        <h5 v-if="!id">Tạo hạng tin mới</h5>
        <h5 v-else>Chỉnh sửa hạng tin</h5>
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
        >
            <el-form-item label="Background hiển thị" prop="background" class="wp-add-bg">
                <div class="add-bg" @click="$refs.fileInput.click()">
                    <div v-if="!icon_url">
                        <img src="@/assets/icons/Collectibles.png" alt="">
                        <p>Bấm để chọn ảnh cần tải lên</p>
                        <p>hoặc kéo thả ảnh vào đây</p>
                    </div>
                    <img :src="$storage + icon_url" alt="" v-else style="max-width: 199px;">
                </div>
                <input type="file" @change="handleFileChange" accept="image/*" style="display: none" ref="fileInput">
            </el-form-item>

            <el-form-item label="Tên hạng" prop="name">
                <el-input v-model="ruleForm.name" type="text" placeholder="Nhập tên hạng, VD: Vip Bạch kim" />
            </el-form-item>

            <el-form-item label="Đơn giá" prop="price">
                <el-input v-model="ruleForm.price" type="text" name="price" id="price" placeholder="Nhập số tiền" @input="inputPrice" />
                <span class="append">đ/Ngày</span>
            </el-form-item>
            <el-form-item label="Cấp độ hiển thị" prop="rank_order">
                <el-select clearable v-model="ruleForm.rank_order" placeholder="Chọn vị trí hiển thị cho tin">
                    <el-option v-for="item in list_rank.length + 1"
                        :key="item"
                        :label="'Top ' + item"
                        :value="item"
                        v-if="!id"
                    >
                    </el-option>
                    <el-option v-for="item in list_rank.length"
                        :label="'Top ' + item"
                        :value="item"
                        v-else
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Màu nền" prop="background_color">
                <el-select clearable v-model="ruleForm.background_color" placeholder="Chọn màu nền tin đăng">
                    <el-option v-for="item in list_color"
                        :key="item"
                        :value="item"
                        :style="{ backgroundColor: item }"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <p class="note"><span>Lưu ý</span>: Chọn cấp độ hiển thị là chọn vị trí đề xuất của bài đăng trên trang chủ và các trang danh mục, tìm kiếm </p>
        </el-form>
        <div class="wp-btn" style="margin-top: 26px;">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm('ruleForm', 'add')" v-if="!id">Xác nhận</button>
            <button class="btn_add" @click="confirm('ruleForm', 'edit')" v-else>Chỉnh sửa</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['status', 'id'],
    data() {
        return {
            dialogVisibleStatus: false,
            ruleForm: {
                background: '',
                name: '',
                price: '',
                rank_order: '',
                background_color: ''
            },
            rules: {
                background: [{required: true, message: "Vui lòng chọn background", trigger: ["blur", "change"]}],
                name: [{required: true, message: "Vui lòng nhập tên hạng", trigger: ["blur", "change"]}],
                price: [{required: true, message: "Vui lòng nhập số tiền", trigger: ["blur", "change"]}],
                rank_order: [{required: true, message: "Vui lòng chọn cấp độ", trigger: ["blur", "change"]}],
                background_color: [{required: true, message: "Vui lòng chọn màu nền", trigger: ["blur", "change"]}],
            },
            icon_url: '',
            list_rank: [],
            list_color: [
                '#A9A9A9',
                '#FFFFFF',
                '#FFC548',
                '#00DCEA',
                '#00B569',
                '#FF7979',
                '#2B97E9',
                '#FF8F50',
                '#BB9BFF',
                '#FF9BCB',
            ],
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.status
        this.getRank()
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
            this.getRank()
        },
        id() {
            if(this.id) {
                this.getRankDetail()
            } else {
                this.turnOffErrorMessage('ruleForm')
                this.icon_url = ''
            }
        }
    },
    methods: {
        async handleFileChange(event) {
            let url_image = await this.uploadFile('rank/background/', event.target.files[0])
            this.icon_url = url_image.path_tam
            this.ruleForm.background = url_image.path
        },
        async getRank() {
            this.list_rank = await this.getListRank()
        },
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
            this.$refs.ruleForm.clearValidate()
            if (!this.id) {
                this.$refs.ruleForm.resetFields()
            }
        },
        confirm(formName, type) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.dialogVisibleStatus = false
                    if(type == 'add') {
                        this.$emit("confirm", this.ruleForm, 'add', false);
                    } else {
                        this.$emit("confirm", this.ruleForm, 'edit', false);
                    }
                    this.turnOffErrorMessage(formName)
                    this.icon_url = ''
                } else {
                    return false;
                }
            });
        },
        async getRankDetail() {
            await this.$axios.get('/real_estate/getRankDetail',{
                params: {
                    id: this.id
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.ruleForm = res.data
                this.ruleForm.rank_order = parseInt(this.ruleForm.rank_order)
                this.ruleForm.price = parseInt(this.ruleForm.price).toLocaleString('vi')
                this.icon_url = res.data.background
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 11px;
    }

    .add-bg {
        text-align: center;
        border-radius: 20px;
        background: #F8F8F8;
        padding: 22px 0 16px;
        cursor: pointer;
    }

    .add-bg p {
        margin-bottom: 0;
        margin-top: 8px;
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        line-height: normal;
    }
    .add-bg p:last-child {
        color: #B4B4B4;
        font-size: 13px;
        margin-top: 5px;
    }

    p.note {
        color: #515151;
        font-size: 14px;
    }

    p.note span {
        color: #EE3439;
    }

    button {
        background: #fff;
        border-radius: 15px;
        border: 0.905px solid #F58E16;
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        width: 210px;
        height: 38px;
    }

    button.btn_add {
        background: #F58E16;
        color: #FFF;
        border: none;
        margin-left: 9px;
    }

    span.append {
        position: absolute;
        right: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
    }
</style>

<style>
    .dialog_rank {
        border-radius: 20px !important;
    }

    .dialog_rank .el-dialog__header {
        display: none;
    }

    .dialog_rank .el-dialog__body {
        padding: 15px 20px 21px;
    }

    .dialog_rank .el-form-item {
        display: flex !important;
        align-items: center;
    }

    .dialog_rank .el-form-item.wp-add-bg,
    .dialog_rank .el-form-item.wp-add-bg .el-form-item__content {
        display: block !important;
    }
    
    .dialog_rank .el-form-item__label {
        font-size: 16px;
        font-weight: 500;
        color: #515151;
    }

    .dialog_rank .el-form-item__content {
        justify-content: flex-end;
    }

    .dialog_rank .el-input {
        width: 290px;
    }

    .dialog_rank .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        height: 39px;
        border: none;
        padding-right: 65px;
    }
    
    .dialog_rank .el-form-item__error {
        left: unset;
    }

    .dialog_rank .el-select .el-input__wrapper {
        padding-right: 11px;
    }
</style>