<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Chi tiết nhân viên</h5>
                <div class="breadcrumb">
                    <span>Quản lý nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết nhân viên</span>
                </div>
            </div>
            <div class="wp-button">
                <button class="reject" @click="rejectVisible = true">Từ chối nhân viên</button>
                <button class="approve" @click="dialogVisible = true">Phê duyệt nhân viên</button>
            </div>
        </div>

        <div class="wp-detail">
            <div class="detail">
                <el-row class="justify-content-between">
                    <el-col :span="7" class="avatar text-center">
                        <Fancybox>
                            <a v-if="dataDetail.avatar" data-fancybox="gallery" :href="$storage + dataDetail.avatar">
                                <img :src="$storage + dataDetail.avatar" alt="" class="img_avatar" @error="handleError">
                            </a>
                        </Fancybox>
                        <p>Mã nhân viên:</p>
                        <p>{{ dataDetail.user_code }}</p>
                    </el-col>
                    <el-col :span="16" class="info">
                        <div class="d-flex justify-content-between">
                            <p>Tên nhân viên:</p>
                            <p>{{ dataDetail.full_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Giới tính:</p>
                            <p v-if="dataDetail.gender == 1">Nam</p>
                            <p v-else>Nữ</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ngày sinh:</p>
                            <p>{{ formateDate(dataDetail.birthday) }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Số điện thoại liên hệ:</p>
                            <p>{{ dataDetail.phone }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Email:</p>
                            <p>{{ dataDetail.email }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Địa chỉ:</p>
                            <p>{{ dataDetail.address }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Chức danh trong công ty:</p>
                            <p>{{ dataDetail.position }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Chi nhánh làm việc:</p>
                            <p>{{ dataDetail.agency_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Số CMT / CCCD:</p>
                            <p>{{ dataDetail.id_number }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ngày cấp:</p>
                            <p v-show="dataDetail.id_date">{{ $moment(dataDetail.id_date).format('DD/MM/YYYY') }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Nơi cấp:</p>
                            <p>{{ dataDetail.id_place }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ảnh 2 mặt CMT / CCCD:</p>
                            <div class="d-flex justify-content-between">
                                <div class="text-center" style="flex-basis: 50%;">
                                    <span style="font-size: 11px; color: #515151;">Mặt trước</span>
                                    <img :src="$storage + dataDetail.id_front" alt="" @error="handleError" style="width: 176px; object-fit: cover;">
                                </div>
                                <div class="text-center" style="flex-basis: 50%;">
                                    <span style="font-size: 11px; color: #515151;">Mặt sau</span>
                                    <img :src="$storage + dataDetail.id_back" alt="" @error="handleError" style="width: 176px; object-fit: cover;">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Số chứng chỉ:</p>
                            <p>{{ dataDetail.certificate_number }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ngày cấp:</p>
                            <p v-show="dataDetail.certificate_date">{{ $moment(dataDetail.certificate_date).format('DD/MM/YYYY') }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Ảnh mặt trước chứng chỉ:</p>
                            <img :src="$storage + dataDetail.certificate_image" alt="" @error="handleError" style="max-width: 360px; object-fit: cover;">
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        title="Duyệt tài khoản nhân viên sale"
        content="Bạn đang yêu cầu duyệt tài khoản nhân viên sale"
    />
    <RejectDialog 
        :rejectVisible="rejectVisible"
        @handleCloseDialog="rejectDialog"
        @confirm="reject"
        title="Từ chối phê duyệt nhân viên"
        content="Bạn đang từ chối yêu cầu phê duyệt tài khoản"
        customer="nhân viên"
    />
</template>

<script>
import Fancybox from '../components/fancy.vue'
import Dialog from '../components/dialog.vue'
import RejectDialog from '../components/rejectDialog.vue'
export default {
    components: {
        Fancybox,
        Dialog,
        RejectDialog
    },
    data() {
        return {
            dataDetail: [],
            dialogVisible: false,
            rejectVisible: false
        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        rejectDialog(dialogStatus) {
            this.rejectVisible = dialogStatus;
        },
        async confirm() {
            var data = {
                user_id: this.decode(this.$route.params.id),
                approve_status: 1
            }
            this.startLoading();
            await this.$axios.put('/auth/approveUser', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Duyệt tài khoản nhân viên Sale thành công!");
                this.$router.push('/quan-ly-nhan-vien/duyet-nhan-vien')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async reject(reason) {
            var data = {
                user_id: this.decode(this.$route.params.id),
                approve_status: 2,
                reason: reason
            }
            this.startLoading();
            await this.$axios.put('/auth/approveUser', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Từ chối duyệt tài khoản nhân viên Sale thành công!");
                this.$router.push('/quan-ly-nhan-vien/duyet-nhan-vien')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/auth/getUserDetail',{
                params: {
                    user_id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                this.formAdd = res.data
                this.img_url = res.data.avatar
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Public Sans', sans-serif;
        padding: 10px 14px;
        height: 40px;
        border-radius: 15px;
        width: 200px;
    }

    .reset-pass {
        color: #F58E16;
        background-color: #fff;
        margin-right: 10px;
    }

    .reject {
        background: #fff;
        color: #F13A3A;
        margin-right: 10px;
    }

    .approve {
        background: #F58E16;
        color: #FFF;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .info>div p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
    }

    .info>div p:last-child {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 50%;
        text-align: right;
    }

    .btn-del {
        margin-top: 20px;
        color: #F13A3A;
        font-size: 14px;
        font-weight: 500;
        width: 200px;
        height: 40px;
        border-radius: 15px;
        border: 1px solid #EE3439;
    }

    .formInput {
        width: 60%;
    }

    .img_show {
        width: 227px;
        height: 227px;
        border-radius: 113px;
        cursor: pointer;
        object-fit: cover;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    .img_avatar {
        margin-bottom: 20px;
        width: 227px;
        height: 227px;
        border-radius: 114px;
    }
</style>