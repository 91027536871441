<template>
    <div class="detail kpi-self">
        <h5>KPI cá nhân</h5>
        <el-row class="justify-content-between">
            <el-col :xs="24" :sm="8">
                <div class="info">
                    <img :src="$storage + dataUser.avatar" alt="" @error="handleError">
                    <div>
                        <p>{{ dataUser.full_name }}</p>
                        <p>{{ dataUser.role_name }}</p>
                    </div>
                </div>
                <el-progress type="circle" v-if="dataKPI" :percentage="dataKPI.complete_percent" :color="colors" class="percentage">
                    <template #default="{ percentage }">
                        <span class="percentage-label">Hoàn thành</span>
                        <span class="percentage-value">{{ percentage }}%</span>
                    </template>
                </el-progress>
                <div class="wp-btn">
                    <button @click="$router.push('/cong-viec/quan-ly-kpi/tao-moi-kpi-nhan-vien/' + $route.params.id)" :disabled="dataKPI.KPI_id">+ Add KPI</button>
                    <button class="act_btn" @click="() => dataKPI.KPI_id && $router.push('/cong-viec/quan-ly-kpi/chinh-sua-kpi/' + encode(dataKPI.KPI_id))"><img src="@/assets/icons/pencil.png" alt=""></button>
                    <button class="act_btn" @click="KPI_id = dataKPI.KPI_id, dialogVisible = true"><img src="@/assets/icons/trash.png" alt=""></button>
                </div>
            </el-col>
            <el-col :xs="24" :sm="16">
                <h6>Tháng {{ $moment().format('MM') }}</h6>
                <div class="item" v-for="item_detail in dataKPI.KPI">
                    <div class="ico">
                        <img src="@/assets/icons/bar_chart.png" alt="">
                        <p>{{ item_detail.text }}: </p>
                    </div>
                    <div class="content">
                        <p v-for="content in item_detail.listKPI">
                            <span :class="content.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ parseInt(content.complete).toLocaleString('vi') }}</span> / {{ parseInt(content.target).toLocaleString('vi') }}
                            <span v-if="content.KPI_type == 1">VNĐ</span>
                            <span v-else>{{ content.active_name }}</span>
                        </p>
                    </div>
                    <p class="stt" :class="item_detail.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ item_detail.complete_percent }}%</p>
                </div>
            </el-col>
        </el-row>
    </div>
    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        title="Xóa KPI"
        content="Bạn đang yêu cầu xóa KPI"
    />
</template>
<script>
import Dialog from '../../../components/dialog.vue'
export default {
    components: {
        Dialog,
    },
    data() {
        return {
            dataKPI: [],
            dataUser: [],
            colors: [
                { color: '#ee3439', percentage: 40 },
                { color: '#f58e16', percentage: 99 },
                { color: '#00b569', percentage: 100 },
            ],
            dialogVisible: false,
            KPI_id: ''
        }
    },
    mounted() {
        this.getDataKPI()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async getDataKPI() {
            this.startLoading()
            await this.$axios.get('/job/getKPIPerson',{
                params: {
                    user_id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataKPI = res.data.KPI_detail
                this.dataUser = res.data.user
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading()
        },
        async confirm() {
            this.startLoading();
            await this.$axios.put('/job/deleteKPI', {id: this.KPI_id}, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa KPI thành công!");
                this.getDataKPI()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
    }

    h5 {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
    }

    .info {
        display: flex;
        align-items: center;
    }

    .info img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        object-fit: cover;
        margin-right: 10px
    }

    .info p {
        color: #515151;
        font-weight: 500;
        line-height: 23.821px;
        letter-spacing: 0.16px;
        margin: 0
    }

    .el-progress {
        margin: 20px 0;
    }

    .percentage-label {
        color: #515151;
        text-align: center;
        font-size: 21.069px;
        font-weight: 500;
        line-height: 38.606px;
        letter-spacing: 0.211px;
        display: block;
    }

    .percentage-value {
        color: #515151;
        text-align: center;
        font-size: 36px;
        font-weight: 500;
        line-height: 38.606px;
        letter-spacing: 0.454px;
    }

    .wp-btn {
        display: flex;
        align-items: center;
    }

    .wp-btn button:first-child {
        border-radius: 15px;
        background: #F58E16;
        padding: 10px 14px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-btn button.act_btn {
        border-radius: 23px;
        background: #F58E16;
        padding: 10px;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }

    .wp-btn button.act_btn img {
        filter: brightness(20);
    }

    .wp-btn button.act_btn:last-child {
        background: #EE3439;
    }

    h6 {
        color: #515151;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 30px;
    }

    .item, .item .ico {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item .ico {
        flex-basis: 100%;
        justify-content: flex-start;
    }

    .item p.stt {
        flex-basis: 10%
    }

    .item .content {
        flex-basis: 75%;
    }

    .ico img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .ico p {
        color: #515151;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin: 0;
        font-weight: 400;
    }
    
    .item {
        margin-bottom: 20px;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .item p {
        margin: 0;
        color: #515151;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.16px;
    }

    .item .stt--success {
        color: #00B569;
    }

    .item .stt--fail {
        color: #EE3439;
    }

    button:disabled {
        opacity: 0.7;
    }

    @media (min-width: 1200px) {
    .item .ico {
        flex-basis: 30%;
    }
    
    .item .content {
        flex-basis: 50%;
    }
}
</style>

<style>
    .kpi-self .el-progress-circle {
        width: 190px !important;
        height: 100% !important;
    }
</style>