import { createRouter, createWebHistory } from 'vue-router';
import adminView from "@/layout/admin.vue"
import loginAdmin from "@/pages/login.vue"
import Dashboard from "@/pages/dashboard"
//Chi nhánh
import ListAgency from "@/pages/agencyManager/list_agency.vue"
import DetailAgency from "@/pages/agencyManager/detail.vue"
import AddAgency from "@/pages/agencyManager/add_agency.vue"
import EditAgency from "@/pages/agencyManager/edit_agency.vue"
//Bất động sản
import ListCate from "@/pages/bdsManager/category/list_cate.vue"
import DetailCate from "@/pages/bdsManager/category/detail_cate.vue"
import AddCate from "@/pages/bdsManager/category/add_cate.vue"
import EditCate from "@/pages/bdsManager/category/edit_cate.vue"
import ListApproveBDS from "@/pages/bdsManager/bds/list_approve.vue"
import ListBDS from "@/pages/bdsManager/bds/list_bds.vue"
import DetailBDS from "@/pages/bdsManager/bds/detail.vue"
import AddBDS from "@/pages/bdsManager/bds/add_new.vue"
import UpdateBDS from "@/pages/bdsManager/bds/update_new.vue"
import ExtendBDS from "@/pages/bdsManager/bds/extend_new.vue"
import SettingsNews from "@/pages/bdsManager/settings_new"
import ListBid from "@/pages/bdsManager/bds/list_bid.vue"
//Dự án
import ListDA from "@/pages/bdsManager/project/list_proj.vue"
import DetailDA from "@/pages/bdsManager/project/detail_proj.vue"
import AddProj from "@/pages/bdsManager/project/add_project.vue"
import EditProject from "@/pages/bdsManager/project/update_project.vue"
//Kho bđs
import Storage from "@/pages/bdsManager/storage"
import StorageDetail from "@/pages/bdsManager/storage/detail.vue"
import StorageAdd from "@/pages/bdsManager/storage/formAdd.vue"

//Danh mục dự án
import ListCatePj from "@/pages/bdsManager/category/projectCategory/list_cate_project.vue"
//Nhân viên
import ListEmploy from "@/pages/employManager/list_employ.vue"
import EmployDetail from "@/pages/employManager/detail.vue"
import AddEmploy from "@/pages/employManager/add_employ.vue"
import AuEmploy from "@/pages/employManager/au_employ.vue"
import listFunc from "@/pages/employManager/list_func.vue"
import listApproveSale from "@/pages/employManager/list_approve.vue"
import DetailApprove from "@/pages/employManager/detail_approve.vue"
//Khách hàng
import ConfirmCustomer from "@/pages/customerManager/list_confirm.vue"
import ListCustomer from "@/pages/customerManager/list_customer.vue"
import DetailCustomer from "@/pages/customerManager/detail_customer.vue"
//Tin tức
import AddNews from "@/pages/newsManager/add_news.vue"
import ListNews from "@/pages/newsManager/list_news.vue"
import DetailNews from "@/pages/newsManager/detail.vue"
//Tài chính
import TransactionHistory from "@/pages/finance/transaction_history.vue"
import ListRevenue from "@/pages/finance/revenue.vue"
import WithdrawRequest from "@/pages/finance/withdraw_request.vue"
import SalarySetting from "@/pages/finance/salary_setting.vue"
import IncomeManger from "@/pages/finance/income_manager.vue"
import DetailIncome from "@/pages/finance/detail_income.vue"
import DetailSetting from "@/pages/finance/detail_setting.vue"
import EditSetting from "@/pages/finance/edit_setting.vue"
import ListUserSetting from "@/pages/finance/list_user.vue"
import RevenueManager from "@/pages/workManager/financeManager/components/team.vue"
//Trang cá nhân
import PersonalPage from "@/pages/personalPage/"
//Hỗ trợ
import Support from "@/pages/support/"
import DetailSupport from "@/pages/support/detail.vue"
//Hệ thống
import General from "@/pages/settings/"
import Regulation from "@/pages/settings/regulation.vue"
import Notificate from "@/pages/settings/notification/"
import DetailNoti from "@/pages/settings/notification/detail.vue"
import FormNoti from "@/pages/settings/notification/formNoti.vue"
import Event from "@/pages/settings/event"
import ListWinners from "@/pages/settings/event/list_winners.vue"
import FormEvent from "@/pages/settings/event/form.vue"
//Công việc
import employManager from "@/pages/workManager/employManager"
import staffAgencyManager from "@/pages/workManager/employManager/staffAgencyManager.vue"
import staffCateManager from "@/pages/workManager/employManager/staffCateManager.vue"
import staffTeamManager from "@/pages/workManager/employManager/staffTeamManager.vue"
import detailEmployManager from "@/pages/workManager/employManager/detail.vue"
//CV-Khách hàng
import ListCustomerJob from "@/pages/workManager/customerManager/"
import AddCustomer from "@/pages/workManager/customerManager/form.vue"
import DetailCusJob from "@/pages/workManager/customerManager/detail.vue"
import Demand from "@/pages/workManager/customerManager/demand/"
import DemandDetail from "@/pages/workManager/customerManager/demand/detail.vue"
import ListSuggest from "@/pages/workManager/customerManager/demand/listSuggest.vue"
import FormDemand from "@/pages/workManager/customerManager/demand/form.vue"
import Work from "@/pages/workManager/customerManager/work/"
//CV-KPI
import KPIManager from "@/pages/workManager/kpiManager"
import KPIAgency from "@/pages/workManager/kpiManager/kpiAgency.vue"
import KPICate from "@/pages/workManager/kpiManager/kpiCate.vue"
import KPITeam from "@/pages/workManager/kpiManager/kpiTeam.vue"
import AddKPI from "@/pages/workManager/kpiManager/form.vue"
import UpdateKPI from "@/pages/workManager/kpiManager/update.vue"
//CV-CV
import Job from "@/pages/workManager/jobManager"
import JobTeam from "@/pages/workManager/jobManager/job_team.vue"
import AddJob from "@/pages/workManager/jobManager/form.vue"
import UpdateJob from "@/pages/workManager/jobManager/update.vue"
import DetailJob from "@/pages/workManager/jobManager/detail.vue"
//CV-QLTC
import JobFinance from "@/pages/workManager/financeManager"
import Money from "@/pages/workManager/financeManager/withdrawMoney.vue"
import HistoryMoney from "@/pages/workManager/financeManager/history.vue"
import Regime from "@/pages/workManager/financeManager/regime.vue"
import DetailTrans from "@/pages/workManager/financeManager/detail_tran.vue"
const routes = [
    {
        path: "/",
        component: adminView,
        meta: 
            { 
                title: "Trang quản trị",
                requiresAuth: true
            },
        children: [
            {
                path: "/",
                component: Dashboard,
                meta: { title: "Trang quản trị" },
            },
            //Trang cá nhân
            {
                path: "/trang-ca-nhan/:id",
                component: PersonalPage,
                meta: { title: "Trang cá nhân" },
            },
            //Quản lý chi nhánh
            {
                path: "/quan-ly-chi-nhanh",
                component: ListAgency,
                meta: { title: "Danh sách chi nhánh" },
            },
            {
                path: "/quan-ly-chi-nhanh/chi-tiet/:id",
                component: DetailAgency,
                meta: { title: "Chi tiết chi nhánh" },
            },
            {
                path: "/quan-ly-chi-nhanh/them-moi",
                component: AddAgency,
                meta: { title: "Thêm mới chi nhánh" },
            },
            {
                path: "/quan-ly-chi-nhanh/chinh-sua/:id",
                component: EditAgency,
                meta: { title: "Chỉnh sửa chi nhánh" },
            },
            //Danh mục
            {
                path: "/quan-ly-bds/danh-muc",
                component: ListCate,
                meta: { title: "Danh mục bất động sản" },
            },
            {
                path: "/quan-ly-bds/danh-muc/chi-tiet/:id",
                component: DetailCate,
                meta: { title: "Chi tiết danh mục" },
            },
            {
                path: "/quan-ly-bds/danh-muc/them-moi",
                component: AddCate,
                meta: { title: "Thêm mới danh mục" },
            },
            {
                path: "/quan-ly-bds/danh-muc/chinh-sua/:id",
                component: EditCate,
                meta: { title: "Chỉnh sửa danh mục" },
            },
            //Danh mục dự án
            {
                path: "/quan-ly-bds/danh-muc-du-an",
                component: ListCatePj,
                meta: { title: "Danh mục dự án" },
            },
            //Quản lý Bất động sản
            {
                path: "/quan-ly-bds/duyet-tin-bds",
                component: ListApproveBDS,
                meta: { title: "Duyệt tin bất động sản" },
            },
            {
                path: "/quan-ly-bds/danh-sach-tin-bds",
                component: ListBDS,
                meta: { title: "Danh sách tin bất động sản" },
            },
            {
                path: "/quan-ly-bds/du-an",
                component: ListDA,
                meta: { title: "Dự án bất động sản" },
            },
            {
                path: "/quan-ly-bds/duyet-tin/chi-tiet/:id",
                component: DetailBDS,
                meta: { title: "Chi tiết bất động sản" },
            },
            {
                path: "/quan-ly-bds/danh-sach/chi-tiet/:id",
                component: DetailBDS,
                meta: { title: "Chi tiết bất động sản" },
            },
            {
                path: "/quan-ly-bds/du-an/chi-tiet/:id",
                component: DetailDA,
                meta: { title: "Chi tiết dự án" },
            },
            {
                path: "/quan-ly-bds/du-an/chinh-sua/:id",
                component: EditProject,
                meta: { title: "Chỉnh sửa dự án" },
            },
            {
                path: "/quan-ly-bds/du-an/them-moi",
                component: AddProj,
                meta: { title: "Thêm mới dự án" },
            },
            {
                path: "/quan-ly-bds/danh-sach/tao-moi",
                component: AddBDS,
                meta: { title: "Tạo mới bất động sản" },
            },
            {
                path: "/quan-ly-bds/danh-sach/chinh-sua/:id",
                component: UpdateBDS,
                meta: { title: "Chỉnh sửa bất động sản" },
            },
            {
                path: "/quan-ly-bds/danh-sach/gia-han/:id",
                component: ExtendBDS,
                meta: { title: "Gia hạn bất động sản" },
            },
            {
                path: "/quan-ly-bds/thiet-lap-tin",
                component: SettingsNews,
                meta: { title: "Thiết lập tin đăng" },
            },
            {
                path: "/quan-ly-bds/danh-sach-tra-gia/:id",
                component: ListBid,
                meta: { title: "Danh sách trả giá bất động sản" },
            },
            //Kho bất động sản
            {
                path: "/quan-ly-bds/kho-bds",
                component: Storage,
                meta: { title: "Kho bất động sản" },
            },
            {
                path: "/quan-ly-bds/kho-bds/chi-tiet/:id",
                component: StorageDetail,
                meta: { title: "Chi tiết bất động sản" },
            },
            {
                path: "/quan-ly-bds/kho-bds/chinh-sua/:id",
                component: StorageAdd,
                meta: { title: "Chỉnh sửa bất động sản" },
            },
            {
                path: "/quan-ly-bds/kho-bds/tao-moi",
                component: StorageAdd,
                meta: { title: "Tạo mới bất động sản" },
            },
            // Quản lý nhân viên
            {
                path: "/quan-ly-nhan-vien/danh-sach",
                component: ListEmploy,
                meta: { title: "Danh sách nhân viên" },
            },
            {
                path: "/quan-ly-nhan-vien/danh-sach/chi-tiet/:id",
                component: EmployDetail,
                meta: { title: "Chi tiết nhân viên" },
            },
            {
                path: "/quan-ly-nhan-vien/danh-sach/chinh-sua/:id",
                component: EmployDetail,
                meta: { title: "Chỉnh sửa nhân viên" },
            },
            {
                path: "/quan-ly-nhan-vien/them-moi",
                component: AddEmploy,
                meta: { title: "Thêm mới nhân viên" },
            },
            {
                path: "/quan-ly-nhan-vien/phan-quyen",
                component: AuEmploy,
                meta: { title: "Phân quyền nhân viên" },
            },
            {
                path: "/quan-ly-nhan-vien/phan-quyen/danh-sach-chuc-nang",
                component: listFunc,
                meta: { title: "Danh sách chức năng" },
            },
            {
                path: "/quan-ly-nhan-vien/duyet-nhan-vien",
                component: listApproveSale,
                meta: { title: "Duyệt nhân viên sale" },
            },
            {
                path: "/quan-ly-nhan-vien/duyet-nhan-vien/chi-tiet/:id",
                component: DetailApprove,
                meta: { title: "Chi tiết nhân viên sale" },
            },
            //DUPLICATE
            {
                path: "/quan-ly-nhan-vien/quan-ly-nhan-su",
                component: employManager,
                meta: { title: "Quản lý nhân sự" },
            },
            {
                path: "/quan-ly-nhan-vien/quan-ly-nhan-su/quan-ly-nhan-su-chi-nhanh/:id",
                component: staffAgencyManager,
                meta: { title: "Quản lý nhân sự chi nhánh" },
            },
            {
                path: "/quan-ly-nhan-vien/quan-ly-nhan-su/quan-ly-nhan-su-mang/:id",
                component: staffCateManager,
                meta: { title: "Quản lý nhân sự mảng" },
            },
            {
                path: "/quan-ly-nhan-vien/quan-ly-nhan-su/quan-ly-nhan-su-team/:id",
                component: staffTeamManager,
                meta: { title: "Quản lý nhân sự team" },
            },
            {
                path: "/quan-ly-nhan-vien/quan-ly-nhan-su/chi-tiet-nhan-vien/:id",
                component: detailEmployManager,
                meta: { title: "Chi tiết nhân viên" },
            },
            {
                path: "/cong-viec/quan-ly-nhan-su/quan-ly-nhan-su-chi-nhanh/:id",
                component: staffAgencyManager,
                meta: { title: "Quản lý nhân sự chi nhánh" },
            },
            {
                path: "/cong-viec/quan-ly-nhan-su/quan-ly-nhan-su-mang/:id",
                component: staffCateManager,
                meta: { title: "Quản lý nhân sự mảng" },
            },
            {
                path: "/cong-viec/quan-ly-nhan-su/quan-ly-nhan-su-team/:id",
                component: staffTeamManager,
                meta: { title: "Quản lý nhân sự team" },
            },
            {
                path: "/cong-viec/quan-ly-nhan-su/chi-tiet-nhan-vien/:id",
                component: detailEmployManager,
                meta: { title: "Chi tiết nhân viên" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly",
                component: ListCustomerJob,
                meta: { title: "Danh sách khách hàng quản lý" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/tao-moi",
                component: AddCustomer,
                meta: { title: "Tạo mới khách hàng" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chi-tiet/:id",
                component: DetailCusJob,
                meta: { title: "Chi tiết khách hàng quản lý" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chinh-sua/:id",
                component: AddCustomer,
                meta: { title: "Chỉnh sửa khách hàng" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chi-tiet/:cusId/nhu-cau",
                component: Demand,
                meta: { title: "Nhu cầu khách hàng" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chi-tiet/:cusId/nhu-cau/chi-tiet/:demandId",
                component: DemandDetail,
                meta: { title: "Chi tiết nhu cầu" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chi-tiet/:cusId/nhu-cau/:demandId/bds-phu-hop",
                component: ListSuggest,
                meta: { title: "Bất động sản phù hợp" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chi-tiet/:cusId/nhu-cau/tao-moi",
                component: FormDemand,
                meta: { title: "Tạo mới nhu cầu" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chi-tiet/:cusId/nhu-cau/chinh-sua/:demandId",
                component: FormDemand,
                meta: { title: "Chỉnh sửa nhu cầu" },
            },
            {
                path: "/cong-viec/khach-hang-quan-ly/chi-tiet/:cusId/dau-viec",
                component: Work,
                meta: { title: "Đầu việc của khách hàng" },
            },
            //Quản lý KPI
            {
                path: "/cong-viec/quan-ly-kpi",
                component: KPIManager,
                meta: { title: "Quản lý KPI" },
            },
            {
                path: "/cong-viec/quan-ly-kpi-chi-nhanh/:agency_id",
                component: KPIAgency,
                meta: { title: "Quản lý KPI chi nhánh" },
            },
            {
                path: "/cong-viec/quan-ly-kpi-mang/:cate_id",
                component: KPICate,
                meta: { title: "Quản lý KPI mảng" },
            },
            {
                path: "/cong-viec/quan-ly-kpi-team/:team_id",
                component: KPITeam,
                meta: { title: "Quản lý KPI team" },
            },
            {
                path: "/cong-viec/quan-ly-kpi/tao-moi",
                component: AddKPI,
                meta: { title: "Tạo mới KPI" },
            },
            {
                path: "/cong-viec/quan-ly-kpi/tao-moi-kpi-tong",
                component: AddKPI,
                meta: { title: "Tạo mới KPI tổng" },
            },
            {
                path: "/cong-viec/quan-ly-kpi/tao-moi-kpi-chi-nhanh/:teamId",
                component: AddKPI,
                meta: { title: "Tạo mới KPI chi nhánh" },
            },
            {
                path: "/cong-viec/quan-ly-kpi/tao-moi-kpi-mang/:teamId",
                component: AddKPI,
                meta: { title: "Tạo mới KPI mảng" },
            },
            {
                path: "/cong-viec/quan-ly-kpi/tao-moi-kpi-team/:teamId",
                component: AddKPI,
                meta: { title: "Tạo mới KPI team" },
            },
            {
                path: "/cong-viec/quan-ly-kpi/tao-moi-kpi-nhan-vien/:teamId",
                component: AddKPI,
                meta: { title: "Tạo mới KPI nhân viên" },
            },
            {
                path: "/cong-viec/quan-ly-kpi/chinh-sua-kpi/:kpi_id",
                component: UpdateKPI,
                meta: { title: "Chỉnh sửa KPI" },
            },
            //Công việc
            {
                path: "/cong-viec/quan-ly-cong-viec",
                component: Job,
                meta: { title: "Quản lý công việc" },
            },
            {
                path: "/cong-viec/quan-ly-cong-viec-team/:teamId",
                component: JobTeam,
                meta: { title: "Quản lý công việc team" },
            },
            {
                path: "/cong-viec/quan-ly-cong-viec/chinh-sua-cong-viec/:jobId",
                component: UpdateJob,
                meta: { title: "Chỉnh sửa công việc" },
            },
            {
                path: "/cong-viec/quan-ly-cong-viec/tao-cong-viec-khach-hang/:cusId",
                component: AddJob,
                meta: { title: "Tạo công việc khách hàng" },
            },
            {
                path: "/cong-viec/quan-ly-cong-viec/tao-cong-viec-team",
                component: AddJob,
                meta: { title: "Tạo công việc team" },
            },
            {
                path: "/cong-viec/quan-ly-cong-viec/tao-cong-viec-ca-nhan",
                component: AddJob,
                meta: { title: "Tạo công việc cá nhân" },
            },
            {
                path: "/cong-viec/quan-ly-cong-viec/chi-tiet-cong-viec/:jobId",
                component: DetailJob,
                meta: { title: "Chi tiết công việc" },
            },
            //Quản lý tài chính (Công việc)
            {
                path: "/cong-viec/tai-chinh",
                component: JobFinance,
                meta: { title: "Quản lý tài chính" },
            },
            {
                path: "/cong-viec/tai-chinh/rut-tien",
                component: Money,
                meta: { title: "Rút tiền" },
            },
            {
                path: "/cong-viec/tai-chinh/rut-tien/lich-su",
                component: HistoryMoney,
                meta: { title: "Lịch sử rút tiền" },
            },
            {
                path: "/cong-viec/tai-chinh/che-do",
                component: Regime,
                meta: { title: "Chế độ lương thưởng" },
            },
            {
                path: "/cong-viec/tai-chinh/chi-tiet-giao-dich/:id",
                component: DetailTrans,
                meta: { title: "Chi tiết giao dịch" },
            },
            //Quản lý khách hàng
            {
                path: "/quan-ly-khach-hang/xac-minh-khach-hang",
                component: ConfirmCustomer,
                meta: { title: "Danh sách khách hàng gửi xác minh" },
            },
            {
                path: "/quan-ly-khach-hang/danh-sach",
                component: ListCustomer,
                meta: { title: "Danh sách khách hàng" },
            },
            {
                path: "/quan-ly-khach-hang/danh-sach/chi-tiet/:id",
                component: DetailCustomer,
                meta: { title: "Chi tiết khách hàng" },
            },
            {
                path: "/quan-ly-khach-hang/xac-minh-khach-hang/chi-tiet/:id",
                component: DetailCustomer,
                meta: { title: "Chi tiết khách hàng gửi phê duyệt" },
            },
            //Quản lý tin tức
            {
                path: "/quan-ly-tin-tuc/tao-moi",
                component: AddNews,
                meta: { title: "Tạo mới tin tức" },
            },
            {
                path: "/quan-ly-tin-tuc/danh-sach/chinh-sua/:id",
                component: AddNews,
                meta: { title: "Chỉnh sửa tin tức" },
            },
            {
                path: "/quan-ly-tin-tuc/danh-sach",
                component: ListNews,
                meta: { title: "Danh sách tin tức" },
            },
            {
                path: "/quan-ly-tin-tuc/danh-sach/chi-tiet/:id",
                component: DetailNews,
                meta: { title: "Chi tiết tin tức" },
            },
            //Tài chính
            {
                path: "/quan-ly-tai-chinh/giao-dich-member",
                component: TransactionHistory,
                meta: { title: "Giao dịch member" },
            },
            {
                path: "/quan-ly-tai-chinh/doanh-thu-ve",
                component: ListRevenue,
                meta: { title: "List doanh thu về" },
            },
            {
                path: "/quan-ly-tai-chinh/quan-ly-doanh-thu",
                component: RevenueManager,
                meta: { title: "Quản lý doanh thu" },
            },
            {
                path: "/quan-ly-tai-chinh/yeu-cau-rut-tien",
                component: WithdrawRequest,
                meta: { title: "Yêu cầu rút tiền" },
            },
            {
                path: "/quan-ly-tai-chinh/quan-ly-thu-nhap-nhan-vien",
                component: IncomeManger,
                meta: { title: "Quản lý thu nhập nhân viên" },
            },
            {
                path: "/quan-ly-tai-chinh/quan-ly-thu-nhap-nhan-vien/chi-tiet/:id",
                component: DetailIncome,
                meta: { title: "Chi tiết thu nhập nhân viên" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong",
                component: SalarySetting,
                meta: { title: "Thiết lập lương thưởng" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/che-do-luong-thuong",
                component: SalarySetting,
                meta: { title: "Chế độ lương thưởng" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/thiet-lap-thu-nhap",
                component: SalarySetting,
                meta: { title: "Thiết lập thu nhập nhân viên" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/thuong-doanh-so",
                component: SalarySetting,
                meta: { title: "Thưởng đạt doanh số" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/thue-thu-nhap",
                component: SalarySetting,
                meta: { title: "Thuế thu nhập cá nhân" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/thiet-lap-chung",
                component: SalarySetting,
                meta: { title: "Thiết lập chung" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/chi-tiet-thu-nhap/:id",
                component: DetailSetting,
                meta: { title: "Chi tiết thiết lập thu nhập" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/chinh-sua-thu-nhap/:id",
                component: EditSetting,
                meta: { title: "Chỉnh sửa thiết lập thu nhập" },
            },
            {
                path: "/quan-ly-tai-chinh/thiet-lap-luong-thuong/danh-sach-nhan-vien/:id",
                component: ListUserSetting,
                meta: { title: "Danh sách nhân viên trong thiết lập" },
            },
            //Hỗ trợ
            {
                path: "/ho-tro",
                component: Support,
                meta: { title: "Danh sách hỗ trợ" },
            },
            {
                path: "/ho-tro/chi-tiet/:id",
                component: DetailSupport,
                meta: { title: "Chi tiết hỗ trợ" },
            },
            //Hệ thống
            {
                path: "/he-thong/quan-ly-chung",
                component: General,
                meta: { title: "Quản lý chung" },
            },
            {
                path: "/he-thong/quy-che-dieu-khoan",
                component: Regulation,
                meta: { title: "Quy chế và điều khoản" },
            },
            {
                path: "/he-thong/quan-ly-thong-bao",
                component: Notificate,
                meta: { title: "Quản lý thông báo" },
            },
            {
                path: "/he-thong/quan-ly-thong-bao/chi-tiet/:id",
                component: DetailNoti,
                meta: { title: "Chi tiết thông báo" },
            },
            {
                path: "/he-thong/quan-ly-thong-bao/tao-moi",
                component: FormNoti,
                meta: { title: "Tạo mới thông báo" },
            },
            {
                path: "/he-thong/quan-ly-thong-bao/chinh-sua/:id",
                component: FormNoti,
                meta: { title: "Chỉnh sửa thông báo" },
            },
            {
                path: "/he-thong/chuong-trinh",
                component: Event,
                meta: { title: "Chương trình" },
            },
            {
                path: "/he-thong/chuong-trinh/danh-sach-trung-thuong/:eventId",
                component: ListWinners,
                meta: { title: "Danh sách trúng thưởng" },
            },
            {
                path: "/he-thong/chuong-trinh/chinh-sua/:eventId",
                component: FormEvent,
                meta: { title: "Chỉnh sửa chương trình" },
            },
        ]
    },
    {
        path: "/dang-nhap",
        component: loginAdmin,
        meta: { title: "Trang đăng nhập quản trị viên" },
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('@/pages/404.vue'),
        meta: { title: "404" },
      },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router.beforeEach((to, from, next) => {
//     window.scrollTo(0, 0);
//     next();
// });

// Navigation guard
router.beforeEach((to, from, next) => {
    const isLoggedIn = localStorage.getItem('ad_tk')
    if (to.matched.some(route => route.meta.requiresAuth) && !isLoggedIn) {
        next('/dang-nhap');
        window.scrollTo(0, 0);
    } else {
        next();
        window.scrollTo(0, 0);
    }
});
export default router;