<template>
    <div class="wp-confirm">
        <h5>Duyệt tin đăng bất động sản</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Duyệt tin bất động sản</span>
        </div>
        <el-row class="align-items-center">
            <el-col :span="12">
                <div class="wp-btn d-flex align-items-center">
                    <button :class="{'active' : btnActive == 0}" @click="type = 0, setPage(1), btnActive = 0">Tất cả</button>
                    <button :class="{'active' : btnActive == 1}" @click="type = 1, setPage(1), btnActive = 1">Rao bán</button>
                    <button :class="{'active' : btnActive == 2}" @click="type = 2, setPage(1), btnActive = 2">Cho thuê</button>
                </div>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>

                <button class="btn_filters" @click="actionFilter('showFilter')" ref="btnFilter">
                    <img src="@/assets/icons/filter.png" alt="">
                    <span>Filters</span>
                </button>
                <div class="filter" :class="{'d-none': !showFilter}" ref="formFilter">
                    <p class="ttl">Bộ lọc tin đăng</p>
                    <p>Chọn danh mục</p>
                    <button @click="actionFilter('showList')">
                        <span>{{ cate_name }}</span>
                        <img src="@/assets/icons/arrow.png" alt=""></button>
                    <p>Hạng tin</p>
                    <button @click="actionFilter('showListRank')">
                        <span>{{ rank_name }}</span>
                        <img src="@/assets/icons/arrow.png" alt=""></button>
                    <p>Ngày tạo</p>
                    <el-date-picker
                        v-model="dateSearch"
                        type="date"
                        popper-class="datePicker"
                        placeholder="Chọn ngày"
                        :disabled-date="disabledDate"
                        value-format="YYYY-MM-DD"
                        @change="setPage(1)"
                    />
                </div>
                <div class="list-cate" :class="{'d-none': !showList}">
                    <p class="ttl">Tất cả danh mục</p>
                    <ul>
                        <li @click="cate_id = '', setPage(1), cate_name = 'Tất cả'">Tất cả</li>
                        <li v-for="item in list_cate" :key="item.id" @click="cate_id = item.id, setPage(1), cate_name = item.category_name">{{ item.category_name }}</li>
                    </ul>
                </div>

                <div class="list-cate" :class="{'d-none': !showListRank}">
                    <p class="ttl">Tất cả hạng tin</p>
                    <ul>
                        <li @click="rank_id = '', setPage(1), rank_name = 'Tất cả'">Tất cả</li>
                        <li @click="rank_id = item.id, setPage(1),rank = item.name" v-for="(item, index) in dataRank" :key="index">{{ item.name }}</li>
                    </ul>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectRoute">
                    <el-table-column header-align="center" align="center" fixed label="Hình ảnh" width="102">
                        <template #default="scope">
                            <img :src="$storage + scope.row.thumbnail" alt="" style="width: 60px; height: 60px; border-radius: 15px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="real_estate_name" label="Tiêu đề tin đăng bất động sản" width="466" />
                    <el-table-column header-align="center" align="center" label="Mã tin" width="129">
                        <template #default="scope">
                            <div class="table_code">#{{ scope.row.real_estate_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="type" label="Loại" min-width="118">
                        <template #default="scope">
                            <span v-if="scope.row.type == 1">Rao bán</span>
                            <span v-if="scope.row.type == 2">Cho thuê</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="category_name" label="Danh mục" min-width="254" />
                    <el-table-column header-align="center" align="center" prop="rank" label="Hạng tin" width="129" />
                    <el-table-column header-align="center" align="center" prop="create_at" label="Ngày tạo" min-width="159">
                        <template #default="scope">
                            <span>{{ this.formateDate(scope.row.create_at) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    setup() {
        const disabledDate = (time) => {
            return time.getTime() > Date.now()
        }
        return {
            disabledDate,
        }
    },
    data() {
        return {
            btnActive: 0,
            type: 0,
            keySearch: '',
            cate_id: '',
            rank_id: '',
            dateSearch: '',
            list_cate: [],
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            showFilter: false,
            showList: false,
            showListRank: false,
            cate_name: 'Tất cả',
            rank_name: 'Tất cả',
            dateForm: '',
            dataRank: []
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        this.getListRealEstate()
        this.getListCate()
        this.getDataRank()
        this.dateForm = document.getElementsByClassName('datePicker')[0]
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            if(!this.dateSearch) {
                this.dateSearch = ''
            }
            this.getListRealEstate()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            if(!this.dateSearch) {
                this.dateSearch = ''
            }
            this.getListRealEstate()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        actionFilter(params) {
            this[params] = true
        },
        handleClickOutside() {
            if (!this.$refs.btnFilter.contains(event.target) && !this.$refs.formFilter.contains(event.target) && !this.dateForm.contains(event.target)) {
                this.showFilter = this.showList = this.showListRank = false
            }
        },
        async getListRealEstate() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/real_estate/getListRealEstateForAdmin',{
                params: {
                    news_status: 0,
                    type: this.type,
                    real_estate_status: '',
                    agency_id: '',
                    category_id: this.cate_id,
                    start_date: '',
                    end_date: '',
                    name: this.keySearch,
                    create_date: this.dateSearch,
                    rank: this.rank_id,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListCate() {
            let data = await this.getListCategory();
            this.list_cate = data.result
        },
        async getDataRank() {
            this.dataRank = await this.getListRank()
        },
        redirectRoute(row) {
            this.$router.push('/quan-ly-bds/duyet-tin/chi-tiet/' + this.encode(row.id))
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn {
        width: 320px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        margin: 15px 0 18px;
    }

    .wp-btn button {
        width: 33%;
        font-size: 15px;
        font-weight: 600;
        color: #515151;
        height: 32px;
    }

    .wp-btn button.active {
        border-radius: 13px;
        background: rgba(245, 142, 22, 0.25);
        color: #F58E16;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .btn_filters {
        border-radius: 15px;
        border: 1px solid #E0E2E7;
        background: #FFF;
        padding: 10px 14px;
        display: flex;
        align-items: center;
        max-height: 40px;
        margin-left: 16px;
    }

    .btn_filters span {
        font-size: 14px;
        font-weight: 500;
        color: #515151;
        margin-left: 10px;
    }

    .filter {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 11px 12.2px 17.6px 12px;
        position: absolute;
        top: 48px;
        z-index: 10;
    }

    .filter p {
        margin-bottom: 0;
        color: #686868;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .filter span {
        color: #515151;
    }

    .filter p.ttl {
        font-size: 18px;
        font-weight: 600;
        color: #515151;
        margin-bottom: 5px;
    }

    .filter button {
        border-radius: 32px;
        background: #F4F4F4;
        border: none;
        width: 295px;
        height: 39px;
        padding: 0 21px;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .list-cate {
        padding: 15px 12px 0;
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        position: absolute;
        top: 48px;
        z-index: 10;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .list-cate .ttl {
        font-size: 18px;
        color: #515151;
        font-weight: 500;
        margin-bottom: 16px;
    }
    
    .list-cate ul {
        list-style: none;
        padding: 0;
        max-height: 500px;
        overflow-y: auto;
    }

    .list-cate ul::-webkit-scrollbar {
        width: 0;
    }

    .list-cate ul li {
        border-radius: 32px;
        background: #F4F4F4;
        height: 35px;
        margin-bottom: 8px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        color: #515151;
        cursor: pointer;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    p.rank {
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        background: #FFF;
        width: 90px;
        height: 26px;
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p.rank--dm {
        background: #1DC3CE;
        color: #FFF;
        border: none;
    }
    p.rank--gd {
        background: #FF9838;
        color: #FFF;
        border: none;
    }

    p.rank--sl {
        background: #A1A1A1;
        color: #FFF;
        border: none;
    }

    .table_code {
        color: #F58E16;
        font-weight: 600;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }
</style>

<style>
    .wp-confirm .el-input__wrapper,
    .wp-confirm .el-input__wrapper:hover,
    .wp-confirm .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .wp-confirm .el-form .el-form-item {
        margin-bottom: 0;
    }

    .filter .el-date-editor.el-input {
        width: 100%;
        height: 39px;
    }

    .filter .el-date-editor.el-input .el-input__wrapper {
        border-radius: 32px;
        background: #F4F4F4;
        border: none;
    }

    .wp-confirm .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-confirm .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .wp-paginate .el-pager li.is-active {
        background: #F58E16 !important;
        color: #FFF;
    }

    .wp-paginate .el-pager li {
        border-radius: 8px;
        background: rgba(245, 142, 22, 0.19) !important;
        color: #F58E16;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
    }

    .wp-paginate .el-pager li:hover,
    .wp-paginate button.btn-prev:hover,
    .wp-paginate .is-last:hover {
        color: #F58E16;
    }

    .wp-paginate .is-last,
    .wp-paginate button.btn-prev {
        border-radius: 8px;
        background: rgba(245, 142, 22, 0.19) !important;
        color: #F58E16;
    }

    .datePicker .el-date-picker__header-label {
        z-index: -1;
        position: relative;
    }

    .datePicker .el-date-picker__header {
        z-index: 2;
        position: relative;
    }
</style>