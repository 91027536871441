<template>
    <div class="wp-confirm">
        <h5>Danh mục bất động sản</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh mục bất động sản</span>
        </div>
        <el-row class="align-items-center">
            <el-col :span="12" class="d-flex align-items-center">
                <div class="wp-btn d-flex align-items-center">
                    <button :class="{'active' : btnActive == ''}" @click="type = '', setPage(1), activeBtn('')">Tất cả</button>
                    <button :class="{'active' : btnActive == 1}" @click="type = 1, setPage(1), activeBtn('1')">Rao bán</button>
                    <button :class="{'active' : btnActive == 2}" @click="type = 2, setPage(1), activeBtn('2')">Cho thuê</button>
                </div>
                <router-link to="/quan-ly-bds/danh-muc-du-an" class="btn-route">Danh mục dự án</router-link>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <button class="btn_add" @click="$router.push('/quan-ly-bds/danh-muc/them-moi')">Tạo danh mục mới +</button>
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <!-- <el-table-column header-align="center" align="center" fixed label="Icon" width="102">
                        <template #default="scope">
                            <img :src="$storage + scope.row.icon" alt="" class="cate_ico" @error="handleError" style="width: 30px; height: 30px;">
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="category_name" label="Tên danh mục" width="500" />
                    <el-table-column header-align="center" align="center" prop="type" label="Loại" min-width="118">
                        <template #default="scope">
                            <span v-if="scope.row.type == 1">Rao bán</span>
                            <span v-else-if="scope.row.type == 2">Cho thuê</span>
                            <span v-else>Tất cả</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="count_real_estate" label="Số lượng bất động sản" min-width="254" />
                    <el-table-column header-align="center" align="center" label="Hiển thị" min-width="159">
                        <template #default="scope">
                            <el-switch
                                v-model="scope.row.view_status"
                                :active-value="1"
                                class="ml-2"
                                style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                                @click="activeCate(scope.row)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action" min-width="159">
                        <template #default="scope">
                            <button style="padding: 0 12px;"><img src="@/assets/icons/pencil.png" alt=""></button>
                            <button><img src="@/assets/icons/trash.png" alt=""></button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
import Dialog from '../../components/dialog.vue'
export default {
    components: {
        Pagination,
        Dialog
    },
    data() {
        return {
            btnActive: '',
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 12,
                currentPage: this.$route.query.page || 1,
            },
            clickedActive: false,
            type: ''
        }
    },
    mounted() {
        this.getListCate()
    },
    methods: {
        activeBtn(btn) {
            this.btnActive = btn
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getListCate()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListCate()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        redirectDetail(row) {
            if(!this.clickedActive) {
                this.$router.push('/quan-ly-bds/danh-muc/chi-tiet/' + this.encode(row.id))
            }
            this.clickedActive = false
        },
        async getListCate() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/real_estate/getListCategory',{
                params: {
                    type: this.type,
                    name: this.keySearch,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async activeCate(row) {
            this.clickedActive = true
            let status = null
            if(!row.view_status) {
                status = 0 
            } else {
                status = 1
            }
            const data = {
                "category_id": row.id,
                "view_status": status
            }
            this.startLoading();
            const res = await this.$axios.put('/real_estate/updateViewStatusCategory',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            })
            if (res.status === 200) {
                this.getListCate('','')
                this.showNotify("success", "Thành công", "Thay đổi trạng thái thành công!");
            } else if(res.status === 401) {
                this.handleCheckTokenAuth();
            } else {
                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
            }
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn {
        width: 320px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        margin: 15px 0 18px;
    }

    .wp-btn button {
        width: 33%;
        font-size: 15px;
        font-weight: 600;
        color: #515151;
        height: 32px;
    }

    .wp-btn button.active {
        border-radius: 13px;
        background: rgba(245, 142, 22, 0.25);
        color: #F58E16;
    }

    .btn_add {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #FFF;
        height: 40px;
        width: 200px;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin: 0 0 0 16px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .list-cate {
        padding: 15px 12px 0;
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        position: absolute;
        top: 48px;
        z-index: 10;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .list-cate .ttl {
        font-size: 18px;
        color: #515151;
        font-weight: 500;
        margin-bottom: 16px;
    }
    
    .list-cate ul {
        list-style: none;
        padding: 0;
        max-height: 500px;
        overflow-y: auto;
    }

    .list-cate ul::-webkit-scrollbar {
        width: 0;
    }

    .list-cate ul li {
        border-radius: 32px;
        background: #F4F4F4;
        height: 35px;
        margin-bottom: 8px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        color: #515151;
        cursor: pointer;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .cate_ico {
        border-radius: 15px;
        background: #979797;
        padding: 7px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .btn-route {
        color: #F58E16;
        font-size: 15px;
        font-weight: 500;
        border-radius: 15px;
        background: #FFF;
        width: 200px;
        padding: 8.75px 0;
        text-align: center;
        text-decoration: none;
        margin-left: 16px;
    }
</style>