<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="469"
        class="dialog_rank"
        align-center
        @close="emitDialogVisible(false)"
    >
        <h5 v-if="!id">Tạo gói ngày đăng mới</h5>
        <h5 v-else>Chỉnh sửa gói ngày đăng</h5>
        <el-form
            ref="formAdd"
            :model="formAdd"
            :rules="rules"
        >
            <el-form-item label="Số ngày" prop="day">
                <el-input v-model="formAdd.day" type="text" placeholder="Nhập số ngày, VD: 25" @input="formAdd.day = formAdd.day.replace(/[^0-9]/g, '')" />
                <span class="append">Ngày</span>
            </el-form-item>

            <el-form-item label="Khuyến mãi gói" prop="discount_percent">
                <el-input v-model="formAdd.discount_percent" type="text" placeholder="Nhập số % khuyến mãi" @input="formAdd.discount_percent = formAdd.discount_percent.replace(/[^0-9]/g, '')" />
                <span class="append">%</span>
            </el-form-item>

        </el-form>
        <div class="wp-btn" style="margin-top: 60px;">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm('formAdd', 'add')" v-if="!id">Xác nhận</button>
            <button class="btn_add" @click="confirm('formAdd', 'edit')" v-else>Lưu chỉnh sửa</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['status', 'id'],
    data() {
        return {
            dialogVisibleStatus: false,
            formAdd: {
                day: '',
                discount_percent: ''
            },
            rules: {
                day: [{required: true, message: "Vui lòng nhập số ngày", trigger: ["blur", "change"]}],
                discount_percent: [{required: true, message: "Vui lòng nhập khuyến mãi gói", trigger: ["blur", "change"]}],
            },
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.status
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
        id() {
            if(this.id) {
                this.getPackageDetail()
            } else {
                this.turnOffErrorMessage('formAdd')
            }
        }
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
            this.$refs.formAdd.clearValidate()
            if (!this.id) {
                this.$refs.formAdd.resetFields()
            }
        },
        confirm(formName, type) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.dialogVisibleStatus = false
                    if(type == 'add') {
                        this.$emit("confirm", this.formAdd, 'add', false);
                    } else {
                        this.$emit("confirm", this.formAdd, 'edit', false);
                    }
                    this.turnOffErrorMessage(formName)
                    this.icon_url = ''
                } else {
                    return false;
                }
            });
        },
        async getPackageDetail() {
            await this.$axios.get('/real_estate/getRankPackageDetail',{
                params: {
                    id: this.id
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.formAdd = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 11px;
    }
 
    button {
        background: #fff;
        border-radius: 15px;
        border: 0.905px solid #F58E16;
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        width: 210px;
        height: 38px;
    }

    button.btn_add {
        background: #F58E16;
        color: #FFF;
        border: none;
        margin-left: 9px;
    }

    span.append {
        position: absolute;
        right: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
    }
</style>