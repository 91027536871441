import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            adminInfo: null
        };
    },
    mutations: {
        updateUserCurent(state, adminInfo) {
            state.adminInfo = adminInfo;
        },
        logout(state) {
            state.adminInfo = null;
            localStorage.removeItem('ad_tk');
        },
    },
    actions: {
        loginAdmin(context, adminInfo) {
            context.commit("updateUserCurent", adminInfo);
        },
        logoutAdmin({ commit }) {
            commit('logout');
        },
    },
    getters: {
        adInfo: state => state.adminInfo
    }
});

export default store;