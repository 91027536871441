<template>
    <div class="wp-detail">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :span="16">
                <h5>Chi tiết thu nhập nhân viên</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý tài chính</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Quản lý thu nhập nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết thu nhập nhân viên</span>
                </div>
            </el-col>
            <!-- <el-col :span="8" class="text-end">
                <button class="btn_confirm">Trả lương</button>
            </el-col> -->
        </el-row>

        <div class="wp-content">
            <div style="margin-bottom: 20px;">
                <router-link to="/quan-ly-tai-chinh/quan-ly-thu-nhap-nhan-vien" class="rout">
                    <img src="@/assets/icons/arrow.png" alt="">
                </router-link>
                <span class="ttl">Chi tiết thu nhập nhân viên</span>
            </div>
            <p style="margin-bottom: 10px;">Trạng thái: 
                <span class="txt--yl" v-if="dataDetail.salary_status == 0">Chưa trả lương</span>
                <span class="txt--green" v-else>Đã trả lương</span>
            </p>
            <p>Nhân viên</p>
            <router-link :to="'/quan-ly-nhan-vien/danh-sach/chi-tiet/' + encode(dataDetail.user.id)" class="wp-user" v-if="dataDetail.user">
                <img :src="$storage + dataDetail.user.avatar" alt="" @error="handleError">
                <div class="user-info">
                    <p class="name">{{ dataDetail.user.full_name }}</p>
                    <p>Chức vụ: <span>{{ dataDetail.user.position }}</span></p>
                    <p class="m-0">Thiết lập: <span>{{ dataDetail.user.salary_setting_name }}</span></p>
                </div>
            </router-link>
            <div class="wp-item">
                <div class="d-flex align-items-center justify-content-between">
                    <p>Bắt đầu tính</p>
                    <p>{{ $moment(dataDetail.start_date).format('DD/MM/YYYY') }}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p>Kết thúc tính</p>
                    <p>{{ $moment(dataDetail.end_date).format('DD/MM/YYYY') }}</p>
                </div>
            </div>
            <div class="wp-item">
                <div class="d-flex align-items-center justify-content-between">
                    <p>Ngày đối soát</p>
                    <p>{{ $moment(dataDetail.propose_date).format('DD/MM/YYYY') }}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p>Ngày trả lương</p>
                    <p>{{ $moment(dataDetail.payday).format('DD/MM/YYYY') }}</p>
                </div>
            </div>
            <div class="wp-item">
                <div class="total">
                    <p>Tổng thu nhập</p>
                    <p v-if="dataDetail.income">{{ dataDetail.income.toLocaleString('vi') }} VNĐ</p>
                    <p v-else>0 VNĐ</p>
                </div>
                <div class="total">
                    <p>Thực lĩnh</p>
                    <p class="txt--red" v-if="dataDetail.real_income">{{ dataDetail.real_income.toLocaleString('vi') }} VNĐ</p>
                    <p class="txt--red" v-else>0 VNĐ</p>
                </div>
            </div>
            <div class="salary-item">
                <p class="ttl">Phụ cấp</p>
                <el-form-item prop="number_off_day" label="Tổng số ngày nghỉ trong tháng">
                    <el-input-number :max="31" v-model="dataDetail.number_off_days" placeholder="Nhập số ngày"/>
                </el-form-item>
                <el-form-item prop="number_off_days_paid" label="Số ngày nghỉ có phép">
                    <el-input-number :max="31" v-model="dataDetail.number_off_days_paid" placeholder="Nhập số ngày"/>
                </el-form-item>
    
                <div class="my-3" v-if="dataDetail.allowance" v-for="(item, index) in dataDetail.allowance.general" :key="index">
                    <div class="item_ext">
                        <p class="name">Phụ cấp {{ index + 1 }}: <span>{{ item.title }}</span></p>
                        <p class="price">Mức phụ cấp {{ index + 1 }}: <span class="txt--red">{{ parseInt(item.amount).toLocaleString('vi') }}</span> <span>VNĐ / Ngày</span></p>
                    </div>
                    <p>Số công trong tháng: <span>{{ item.working_day }}</span></p>
                </div>

                <div class="d-flex" v-if="dataDetail.allowance" v-for="(item, index) in dataDetail.allowance.person" :key="index" style="border-bottom: 1px solid #DFE3E7; margin-bottom: 8px;">
                    <div style="flex-basis: 90%;">
                        <el-form-item prop="" label="Phụ cấp khác:">
                            <el-input type="text" v-model="item.title" placeholder="Nhập phụ cấp khác"/>
                        </el-form-item>
                        <el-form-item prop="" label="Số tiền phụ cấp:">
                            <el-input type="text" v-model="item.amount" placeholder="Nhập số tiền" @input="formatPrice('allowance', index)"/>
                            <span class="tag">VNĐ</span>
                        </el-form-item>
                    </div>
                    <button style="flex-basis: 10%;" @click="dataDetail.allowance.person.splice(index, 1)"><img src="@/assets/icons/cross.png" alt=""></button>
                </div>
                <div class="text-end">
                    <button class="add_item" @click="addRow('allowance')">+ Thêm</button>
                </div>
            </div>
            <div class="salary-item">
                <p class="ttl">Phạt</p>
                <div v-if="dataDetail.fine" v-for="(item, index) in dataDetail.fine.general" :key="index">
                    <el-form-item prop="" :label="item.title + ' :'">
                        <el-input type="text" v-model="item.working_day" placeholder="Nhập số ngày"/>
                    </el-form-item>
                    <p>Số tiền {{ item.title }}: <span class="txt--red">{{ parseInt(item.amount).toLocaleString('vi') }}</span> <span>VNĐ / Ngày</span></p>
                </div>

                <div class="d-flex" v-if="dataDetail.fine" v-for="(item, index) in dataDetail.fine.person" :key="index" style="border-bottom: 1px solid #DFE3E7; margin-bottom: 8px;">
                    <div style="flex-basis: 90%;">
                        <el-form-item prop="" label="Phạt khác:">
                            <el-input type="text" v-model="item.title" placeholder="Nhập lỗi"/>
                        </el-form-item>
                        <el-form-item prop="" label="Số tiền phạt:">
                            <el-input type="text" v-model="item.amount" placeholder="Nhập số tiền" @input="formatPrice('fine', index)"/>
                            <span class="tag">VNĐ</span>
                        </el-form-item>
                    </div>
                    <button style="flex-basis: 10%;" @click="dataDetail.fine.person.splice(index, 1)"><img src="@/assets/icons/cross.png" alt=""></button>
                </div>
                <div class="text-end">
                    <button class="add_item" @click="addRow('fine')">+ Thêm</button>
                </div>
            </div>
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12">
                    <div v-if="dataDetail.salary_detail">
                        <h5 class="ttl">Chi tiết thu nhập</h5>
                        <div class="item-clauses" style="background: #F3F7FA;">
                            <p>Hoa hồng doanh số</p>
                            <p class="txt--green">{{ parseInt(dataDetail.salary_detail.total_commission_sales).toLocaleString('vi') }} VNĐ</p>
                        </div>
                        <el-collapse v-model="activeName" accordion class="income-col">
                            <el-collapse-item>
                                <template #title>
                                    <div class="col-ttl">
                                        <div>
                                            <p style="font-weight: 400;">Thưởng doanh số</p>
                                            <p class="txt--green" v-if="dataDetail.salary_detail.total_bonus">{{ parseInt(dataDetail.salary_detail.total_bonus).toLocaleString('vi') }} VNĐ</p>
                                            <p class="txt--green" v-else>0 VNĐ</p>
                                        </div>
                                        <!-- <p>55%</p> -->
                                    </div>
                                </template>
                                <ul class="list-income">
                                    <li v-for="(item, index) in dataDetail.salary_detail.bonus" :key="index">
                                        <p>{{ item.text }}</p>
                                        <p>{{ parseInt(item.amount).toLocaleString('vi') }} VNĐ</p>
                                    </li>
                                </ul>
                            </el-collapse-item>
                        </el-collapse>
                        <div class="item-clauses" style="background: #F3F7FA;">
                            <p>Lương cứng</p>
                            <p class="txt--green">{{ parseInt(dataDetail.salary_detail.base_salary).toLocaleString('vi') }} VNĐ</p>
                        </div>
                        <div class="item-clauses" style="background: #F3F7FA;">
                            <p>Phụ cấp</p>
                            <p class="txt--green">{{ parseInt(dataDetail.salary_detail.allowance).toLocaleString('vi') }} VNĐ</p>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <div class="wp-clauses" v-if="dataDetail.salary_detail">
                        <h5 class="ttl">Các khoản khấu trừ</h5>
                        <div class="item-clauses">
                            <p>Khấu trừ BHXH</p>
                            <p class="txt--red">{{ parseInt(dataDetail.salary_detail.BHXH).toLocaleString('vi') }} VNĐ</p>
                        </div>
                        <div class="item-clauses">
                            <p>Khấu trừ thuế TNCN</p>
                            <p class="txt--red">{{ parseInt(dataDetail.salary_detail.TNCN).toLocaleString('vi') }} VNĐ</p>
                        </div>
                        <div class="item-clauses">
                            <p>Phạt</p>
                            <p class="txt--red">{{ parseInt(dataDetail.salary_detail.total_fine).toLocaleString('vi') }} VNĐ</p>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="wp-btn" v-if="dataDetail.salary_status == 0">
                <button @click="$router.push('/quan-ly-tai-chinh/quan-ly-thu-nhap-nhan-vien')">Thoát</button>
                <button class="btn_cal" @click="updateSalaryStatus(0)">Tính lại lương</button>
                <button class="btn_confirm" @click="updateSalaryStatus(1)">Xác nhận lương</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeName: '',
            dataDetail: []
        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/finance/getDetailSalaryUser',{
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                for (let index = 0; index < this.dataDetail.allowance.person.length; index++) {
                    const element = this.dataDetail.allowance.person[index];
                    element.amount = parseInt(element.amount).toLocaleString('vi')
                }
                for (let index = 0; index < this.dataDetail.fine.person.length; index++) {
                    const element = this.dataDetail.fine.person[index];
                    element.amount = parseInt(element.amount).toLocaleString('vi')
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        addRow(type) {
            const data = {
                title: '',
                working_day: 1,
                amount: ''
            }
            this.dataDetail[type].person.push(data)
        },
        async updateSalaryStatus(type) {
            this.dataDetail.id = this.decode(this.$route.params.id),
            this.dataDetail.salary_status = type
            for (let index = 0; index < this.dataDetail.allowance.person.length; index++) {
                const element = this.dataDetail.allowance.person[index];
                element.amount = element.amount.replace(/[^\d]/g, '')
            }
            for (let index = 0; index < this.dataDetail.fine.person.length; index++) {
                const element = this.dataDetail.fine.person[index];
                element.amount = element.amount.replace(/[^\d]/g, '')
            }
            this.startLoading();
            await this.$axios.put('/finance/updateSalaryStatus', this.dataDetail, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                if(type == 1) {
                    this.showNotify("success", "Thành công", "Xác nhận lương thành công!");
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        formatPrice(type, index) {
            const cleanedValue = this.dataDetail[type].person[index].amount.replace(/[^\d]/g, '');
            this.number = parseInt(cleanedValue, 10);
            this.dataDetail[type].person[index].amount = this.number.toLocaleString('vi');
            if(this.dataDetail[type].person[index].amount == 'NaN') {
                this.dataDetail[type].person[index].amount = ''
            }
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-content a.rout img {
        rotate: 180deg;
        margin-right: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
    }

    button.btn_confirm {
        color:#FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #F58E16;
        min-width: 200px;
        height: 40px;
    }

    .wp-content p {
        font-size: 15px;
        color: #515151;
        margin-bottom: 0
    }

    .wp-user {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        text-decoration: none;
    }

    .wp-user img {
        width: 60px;
        height: 60px;
        border-radius: 32px;
        margin-right: 20px;
        object-fit: cover;
    }

    .wp-user p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .wp-user p span {
        font-weight: 500;
    }

    .wp-user p.name {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .wp-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-item > div {
        border-radius: 15px;
        border: 1px solid#DFE3E7;
        padding: 10px 20px;
        flex-basis: 49%;
        margin-bottom: 5px;
    }

    .wp-item > div:not(.total) p {
        color: #999;
    }

    .wp-item > div p:last-child {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
    }

    .wp-item .total {
        text-align: center;
        margin: 15px 0 0;
    }

    .wp-item .total p:last-child {
        font-size: 20px;
        font-weight: 700;
        margin-top: 5px;
    }

    .salary-item {
        border-bottom: 1px solid #DFE3E7;
        padding: 20px 0;
    }

    .salary-item p {
        margin-bottom: 10px;
    }

    .salary-item p.ttl, .salary-item p span, span.tag {
        font-weight: 500;
    }

    .salary-item p span {
        margin-left: 5px
    }

    span.tag {
        position: absolute;
        right: 15px;
    }

    .item_ext {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item_ext > p {
        flex-basis: 50%;
    }

    p.price span.txt--red {
        width: 50%;
        display: inline-block;
    }

    button.add_item {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        border: 1px solid#F58E16;
        padding: 3px 14px;
        margin-top: 10px;
    }

    h5.ttl {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-top: 20px;
    }

    .col-ttl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        height: 100%;
    }

    .col-ttl p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        /* font-weight: 500; */
        line-height: 110%;
    }

    .col-ttl > div p:last-child {
        font-size: 16px;
        line-height: 120%;
        margin-top: 6px;
    }

    .list-income {
        list-style: none;
        margin: 0;
    }

    .list-income li {
        border-radius: 15px;
        background:#F3F7FA;
        padding: 10px 16px;
        margin: 0 0 5px 20px;
        position: relative;
    }

    .list-income li::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 33%;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #515151;
    }

    .list-income li p:last-child {
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 0;
    }

    .list-income li p {
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .item-clauses {
        padding: 10px 16px;
        border-radius: 15px;
        background:#FEECEC;
        margin-bottom: 10px;
    }

    .item-clauses p {
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .item-clauses p:last-child {
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 0;
    }

    .wp-btn {
        margin-top: 20px
    }

    .wp-btn button:not(.btn_confirm) {
        color:#F13A3A;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid#F13A3A;
        width: 93px;
        height: 40px;
        margin-right: 10px;
    }

    .wp-btn button.btn_confirm {
        width: 100%;
        max-width: 479px;
    }
    
    .wp-btn button.btn_cal {
        width: 100%;
        max-width: 210px;
        color: #fff;
        background-color: #F13A3A;
        border: none;
    }

    .txt--yl {
        color: #F58E16 !important;
        font-weight: 500;
    }

    .txt--red {
        color: #F13A3A !important;
        font-weight: 500;
    }

    .txt--green {
        color: #00B569 !important;
        font-weight: 500;
    }
    
</style>

<style>
    .salary-item .el-form-item {
        display: flex !important;
        align-items: center;
        margin-bottom: 10px;
    }

    .salary-item .el-form-item .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
    }

    .salary-item .el-input__wrapper {
        border-radius: 15px;
        background:#F6F6F6;
        padding: 5px 15px;
    }

    .salary-item .el-input-number {
        width: 100%;
    }

    .salary-item .el-input-number .el-input__wrapper {
       padding-left: 15px;
    }

    .salary-item  .el-input-number .el-input__inner {
       text-align: left;
    }

    .salary-item .el-input-number .el-input-number__decrease,
    .salary-item .el-input-number .el-input-number__increase {
        display: none;
    }
</style>