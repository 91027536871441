<template>
    <div class="wp-form-noti">
        <h5>Quản lý thông báo</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Quản lý thông báo</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active" v-if="!editPage">Tạo thông báo mới</span>
            <span class="active" v-else>Chỉnh sửa thông báo</span>
        </div>
        <div class="wp-form">
            <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 15px;">
                <h5>Tạo thông báo</h5>
                <div class="wp-btn">
                    <button class="cancel" @click="$router.push('/he-thong/quan-ly-thong-bao')">Hủy bỏ</button>
                    <button class="add" @click="addNoti('formNoti')" v-if="!editPage">Tạo thông báo</button>
                    <button class="add" @click="updateNoti('formNoti')" v-else>Chỉnh sửa thông báo</button>
                </div>
            </div>
            <el-form
                ref="formNoti"
                :model="formNoti"
                :rules="rules"
                status-icon
                style="margin-top: 25px;"
            >
                <el-form-item prop="title" label="Tiêu đề">
                    <el-input id="name" type="text" v-model="formNoti.title" placeholder="Nhập tiêu đề"/>
                </el-form-item>
                <el-form-item prop="description" label="Mô tả chi tiết ">
                    <CKeditor
                        :editorData="formNoti.description"
                        @getData="getDataEditor"
                    />
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import CKeditor from '../../components/ckeditor.vue'
export default {
    components: {CKeditor},
    data() {
        return {
            formNoti: {
                title: '',
                description: ''
            },
            rules: {
                title: [{required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]}],
                description: [{required: true, message: "Vui lòng nhập mô tả", trigger: ["blur", "change"]}],
            },
            editPage: false
        }
    },
    watch: {
        "$route.path": function(newUrl, oldUrl) {
            if(newUrl.includes('/quan-ly-thong-bao/chinh-sua')) {
                this.editPage = true
                this.getDetail()
            } else {
                this.editPage = false
                if(this.$refs.formNoti) {
                    this.turnOffErrorMessage('formNoti')
                }
            }
        }
    },
    mounted() {
        if(this.$route.path.includes('/quan-ly-thong-bao/chinh-sua')) {
            this.editPage = true
            this.getDetail()
        } else {
            this.editPage = false
            if(this.$refs.formNoti) {
                this.turnOffErrorMessage('formNoti')
            }
        }
    },
    methods: {
        getDataEditor(data) {
            this.formNoti.description = data
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/notification/getNotificationSystemDetail',{
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.formNoti = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        addNoti(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    await this.$axios.post('/notification/createNotification', this.formNoti, {
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Thêm thông báo thành công!");
                        this.turnOffErrorMessage(formName)
                        this.$router.push('/he-thong/quan-ly-thong-bao')
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
        updateNoti(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    const data = {
                        id: this.decode(this.$route.params.id),
                        title: this.formNoti.title,
                        description: this.formNoti.description,
                        push_status: 0 
                    }
                    this.startLoading();
                    await this.$axios.put('/notification/updateNotification', data, {
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Chỉnh sửa thông báo thành công!");
                        this.turnOffErrorMessage(formName)
                        this.$router.push('/he-thong/quan-ly-thong-bao/chi-tiet/' + this.$route.params.id)
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn button {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        width: 160px;
        height: 38px;
        border-radius: 15px;
        color: #FFF;
    }

    .wp-btn button.cancel {
        border: 1px solid #F13A3A;
        color: #F13A3A;
    }

    .wp-btn button.add {
        background: #F58E16;
        margin-left: 10px;
    }

    .wp-form {
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin-top: 15px;
    }

    .wp-form h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 0;
    }
</style>

<style>
    .wp-form-noti .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 10px;
        box-shadow: none;
    }
</style>