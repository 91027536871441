<template>
    <div v-if="$route.path.includes('/quan-ly-tai-chinh')">
        <h5 class="br_ttl">Quản lý doanh thu</h5>
        <div class="breadcrumb">
            <span>Quản lý tài chính</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Quản lý doanh thu</span>
        </div>
    </div>
    <div v-if="type == 'month'">
        <div class="wp-team" :class="{'wp-team-radius' : $route.path.includes('/quan-ly-tai-chinh')}">
            <el-row :gutter="30">
                <el-col :xs="24" :sm="24" :md="10">
                    <h5 class="ttl" v-if="type_api == 'general'">Tổng doanh thu RAKHOI</h5>
                    <h5 class="ttl" v-else-if="type_api == 'agency'">Tổng doanh thu chi nhánh</h5>
                    <h5 class="ttl" v-else-if="type_api == 'group'">Tổng doanh thu mảng bất động sản</h5>
                    <h5 class="ttl" v-else-if="type_api == 'team'">Tổng doanh thu Team</h5>
                    <h5 class="ttl" v-else-if="type_api == 'person'">Doanh thu cá nhân</h5>
                    <div class="wp-filter" v-if="type_api != 'person'">
                        <el-select clearable placeholder="Chi nhánh" v-model="agency_id" style="flex-basis: 32%;" @change="getGroupByAgency">
                            <el-option v-for="(item, index) in listAgency"
                                :key="index"
                                :label="item.agency_name"
                                :value="item.id"
                            />
                        </el-select>
                        <el-select clearable placeholder="Mảng" v-model="group_id" style="flex-basis: 32%;" @change="getTeamByGroup">
                            <el-option v-for="(item, index) in listGroup"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                        <el-select clearable placeholder="Team" v-model="team_id" style="flex-basis: 32%;" @change="changeTeam">
                            <el-option v-for="(item, index) in listTeam"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="wp-user" v-if="type_api == 'person'">
                        <img :src="$storage + userDetail.avatar" alt="" @error="handleError">
                        <div>
                            <p class="name">{{ userDetail.full_name }}</p>
                            <p>Chức vụ: <span>{{ userDetail.role_name }}</span></p>
                        </div>
                    </div>
                    <el-row :gutter="20" style="margin-bottom: 40px;">
                        <el-col :xs="24" :sm="10">
                            <el-progress type="circle" :percentage="dataKPI.complete_percent" :color="colors">
                                <template #default="{ percentage }">
                                    <span class="percentage-label">Hoàn thành</span>
                                    <span class="percentage-value">{{ percentage }}%</span>
                                    <span class="percentage-label">{{ $moment.utc().format('DD/MM/YYYY') }}</span>
                                </template>
                            </el-progress>
                        </el-col>
                        <el-col :xs="24" :sm="14">
                            <div class="item">
                                <p>Doanh thu hiện tại</p>
                                <p class="txt--red" v-if="dataKPI.finance">{{ parseInt(dataKPI.finance).toLocaleString('vi')}} VNĐ</p>
                                <p class="txt--red" v-else>0 VNĐ</p>
                            </div>
                            <div class="item">
                                <p>KPI doanh thu / tháng</p>
                                <p v-if="dataKPI.target">{{ parseInt(dataKPI.target).toLocaleString('vi')}} VNĐ</p>
                                <p v-else>0 VNĐ</p>
                            </div>
                            <p class="time">Bắt đầu: <span>{{ $moment(dataKPI.start_date).format('DD/MM/YYYY') }}</span></p>
                            <p class="time">Kết thúc: <span>{{ $moment(dataKPI.end_date).format('DD/MM/YYYY') }}</span></p>
                        </el-col>
                    </el-row>
                    <div v-if="total_percent != 0 && type_api != 'person'">
                        <Doughnut
                            :txt="'Tỷ trọng doanh số'"
                            :dataValue="dataDoughnut"
                        />
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="14">
                    <div class="nav-bar text-end mt-3 mt-lg-0">
                        <button @click="changeDate('prev')"><img src="@/assets/icons/arrow-left.png" alt="" style="rotate: 180deg;"></button>
                        <span>Tháng {{ formattedDate }}</span>
                        <button @click="changeDate('next')"><img src="@/assets/icons/arrow-left.png" alt="" style="margin-bottom: 1px;"></button>
                        <button @click="type = 'year'">Tháng</button>
                    </div>
                    <BarChart :color="'#E4983E'" :dataValue="dataBar"/>
                </el-col>
            </el-row>
        </div>
        <div class="wp-team-item" v-if="type_api != 'person'">
            <h5 class="ttl">Chi tiết doanh thu</h5>
            <el-row class="align-items-center mt-3">
                <el-col :xs="24" :sm="12">
                    <p class="total">Số nhân viên: <span>{{ dataUser.length }}</span></p>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="mt-4">
                <el-col :xs="24" :sm="12" :md="8" v-for="(item, index) in dataUser" :key="index" @click="getDetail(item)">
                    <div class="item-staff">
                        <img :src="$storage + item.avatar" alt="" @error="handleError">
                        <div class="staff_info">
                            <p class="name" v-if="index == 0 || type_api == 'team'">{{ item.full_name }}</p>
                            <p class="name" v-else>{{ item.team_name }}</p>
                            <p v-if="index == 0 || type_api == 'team'">Chức vụ: <span>{{ item.role_name }}</span></p>
                            <p v-else>Quản lý: <span>{{ item.full_name }}</span></p>
                            <p class="mb-0">Doanh số: <span class="txt--red" style="font-weight: 500;">{{ parseInt(item.finance).toLocaleString('vi') }} VNĐ</span></p>
                        </div>
                        <p class="mb-0" style="font-size: 15px; font-weight: 500;">{{ item.complete_percent }}%</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <TransTBL
            v-else
            :tableData="tableData"
            :page="page"
            @setPerPage="setPerPage"
            @setPage="setPage"
            @changeOrder="changeOrder"
        />
    </div>
    <ViewYear v-else
        :colorBar="'#E4983E'"
        :type="'team'"
        @changeTypeView="changeType"
    />
</template>
<script>
import BarChart from '../../../components/barChart'
import ViewYear from './view_year'
import Doughnut from './doughnut'
import TransTBL from './tran_tbl'
export default {
    components: {
        BarChart,
        ViewYear,
        Doughnut,
        TransTBL
    },
    data() {
        return {
            colors: [
                { color: '#ee3439', percentage: 40 },
                { color: '#f58e16', percentage: 99 },
                { color: '#00b569', percentage: 100 },
            ],
            currentDate: this.$moment(),
            type: 'month',
            sort_txt: 'Sắp xếp',
            listAgency: [],
            listGroup: [],
            listTeam: [],
            agency_id: '',
            group_id: '',
            team_id: '',
            type_api: 'general',
            dataKPI: [],
            dataBar: {
                label: [],
                data: []
            },
            dataDoughnut: {
                label: [],
                data: []
            },
            dataUser: [],
            total_percent: 1,
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: 1,
            },
            tableData: [],
            order: '',
            user_id: '',
            userDetail: []
        }
    },
    computed: {
        formattedDate() {
            return this.currentDate.format('MM - YYYY');
        },
    },
    mounted() {
        this.getListAgency()
        this.getFinanceMonth()
    },
    methods: {
        changeDate(type) {
            if(type == 'prev') {
                this.currentDate = this.currentDate.clone().subtract(1, 'month');
            } else {
                this.currentDate = this.currentDate.clone().add(1, 'month');
            }
            this.getFinanceMonth()
        },
        changeType(value) {
            this.type = value
            this.getFinanceMonth()
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getListJobTransactionPerson()
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListJobTransactionPerson()
        },
        changeOrder(value) {
            this.order = value
            this.setPage(1)
        },
        async getListAgency() {
            await this.$axios.get('/common/getListAgency',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listAgency = res.data
            })
        },
        async getGroupByAgency() {
            if(!this.agency_id) {
                this.group_id = ''
                this.team_id = ''
                this.listTeam = []
                this.type_api = 'general'
            } else {
                this.type_api = 'agency'
            }
            this.startLoading()
            await this.$axios.get('/common/getListGroupByAgency?agency_id=' + this.agency_id,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listGroup = res.data
            })
            this.endLoading()
            this.getFinanceMonth()
        },
        changeTeam() {
            if(!this.team_id) {
                this.type_api = 'group'
            } else {
                this.type_api = 'team'
            }
            this.getFinanceMonth()
        },
        async getTeamByGroup() {
            if(!this.group_id) {
                this.team_id = ''
                this.type_api = 'agency'
            } else {
                this.type_api = 'group'
            }
            this.startLoading()
            await this.$axios.get('/common/getListTeamByGroup?group_id=' + this.group_id,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listTeam = res.data
            })
            this.endLoading()
            this.getFinanceMonth()
        },
        async getFinanceMonth() {
            this.startLoading();
            await this.$axios.get('/finance/getFinanceMonth',{
                params: {
                    month: this.currentDate.format('M'),
                    year: this.currentDate.format('YYYY'),
                    type: this.type_api,
                    agency_id: this.agency_id,
                    group_id: this.group_id,
                    team_id: this.team_id,
                    user_id: this.user_id
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataKPI = res.data.KPI
                this.dataUser = res.data.list_user
                this.dataBar.label = res.data.list_finance.map(item => item.day)
                this.dataBar.data = res.data.list_finance.map(item => item.deposits)
                this.dataDoughnut.data = res.data.list_user.map(item => item.complete_percent)
                this.dataDoughnut.label = []
                this.total_percent = 0
                for (let index = 0; index < res.data.list_user.length; index++) {
                    const element = res.data.list_user[index];
                    if(index == 0 || this.type_api == 'team') {
                        this.dataDoughnut.label.push(element.full_name + ' (' + element.complete_percent + '%)' + ': ' + parseInt(element.finance).toLocaleString('vi') + ' VNĐ')
                    } else {
                        this.dataDoughnut.label.push(element.team_name + ' (' + element.complete_percent + '%)' + ': ' + parseInt(element.finance).toLocaleString('vi') + ' VNĐ')
                    }
                    this.total_percent += element.complete_percent
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail(data) {
            this.userDetail = data
            this.agency_id = data.agency_id
            this.group_id = data.group_id
            this.team_id = data.team_id
            this.type_api = data.type
            if(this.type_api == 'agency') {
                this.getGroupByAgency()
            } else if(this.type_api == 'group') {
                this.getTeamByGroup()
            } else if (this.type_api == 'team') {
                this.changeTeam()
            } else if (this.type_api == 'person') {
                this.user_id = data.user_id
                this.getFinanceMonth()
                this.getListJobTransactionPerson()
            }
        },
        async getListJobTransactionPerson() {
            this.startLoading();
            await this.$axios.get('/finance/getListJobTransactionPerson',{
                params: {
                    order_by: this.order,
                    month: this.currentDate.format('M'),
                    year: this.currentDate.format('YYYY'),
                    page: this.page.currentPage,
                    per_page: this.page.pageSize,
                    user_id: this.user_id
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    },
}
</script>
<style lang="css" scoped>
    .wp-team {
        padding: 20px;
        border-radius: 0 20px 20px 20px;
        background: #FFF;
        margin-bottom: 20px;
    }

    .wp-team-radius {
        border-radius: 20px;
    }

    h5.br_ttl {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    h5.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
    }

    .item {
        border-radius: 15px;
        border: 1px solid #DFE3E7;
        padding: 10px;
        text-align: center;
        margin-bottom: 15px;
    }

    .item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .txt--red {
        color: #F13A3A !important;
    }

    .item p:last-child {
        color: #515151;
        font-size: 20px;
        font-weight: 700;
    }

    p.time {
        color:#999;
        line-height: 100%;
        margin-bottom: 5px;
        text-align: center;
    }

    p.time span {
        color: #515151;
        text-align: center;
        font-weight: 500;
        line-height: 120%;
    }

    .nav-bar {
        margin-bottom: 20px;
    }

    .nav-bar button {
        border-radius: 10px;
        border: 1px solid#999;
        padding: 2px 7.5px;
        margin: 0 10px;
    }

    .nav-bar button img {
        width: 13px;
        height: 10px;
    }

    .nav-bar button:first-child {
        margin-left: 0;
    }

    .nav-bar button:last-child {
        color: #515151;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        width: 94px;
        height: 40px;
        border-radius: 15px;
        margin: 0 0 0 10px;
        position: relative;
    }

    .nav-bar button:last-child::before {
        content: '';
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 0px;
        background: #999;
    }

    .nav-bar span {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
    }

    .wp-team-item h5.ttl {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 5px;
    }

    p.total {
        color: #515151;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin-bottom: 0;
    }

    p.total span {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.18px;
        margin-left: 5px;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .item-staff {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border-radius: 15px;
        background:#FFF;
        padding: 10px 16px;
        cursor: pointer;
    }

    .item-staff img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        object-fit: cover;
        margin-right: 10px;
    }

    .staff_info {
        flex-basis: 80%;
    }

    .staff_info p.name {
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
    }

    .staff_info p {
        margin-bottom: 10px;
        color: #515151;
        font-size: 15px;
        line-height: 110%;
    }

    .staff_info p span {
        font-size: 16px;
    }

    .wp-user {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    .wp-user img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 32px;
        margin-right: 20px;
    }

    .wp-user p {
        color: #515151;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 0
    }

    .wp-user p span {
        font-weight: 500;
    }

    .wp-user p.name {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    @media (min-width: 992px) {
        .wp-gift {
            margin-bottom: 0;
        }
    }

    @media (min-width: 575px) {
        .nav-bar button {
            margin: 0 20px;
        }

        .nav-bar button:last-child::before {
            width: 1px;
        }
        
        .nav-bar button:last-child {
            margin: 0 0 0 40px;
        }
    }
</style>

<style>
    .wp-team .wp-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
    }
    .wp-team .wp-filter .el-select .el-input__wrapper {
        border-radius: 15px;
        box-shadow: 0 0 0 1px #515151 inset;
        height: 40px;
    }

    .wp-team .wp-filter .el-select .el-input__inner::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: #515151;
    }
</style>