<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="348"
        class="dialog_reject"
        @close="emitDialogVisible(false)"
        align-center
    >
        <div v-if="type == 1">
            <img src="@/assets/icons/ok.png" alt="">
            <p class="ttl">Chấp nhận yêu cầu đổi KPI</p>
            <p>Yêu cầu đổi KPI của nhân viên đã được xác nhận. Mức KPI đã được thay đổi</p>
        </div>
        <div v-else-if="type == 2">
            <img src="@/assets/icons/close.png" alt="">
            <p class="ttl">Từ chối yêu cầu đổi KPI</p>
            <p>Yêu cầu đổi KPI của nhân viên đã bị từ chối. <br/> Mức KPI sẽ được giữ nguyên </p>
            <p style="font-size: 15px; color: #515151; font-weight: 500; text-align: left; margin-bottom: 5px;">Lý do từ chối:</p>
            <p class="content">{{ reason }}</p>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['complainDetail', 'reason', 'type'],
    data() {
        return {
            dialogVisibleStatus: false,
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.complainDetail
    },
    watch: {
        complainDetail() {
            this.dialogVisibleStatus = this.complainDetail;
        },
    },
    methods: {
        emitDialogVisible(complainDetail) {
            this.$emit("handleCloseDialog", complainDetail);
        }
    },
}
</script>
<style lang="css" scoped>
    img {
        width: 54px;
        height: 54px;
        margin-bottom: 10px;
    }

    p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        line-height: 100%;
    }

    p {
        color: #515151;
        font-size: 16px;
        margin: 15px 0;
    }

    p.content {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        border-radius: 15px;
        padding: 12px;
        text-align: justify;
        border-radius: 15px;
        background: #FEECEC;
        margin: 0;
    }
</style>