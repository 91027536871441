<template>
    <div v-if="type == 'month'">
        <div class="wp-personal">
            <el-row :gutter="30">
                <el-col :xs="24" :sm="24" :md="10">
                    <h5 class="ttl">Doanh thu cá nhân</h5>
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="10">
                            <el-progress type="circle" :percentage="dataKPI.complete_percent" :color="colors">
                                <template #default="{ percentage }">
                                    <span class="percentage-label">Hoàn thành</span>
                                    <span class="percentage-value">{{ percentage }}%</span>
                                    <span class="percentage-label">{{ $moment.utc().format('DD/MM/YYYY') }}</span>
                                </template>
                            </el-progress>
                        </el-col>
                        <el-col :xs="24" :sm="14">
                            <div class="item">
                                <p>Doanh thu hiện tại</p>
                                <p class="txt--red" v-if="dataKPI.finance">{{ parseInt(dataKPI.finance).toLocaleString('vi')}} VNĐ</p>
                                <p class="txt--red" v-else>0 VNĐ</p>
                            </div>
                            <div class="item">
                                <p>KPI doanh thu / tháng</p>
                                <p v-if="dataKPI.target">{{ parseInt(dataKPI.target).toLocaleString('vi')}} VNĐ</p>
                                <p v-else>0 VNĐ</p>
                            </div>
                            <p class="time">Bắt đầu: <span>{{ $moment(dataKPI.start_date).format('DD/MM/YYYY') }}</span></p>
                            <p class="time">Kết thúc: <span>{{ $moment(dataKPI.end_date).format('DD/MM/YYYY') }}</span></p>
                        </el-col>
                    </el-row>
                    <div class="wp-gift" v-if="dataMocDoanhThu.bonus_sales_status == 1">
                        <p>Mốc thưởng doanh thu cá nhân</p>
                        <p class="price"><span class="txt--red">{{ parseInt(dataMocDoanhThu.finance).toLocaleString('vi') }}</span> / {{ parseInt(dataMocDoanhThu.next_target).toLocaleString('vi') }} VNĐ</p>
                        <el-progress :percentage="dataMocDoanhThu.complete_percent" :color="colors">
                            <el-tooltip
                                class="box-item"
                                effect="dark"
                                :content="parseInt(dataMocDoanhThu.next_bonus).toLocaleString('vi') + ' VNĐ'"
                                placement="top"
                            >
                                <img src="@/assets/icons/gift.png" alt="" style="width: 26px; height: 26px;">
                            </el-tooltip>
                        </el-progress>
                        <p>Mức thưởng đã đạt: <span>{{ parseInt(dataMocDoanhThu.bonus).toLocaleString('vi') }} VNĐ</span></p>
                        <p>Kết quả sẽ được cập nhật lại sau ngày: <span>{{ currentDate.endOf('month').format('DD/MM/YYYY') }}</span></p>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="14">
                    <div class="nav-bar text-end">
                        <button @click="changeDate('prev')"><img src="@/assets/icons/arrow-left.png" alt="" style="rotate: 180deg;"></button>
                        <span>Tháng {{ formattedDate }}</span>
                        <button @click="changeDate('next')"><img src="@/assets/icons/arrow-left.png" alt="" style="margin-bottom: 1px;"></button>
                        <button @click="getFinanceYear">Tháng</button>
                    </div>
                    <BarChart :color="'#3FA5FF'" :dataValue="dataBar"/>
                </el-col>
            </el-row>
        </div>
        <TransTBL
            :tableData="tableData"
            :page="page"
            @setPerPage="setPerPage"
            @setPage="setPage"
            @changeOrder="changeOrder"
        />
    </div>
    <ViewYear v-else
        :colorBar="'#3FA5FF'"
        :type="'personal'"
        :dataValue="dataBar"
        :dataYear="dataYear"
        :currentDate="currentDate"
        @changeTypeView="changeType"
        @changeYear="changeYear"
    />
</template>
<script>
import BarChart from '../../../components/barChart'
import TransTBL from './tran_tbl'
import ViewYear from './view_year'
export default {
    components: {
        BarChart,
        TransTBL,
        ViewYear,
    },
    data() {
        return {
            colors: [
                { color: '#ee3439', percentage: 40 },
                { color: '#f58e16', percentage: 99 },
                { color: '#00b569', percentage: 100 },
            ],
            currentDate: this.$moment(),
            type: 'month',
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: 1,
            },
            tableData: [],
            order: '',
            dataKPI: [],
            dataBar: {
                label: [],
                data: []
            },
            dataYear: [],
            dataMocDoanhThu: []
        }
    },
    computed: {
        formattedDate() {
            return this.currentDate.format('MM - YYYY');
        },
    },
    mounted() {
        this.getFinanceMonth()
    },
    methods: {
        changeDate(type) {
            if(type == 'prev') {
                this.currentDate = this.currentDate.clone().subtract(1, 'month');
            } else {
                this.currentDate = this.currentDate.clone().add(1, 'month');
            }
            this.getFinanceMonth()
        },
        changeType(value) {
            this.type = value
            this.getFinanceMonth()
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getListJobTransactionSelf()
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListJobTransactionSelf()
        },
        changeOrder(value) {
            this.order = value
            this.setPage(1)
        },
        async getListJobTransactionSelf() {
            this.startLoading();
            await this.$axios.get('/finance/getListJobTransactionSelf',{
                params: {
                    order_by: this.order,
                    month: this.currentDate.format('M'),
                    year: this.currentDate.format('YYYY'),
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getFinanceMonth() {
            this.startLoading();
            await this.$axios.get('/finance/getFinanceMonth',{
                params: {
                    month: this.currentDate.format('M'),
                    year: this.currentDate.format('YYYY'),
                    type: 'person'
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataKPI = res.data.KPI
                this.dataMocDoanhThu = res.data.moc_doanh_thu
                this.dataBar.label = res.data.list_finance.map(item => item.day)
                this.dataBar.data = res.data.list_finance.map(item => item.deposits)
                this.getListJobTransactionSelf()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getFinanceYear() {
            this.type = 'year'
            this.startLoading();
            await this.$axios.get('/finance/getFinanceYear',{
                params: {
                    year: this.currentDate.format('YYYY'),
                    type: 'person'
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataYear = res.data
                this.dataBar.label = res.data.list_finance.map(item => item.months)
                this.dataBar.data = res.data.list_finance.map(item => item.total_deposits_month)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        changeYear(value) {
            this.currentDate = value
            this.getFinanceYear()
        }
    },
}
</script>
<style lang="css" scoped>
    .wp-personal {
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin-bottom: 20px;
    }

    h5.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
    }

    .item {
        border-radius: 15px;
        border: 1px solid #DFE3E7;
        padding: 10px;
        text-align: center;
        margin-bottom: 15px;
    }

    .item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .txt--red {
        color: #F13A3A !important;
    }

    .item p:last-child {
        color: #515151;
        font-size: 20px;
        font-weight: 700;
    }

    p.time {
        color:#999;
        line-height: 100%;
        margin-bottom: 5px;
        text-align: center;
    }

    p.time span {
        color: #515151;
        text-align: center;
        font-weight: 500;
        line-height: 120%;
    }

    .wp-gift {
        border-radius: 15px;
        border: 1px solid #DFE3E7;
        padding: 10px;
        margin: 20px 0;
    }

    .wp-gift p {
        color:#999;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .wp-gift p:first-child {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
    }

    .wp-gift p.price {
        color:#515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 117%;
        margin-bottom: 0;
    }

    .wp-gift p span {
        color:#515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
    }
    
    .nav-bar {
        margin-bottom: 20px;
    }

    .nav-bar button {
        border-radius: 10px;
        border: 1px solid#999;
        padding: 2px 7.5px;
        margin: 0 10px;
    }

    .nav-bar button img {
        width: 13px;
        height: 10px;
    }

    .nav-bar button:first-child {
        margin-left: 0;
    }

    .nav-bar button:last-child {
        color: #515151;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        width: 94px;
        height: 40px;
        border-radius: 15px;
        margin: 0 0 0 10px;
        position: relative;
    }

    .nav-bar button:last-child::before {
        content: '';
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 0px;
        background: #999;
    }

    .nav-bar span {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
    }

    @media (min-width: 992px) {
        .wp-gift {
            margin-bottom: 0;
        }
    }

    @media (min-width: 575px) {
        .nav-bar button {
            margin: 0 20px;
        }

        .nav-bar button:last-child::before {
            width: 1px;
        }
        
        .nav-bar button:last-child {
            margin: 0 0 0 40px;
        }
    }
</style>