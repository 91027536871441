<template>
    <div class="wp-money">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :span="24">
                <h5>Quản lý tài chính</h5>
                <div class="breadcrumb mb-0">
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Quản lý tài chính</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chế độ lương thưởng</span>
                </div>
            </el-col>
        </el-row>

        <div class="wp-content">
            <div style="margin-bottom: 20px;">
                <router-link to="/cong-viec/tai-chinh">
                    <img src="@/assets/icons/arrow.png" alt="">
                </router-link>
                <span class="ttl">Chế độ lương thưởng</span>
                <p style="margin: 25px 0 0;">
                    <b>Chế độ lương, thưởng</b> <br>
                    <b>1: Chế độ lương, thưởng của Địa phương</b><br>
                    Mức lương: 10.000.000/ tháng<br>
                    Hoa hồng Địa phương được hưởng:<br>
                    + Trực tiếp: Hưởng 60% doanh thu từ hoa hồng<br>
                    + Gián tiếp: Hưởng từ hoa hồng gián tiếp 60%<br>
                    + Qua công ty: Công ty sẽ giữ lại 50% hoa hồng. Địa phương sẽ nhận được 60% từ 50% hoa hồng còn lại.<br>
                    Thưởng: Được thưởng thêm 10% hoa hồng nếu doanh thu đạt 300.000.000 về công ty<br>
                    <b>2: Chế độ lương, thưởng của CTV</b><br>
                    Mức lương<br>
                        + Doanh thu đạt: 100.000.000 nhận mức lương 6.000.000<br>
                        + Doanh thu đạt: 25.000.000 - 50.000.000 nhận mức lương 3.000.000<br>
                        + Doanh thu đạt: &lt; 25.000.000 nhận mức lương 1.500.000 <br>
                    Hoa hồng CTV được hưởng: Sẽ thoả thuận trực tiếp với Địa phương quản lý CTV<br>
                    Thưởng: Được thưởng 30.000.000 nếu doanh thu đạt 300.000.000<br>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
           
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-content a img {
        rotate: 180deg;
        margin-right: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
    }
</style>