<template>
    <div>
        <div class="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div>
                <h5>Quản lý công việc</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Quản lý công việc</span>
                </div>
            </div>
        </div>

        <el-row>
            <el-col :span="24">
                <div class="wp-job">
                    <h5>Quản lý đầu việc của team</h5>
                    <h5 v-if="team_name">{{ team_name }}</h5>
                    <!-- <div class="text-end">
                        <button @click="redirectAdd">+ Thêm đầu việc mới</button>
                    </div> -->
                    <calender
                        :data="dataProps"
                        :dataCalender="dataCalender"
                        :type="2"
                        :teamId="team_id"
                        @deleteSuccess="deleteSuccess"
                        @filterChange="getDataFilter"
                        @selectDay="getDay"
                        @leader="getInfoTeam"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
    <formInterac
        :status="dialogVisible"
        @handleCloseDialog="listenDialog"
    />
</template>

<script>
import calender from "@/pages/components/calender.vue"
import formInterac from "./dialog/interacList.vue"
export default {
    components: {
        calender,
        formInterac
    },
    data() {
        return {
            dialogVisible: false,
            dataProps: [],
            dataCalender: [],
            dataFilter: {
                kpi: '',
                job_status: '',
                active_id: '',
                level: '',
                start_date: '',
                end_date: '',
                user_id: ''
            },
            team_id: this.decode(this.$route.params.teamId),
            team_name: '',
            listTeam: []
        }
    },
 
    mounted() {
       this.getDataJobTeam()
       this.getDataCalender()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        selectTab() {
            this.dataFilter = {
                kpi: '',
                job_status: '',
                active_id: '',
                level: '',
                start_date: '',
                end_date: '',
                user_id: ''
            }
            this.getDataJobTeam()
            this.getDataCalender()
        },
        deleteSuccess(start_date, end_date) {
            this.dataFilter.start_date = start_date
            this.dataFilter.end_date = end_date
            this.getDataJobTeam()
            this.getDataCalender()
        },
        getDataFilter(data) {
            this.dataFilter = data
            this.getDataJobTeam()
            this.getDataCalender()
        },
        getDay(data) {
            this.dataFilter.start_date = data
            this.dataFilter.end_date = data
            this.getDataJobTeam()
        },
        async getDataJobTeam() {
            this.startLoading();
            await this.$axios.get('/job/getListJobForTeam',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    user_id: this.dataFilter.user_id,
                    team_id: this.decode(this.$route.params.teamId),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataProps = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDataCalender() {
            this.startLoading();
            await this.$axios.get('/job/getListJob',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    team_id: this.decode(this.$route.params.teamId),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataCalender = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        getInfoTeam(data) {
            this.team_name = data.group_name
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .btn_add {
        border-radius: 15px;
        background: #F58E16;
        font-family: 'Public Sans', sans-serif;
        height: 40px;
        width: 200px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .btn_add img {
        filter: brightness(20);
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .type {
        display: flex;
        align-items: center;
        height: 50px;
    }

    .type p {
        margin: 0;
        color: #515151;
        text-align: center;
        font-family: 'Public Sans', sans-serif;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.08px;
        width: 193px;
        cursor: pointer;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .type p.active {
        color: #F13A3A;
        background-color: #fff;
        border-radius: 20px 20px 0 0;
    }

    .wp-job {
        border-radius: 0px 20px 20px 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-job h5 {
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.22px;
        text-align: center;
        margin-bottom: 20px;
    }

    .wp-job button {
        color: #F58E16;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 20px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        padding: 8.5px 34px;
    }

    .list_team {
        list-style: none;
        margin: 0
    }

    .list_team li {
        cursor: pointer;
        font-size: 16px;
        color: #515151;
        font-weight: 500;
        padding: 20px;
        border-radius: 20px
    }

    .list_team li:hover {
        background-color: #FCE3C5;
    }
</style>