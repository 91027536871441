<template>
    <div class="support_detail">
        <h5>Tiếp nhận thông tin khách hàng</h5>
        <div class="breadcrumb">
            <span>Hỗ trợ</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Tiếp nhận thông tin khách hàng</span>
        </div>

        <div class="wp-detail">
            <div class="detail">
                <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 20px;">
                    <h5>Chi tiết thông tin</h5>
                    <span style="font-size: 15px; ">Trạng thái: 
                        <span v-if="dataDetail.status == 2" style="color: #F58E16; font-weight: 500;">Đã phản hồi</span>
                        <span v-else-if="dataDetail.status == 1" style="color: #00B569; font-weight: 500;">Đã tiếp nhận</span>
                        <span v-else-if="dataDetail.status == 0" style="color: #F03030; font-weight: 500;">Chưa xem</span>
                    </span>
                </div>
                <p>Tên khách hàng</p>
                <p class="info">{{ dataDetail.member_name }}</p>
                <p>Số điện thoại</p>
                <p class="info">{{ dataDetail.member_phone }}</p>
                <p>Email</p>
                <p class="info">{{ dataDetail.member_email }}</p>
                <p>Nội dung yêu cầu</p>
                <p class="info info--txt">{{ dataDetail.member_content }}</p>
                <div v-if="!dataDetail.real_estate_id">
                    <p>Chi nhánh phụ trách</p>
                    <el-select clearable v-model="formUpdate.agency_id" placeholder="Chi nhánh phụ trách" @change="getUser">
                        <el-option v-for="(item, index) in listAgency" :value="parseInt(item.id)" :label="item.agency_name" :key="index">{{ item.agency_name }}</el-option>
                    </el-select>
                    <p>Mảng phụ trách</p>
                    <el-select clearable v-model="formUpdate.category_id" placeholder="Mảng phụ trách">
                        <el-option v-for="(item, index) in listCate" :value="parseInt(item.id)" :label="item.category_name" :key="index">{{ item.category_name }}</el-option>
                    </el-select>
                    <!-- <p>Team phụ trách</p>
                    <el-select clearable placeholder="Team phụ trách">
                        <el-option v-for="i in 5" :value="i">Team {{ i }}</el-option>
                    </el-select> -->
                    <p>Người phụ trách</p>
                    <el-select clearable v-model="formUpdate.user_id" placeholder="Người phụ trách">
                        <el-option v-for="(item, index) in listUser" :value="parseInt(item.user_id)" :label="item.full_name" :key="index">{{ item.full_name }}</el-option>
                    </el-select>
                </div>
                <div v-else>
                    <p>Tin đăng BĐS</p>
                    <div class="item">
                        <img :src="$storage + dataDetail.real_estate_image" alt="">
                        <div class="">
                            <p>{{ dataDetail.real_estate_name }}</p>
                            <p class="code">#{{ dataDetail.real_estate_code }}</p>
                        </div>
                    </div>
                    <p>Người phụ trách BĐS</p>
                    <p class="info">{{ dataDetail.user_name }}</p>
                    <p>Chi nhánh phụ trách BĐS</p>
                    <p class="info">{{ dataDetail.agency_name }}</p>
                </div>
                <p>Phản hồi của người phụ trách</p>
                <div style="position: relative;">
                    <el-form-item prop="name">
                        <textarea :disabled="!edit" :style="{ height: this.calculatedHeight('response') }" ref="response" v-model="formUpdate.user_support_content" class="form-control" placeholder="Phản hồi của người phụ trách" @input="this.adjustTextareaHeight"></textarea>
                        <el-input type="textarea" v-model="formUpdate.user_support_content" class="d-none"></el-input>
                    </el-form-item>
                    <div class="wp-btn-action">
                        <button @click="editRes" v-if="adInfo && (adInfo.user_id == dataDetail.user_id)"><img src="@/assets/icons/pencil.png" alt=""></button>
                        <button @click="updateSupport('delete')"><img src="@/assets/icons/trash.png" alt=""></button>
                    </div>
                </div>
                <div class="text-end" style="margin-top: 22px;">
                    <div class="wp-button">
                        <button @click="$router.push('/ho-tro')">Hủy bỏ</button>
                        <button class="btn_save" @click="updateSupport('update')">Xác nhận</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            formUpdate: {
                id: this.decode(this.$route.params.id),
                agency_id: '',
                category_id: '',
                user_id: '',
                user_support_content: '',
            },
            id: this.decode(this.$route.params.id),
            bds_sup: false,
            dataDetail: [],
            listAgency: [],
            listCate: [],
            listUser: [],
            edit: false,
        }
    },
    computed: {
        ...mapGetters(['adInfo']),
    },
    mounted() {
        this.getDetail()
        this.getCate()
        this.getAgency()
    },
    watch: {
        "$route": function (url) {
            if(url.path.includes('/ho-tro/chi-tiet')) {
                this.id = this.decode(url.params.id)
                this.getDetail();
            }
        },
    },
    methods: {
        editRes() {
            this.edit = !this.edit
            setTimeout(() => {
                this.$refs.response.focus()
            }, 50);
        },
        async getCate() {
            var data = await this.getListCategory()
            this.listCate = data.result
        },
        async getAgency() {
            var data = await this.getListAgency()
            this.listAgency = data.result
        },
        async getUser() {
            this.startLoading();
            await this.$axios.get('/auth/getListUser',{
                params: {
                    user_name: '',
                    agency_id: this.formUpdate.agency_id,
                    page: 1,
                    per_page: 1000
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listUser = res.data.result;
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/support/getSupportDetail',{
                params: {
                    id: this.id
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                document.title = `Chi tiết hỗ trợ - ${this.dataDetail.member_name}`;
                this.formUpdate.agency_id = res.data.agency_id
                this.formUpdate.category_id = res.data.category_id
                this.formUpdate.user_id = res.data.user_id
                this.formUpdate.user_support_content = res.data.user_support_content
                this.getUser()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateSupport(type) {
            if(type == 'delete') {
                this.formUpdate.user_support_content = ''
            }
            this.startLoading();
            await this.$axios.put('/support/updateSupport',this.formUpdate, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Chỉnh sửa thông tin thành công!");
                this.getDetail()
                this.edit = false
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-weight: 500;
        font-family: 'Public Sans', sans-serif;
        padding: 10px 14px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        color: #F58E16;
        text-align: center;
        font-size: 14px;
        width: 200px;
    }

    .wp-button button.btn_save {
        color: #FFF;
        background: #F58E16;
        margin-left: 15px;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .detail h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 0;
    }

    .detail p {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 5px;
    }

    p.info {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 6.5px 10px;
        margin-bottom: 10px;
    }

    p.info--txt {
        min-height: 100px;
        word-wrap: break-word;
    }

    .item {
        padding: 10px;
        border-radius: 20px;
        background: #F6F6F6;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        height: 90px;
    }

    .item img {
        border-radius: 15px;
        width: 70px;
        height: 70px;
        margin-right: 11px;
        object-fit: cover;
    }

    .item p {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .item p.code {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .item > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    textarea.form-control {
        resize: none;
        background: #F6F6F6;
        box-shadow: none !important;
        border-radius: 15px;
        border: none !important;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        padding: 12px 70px 12px 10px;
    }

    textarea.form-control::placeholder {
        color: #606266;
        opacity: 0.5;
    }

    .wp-btn-action {
        position: absolute;
        right: 10px;
        top: 32%;
    }
</style>

<style>
    .support_detail .el-select {
        width: 100%;
        margin-bottom: 10px;
    }

    .support_detail .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        box-shadow: none !important;
        padding: 5px 10px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
    }
    
    .support_detail .el-select .el-input__wrapper.is-focus,
    .support_detail .el-select .el-input.is-focus .el-input__wrapper {
        box-shadow: none !important;
    }
</style>