<template>
    <div class="wp-detail">
        <h5>Chi tiết khách hàng</h5>
        <div class="breadcrumb">
            <span>Công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Khách hàng quản lý</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết khách hàng</span>
        </div>
        <div class="wp-content">
            <el-row class="align-items-center" style="margin-bottom: 20px;">
                <el-col :sm="14">
                    <img src="@/assets/icons/arrow.png" alt="" style="rotate: 180deg; margin-right: 20px; cursor: pointer;" @click="$router.go(-1)">
                    <span class="ttl">Đầu việc khách hàng</span>
                </el-col>
                <el-col :sm="10" style="text-align: right">
                    <button class="btn-add" @click="$router.push('/cong-viec/quan-ly-cong-viec/tao-cong-viec-khach-hang/' + this.$route.params.cusId)">+ Thêm đầu việc mới</button>
                </el-col>
            </el-row>
            <div class="d-flex justify-content-center" style="margin-bottom: 20px;">
                <button class="btn-act" @click="$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.cusId +'/nhu-cau')">
                    <div class="wp-img">
                        <img src="@/assets/icons/favorite.png" alt="" style="filter: brightness(0.5);">
                    </div>
                    <p>Nhu cầu</p>
                </button>
                <button class="btn-act">
                    <div class="wp-img" style="background-color: #F58E16; border: none;">
                        <img src="@/assets/icons/job.png" alt="" style="filter: brightness(20);">
                    </div>
                    <p>Đầu việc</p>
                </button>
            </div>
            <div class="wp-work">
                <calender
                    :data="dataProps"
                    :dataCalender="dataCalender"
                    :type="3"
                    @deleteSuccess="deleteSuccess"
                    @filterChange="getDataFilter"
                    @selectDay="getDay"
                />
            </div>
        </div>
    </div>
</template>
<script>
import calender from '@/pages/components/calender.vue'
export default {
    components: {
        calender,
    },
    data() {
        return {
            dataProps: [],
            dataCalender: [],
            dataFilter: {
                kpi: '',
                job_status: '',
                active_id: '',
                level: '',
                start_date: '',
                end_date: '',
                user_id: ''
            },
        }
    },
    mounted() {
        this.getDataJobCustomer()
        this.getDataCalender()
    },
    methods: {
        deleteSuccess(start_date, end_date) {
            this.dataFilter.start_date = start_date
            this.dataFilter.end_date = end_date
            this.getDataJobCustomer()
            this.getDataCalender()
        },
        getDataFilter(data) {
            this.dataFilter = data
            this.getDataJobCustomer()
            this.getDataCalender()
        },
        getDay(data) {
            this.dataFilter.start_date = data
            this.dataFilter.end_date = data
            this.getDataJobCustomer()
        },
        async getDataJobCustomer() {
            this.startLoading();
            await this.$axios.get('/job/getListJobForCustomer',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    customer_id: this.decode(this.$route.params.cusId)
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataProps = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDataCalender() {
            this.startLoading();
            await this.$axios.get('/job/getListJob',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    customer_id: this.decode(this.$route.params.cusId)
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataCalender = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin: 0 auto;
    }

    h6 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
    }

    .btn-act {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .btn-act .wp-img {
        width: 46px;
        height: 46px;
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-act:first-child {
        margin-right: 30px;
    }

    .btn-act img {
        width: 30px;
        height: 30px;
    }

    .btn-act p {
        margin: 5px 0 0;
        color: #515151;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    span.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px; 
    }

    .btn-add {
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        background: #F58E16;
        width: 200px;
        height: 40px;
    }

</style>

<style>
    .wp-work .list-item-work {
        display: unset
    }
</style>