<template>
    <div class="wp-detail">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :span="16">
                <h5>Chi tiết thiết lập thu nhập</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý tài chính</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Thiết lập lương thưởng</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết thu nhập</span>
                </div>
            </el-col>
            <el-col :span="8" class="text-end">
                <button class="btn_del" @click="$router.push('/quan-ly-tai-chinh/thiet-lap-luong-thuong/chi-tiet-thu-nhap/' + $route.params.id)">Hủy bỏ</button>
                <button class="btn_confirm" @click="updateSalarySetting('formEdit')">Lưu chỉnh sửa thiết lập</button>
            </el-col>
        </el-row>

        <div class="wp-content">
            <div style="margin-bottom: 25px">
                <router-link :to="'/quan-ly-tai-chinh/thiet-lap-luong-thuong/chi-tiet-thu-nhap/' + $route.params.id" class="rout">
                    <img src="@/assets/icons/arrow.png" alt="">
                </router-link>
                <span class="ttl">Chỉnh sửa thiết lập thu nhập</span>
            </div>
            <el-form
                ref="formEdit"
                class="formEditSetting"
                :model="formEdit"
                :rules="rules"
                @submit.prevent="submitForm('formEdit')"
            >
                <el-form-item prop="name" label="Tiêu đề thiết lập">
                    <el-input type="text" v-model="formEdit.name" placeholder="Nhập tiêu đề"/>
                </el-form-item>

                <el-form-item prop="working_day" label="Số ngày công trong tháng:">
                    <el-input-number :max="31" v-model="formEdit.working_day" placeholder="Nhập số ngày công"/>
                </el-form-item>
                
                <p class="title">Các khoản thu nhập</p>
                <div class="salary-item">
                    <p class="ttl">Lương cứng</p>
                    <el-form-item prop="base_salary.type" label="Tính lương theo:">
                        <el-select filterable  clearable v-model="formEdit.base_salary.type" placeholder="Chọn loại">
                            <el-option :value="1" label="KPI Doanh số"></el-option>
                            <el-option :value="2" label="Mức doanh số"></el-option>
                            <el-option :value="3" label="Tổng KPI"></el-option>
                        </el-select>
                    </el-form-item>
                    <div v-if="formEdit.base_salary.type && formEdit.base_salary.type != 2">
                        <el-form-item prop="base_salary.base_salary" label="Mức lương cơ bản:">
                            <el-input type="text" v-model="formEdit.base_salary.base_salary" placeholder="Nhập mức lương cơ bản" @input="formatInput('base_salary', 'base_salary')"/>
                            <span class="tag">VNĐ</span>
                        </el-form-item>
                        <el-form-item prop="base_salary.min_percent_KPI" label="Số phần trăm tối thiểu hưởng lương:">
                            <el-input type="text" v-model="formEdit.base_salary.min_percent_KPI" placeholder="Nhập số phần trăm tối thiểu hưởng lương"/>
                            <span class="tag" v-if="formEdit.base_salary.type == 1">KPI Doanh số</span>
                            <span class="tag" v-else-if="formEdit.base_salary.type == 3">Tổng KPI</span>
                        </el-form-item>
                    </div>
                    <div v-else-if="formEdit.base_salary.type == 2">
                        <div class="item-input" v-for="(item, index) in formEdit.base_salary.moc_doanh_so" :key="index">
                            <el-form-item label="Doanh số đạt" style="width: 45%;">
                                <el-input type="text" placeholder="Nhập doanh số" v-model="item.sales" @input="formatPrice(index, 'sales')"/>
                                <span class="tag">VNĐ</span>
                            </el-form-item>
                            <el-form-item label="Nhận mức lương" style="width: 45%;">
                                <el-input type="text" placeholder="Nhập mức lương" v-model="item.bonus" @input="formatPrice(index, 'bonus')"/>
                                <span class="tag">VNĐ</span>
                            </el-form-item>
                            <button type="button" class="p-0" @click="formEdit.base_salary.moc_doanh_so.splice(index, 1)"><img src="@/assets/icons/trash.png" alt=""></button>
                        </div>
                        <div class="text-end">
                            <button class="add-cate mt-3" type="button" style="display: inline !important; width: 90px;" @click="addRow('mocdoanhso')">+ Thêm</button>
                        </div>
                    </div>
                </div>
                <div class="salary-item">
                    <p class="ttl">Hoa hồng doanh số</p>
                    <el-form-item prop="commission.type" label="Tính hoa hồng theo:">
                        <el-select filterable  clearable v-model="formEdit.commission.type" placeholder="Chọn loại">
                            <el-option :value="1" label="Tổng doanh số"></el-option>
                            <el-option :value="2" label="Giao dịch bất động sản"></el-option>
                        </el-select>
                    </el-form-item>
                    <p v-if="formEdit.commission.type == 2">Số phần trăm hưởng hoa hồng: <span>Theo % hoa hồng được chia từng bất động sản</span></p>
                    <el-form-item prop="commission.commission_sales_person" label="Số phần trăm hưởng hoa hồng:" v-else-if="formEdit.commission.type == 1">
                        <el-input-number :max="100" v-model="formEdit.commission.commission_sales_person" placeholder="Nhập số phần trăm hoa hồng"/>
                        <span class="tag">Tổng doanh số</span>
                    </el-form-item>
                    <el-form-item prop="commission.commission_sales_team" label="Hoa hồng doanh số theo team:" v-if="formEdit.commission.type">
                        <el-input-number :max="100" v-model="formEdit.commission.commission_sales_team" placeholder="Nhập số phần trăm hoa hồng"/>
                        <span class="tag">Tổng doanh số team</span>
                    </el-form-item>
                </div>
                <div class="salary-item">
                    <p class="ttl">Thưởng doanh số</p>
                    <el-form-item prop="bonus_sales" label="Thưởng đạt đủ KPI doanh số:">
                        <el-input type="text" v-model="formEdit.bonus_sales" placeholder="Nhập số tiền thưởng" @input="formatInput('bonus_sales')"/>
                        <span class="tag">VNĐ</span>
                    </el-form-item>
                    <p>Thưởng theo mức doanh số: <span>Thưởng theo các mốc doanh thu quy định</span></p>
                    <el-form-item prop="bonus_KPI" label="Thưởng team đạt đủ KPI:">
                        <el-input type="text" v-model="formEdit.bonus_KPI" placeholder="Nhập số tiền thưởng" @input="formatInput('bonus_KPI')"/>
                        <span class="tag">VNĐ</span>
                    </el-form-item>
                </div>
                <div class="salary-item">
                    <p class="ttl">Phụ cấp</p>
                    <div class="item_ext" v-for="(item, index) in formEdit.allowance" :key="index">
                        <div>
                            <el-form-item prop="item.title" label="Tiêu đề:">
                                <el-input type="text" v-model="item.title" placeholder="Nhập tiêu đề"/>
                            </el-form-item>
                            <el-form-item prop="item.working_day" label="Số ngày công trong tháng:">
                                <el-input-number :max="31" :min="1" v-model="item.working_day" placeholder="Nhập số ngày công"/>
                                <span class="tag">Công</span>
                            </el-form-item>
                            <el-form-item prop="item.amount" label="Mức tiền:">
                                <el-input type="text" v-model="item.amount" @input="formatInputFor('allowance', 'amount', index)" placeholder="Nhập mức tiền"/>
                                <span class="tag">/ Ngày</span>
                            </el-form-item>
                        </div>
                        <button type="button" @click="formEdit.allowance.splice(index, 1)"><img src="@/assets/icons/trash.png" alt=""></button>
                    </div>
                    <div class="text-end">
                        <button class="add" type="button" @click="addRow('phucap')">+ Thêm</button>
                    </div>
                </div>
                <div class="salary-item">
                    <p class="ttl">Phạt</p>
                    <div class="item_ext" v-for="(item, index) in formEdit.fine" :key="index">
                        <div>
                            <el-form-item prop="item.title" label="Tiêu đề:">
                                <el-input type="text" v-model="item.title" placeholder="Nhập tiêu đề"/>
                            </el-form-item>
                            <el-form-item prop="item.title" label="Mức tiền:">
                                <el-input type="text" v-model="item.amount" placeholder="Nhập mức tiền" @input="formatInputFor('fine', 'amount', index)"/>
                                <span class="tag">/ Buổi</span>
                            </el-form-item>
                        </div>
                        <button type="button" @click="formEdit.fine.splice(index, 1)"><img src="@/assets/icons/trash.png" alt=""></button>
                    </div>
                    <div class="text-end">
                        <button class="add" type="button" @click="addRow('phat')">+ Thêm</button>
                    </div>
                </div>

                <p class="title">Các khoản khấu trừ cố định</p>
                <div class="wp-money wp-detail-settings">
                    <el-form-item prop="social_insurance_status" label="Đóng BHXH">
                        <el-radio v-model="formEdit.social_insurance_status" :label="1">Đóng BHXH</el-radio>
                        <el-radio v-model="formEdit.social_insurance_status" :label="2">Không đóng BHXH</el-radio>
                    </el-form-item>
                    <div class="salary-item" v-if="formEdit.social_insurance_status == 1">
                        <el-form-item prop="total_social_insurance" label="Mức đóng BHXH:">
                            <el-input type="text" v-model="formEdit.total_social_insurance" placeholder="Nhập mức đóng" @input="formatInput('total_social_insurance')"/>
                            <span class="tag">VNĐ</span>
                        </el-form-item>
                        <el-form-item prop="amount_social_insurance" label="Số tiền đóng bảo hiểm của nhân viên:">
                            <el-input type="text" v-model="formEdit.amount_social_insurance" placeholder="Nhập số tiền" @input="formatInput('amount_social_insurance')"/>
                            <span class="tag">VNĐ</span>
                        </el-form-item>
                    </div>

                    <el-form-item prop="tax_status" label="Thuế TNCN" style="margin-top: 20px;">
                        <el-radio v-model="formEdit.tax_status" :label="1">Đóng thuế TNCN</el-radio>
                        <el-radio v-model="formEdit.tax_status" :label="2">Không đóng thuế TNCN</el-radio>
                    </el-form-item>
                    <div v-if="formEdit.tax_status == 1 && formEdit.social_insurance_status == 1">
                        <p>Thuế TNCN tính theo công thức tính ở phần<span class="txt--red"> Thuế Thu nhập cá nhân</span></p>
                    </div>
                    <div v-else-if="formEdit.tax_status == 1 && formEdit.social_insurance_status == 2">
                        <el-form-item prop="tax_amount" label="Mức đóng thuế TNCN:">
                            <el-input-number :max="100" :min="0" v-model="formEdit.tax_amount" placeholder="Nhập phần trăm" />
                            <span class="tag">%</span>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            formEdit: {
                name: '',
                working_day: '',
                base_salary: {
                    type: null,
                    base_salary: "",
                    min_percent_KPI: null,
                    moc_doanh_so: [{
                        sales: "",
                        bonus: ""
                    }]
                },
                commission: {
                    type: null,
                    commission_sales_person: null,
                    commission_sales_team: null
                },
                bonus_KPI: "",
                bonus_sales: "",
                allowance: [{
                    title: "",
                    working_day: null,
                    amount: ""
                }],
                fine: [{
                    title: "",
                    amount: ""
                }],
                social_insurance_status: 1,
                total_social_insurance: "",
                amount_social_insurance: "",
                tax_status: 1,
                tax_amount: null
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]}],
                working_day: [{required: true, message: "Vui lòng nhập số ngày công", trigger: ["blur", "change"]}],
                social_insurance_status: [{required: true, message: "Vui lòng chọn", trigger: ["blur", "change"]}],
                tax_status: [{required: true, message: "Vui lòng chọn", trigger: ["blur", "change"]}],
                bonus_KPI: [{required: true, message: "Vui lòng nhập", trigger: ["blur", "change"]}],
                bonus_sales: [{required: true, message: "Vui lòng nhập", trigger: ["blur", "change"]}],
                total_social_insurance: [{required: true, message: "Vui lòng nhập", trigger: ["blur", "change"]}],
                amount_social_insurance: [{required: true, message: "Vui lòng nhập", trigger: ["blur", "change"]}],
            },
        }
    },
    mounted() {
        this.getSalarySettingDetail()
    },
    methods: {
        formatPrice(index, type) {
            const cleanedValue = this.formEdit.base_salary.moc_doanh_so[index][type].replace(/[^\d]/g, '');
            this.number = parseInt(cleanedValue, 10);
            this.formEdit.base_salary.moc_doanh_so[index][type] = this.number.toLocaleString('vi');
            if(this.formEdit.base_salary.moc_doanh_so[index][type] == 'NaN') {
                this.formEdit.base_salary.moc_doanh_so[index][type] = ''
            }
        },
        formatInput(type, key) {
            if(!key) {
                const cleanedValue = this.formEdit[type].replace(/[^\d]/g, '');
                this.number = parseInt(cleanedValue, 10);
                this.formEdit[type] = this.number.toLocaleString('vi');
                if(this.formEdit[type] == 'NaN') {
                    this.formEdit[type] = ''
                }
            } else {
                const cleanedValue = this.formEdit[type][key].replace(/[^\d]/g, '');
                this.number = parseInt(cleanedValue, 10);
                this.formEdit[type][key] = this.number.toLocaleString('vi');
                if(this.formEdit[type][key] == 'NaN') {
                    this.formEdit[type][key] = ''
                }
            }
        },
        formatInputFor(key, type, index) {
            const cleanedValue = this.formEdit[key][index][type].replace(/[^\d]/g, '');
            this.number = parseInt(cleanedValue, 10);
            this.formEdit[key][index][type] = this.number.toLocaleString('vi');
            if(this.formEdit[key][index][type] == 'NaN') {
                this.formEdit[key][index][type] = ''
            }
        },
        addRow(type) {
            if(type == 'mocdoanhso') {
                const item = {
                    sales: '',
                    bonus: ''
                }
                this.formEdit.base_salary.moc_doanh_so.push(item)
            } else if(type == 'phucap') {
                const item = {
                    title: '',
                    working_day: null,
                    amount: '',
                }
                this.formEdit.allowance.push(item)
            } else if(type == 'phat') {
                const item = {
                    title: '',
                    amount: '',
                }
                this.formEdit.fine.push(item)
            }
        },
        async getSalarySettingDetail() {
            this.startLoading();
            await this.$axios.get('/finance/getSalarySettingDetail',{
                params: {
                    id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.formEdit = res.data
                for (let index = 0; index < this.formEdit.base_salary.moc_doanh_so.length; index++) {
                    const element = this.formEdit.base_salary.moc_doanh_so[index];
                    element.sales = parseInt(element.sales).toLocaleString('vi')
                    element.bonus = parseInt(element.bonus).toLocaleString('vi')
                }
                for (let index = 0; index < this.formEdit.fine.length; index++) {
                    const element = this.formEdit.fine[index];
                    element.amount = parseInt(element.amount).toLocaleString('vi')
                }
                for (let index = 0; index < this.formEdit.allowance.length; index++) {
                    const element = this.formEdit.allowance[index];
                    element.amount = parseInt(element.amount).toLocaleString('vi')
                }
                this.formEdit.base_salary.base_salary = parseInt(this.formEdit.base_salary.base_salary).toLocaleString('vi')
                this.formEdit.bonus_KPI = parseInt(this.formEdit.bonus_KPI).toLocaleString('vi')
                this.formEdit.bonus_sales = parseInt(this.formEdit.bonus_sales).toLocaleString('vi')
                this.formEdit.amount_social_insurance = parseInt(this.formEdit.amount_social_insurance).toLocaleString('vi')
                this.formEdit.total_social_insurance = parseInt(this.formEdit.total_social_insurance).toLocaleString('vi')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        updateSalarySetting(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid && this.validateForm()) {
                    for (let index = 0; index < this.formEdit.base_salary.moc_doanh_so.length; index++) {
                        const element = this.formEdit.base_salary.moc_doanh_so[index];
                        element.sales = element.sales.replace(/[^\d]/g, '')
                        element.bonus = element.bonus.replace(/[^\d]/g, '')
                    }
                    for (let index = 0; index < this.formEdit.fine.length; index++) {
                        const element = this.formEdit.fine[index];
                        element.amount = element.amount.replace(/[^\d]/g, '')
                    }
                    for (let index = 0; index < this.formEdit.allowance.length; index++) {
                        const element = this.formEdit.allowance[index];
                        element.amount = element.amount.replace(/[^\d]/g, '')
                    }
                    this.formEdit.base_salary.base_salary = this.formEdit.base_salary.base_salary.replace(/[^\d]/g, '')
                    this.formEdit.bonus_KPI = this.formEdit.bonus_KPI.replace(/[^\d]/g, '')
                    this.formEdit.bonus_sales = this.formEdit.bonus_sales.replace(/[^\d]/g, '')
                    this.formEdit.amount_social_insurance = this.formEdit.amount_social_insurance.replace(/[^\d]/g, '')
                    this.formEdit.total_social_insurance = this.formEdit.total_social_insurance.replace(/[^\d]/g, '')

                    this.startLoading()
                    await this.$axios.put('/finance/updateSalarySetting', this.formEdit, {
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Chỉnh sửa thiết lập thành công!");
                        this.getSalarySettingDetail()
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", err.response.data.error);
                            }
                        }
                    })
                    this.endLoading()
                } else {
                    this.showNotify("warning", "Chú ý", 'Vui lòng kiểm tra lại thông tin!');
                }
            })
        },
        validateForm() {
            if(this.isEmptyCustom(this.formEdit.base_salary.base_salary)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng nhập mức lương!");
                return false
            } else if(this.isEmptyCustom(this.formEdit.base_salary.type)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng chọn cách tính lương!");
                return false
            } else if (this.isEmptyCustom(this.formEdit.base_salary.min_percent_KPI)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng nhập % tối thiểu hưởng lương!");
                return false
            } else if(this.isEmptyCustom(this.formEdit.commission.type)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng chọn loại tính hoa hồng!");
                return false
            } else if(this.isEmptyCustom(this.formEdit.commission.commission_sales_person)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng nhập % hoa hồng!");
                return false
            } else if(this.isEmptyCustom(this.formEdit.commission.commission_sales_team)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng nhập % hoa hồng theo team!");
                return false
            } else if(this.isEmptyCustom(this.formEdit.social_insurance_status)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng chọn loại BHXH!");
                return false
            } else if(this.isEmptyCustom(this.formEdit.tax_status)) {
                this.showNotify("warning", "Lưu ý", "Vui lòng chọn loại thuế TNCN!");
                return false
            }
            for (let i = 0; i < this.formEdit.base_salary.moc_doanh_so.length; i++) {
                if(this.isEmptyCustom(this.formEdit.base_salary.moc_doanh_so[i].sales)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập doanh số!");
                    return false
                } else if(this.isEmptyCustom(this.formEdit.base_salary.moc_doanh_so[i].bonus)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập mức lương được nhận!");
                    return false
                }
            }
            for (let i = 0; i < this.formEdit.allowance.length; i++) {
                if(this.isEmptyCustom(this.formEdit.allowance[i].title)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập tiêu đề phụ cấp!");
                    return false
                } else if(this.isEmptyCustom(this.formEdit.allowance[i].amount)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập mức phụ cấp!");
                    return false
                }
            }
            for (let i = 0; i < this.formEdit.fine.length; i++) {
                if(this.isEmptyCustom(this.formEdit.fine[i].title)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập tiêu đề phạt!");
                    return false
                } else if(this.isEmptyCustom(this.formEdit.fine[i].amount)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập mức phạt!");
                    return false
                }
            }
            return true
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    h6 {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
        margin: 0;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-content a.rout img {
        rotate: 180deg;
        margin-right: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
    }

    button.btn_confirm {
        color:#FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #F58E16;
        min-width: 200px;
        height: 40px;
    }

    button.btn_del {
        min-width: 200px;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #F13A3A;
        background-color: #FFF;
        border-radius: 15px;
        margin-right: 10px;
    }

    button.add {
        color:#F58E16;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        border: 1px solid#F58E16;
        padding: 9px 20px;
    }

    .wp-content p {
        font-size: 15px;
        color: #515151;
        margin-bottom: 0
    }

    .salary-item {
        border-bottom: 1px solid #DFE3E7;
        padding: 20px 0;
    }

    .salary-item p {
        margin-bottom: 10px;
    }

    .salary-item p.ttl, p span {
        font-weight: 500;
    }

    .salary-item p span {
        margin-left: 5px
    }

    .item_ext {
        border-radius: 20px;
        border: 1px solid #ABABAB;
        padding: 10px 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item_ext > div {
        flex-basis: 95%;
    }
    
    p.title {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        margin: 20px 0;
        text-align: center;
    }

    .wp-btn button {
        color:#FFF;
        font-size: 14px;
        font-weight: 500;
        width: 160px;
        height: 28px;
        border-radius: 15px;
        background:#F58E16;
    }
    
    .wp-btn button:first-child {
        color: #F58E16;
        background: #FFF;
        margin-right: 10px;
        border: 1px solid #F58E16;
    }

    .item-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 15px;
        font-weight: 500;
        border-radius: 15px;
        padding: 5px 14px;
    }

    span.tag {
        position: absolute;
        font-weight: 500;
        right: 15px;
    }

    .txt--red {
        color: #F13A3A !important;
        font-weight: 500;
    }
</style>

<style>
    .wp-detail-settings .el-radio.is-checked {
       background: #FEECEC !important;
    }

    .wp-detail-settings .el-radio {
        width: 30% !important;
    }

    .wp-detail-settings .el-radio:last-child {
        margin: 0 0 0 10px !important;
    }

    .formEditSetting .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 15px;
    }

    .formEditSetting .el-form-item__label::before {
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
    }

    .formEditSetting .el-input-number {
        width: 100%;
    }

    .formEditSetting .el-input-number .el-input__wrapper {
       padding-left: 15px;
    }

    .formEditSetting  .el-input-number .el-input__inner {
       text-align: left;
    }

    .formEditSetting .el-input-number .el-input-number__decrease,
    .formEditSetting .el-input-number .el-input-number__increase {
        display: none;
    }
</style>