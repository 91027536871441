<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="650"
        class="dialog_add_au"
        @close="emitDialogVisible(false)"
        title="Thêm phân quyền"
    >
        <h5 v-if="typeDialog == 'add'">Thêm chức năng</h5>
        <h5 v-else>Chỉnh sửa chức năng</h5>
        <el-form
            ref="formAdd"
            :model="formAdd"
            :rules="rules"
        >
            <el-form-item prop="resource_name" label="Tên chức năng">
                <el-input type="text" v-model="formAdd.resource_name" placeholder="Nhập tên chức năng"/>
            </el-form-item>
            <el-form-item prop="menu" label="Menu">
                <el-select clearable filterable placeholder="Chọn menu"
                    v-model="formAdd.menu"
                >
                    <el-option v-for="item in list_menu"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <p class="label">Chọn hành động</p>
            <div class="wp-action">
                <label class="wp-lable" v-for="(item, index) in list_action" :key="index">
                    <p>{{ item.action_name }}</p>
                    <input type="checkbox" class="input-style" @input="checkAction(item.action_id)" v-model="checkedItems[item.action_id]">
                    <span class="checkmark"></span>
                </label>
            </div>
        </el-form>
        <div class="wp-btn">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm('formAdd', 'add')" v-if="typeDialog == 'add'">Xác nhận</button>
            <button class="btn_add" @click="confirm('formAdd', 'update')" v-else>Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['data'],
    data() {
        return {
            dialogVisibleStatus: false,
            typeDialog: '',
            formAdd: {
                resource_name: '',
                menu: '',
                action: []
            },
            rules: {
                resource_name: [{required: true, message: "Vui lòng nhập tên chức năng", trigger: ["blur", "change"]}],
                menu: [{required: true, message: "Vui lòng chọn menu", trigger: ["blur", "change"]}],
                action: [{required: true, message: "Vui lòng chọn hành động", trigger: ["blur", "change"]}],
            },
            list_action: [],
            list_menu: [
                {id: 1, name: 'Quản lý chi nhánh'},
                {id: 2, name: 'Quản lý bất động sản'},
                {id: 3, name: 'Quản lý nhân viên'},
                {id: 4, name: 'Quản lý khách hàng'},
                {id: 5, name: 'Quản lý tin tức'},
                {id: 6, name: 'Quản lý tài chính'},
                {id: 7, name: 'Công việc'},
                {id: 8, name: 'Báo cáo chung'},
                {id: 9, name: 'Hỗ trợ'},
                {id: 10, name: 'Hệ thống'},
            ],
            checkedItems: [false, false, false, false, false, false],
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.data.dialogVisible;
        this.typeDialog = this.data.type;
        this.getAction()
    },
    watch: {
        data() {
            this.dialogVisibleStatus = this.data.dialogVisible;
            this.typeDialog = this.data.type;
            if(this.data.type == 'edit') {
                this.getDetail(this.data.id)
            } else {
                this.formAdd.resource_name = this.formAdd.menu = ''
                this.checkedItems = [false, false, false, false, false, false]
                this.formAdd.action = this.list_action.map(({ action_id, status }) => ({ action_id, status }));
                this.formAdd.action.forEach(item => {
                    item.status = 0
                });
                if(this.$refs.formAdd) {
                    this.turnOffErrorMessage('formAdd')
                }
            }
        },
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        confirm(formName, type) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.dialogVisibleStatus = false
                    if(type == 'add') {
                        this.$emit("confirm", this.formAdd, 'add');
                    } else {
                        this.$emit("confirm", this.formAdd, 'edit');
                    }
                    this.turnOffErrorMessage(formName)
                    this.checkedItems = this.checkedItems.map(() => false);
                } else {
                    return false;
                }
            });
        },
        async getAction() {
            this.startLoading();
            await this.$axios.get('/permission/getListAction',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_action = res.data
                this.formAdd.action = this.list_action.map(({ action_id, status }) => ({ action_id, status }));
                this.formAdd.action.forEach(item => {
                    item.status = 0
                });
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();

        },
        checkAction(id) {
            this.formAdd.action.forEach(item => {
                if(item.action_id == id) {
                    if(item.status == 1) {
                        item.status = 0
                    } else {
                        item.status = 1
                    }
                } else {
                    return true
                }
            });
        },
        async getDetail(id) {
            this.startLoading();
            await this.$axios.get('/permission/getResourceDetail',{
                params: {
                    resource_id: id,
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.formAdd = res.data
                res.data.action.forEach(item => {
                    if(item.status == 1) {
                        this.checkedItems[item.action_id] = true
                    }
                });
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-lable {
        color: #515151;
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 16px;
    }

    .wp-lable input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .wp-lable input:checked ~ .checkmark:after {
        display: block;
    }

    .wp-lable .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/Done_check.png');
    }

    .wp-lable p {
        margin: 0 0 0 28px;
        color: #515151;
    }
    .wp-btn {
        margin-top: 26px;
        text-align: right;
    }

    button {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        width: 48.5%;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #F58E16;
    }

    button.btn_add {
        color: #FFF;
        background: #F58E16;
        border: none;
        margin-left: 13px;
    }

    p.label {
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 0;
        height: 32px;
        display: flex;
        align-items: center;
    }

    .wp-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        background: #F3F3F3;
        padding: 11px 20px;
    }
</style>