<template>
    <div>
        <canvas ref="chartCanvas"></canvas>
    </div>
</template>
<script>
 import Chart from 'chart.js/auto'
export default {
    props: ['dataValue', 'color'],
    data() {
        return {
            chart: null
        }
    },
    // mounted() {
    //     if(this.dataValue) {
    //         this.renderBarChart();
    //     }
    // },
    watch: {
        dataValue: {
            handler(newVal, oldVal) {
                if(this.dataValue) {
                    this.updateChart();
                }
            },
            deep: true
        },
    },
    methods: {
        renderBarChart() {
            const ctx = this.$refs.chartCanvas.getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.dataValue.label,
                    datasets: [
                        {
                            label: 'Doanh thu',
                            data: this.dataValue.data,
                            backgroundColor: this.color, 
                        },
                    ],
                },
                options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                        align: 'end',
                        labels: {
                                usePointStyle: true,
                            pointStyle: 'rectRounded',
                            boxWidth: 15,
                            boxHeight: 15,
                            font: {
                                size: 16
                            }
                        },
                    },
                    tooltip: {
                        usePointStyle: true,
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'vnd' }).format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                },
                responsive: true,
                scales: {
                    y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function(value, index, ticks) {
                            if(value == 0) {
                                return value
                            } else if(value >= 1000000000) {
                                return value/1000000000 + 'Tỷ'
                            } else if(value >= 1000000) {
                                return value/1000000 + 'Tr'
                            } else if (value >= 1000) {
                                return value/1000 + 'K'
                            }
                        }
                    }
                    },
                },
                },
            });
        },
        updateChart() {
            if(this.chart) {
                this.chart.destroy()
            }
            this.renderBarChart()
        }
    },
}
</script>
<!-- <style scoped>
    canvas {
        width: 100% !important;
        height: auto !important;
    }
</style> -->