<template>
    <div class="col-12 mb-3 position-relative">
        <swiper :list_images="list_images" :video="dataDetail.video" :thumbnail="dataDetail.video_thumbnail"/>
        <div class="base_params">
            <div class="d-flex">
                <div class="price">
                    <img src="@/assets/icons/Price-Tag.png" alt="">
                    <div class="text-price">
                        <p v-if="dataDetail.unit_id != 5">{{ formatPrice(parseInt(dataDetail.price)) }}</p>
                        <p v-else>Thỏa thuận</p>
                        <p class="text--bl" v-if="dataDetail.unit_id == 2">~ {{ formatPrice((dataDetail.price / dataDetail.acreage).toFixed(1)) }} /m2</p>
                        <p class="text--bl" v-else-if="dataDetail.unit_id != 5">{{ dataDetail.unit_name }}</p>
                    </div>
                </div>
                <div class="area">
                    <img src="@/assets/icons/Torrent.png" alt="">
                    <div class="text-price">
                        <p>{{ dataDetail.acreage }} m2</p>
                    </div>
                </div>
            </div>

            <div class="count_gallery d-flex">
                <div class="video" v-if="dataDetail.video">
                    <img src="@/assets/icons/Cinema_1.png" alt="">1
                </div>
                <div class="image">
                    <img src="@/assets/icons/Photo_Gallery_1.png" alt="">
                    {{ list_length }}
                </div>
            </div>
        </div>
        
        <div class="pr-xl-3">
            <div class="product-name">
                {{ dataDetail.real_estate_name }}
            </div>
            <div class="address">
                <img class="d-none d-xl-flex" src="@/assets/icons/Visit.png" alt="">
                {{ dataDetail.address }}, {{ dataDetail.ward_name }}, {{ dataDetail.district_name }}, {{ dataDetail.province_name }}
            </div>
            <div class="title">Đặc điểm thông số bất động sản</div>
            <div class="">
                <div class="params">
                    <div class="col-12 col-lg-6">
                        <div class="items">
                            <div class="icon-width">
                                <img style="width: 28px; height: 28px;" src="@/assets/icons/Torrent.png" alt="">
                            </div>
                            <div class="title-width">Diện tích tổng:</div>
                            <strong>{{ dataDetail.acreage }} m2</strong>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6" v-for="(item, index) in dataDetail.describe" :key="index" v-show="!item.description && item.value && item.value!=0">
                        <div class="items">
                            <div class="icon-width">
                                <img :src="$storage + item.icon" alt="" style="max-width: 32px; max-height: 32px;">
                            </div>
                            <div class="title-width">{{ item.describe_name }}:</div>
                            <strong>{{ item.value }} {{ item.describe_unit }}</strong>
                        </div>
                    </div>
                    <div class="col-12" v-for="(item, index) in dataDetail.describe" :key="index" v-show="item.description && item.value && item.value!=0">
                        <div class="items">
                            <div class="icon-width">
                                <img :src="$storage + item.icon" alt="" style="max-width: 32px; max-height: 32px;">
                            </div>
                            <div class="title-width">{{ item.describe_name }}:</div>
                            <strong>{{ item.value }}</strong>
                            <div class="discript">
                                {{ item.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="more ? 'd-block' : 'd-none'">
                <div class="title">Thông tin mô tả chi tiết</div>
                <div class="discription">
                    <p v-html="dataDetail.description"></p>
                </div>
                <div class="title">Mặt bằng và thiết kế</div>
                <div class="discription">
                    <p v-html="dataDetail.design"></p>
                    <div v-for="item in dataDetail.design_image">
                        <img :src="$storage + item" alt="" style="margin-bottom: 10px;">
                    </div>
                </div>
                <div v-if="dataDetail.project_id">
                    <div class="title">Thông tin dự án</div>
                    <div class="flex-center scroll-mobie col-12 p-1">
                        <CateProjItem :project_id="dataDetail.project_id"/>
                    </div>
                </div>
                <!-- <div class="title">Xem trên bản đồ</div> -->
                <!-- <GMAPS :lng="dataDetail.longtitude" :lat="dataDetail.latitude"/> -->
                <div class="align-items-center my-3 d-none d-xl-flex">
                    <div class="nsx">
                        <div>Ngày đăng tin</div>
                        <div><strong>{{ formateDate(dataDetail.create_at) }}</strong></div>
                    </div>
                    <div class="nsx" v-if="dataDetail.end_date">
                        <div>Ngày hết hạn</div>
                        <div><strong>{{ formateDate(dataDetail.end_date) }}</strong></div>
                    </div>
                    <div class="nsx">
                        <div>Mã tin đăng</div>
                        <div><strong>{{ dataDetail.real_estate_code }}</strong></div>
                    </div>
                </div>
            </div>
            <button class="btn_seemore" @click="more = !more">
                <span v-if="!more">Xem thêm</span>
                <span v-else>Thu gọn</span>
            </button>
        </div>
    </div>
</template>
<script>
import swiper from './swiper';
import CateProjItem from './cate_proj.vue';
// import GMAPS from "../../components/goong_maps.vue"
export default {
    props:['data'],
    components:{
        swiper,
        CateProjItem,
        // GMAPS
    },
    data() {
        return {
            list_images: this.data.image,
            dataDetail: this.data,
            list_length: '',
            more: false
        }
    },
    watch: {
        data() {
            this.dataDetail = this.data;
            this.dataDetail.description = this.dataDetail.description.replaceAll('\n', '<br/>')
            if(this.dataDetail.design) {
                this.dataDetail.design = this.dataDetail.design.replaceAll('\n', '<br/>')
            }
            this.list_images = this.dataDetail.image
            this.list_length = this.dataDetail.image.length
        },
    },
    mounted(){
        this.dataDetail = this.data
        this.list_images = this.dataDetail.image
    }
}
</script>
<style scoped lang="css">
    .base_params{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: absolute;
        top: 402px;
        left: 22px;
        align-items: center;
        width: 80%;
    }

    .base_params .price{
        margin-bottom: 10px;
    }

    .price, .area{
        width: 182.796px;
        height: 68.312px;
        border-radius: 49.526px;
        background: #FFF;
        box-shadow: 0px 0px 4.26947px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        padding: 12px;
        margin: 0 3.5px;
    }

    .price img, .area img{
        width: 44px;
        height: 42px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .text-price{
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #808080;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        width: 120px;
    }

    .text-price p.text--bl {
        color: #808080;
        font-size: 14px;
        font-weight: 500;
    }

    .text-price p {
        color: #F13A3A;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 150%;
    }
    .product-name{
        color: #515151;
        font-size: 16px;
        font-weight: 600;
        padding: 14px 0 5px 0;
        line-height: normal;
    }
    .address{
        color: #808080;
        font-size: 13px;
        font-weight: 500;
        padding-bottom: 31px;
    }
    .title{
        color: #515151;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 12px 0;
    }

    .params{
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0 13px;
        margin: 15px 0;
        display: flex;
        flex-wrap: wrap;
    }

    .params .items{
        display: flex;
        padding: 13px 0;
        border-bottom: #E5E5E5 1px solid;
        flex-wrap: wrap;
        align-items: center;
    }

    .params .icon-width{
        min-width: 46px;
        width: 46px;
        display: flex;
        align-items: center;
    }

    .params .title-width{
        min-width: 150px;
        /* width: 150px; */
        color: #A1A1A1;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
    }

    .params strong{
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        min-width: 100px;
        min-width: 35%;
        width: min-content;
        text-align: justify;
        padding: 0 5px;
    }

    .params .discript{
        color: #515151;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-width: 100%;
        width: auto;
    }

    .discription{
        color: #7E7E7E;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 20px;
    }

    .nsx{
        color: #ABABAB;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
    }

    .nsx strong{
        color: #515151;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .count_gallery{
        color: #FFF;
        text-shadow: 0px 0px 1.2021247148513794px rgba(0, 0, 0, 0.25);
        font-family: Roboto;
        font-size: 15.538px;
        font-weight: 600;
    }
    .count_gallery .video{
        width: 61px;
    }
    .list-search .title{
        border-bottom: #EFEFEF 1px solid;
        padding: 10px 0 5px 0;
    }

    .btn_seemore {
        color: #EE3439;
        text-align: center;
        font-weight: 600;
        margin-top: 17px;
        width: 100%;
    }

    @media (min-width: 360px) {
        .params strong{
            min-width: 50%;
        }
    }

    @media (min-width: 576px) {
        .params strong{
            width: auto;
            min-width: 100px;
        }
        .breadcrumb>*{
            max-width: 75%;
        }
        .params .items{
            flex-wrap: nowrap;
        }
        .params .discript{
            min-width: unset;
        }
    }

    @media (min-width: 992px) {
        .params .items{
            min-height: 54px;
            height: 100%;
        }
    }

    @media (min-width: 1280px) {
        .items{
            min-height: 64px;
            height: 100%;
        }
    
        .product-name{
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .container-xxl{
            max-width: 1280px;
            padding: 0 12px;
        }
        .product-state{
            height: 65px;
        }
        .address{
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
        }
        .address img{
            width: 28px;
            height: 28px;
        }
        .title{
            font-size: 18px;
        }
        .params .title-width, .params strong{
            font-size: 15px;
        }
        .params .discript{
            min-width: unset;
        }
        .params{
            margin-top: 0;
        }
        .nsx{
            width: 246px;
        }
    }
</style>