<template>
    <div class="wp-confirm wp-tran">
        <h5 class="ttl">Giao dịch trong tháng</h5>
        <el-row class="align-items-center mt-3">
            <el-col :xs="24" :sm="12">
                <p class="total">Số giao dịch: <span>{{ page.totalData }}</span></p>
            </el-col>
            <el-col :xs="24" :sm="12" style="text-align: right;">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ sort_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Sắp xếp</p>
                        <li><a style="color: #00B569;" class="dropdown-item" href="#" @click="$emit('changeOrder', 'latest'), sort_txt = 'Mới nhất'">Mới nhất</a></li>
                        <li><a style="color: #00B569;" class="dropdown-item" href="#" @click="$emit('changeOrder', 'oldest'), sort_txt = 'Cũ nhất'">Cũ nhất</a></li>
                    </ul>
                </div>
            </el-col>
        </el-row>
        <el-row class="mt-4">
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed type="index" label="Stt" min-width="70" />
                    <el-table-column header-align="center" align="center" label="Bất động sản" width="465">
                        <template #default="scope">
                            <div class="wp-item">
                                <img :src="$storage + scope.row.thumbnail" alt="" style="width: 70px; height: 70px; border-radius: 15px; object-fit: cover;" @error="handleError">
                                <div class="d-flex align-items-center flex-column justify-content-between text-start ms-2">
                                    <p class="name">{{ scope.row.real_estate_name }}</p>
                                    <p>{{ scope.row.real_estate_code }}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="transaction_code" label="Mã giao dịch" min-width="116">
                        <template #default="scope">
                            <span class="code">{{ scope.row.transaction_code }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="finish_at" label="Thời gian" min-width="162">
                        <template #default="scope">
                            <span>{{ $moment(scope.row.finish_at).format('hh:mm DD/MM/YYYY') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="full_name" label="Người phụ trách" min-width="203"/>
                    <el-table-column header-align="center" align="center" prop="commission" label="Hoa hồng BĐS" min-width="206">
                        <template #default="scope">
                            <span class="price">{{ parseInt(scope.row.commission).toLocaleString('vi') }} VNĐ</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="deposits" label="Tiền thu" min-width="206">
                        <template #default="scope">
                            <span class="price">{{ parseInt(scope.row.deposits).toLocaleString('vi') }} VNĐ</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Pagination from '../../../components/pagination.vue'
export default {
    props: ['tableData', 'page'],
    components: {
        Pagination,
    },
    data() {
        return {
            sort_txt: 'Sắp xếp',
        }
    },
    methods: {
        setPage(val) {
            this.$emit("setPage", val);
        },
        setPerPage(val) {
            this.$emit("setPerPage", val);
        },
        redirectDetail(row) {
            this.$router.push('/cong-viec/tai-chinh/chi-tiet-giao-dich/' + this.encode(row.id))
        }
    }
}
</script>
<style lang="css" scoped>
    h5.ttl {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 5px;
    }

    p.total {
        color: #515151;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin-bottom: 0;
    }

    p.total span {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.18px;
        margin-left: 5px;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .wp-item {
        display: flex;
        align-items: center;
    }

    .wp-item p {
        margin: 0;
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        width: 100%;
    }

    .wp-item p.name {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    span.code {
        color: #3FA5FF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    span.price {
        color: #F13A3A;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }
</style>