<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="650"
        class="dialog_staff_manager"
        @close="emitDialogVisible(false)"
        @open="resetForm"
    >
        <div class="wp-form">
            <h5 v-if="typeDialog == 'add'">Tạo team sale mới</h5>
            <h5 v-else>Chỉnh sửa team sale</h5>
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                status-icon
                @submit.prevent="submitForm('formAdd')"
            >
                <el-form-item prop="name" label="Tên team" class="tall_inp">
                    <el-input id="name" type="text" v-model="formAdd.name" placeholder="Nhập tên team"/>
                </el-form-item>
                <el-form-item prop="role_id" label="Chọn phân quyền">
                    <el-select clearable v-model="formAdd.role_id" filterable placeholder="Chọn phân quyền" @change="getListEmploy('choose')">
                        <el-option v-for="item in list_role" :key="item.role_id" :label="item.role_name" :value="parseInt(item.role_id)"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="leader_id" label="Chọn quản lý mảng BĐS">
                    <el-select clearable v-model="formAdd.leader_id" filterable placeholder="Chọn quản lý mảng BĐS">
                        <el-option v-for="item in list_employ" :key="item.user_id" :label="item.full_name" :value="parseInt(item.id)"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 22px;">
                <button class="btn_cancel" @click="emitDialogVisible(false)">Hủy bỏ</button>
                <button class="btn_send" @click="submitForm('formAdd')">Xác nhận</button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['status', 'data', 'type'],
    data() {
        return {
            dialogVisibleStatus: false,
            formAdd: {
                id: '',
                name: '',
                role_id: '',
                leader_id: '',
                group_id: this.decode(this.$route.params.id)
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên mảng BĐS", trigger: ["blur", "change"]}],
                role_id: [{required: true, message: "Vui lòng chọn phân quyền", trigger: ["blur", "change"]}],
                leader_id: [{required: true, message: "Vui lòng chọn quản lý mảng BĐS", trigger: ["blur", "change"]}],
            },
            list_role: [],
            list_employ: [],
            typeDialog: ''
        };
    },
    mounted() {
        this.getDataRole()
        this.dialogVisibleStatus = this.status
        this.typeDialog = this.type;
        this.formAdd.id = this.data.id
        this.formAdd.name = this.data.name
        this.formAdd.role_id = this.data.role_id
        this.formAdd.leader_id = this.data.leader_id
        if(this.formAdd.role_id) {
            this.getListEmploy('get')
        }
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
        type() {
            this.typeDialog = this.type;
        },
        data() {
            this.formAdd.id = this.data.id
            this.formAdd.name = this.data.name
            this.formAdd.role_id = this.data.role_id
            this.formAdd.leader_id = this.data.leader_id
            if(this.formAdd.role_id) {
                this.getListEmploy('get')
            }
        },
    },
    methods: {
        resetForm() {
            if(this.typeDialog == 'add') {
                this.$nextTick(() => {
                    this.$refs.formAdd.clearValidate()
                });
            }
        },
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
            this.$refs.formAdd.clearValidate()
            this.$refs.formAdd.resetFields()
        },
        async getDataRole() {
            await this.$axios.get('/permission/getListRole',{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_role = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
        },
        async getListEmploy(type) {
            if(type == 'choose') {
                this.formAdd.leader_id = ''
            }
            this.startLoading();
            await this.$axios.get('/job/getlistUserbyRole',{
                params: {
                    role_id : this.formAdd.role_id,
                    group_id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_employ = res.data;
            })
            this.endLoading();
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading()
                    if(this.typeDialog == 'add') {
                        var type = 'post'
                        var lnk = 'createUserGroup'
                        var mss = 'Tạo team sale mới thành công!'
                    } else {
                        var type = 'put'
                        var lnk = 'updateUserGroup'
                        var mss = 'Chỉnh sửa team sale thành công!'
                    }
                    await this.$axios[type]('/job/'+ lnk, this.formAdd, {
                        headers: {
                            'x-access-token': this.getToken()
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", mss);
                        this.turnOffErrorMessage('formAdd')
                        this.dialogVisibleStatus = false
                        this.$emit('addSuccess')
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    .wp-btn {
        text-align: right;
    }
    .wp-btn button {
        width: 200px;
        height: 38px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #FFF;
        color: #F58E16;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    .wp-btn button.btn_send {
        background: #F58E16;
        border: none;
        color: #fff;
        margin-left: 15px;
    }
</style>