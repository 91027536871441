<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="802"
        class="dialog_list_user"
        @close="emitDialogVisible(false)"
    >
        <h5 class="ttl">Chọn chủ bất động sản</h5>
        <el-form-item>
            <el-input v-model="keySearch" type="text" placeholder="Search" />
        </el-form-item>
        <div class="list">
            <div class="item" v-for="(item, index) in filteredItems" :key="index" :class="{'active' : keyActive == item.id}" @click="keyActive = item.id, dataChoose = item">
                <img :src="$storage + item.avatar" @error="handleError" alt="">
                <span>{{ item.full_name }}</span>
            </div>
        </div>
        <div class="wp-btn">
            <button @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button @click="emitData">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['status'],
    data() {
        return {
            dialogVisibleStatus: false,
            keySearch: '',
            keyActive: 0,
            ListCustomer: [],
            dataChoose: []
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.status
        this.getListCustomer()
    },
    computed: {
        filteredItems() {
            return this.ListCustomer.filter(item => item.full_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        async getListCustomer() {
            this.startLoading();
            await this.$axios.get('/job/getListCustomer',{
                params: {
                    full_name: '',
                    type: '',
                    system: '',
                    page: 1,
                    per_page: 1000
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.ListCustomer = res.data.result;
            })
            this.endLoading();
        },
        emitData() {
            this.$emit('dataCustomer', this.dataChoose)
            this.dialogVisibleStatus = false
        }
    }
}
</script>
<style lang="css" scoped>
    button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        width: 200px;
        height: 40px;
        color: #FFF;
        background: #F58E16;
        outline: none;
    }

    .wp-btn {
        text-align: end;
        margin-top: 20px;
    }

    .wp-btn button:first-child {
        color: #F58E16;
        border: 1px solid #F58E16;
        margin-right: 15px;
        background: transparent
    }

    h5.ttl {
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.22px;
        text-align: center;
        margin-bottom: 20px;
    }

    .list {
        margin-top: 20px;
        max-height: 400px;
        overflow: auto;
    }

    .list::-webkit-scrollbar {
        width: 0;
    }

    .list .item {
        display: flex;
        align-items: center;
        border-radius: 20px;
        background: #F3F3F3;
        padding: 11px 20px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .list .item.active {
        background: #F58E16;
    }

    .list .item.active span {
        color: #FFF
    }

    .list .item img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 8px;
    }

    .list .item span {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin-right: 8px;
    }
</style>