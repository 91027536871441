<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="842"
        class="detail_revenue"
        @close="emitDialogVisible(false)"
        align-center
    >
        <div class="wp-detail">
            <h5>Yêu cầu rút tiền</h5>
            <p class="stt">Trạng thái: 
                <span :class="{
                    'txt--yl' : dataDetail.approve_status == 0,
                    'txt--green' : dataDetail.approve_status == 1,
                    'txt--red' : dataDetail.approve_status == 2
                }"
                >{{ dataDetail.approve_text }}</span>
            </p>
            <p class="label">Số tiền yêu cầu rút</p>
            <p class="content">
                <span class="txt--red">{{ parseInt(dataDetail.amount).toLocaleString('vi') }}</span>
                <span style="position: absolute; right: 10px;">VNĐ</span>
            </p>
            <p class="label">Người gửi yêu cầu</p>
            <router-link :to="'/quan-ly-nhan-vien/danh-sach/chi-tiet/' + encode(dataDetail.user_id)" class="wp-user">
                <img :src="$storage + dataDetail.avatar" alt="" @error="handleError">
                <div class="info">
                    <p class="name">{{ dataDetail.full_name }}</p>
                    <p>Mã nhân viên: <span style="color: #3AB7EC;">{{ dataDetail.user_code }}</span></p>
                    <p>Chức vụ: <span>{{ dataDetail.role_name }}</span></p>
                    <p>Số điện thoại: <span>{{ dataDetail.phone }}</span></p>
                </div>
            </router-link>
            <p class="label">Thời gian gửi yêu cầu</p>
            <p class="content">{{ $moment(dataDetail.create_at).format('hh:ss DD/MM/YYYY') }}</p>
            <p class="label">Nội dung</p>
            <p class="content">{{ dataDetail.content }}</p>
            <p class="label">Tài khoản nhận tiền</p>
            <p class="content" v-if="!dataDetail.bank_id">Tài khoản nhận lương</p>
            <div v-else>
                <p class="content">Tài khoản khác</p>
                <div class="ms-4">
                    <p class="content">{{ dataDetail.bank_name }} - {{ dataDetail.pre_name }}</p>
                    <p class="content">{{ dataDetail.bank_number }}</p>
                    <p class="content">{{ dataDetail.owner_name }}</p>
                </div>
            </div>
            <div v-if="dataDetail.approve_status == 1">
                <p class="label">Thời gian gửi xác nhận</p>
                <p class="content">{{ $moment(dataDetail.approved_at).format('hh:ss DD/MM/YYYY') }}</p>
            </div>
            <div v-if="dataDetail.reason_reject">
                <p class="label">Lý do từ chối</p>
                <p class="content">{{ dataDetail.reason_reject }}</p>
            </div>
            <div class="wp-button" :class="{'text-end' : dataDetail.approve_status != 0}">
                <button @click="dialogVisibleStatus = false">Thoát</button>
                <button @click="rejectVisible = true" v-show="dataDetail.approve_status == 0">Từ chối yêu cầu</button>
                <button @click="dialogConfirm = true" v-show="dataDetail.approve_status == 0">Xác nhận</button>
            </div>
        </div>
    </el-dialog>
    <Dialog 
        :dialogVisible="dialogConfirm"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        title="Chấp nhận yêu cầu rút tiền"
        content="Bạn chấp nhận yêu cầu rút tiền của nhân viên. Hãy đảm bảo đã hoàn thành các thủ tục và chuyển tiền cho nhân viên trước khi ấn xác nhận"
    />
    <RejectDialog 
        :rejectVisible="rejectVisible"
        @handleCloseDialog="rejectDialog"
        @confirm="reject"
        title="Từ chối xác nhận"
        content="Bạn đã từ chối yêu cầu rút tiền của nhân viên"
        customer="nhân viên"
    />
</template>
<script>
import Dialog from '../../components/dialog'
import RejectDialog from '../../components/rejectDialog'
export default {
    props: ['dialogVisible', 'dataDetail'],
    emits: ['handleCloseDialog', 'confirm', 'reject'],
    components: {
        Dialog,
        RejectDialog
    },
    data() {
        return {
            dialogVisibleStatus: false,
            dialogConfirm: false,
            rejectVisible: false
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.dialogVisible
    },
    watch: {
        dialogVisible() {
            this.dialogVisibleStatus = this.dialogVisible;
        },
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        listenDialog(status) {
            this.dialogConfirm = status
        },
        rejectDialog(status) {
            this.rejectVisible = status
        },
        confirm() {
            this.$emit("confirm");
        },
        reject(data) {
            this.$emit("reject", data);
            this.rejectVisible = false
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 180%;
        margin-bottom: 20px;
    }

    p.stt {
        color: #515151;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.075px;
        margin-bottom: 10px;
    }

    p.stt span {
        font-size: 16px;
        font-weight: 500;
    }

    .txt--red {
        color: #F13A3A !important;
    }

    .txt--green {
        color: #00B569 !important;
    }

    .txt--yl {
        color: #F58E16 !important;
    }

    p.label {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 5px;
    }

    p.content {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        line-height: 180%;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 15px;
        background: #F6F6F6;
    }

    .wp-user {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        border-radius: 15px;
        background: #F6F6F6;
        margin-bottom: 10px;
        text-decoration: none;
    }

    .wp-user p {
        color: #515151;
        margin-bottom: 8px;
        font-weight: 400;
        line-height: 105%;
    }

    .wp-user p:last-child {
        margin-bottom: 0;
    }

    .wp-user p span {
        font-weight: 500;
    }

    p.name {
        font-size: 20px;
        font-weight: 600;
    }

    .wp-user img {
        width: 60px;
        height: 60px;
        border-radius: 32px;
        object-fit: cover;
        margin-right: 20px;
    }

    .wp-button {
        margin-top: 20px;
    }

    .wp-button button {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        width: 344px;
        height: 38px;
        margin-left: 10px;
        border-radius: 15px;
        padding: 0;
        background-color: #F13A3A;
    }

    .wp-button button:first-child {
        width: 93px;
        margin-left: 0;
        color: #F13A3A;
        border: 1px solid #F13A3A;
        background-color: transparent;
    }

    .wp-button button:last-child {
        background-color: #F58E16;
    }

    .wp-detail {
        max-height: 85vh;
        overflow: auto;
    }

    .wp-detail::-webkit-scrollbar {
        width: 0;
    }
</style>

<style>
    .detail_revenue {
        border-radius: 20px !important;
    }

    .detail_revenue .el-dialog__header {
        display: none;
    }
</style>