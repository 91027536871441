<template>
    <div class="wp-job-employ">
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="24">
                <h5>Quản lý nhân sự</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý nhân sự</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách chi nhánh</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết nhân viên</span>
                </div>
            </el-col>
        </el-row>
        <div class="wp-content">
            <div class="wp-tab">
                <el-tabs @tab-click="handleClick">
                    <el-tab-pane>
                        <template #label>
                          <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/user.png" alt="">
                            </div>
                            <p>Thông tin cá nhân</p>
                          </span>
                        </template>
                        <information :data="dataDetail"/>
                      </el-tab-pane>
                    <el-tab-pane>
                        <template #label>
                          <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/cus_ico.png" alt="">
                            </div>
                            <p>List khách hàng</p>
                          </span>
                        </template>
                        <listCustomer />
                      </el-tab-pane>
                    <el-tab-pane>
                        <template #label>
                          <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/list_ico.png" alt="">
                            </div>
                            <p>Bất động sản quản lý</p>
                          </span>
                        </template>
                        <bdsManager/>
                      </el-tab-pane>
                    <el-tab-pane>
                        <template #label>
                          <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/job.png" alt="">
                            </div>
                            <p>Đầu việc cá nhân</p>
                          </span>
                        </template>
                            
                      </el-tab-pane>
                    <el-tab-pane>
                        <template #label>
                          <span class="custom-tabs-label">
                            <div class="wp-img">
                                <img src="@/assets/icons/kpi.png" alt="">
                            </div>
                            <p>KPI cá nhân</p>
                          </span>
                        </template>
                        <KPI/>
                      </el-tab-pane>
                </el-tabs>
                <div class="wp-job" v-if="tabPane == 3">
                    <h5>Đầu việc cá nhân</h5>
                    <!-- <div class="text-end">
                        <button> + Thêm đầu việc mới</button>
                    </div> -->
                    <calender
                        :data="dataProps"
                        :dataCalender="dataCalender"
                        :type="1"
                        @deleteSuccess="deleteSuccess"
                        @filterChange="getDataFilter"
                        @selectDay="getDay"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import information from './components/infomation.vue'
import listCustomer from './components/listCustomer.vue'
import bdsManager from './components/bdsManager.vue'
import calender from "@/pages/components/calender.vue"
import KPI from './components/kpiSefl.vue'
export default {
    components: {
        information,
        listCustomer,
        bdsManager,
        KPI,
        calender
    },
    data() {
        return {
            dataDetail: [],
            dataProps: [],
            dataCalender: [],
            dataFilter: {
                kpi: '',
                job_status: '',
                active_id: '',
                level: '',
                start_date: '',
                end_date: '',
                user_id: ''
            },
            tabPane: 0
        }
    },
    mounted() {
        this.getDetail()
        this.getDataJobTeam()
        this.getDataCalender()
    },
    methods: {
        handleClick(tab) {
            this.tabPane = tab.paneName
        },
        deleteSuccess(start_date, end_date) {
            this.dataFilter.start_date = start_date
            this.dataFilter.end_date = end_date
            this.getDataJobTeam()
            this.getDataCalender()
        },
        getDataFilter(data) {
            this.dataFilter = data
            this.getDataJobTeam()
            this.getDataCalender()
        },
        getDay(data) {
            this.dataFilter.start_date = data
            this.dataFilter.end_date = data
            this.getDataJobTeam()
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/job/getPersonDetail',{
                params: {
                    user_id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDataJobTeam() {
            this.startLoading();
            await this.$axios.get('/job/getListJobForTeam',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    user_id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataProps = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDataCalender() {
            this.startLoading();
            await this.$axios.get('/job/getListJob',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    user_id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataCalender = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .custom-tabs-label {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .custom-tabs-label .wp-img {
        width: 46px;
        height: 46px;
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
    }

    .custom-tabs-label img {
        width: 30px;
        height: 30px;
    }

    .custom-tabs-label p {
        margin: 0;
        color: #515151;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-job {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-job h5 {
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.22px;
        margin-bottom: 20px;
        text-align: center;
    }

    .wp-job button {
        color: #F58E16;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        border: 1px solid #F58E16;
        margin-bottom: 20px;
        padding: 8.5px 34px;
    }
</style>

<style>
    .wp-job-employ .el-tabs__active-bar {
        background: none;
    }

    .wp-job-employ .el-tabs__nav-wrap::after {
        background: none;
    }

    .wp-job-employ .el-tabs {
        --el-tabs-header-height: 72px;
    }

    .wp-job-employ .el-tabs__header {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        width: 100%;
        max-width: 842px;
        margin: 0 auto 20px;
    }

    .wp-job-employ .el-tabs__nav-scroll {
        overflow: auto !important;
    }

    .wp-job-employ .el-tabs__nav-scroll::-webkit-scrollbar {
        width: 0;
    }

    .wp-job-employ .el-tabs__nav {
        float: unset;
        justify-content: space-between;
    }

    .wp-job-employ .el-tabs__item.is-active .custom-tabs-label .wp-img {
        background: #F58E16;
        border: none;
    }

    .wp-job-employ .el-tabs__item.is-active .custom-tabs-label img {
        filter: brightness(20);
    }

    .wp-job-employ .el-tabs__item.is-active .custom-tabs-label p {
        color: #F58E16;
    }
</style>