<template>
    <div class="wp-kpi">
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :xs="24" :sm="12">
                <h5>Quản lý KPI</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Quản lý KPI</span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" class="wp-btn">
                <button class="btn-add" @click="$router.push('/cong-viec/quan-ly-kpi/tao-moi-kpi-tong')">+ Add KPI tổng </button>
                <button class="btn-add" @click="$router.push('/cong-viec/quan-ly-kpi/tao-moi')">+ Add KPI </button>
                <button @click="dialogVisible = true, type = 'all', KPI_id = dataKPI.KPI_id" v-if="dataKPI.KPI_id"><img src="@/assets/icons/trash.png" alt=""></button>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :md="17">
                <div class="wp-head">
                    <h5>Quản lý KPI tổng</h5>
                    <el-row>
                        <el-col :xs="24" :sm="8" class="text-center">
                            <h6>Ra khơi</h6>
                            <el-progress type="circle" :percentage="dataKPI.complete_percent" :color="colors">
                                <template #default="{ percentage }">
                                    <span class="percentage-label">Hoàn thành</span>
                                    <span class="percentage-value">{{ percentage }}%</span>
                                    <span class="percentage-label">{{ $moment.utc().format('DD/MM/YYYY') }}</span>
                                </template>
                            </el-progress>
                            <p class="time mt-3" v-if="dataKPI.start_date">Bắt đầu: {{ $moment.utc(dataKPI.start_date).format('DD/MM/YYYY') }}</p>
                            <p class="time" v-if="dataKPI.end_date">Kết thúc: {{ $moment.utc(dataKPI.end_date).format('DD/MM/YYYY') }}</p>
                        </el-col>
                        <el-col :xs="24" :sm="16">
                            <h6>KPI và tiến độ hiện tại</h6>
                            <div class="item" v-if="dataKPI.KPI" v-for="(item_kpi, index) in dataKPI.KPI" :key="index">
                                <div class="ico">
                                    <img src="@/assets/icons/bar_chart.png" alt="">
                                    <p>{{ item_kpi.text }}: </p>
                                </div>
                                <div class="content">
                                    <p v-for="item in item_kpi.listKPI">
                                        <span :class="item.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ parseInt(item.complete).toLocaleString('vi') }}</span> / {{ parseInt(item.target).toLocaleString('vi') }}
                                        <span v-if="item.KPI_type == 1">VNĐ</span>
                                        <span v-else>{{ item.active_name }}</span>
                                    </p>
                                </div>
                                <p class="stt" :class="item_kpi.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ item_kpi.complete_percent }}%</p>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="0" :xs="0" :md="7">
                <div class="wp-doughnut">
                    <DoughtNut :dataValue="dataProps"/>
                </div>
            </el-col>
        </el-row>
        <h6 class="ttl">Tiến độ hoàn thành KPI từng chi nhánh</h6>
        <div class="kpi-item" v-for="(item, index) in listItemKpi" @click="() => index != 0 && item.KPI_detail.team_id && $router.push('/cong-viec/quan-ly-kpi-chi-nhanh/' + encode(item.id))">
            <el-row class="align-items-center">
                <el-col :xs="24" :md="9">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <p class="agency_name">{{ item.agency_name }}</p>
                            <div class="d-flex align-items-center">
                                <img class="avatar" :src="$storage + item.avatar" alt="" @error="handleError">
                                <div class="info">
                                    <p>{{ item.full_name }}</p>
                                    <p>{{ item.role_name }}</p>
                                </div>
                            </div>
                            <div class="wp-btn">
                                <button @click.stop="$router.push('/cong-viec/quan-ly-kpi/tao-moi-kpi-chi-nhanh/' + encode(item.KPI_detail.team_id))" :disabled="!item.KPI_detail.team_id" class="btn-add-kpi" v-if="index != 0">+ Add KPI</button>
                                <button @click.stop="$router.push('/cong-viec/quan-ly-kpi/tao-moi')" class="btn-add-kpi" v-else>+ Add KPI</button>
                                <button @click.stop="() => item.KPI_detail.KPI_id && $router.push('/cong-viec/quan-ly-kpi/chinh-sua-kpi/' + encode(item.KPI_detail.KPI_id))" :disabled="!item.KPI_detail.KPI_id" class="btn-edit"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button @click.stop="dialogVisible = true, type = 'item', KPI_id = item.KPI_detail.KPI_id" :disabled="!item.KPI_detail.KPI_id"><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                            <button class="complain" @click.stop="complainDialog = true, KPI_id = item.KPI_detail.KPI_id, reasonReject = item.KPI_detail.reason"
                                v-if="item.KPI_detail.approve_status == 4"
                            >
                                <img src="@/assets/icons/warning.png" alt="">
                                <span>Khiếu nại mức KPI</span>
                            </button>
                            <button @click.stop="complainDetail = true, typeComplain = item.KPI_detail.approve_status, reasonReject = item.KPI_detail.reason"
                                v-else-if="item.KPI_detail.approve_status == 1 || item.KPI_detail.approve_status == 2"
                            >
                                <img src="@/assets/icons/warning--red.png" alt="" style="width: 26px; height: 26px; margin-top: 20px;">
                            </button>
                        </div>
                        <el-progress type="circle" v-if="item.KPI_detail" :percentage="item.KPI_detail.complete_percent" :color="colors" class="percentage">
                            <template #default="{ percentage }">
                                <span class="percentage-label">Hoàn thành</span>
                                <span class="percentage-value">{{ percentage }}%</span>
                            </template>
                        </el-progress>
                    </div>
                </el-col>
                <el-col :xs="24" :md="15">
                    <div class="item" v-for="item_detail in item.KPI_detail.KPI">
                        <div class="ico">
                            <img src="@/assets/icons/bar_chart.png" alt="">
                            <p>{{ item_detail.text }}: </p>
                        </div>
                        <div class="content">
                            <p v-for="content in item_detail.listKPI">
                                <span :class="content.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ parseInt(content.complete).toLocaleString('vi') }}</span> / {{ parseInt(content.target).toLocaleString('vi') }}
                                <span v-if="content.KPI_type == 1">VNĐ</span>
                                <span v-else>{{ content.active_name }}</span>
                            </p>
                        </div>
                        <p class="stt" :class="item_detail.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ item_detail.complete_percent }}%</p>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>

    <Dialog 
        :dialogVisible="dialogVisible"
        :success="successDialog"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        title="Xóa KPI"
        content="Bạn đang yêu cầu xóa KPI"
        content_success=""
    />

    <ComplainDialog 
        :complainDialog="complainDialog"
        :reason="reasonReject"
        @handleCloseDialog="complainStatus"
        @confirm="confirmComplain"
        @rejectRequest="rejectRequest"
    />

    <ComplainDetail
        :complainDetail="complainDetail"
        :reason="reasonReject"
        :type="typeComplain"
        @handleCloseDialog="complainDetailStatus"
    />
</template>
<script>
import DoughtNut from './components/doughnut'
import Dialog from '../../components/dialog.vue'
import ComplainDialog from './components/complain.vue'
import ComplainDetail from './components/detail_complain.vue'
export default {
    components: {
        DoughtNut,
        Dialog,
        ComplainDialog,
        ComplainDetail
    },
    data() {
        return {
            dialogVisible: false,
            successDialog: false,
            complainDialog: false,
            complainDetail: false,
            dataKPI: [],
            colors: [
                { color: '#ee3439', percentage: 40 },
                { color: '#f58e16', percentage: 99 },
                { color: '#00b569', percentage: 100 },
            ],
            dataProps: {
                labels: [],
                data: []
            },
            listItemKpi: [],
            KPI_id: '',
            type: '',
            reasonReject: '',
            typeComplain: ''
        }
    },
    mounted() {
        this.getKPI()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        complainStatus(dialogStatus) {
            this.complainDialog = dialogStatus;
        },
        complainDetailStatus(dialogStatus) {
            this.complainDetail = dialogStatus;
        },
        async getKPI() {
            this.startLoading();
            await this.$axios.get('/job/getKPI', {
                params: {
                    type: 1
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataKPI = res.data.KPI
                this.dataProps.labels = res.data.KPI.KPI.map(item => item.text)
                this.dataProps.data = res.data.KPI.KPI.map(item => item.percent)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
            this.getListKpiForTeam()
        },
        async getListKpiForTeam() {
            this.startLoading();
            await this.$axios.get('/job/getListKPIForTeam', {
                params: {
                    type: 1
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listItemKpi = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirm() {
            this.startLoading();
            await this.$axios.put('/job/deleteKPI', {id: this.KPI_id}, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa KPI thành công!");
                if(this.type == 'all') {
                    this.getKPI()
                } else {
                    this.getListKpiForTeam()
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async confirmComplain() {
            this.startLoading();
            var data = {
                kpi_id: this.KPI_id,
                status: 1
            }
            await this.$axios.put('/job/updateKPIApproveStatus', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Chấp nhận khiếu nại KPI thành công!");
                this.complainDialog = false
                this.getKPI()
                this.getListKpiForTeam()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async rejectRequest(reason) {
            this.startLoading();
            var data = {
                kpi_id: this.KPI_id,
                status: 2,
                reason: reason
            }
            await this.$axios.put('/job/updateKPIApproveStatus', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Từ chối khiếu nại KPI thành công!");
                this.complainDialog = false
                this.getListKpiForTeam()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped src="./assets/style.css"></style>

<style>
    .wp-kpi .el-progress-circle {
        width: 220px !important;
        height: 100% !important;
    }

    .wp-kpi .kpi-item .el-progress-circle {
        width: 120px !important;
        height: 100% !important;
    }

</style>