<template>
    <div class="wp-finance">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :xs="24" :sm="12">
                <h5>Quản lý tài chính</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Quản lý tài chính</span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" class="text-end mt-3 mt-md-0">
                <router-link to="/cong-viec/tai-chinh/rut-tien" class="btn-money" v-if="activeTab == 'second'">
                    <img src="@/assets/icons/wallet.png" alt="">
                    Rút tiền
                </router-link>
                <router-link to="/cong-viec/tai-chinh/che-do" class="btn-regime">Chế độ lương thưởng</router-link>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Doanh thu team" name="first">
                        <Team/>
                    </el-tab-pane>
                    <el-tab-pane label="Doanh thu cá nhân" name="second">
                       <Personal/>
                    </el-tab-pane>
                    <el-tab-pane label="Thu nhập" name="third">
                        <Income/>
                    </el-tab-pane>
                  </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Personal from './components/personal'
import Team from './components/team'
import Income from './components/income'
export default {
    components: {
        Personal,
        Team,
        Income
    },
    data() {
        return {
            activeTab: 'second',
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .btn-money {
        color: #FFF;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        background: #F13A3A;
        padding: 6px 10px;
        text-decoration: none;
        display: inline-block;
    }

    .btn-money img {
        width: 28px;
        height: 28px;
        margin-right: 15px;
    }

    .btn-regime {
        color: #F13A3A;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        background: #FFF;
        padding: 10px 25px;
        margin-left: 10px;
        text-decoration: none;
        display: inline-block;
    }
</style>

<style>
    .wp-finance .el-tabs__nav-wrap::after {
        width: 0;
    }

    .wp-finance .el-tabs__active-bar {
        width: 0 !important;
    }

    .wp-finance .el-tabs__item {
        color: #515151 !important;
        text-align: center;
        font-family: 'Public Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.08px;
        padding: 0 20px !important;
    }

    .wp-finance .el-tabs__item.is-active {
        color: #F13A3A !important;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
    }

    .wp-finance .percentage-label,
    .wp-team .percentage-label {
        color: #515151;
        font-size: 17.758px;
        font-weight: 500;
        line-height: 31.57px;
        letter-spacing: 0.178px;
        display: block;
    }

    .wp-finance .percentage-label:last-child,
    .wp-team .percentage-label:last-child {
        font-size: 19.731px;
        letter-spacing: 0.197px;
    }

    .wp-finance .percentage-value,
    .wp-team .percentage-value {
        color: #515151;
        font-size: 39.462px;
        font-weight: 500;
        line-height: 31.57px;
        letter-spacing: 0.395px;
        display: block;
        margin: 5px 0;
    }

    .wp-finance .el-progress, 
    .wp-team .el-progress {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .wp-finance .el-progress-circle,
    .wp-team .el-progress-circle {
        width: 100% !important;
        max-width: 220px;
        height: auto !important;
    }

    .wp-finance .el-tabs__header {
        margin: 0;
    }

    @media (min-width: 768px) {
        .wp-finance .el-progress,
        .wp-team .el-progress {
            margin-bottom: 0;
        }
    }
</style>