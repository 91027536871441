<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="842"
        class="detail_revenue"
        @close="emitDialogVisible(false)"
        align-center
    >
        <div class="wp-detail">
            <h5>Xác nhận tiền về</h5>
            <p class="stt">Trạng thái: 
                <span class="txt--yl" v-if="dataDetail.transaction_detail.accept_status == 0">Chưa xác nhận</span>
                <span class="txt--green" v-else-if="dataDetail.transaction_detail.accept_status == 1">Đã xác nhận</span>
                <span class="txt--red" v-else-if="dataDetail.transaction_detail.accept_status == 2">Bị từ chối</span>
            </p>
            <p class="ttl">Bất động sản liên quan</p>
            <router-link :to="'/quan-ly-bds/danh-sach/chi-tiet/' + encode(dataDetail.transaction_detail.real_estate_id)" class="wp-bds" target="_blank">
                <img :src="$storage + dataDetail.transaction_detail.thumbnail" alt="" @error="handleError">
                <div class="wp-txt">
                    <p>{{ dataDetail.transaction_detail.real_estate_name }}</p>
                    <p class="txt--yl">#{{ dataDetail.transaction_detail.real_estate_code }}</p>
                </div>
            </router-link>
            <div class="wp-money">
                <p>Tiền thu trong giao dịch</p>
                <p class="txt--red">{{ parseInt(dataDetail.transaction_detail.deposits).toLocaleString('vi') }} VNĐ</p>
            </div>
            <p style="font-size: 15px; margin-bottom: 5px;">Người phụ trách</p>
            <router-link :to="'/quan-ly-nhan-vien/danh-sach/chi-tiet/' + encode(dataDetail.transaction_detail.user_id)" class="wp-sale" target="_blank">
                <img :src="$storage + dataDetail.transaction_detail.avatar" alt="" @error="handleError">
                <div>
                    <p>{{ dataDetail.transaction_detail.full_name }}</p>
                    <p>Chức vụ: <span>{{ dataDetail.transaction_detail.position }}</span></p>
                </div>
            </router-link>
            <p style="font-size: 15px; margin-bottom: 5px;">Đầu việc liên quan</p>
            <router-link :to="'/cong-viec/quan-ly-cong-viec/chi-tiet-cong-viec/' + encode(dataDetail.transaction_detail.id)" class="wp-job" target="_blank">
                <div class="d-flex align-items-center justify-content-between">
                    <p style="font-size: 18px;">{{ dataDetail.transaction_detail.title }}</p>
                    <p style="font-size: 16px;" class="txt--yl" v-if="dataDetail.transaction_detail.job_status == 0">Đang xử lý</p>
                    <p style="font-size: 16px;" class="txt--yl" v-else-if="dataDetail.transaction_detail.job_status == 1">Đã hoàn thành - chờ xác nhận</p>
                    <p style="font-size: 16px;" class="txt--green" v-else-if="dataDetail.transaction_detail.job_status == 2">Hoàn thành</p>
                    <p style="font-size: 16px;" class="txt--red" v-else-if="dataDetail.transaction_detail.job_status == 3">Không hoàn thành</p>
                    <p style="font-size: 16px;" class="txt--red" v-else-if="dataDetail.transaction_detail.job_status == 4">Quá hạn</p>
                </div>
                <p>
                    <img src="@/assets/icons/clock.png" alt="">
                    {{ $moment(dataDetail.transaction_detail.start_date).format('HH[h]mm') }} - {{ $moment(dataDetail.transaction_detail.end_date).format('HH[h]mm') }}
                </p>
                <p>
                    <img src="@/assets/icons/task.png" alt="">
                    {{ dataDetail.transaction_detail.active_name }}
                </p>
                <p>
                    <img src="@/assets/icons/user.png" alt="">
                    {{ dataDetail.transaction_detail.full_name }}
                </p>
                <p v-if="dataDetail.transaction_detail.customer_name">
                    <img src="@/assets/icons/group.png" alt="">
                    {{ dataDetail.transaction_detail.customer_name }}
                </p>
                <p>
                    <img src="@/assets/icons/address.png" alt="">
                    {{ dataDetail.transaction_detail.job_address }}
                </p>
                <div class="d-flex">
                    <p class="txt--red" style="margin-right: 15px;" v-if="dataDetail.transaction_detail.reminder_status == 1">
                        <img src="@/assets/icons/alarm.png" alt="">
                        {{ $moment.utc(dataDetail.transaction_detail.reminder_time).format('HH[h]mm') }}
                    </p>
                    <p :class="{'txt--green' : dataDetail.transaction_detail.level == 1,
                        'txt--green' : dataDetail.transaction_detail.level == 2,
                        'txt--yl' : dataDetail.transaction_detail.level == 3,
                        'txt--red' : dataDetail.transaction_detail.level == 4
                    }">
                        <img src="@/assets/icons/bookmark_ico.png" alt="">
                        <span v-if="dataDetail.transaction_detail.level == 1">Không quan trọng</span>
                        <span v-else-if="dataDetail.transaction_detail.level == 2">Bình thường</span>
                        <span v-else-if="dataDetail.transaction_detail.level == 3">Quan trọng</span>
                        <span v-else-if="dataDetail.transaction_detail.level == 4">Rất quan trọng</span>
                    </p>
                </div>
            </router-link>
            <p style="font-size: 15px; margin-bottom: 5px;">File đính kèm:</p>
            <ul>
                <li v-for="(item, index) in dataDetail.file" :key="index">
                    <Fancybox>
                        <a v-if="item" data-fancybox="gallery" :href="$storage + item.file_path">
                            <img src="@/assets/icons/Note.png" alt="">
                            {{ item.file_path.substring(item.file_path.lastIndexOf('-') + 1) }}
                        </a>
                    </Fancybox>
                </li>
            </ul>
            <div v-if="dataDetail.transaction_detail.finish_at">
                <p class="ttl">Thời gian gửi xác nhận</p>
                <p style="font-size: 16px; font-weight: 500; margin-bottom: 20px;">{{ $moment(dataDetail.transaction_detail.finish_at).format('hh:mm DD/MM/YYYY') }}</p>
            </div>
            <div v-if="dataDetail.transaction_detail.reason">
                <p class="ttl">Lý do từ chối</p>
                <p style="font-size: 16px; font-weight: 500; margin-bottom: 20px;">{{ dataDetail.transaction_detail.reason }}</p>
            </div>
            <div class="wp-button">
                <button @click="dialogVisibleStatus = false">Thoát</button>
                <button @click="rejectVisible = true" v-show="dataDetail.transaction_detail.accept_status == 0">Từ chối xác nhận</button>
                <button @click="dialogConfirm = true" v-show="dataDetail.transaction_detail.accept_status == 0">Xác nhận tiền về</button>
            </div>
        </div>
    </el-dialog>
    <Dialog 
        :dialogVisible="dialogConfirm"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        title="Xác nhận tiền về"
        content="Bạn xác nhận tiền giao dịch đã về tài khoản công ty? Một khi xác nhận sẽ không thể hoàn tác và bạn phải chịu tất cả trách nhiệm nếu có vấn đề xảy ra!"
    />
    <RejectDialog 
        :rejectVisible="rejectVisible"
        @handleCloseDialog="rejectDialog"
        @confirm="reject"
        title="Từ chối xác nhận"
        content="Bạn đã từ chối xác nhận yêu cầu của nhân viên"
        customer="nhân viên"
    />
</template>
<script>
import Dialog from '../../components/dialog'
import RejectDialog from '../../components/rejectDialog'
import Fancybox from '../../components/fancy.vue'
export default {
    props: ['dialogVisible', 'dataDetail'],
    emits: ['handleCloseDialog', 'confirm', 'reject'],
    components: {
        Fancybox,
        Dialog,
        RejectDialog
    },
    data() {
        return {
            dialogVisibleStatus: false,
            dialogConfirm: false,
            rejectVisible: false
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.dialogVisible
    },
    watch: {
        dialogVisible() {
            this.dialogVisibleStatus = this.dialogVisible;
        },
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        listenDialog(status) {
            this.dialogConfirm = status
        },
        rejectDialog(status) {
            this.rejectVisible = status
        },
        confirm() {
            this.$emit("confirm");
        },
        reject(data) {
            this.$emit("reject", data);
            this.rejectVisible = false
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 180%;
        margin-bottom: 20px;
    }

    p.stt {
        color: #515151;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.075px;
        margin-bottom: 10px;
    }

    p.stt span {
        font-size: 16px;
        font-weight: 500;
    }

    p.ttl {
        color: #515151;
        font-size: 15px;
        line-height: 180%;
        margin: 0;
    }

    .wp-bds {
        border-radius: 20px;
        background: #F6F6F6;
        padding: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        text-decoration: none;
    }

    .wp-bds img {
        width: 70px;
        height: 70px;
        border-radius: 15px;
        object-fit: cover;
        margin-right: 10px;
    }

    .wp-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        min-height: 70px;
    }

    .wp-txt p {
        margin-bottom: 0;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        width: 100%;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .wp-money, .wp-sale {
        border-radius: 15px;
        background:#F6F6F6;
        padding: 10px 16px;
        margin-bottom: 10px;
    }

    .wp-money p {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;
    }

    .wp-money p:last-child {
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 0;
    }

    .wp-sale {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .wp-sale img {
        width: 60px;
        height: 60px;
        border-radius: 32px;
        object-fit: cover;
        margin-right: 20px;
    }

    .wp-sale p:first-child {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .wp-sale p {
        color: #515151;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 0;
    }

    .wp-sale p span {
        font-weight: 500;
    }

    .txt--red {
        color: #F13A3A !important;
    }

    .txt--green {
        color: #00B569 !important;
    }

    .txt--yl {
        color: #F58E16 !important;
    }

    .wp-job {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 10px 15px;
        margin-bottom: 10px;
        display: block;
        text-decoration: none;
    }

    .wp-job p {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    .wp-job p img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 20px 0;
    }

    ul li {
        border-radius: 20px;
        background: #FCE3C5;
        padding: 7.5px 15px;
        margin-bottom: 10px;
    }

    ul li:last-child {
        margin-bottom: 0;
    }

    ul li a {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        display: block;
        overflow: hidden;
    }

    ul li a img {
        margin-right: 11px;
    }

    .wp-button button {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        width: 344px;
        height: 38px;
        margin-left: 10px;
        border-radius: 15px;
        padding: 0;
        background-color: #F13A3A;
    }

    .wp-button button:first-child {
        width: 93px;
        margin-left: 0;
        color: #F13A3A;
        border: 1px solid #F13A3A;
        background-color: transparent;
    }

    .wp-button button:last-child {
        background-color: #F58E16;
    }

    .wp-detail {
        max-height: 85vh;
        overflow: auto;
    }

    .wp-detail::-webkit-scrollbar {
        width: 0;
    }
</style>

<style>
    .detail_revenue {
        border-radius: 20px !important;
    }

    .detail_revenue .el-dialog__header {
        display: none;
    }
</style>