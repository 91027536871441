<template>
    <a :href="'/quan-ly-bds/du-an/chi-tiet/' + encode(id)" class="item">
        <div class="row col-md-12 p-md-0 mr-md-0">
            <div class="col-md-3">
                <swiper
                    :cssMode="true"
                    :navigation="true"
                    :pagination="{ clickable: true }"
                    :mousewheel="true"
                    :keyboard="true"
                    :modules="modules"
                    class="mySwiper"
                    :loop="true"
                    :auto-play="true"
                >
                    <swiper-slide v-for="(item, index) in dataDetailProj.image"><img :src="$storage + item.path" alt="" @error="handleError"></swiper-slide>
                </swiper>
            </div>
            <div class="col-md-9">
                <div class="info">
                    <p>Dự án <span class="status" :style="{'color': color}">{{ status_txt }}</span></p>
                    <p>{{ dataDetailProj.name }}</p>
                    <p>{{ dataDetailProj.meta_description }}</p>
                    <img src="@/assets/icons/Torrent.png" alt="" style="width: 20px;"> <span>{{ dataDetailProj.acreage }} ha</span> <br >
                    <img src="@/assets/icons/location.png" alt="" style="width: 20px; height: 20px;"> <span>{{ dataDetailProj.district_name }}, {{ dataDetailProj.province_name }}</span>
                </div>
            </div>
        </div>
    </a>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
    props: ['project_id'],
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            modules: [Navigation, Pagination, Mousewheel, Keyboard],
            iconHover: true,
            id: this.project_id,
            dataDetailProj: [],
            status_txt: '',
            color: ''
        };
    },
    watch: {
        project_id() {
            this.id = this.project_id;
            if(this.id) {
                this.getDetailProject()
            }
        },
    },
    mounted(){
        this.id = this.project_id;
        if(this.id) {
            this.getDetailProject()
        }
    },
    methods: {
        async getDetailProject() {
            await this.$axios.get('/project/getProjectDetailForAdmin',{
                params: {
                    id: this.id,
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetailProj = res.data
                if(res.data.project_status == 1) {
                    this.status_txt = 'Chưa mở bán'
                    this.color = '#F58E16'
                } else if (res.data.project_status == 2) {
                    this.status_txt = 'Sắp mở bán'
                    this.color = '#F58E16'
                } else if (res.data.project_status == 3) {
                    this.status_txt = 'Mở bán'
                    this.color = '#00B569'
                } else if (res.data.project_status == 4) {
                    this.status_txt = 'Đang cập nhật'
                    this.color = '#F58E16'
                } else {
                    this.status_txt = 'Đã bán'
                    this.color = '#EE3439'
                }
            })
        }
    }
}
</script>
<style lang="css" scoped>
    .swiper-slide img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }

    .swiper {
        height: 239px;
    }
    .item {
        display: block;
        font-family: Roboto;
        border-radius: 15px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
        margin-bottom: 15px;
        max-width: 372px;
        width: 100%;
        text-decoration: none;
    }
    .status {
        display: block;
    }
    .item p {
        margin: 0;
    }

    .info {
        padding: 12px 15px 7px 20px;
    }

    .info p:nth-child(1) {
        color: #F58E16;
        font-weight: 600;
        margin-bottom: 6px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
    }

    .info p:nth-child(2) {
        color: #515151;
        font-family: "Baloo Tammudu 2", cursive;
        font-size: 18px;
        font-weight: 700;
    }

    .info p:nth-child(3) {
        color: #808080;
        font-size: 13px;
        margin-bottom: 8px;
        height: 58px;
        display: none;
    }


    .info span:nth-child(5) {
        color: #696969;
        font-size: 14px;
        font-weight: 500;
    }

    .info span:nth-child(8) {
        color: #ABABAB;
        font-size: 14px;
        font-weight: 500;
    }

    .br_pc {
        display: none;
    }
    @media (min-width: 768px) {
        .info p:nth-child(3) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .info {
            padding: 8px 0px 7px 0px;
        }
        .item{
            height: 183px;
            width: 100%;
            max-width: unset;
        }
        .swiper {
            height: 183px;
        }
    }
    @media (min-width: 1280px) {
        .br_pc {
            display: block;
        }
    }
</style>