<template>
    <div class="wp-login">
        <div class="login">
            <div class="text-center">
                <img src="@/assets/icons/logo_login.png" alt="">
            </div>
            <el-form
                label-width="100px"
                style="max-width: 460px"
                ref="formLogin"
                :model="formLogin"
                :rules="rules"
                @submit.prevent="Login('formLogin')"
            >
                <div class="input-item">
                    <img src="@/assets/icons/user_ico.png" alt="">
                    <el-form-item prop="user_name">
                        <el-input v-model="formLogin.user_name" type="text" placeholder="Nhập tên đăng nhập"/>
                    </el-form-item>
                </div>
                <div class="input-item">
                    <img src="@/assets/icons/pass_ico.png" alt="">
                    <el-form-item prop="password">
                        <el-input v-model="formLogin.password" :type="typePass" placeholder="Nhập mật khẩu"/>
                    </el-form-item>
                    <img :src="url_img" alt="" class="pass--ico" @click="showPass">
                </div>
                <span class="error" v-if="error">{{ error }}</span>
                <label class="wp-lable">
                    <p>Ghi nhớ đăng nhập</p>
                    <input type="checkbox" class="input-style">
                    <span class="checkmark"></span>
                </label>
                <button>Đăng nhập</button>
            </el-form>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { notification } from '../firebase/index';
export default {
    data() {
        return {
            formLogin: {
                user_name: '',
                password: '',
                deviceToken: ''
            },
            url_img: require('@/assets/icons/seyes.png'),
            typePass: 'password',
            rules: {
                user_name: [{required: true, message: "Vui lòng nhập tên đăng nhập", trigger: ["blur", "change"]}],
                password: [{required: true, message: "Vui lòng nhập password", trigger: ["blur", "change"]}],
            },
            error: ''
        }
    },
    computed: {
        getDeviceToken() {
            return notification;
        }
    },
    mounted() {
        this.formLogin.deviceToken = this.getDeviceToken.token
    },
    methods: {
        showPass() {
            if(this.typePass == 'password') {
                this.typePass = 'text'
                this.url_img = require('@/assets/icons/eyes.png')
            } else {
                this.typePass = 'password'
                this.url_img = require('@/assets/icons/seyes.png')
        }
        },
        ...mapActions(['loginAdmin']),
        Login(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    await this.$axios.post('/auth/signinAdmin',this.formLogin,{
                        headers: {
                            'x-access-token': ''
                        }
                    }).then(res => {
                        window.localStorage.setItem("ad_tk", res.data.userData.accessToken);
                        this.$router.push('/')
                    }).catch(err => {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        if (err.response.data) {
                            this.error = err.response.data.error
                        }
                    })
                    this.endLoading();
                } else {
                   return true
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    .wp-lable {
        color: #515151;
        display: block;
        position: relative;
        margin: 20px 0 74px;
        cursor: pointer;
        font-size: 16px;
    }

    .wp-lable input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .wp-lable input:checked ~ .checkmark:after {
        display: block;
    }

    .wp-lable .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/Done_check.png');
    }

    .wp-lable p {
        margin: 0 0 0 28px;
        color: #F58E16;
        font-size: 14px;
        font-weight: 400;
    }

    .wp-login {
        background: linear-gradient(180deg, #F99E33 0%, #DA8622 61.95%, #B2650C 100%);
        min-height: 100vh;
        display: flex;
        align-items: center;
    }

    .login {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        width: 470px;
        margin: 0 auto;
        padding: 100px 48px 52px;
    }

    .input-item {
        position: relative;
    }

    .input-item img {
        position: absolute;
        top: 10px;
        left: 14px;
    }

    .input-item:first-child {
        margin: 85px 0 15px;
    }

    button {
        width: 100%;
        border: none;
        color: #FFF;
        font-size: 18px;
        font-weight: 600;
        border-radius: 41px;
        background: #F58E16;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 11.5px 0;
    }

    .pass--ico {
        right: 15px;
        left: unset !important;
        top: 18px !important;
        cursor: pointer;
        z-index: 2;
        width: 20px;
        height: 20px;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }
</style>

<style>
    .login .el-input__wrapper {
        background: transparent;
        padding: 0 50px;
        border-radius: 41px;
        border: 1px solid #DBDBDB;
        height: 57px;
        box-shadow: none;
        font-size: 15px;
    }

    .login .el-input__wrapper.is-focus,
    .login .el-input__wrapper:hover {
        box-shadow: none;
    }
</style>