<template>
    <div class="wp-confirm">
        <h5>Yêu cầu rút tiền</h5>
        <div class="breadcrumb">
            <span>Quản lý tài chính</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Yêu cầu rút tiền</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <p style="margin: 0;">Số giao dịch: <span style="font-size: 18px; font-weight: 600;">{{ page.totalData }}</span></p>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <div class="dropdown" style="margin-right: 10px;">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ status_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn trạng thái</p>
                        <li>
                            <a class="dropdown-item" href="#" @click="approve_status = '', setPage(1), status_txt = 'Tất cả trạng thái'">
                                Tất cả trạng thái
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#" @click="approve_status = 1, setPage(1), status_txt = 'Đã xác nhận'">
                                Đã xác nhận
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#" @click="approve_status = 0, setPage(1), status_txt = 'Chưa xác nhận'">
                                Chưa xác nhận
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#" @click="approve_status = 2, setPage(1), status_txt = 'Đã từ chối'">
                                Đã từ chối
                            </a>
                        </li>
                    </ul>
                </div>

                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" class="history_tbl" @row-click="getDetail">
                    <el-table-column header-align="center" align="center" type="index" fixed label="Stt" width="70"/>
                    <el-table-column header-align="center" align="center" prop="full_name" label="Tên nhân viên" min-width="270" />
                    <el-table-column header-align="center" align="center" prop="user_code" label="Mã nhân viên" min-width="214">
                        <template #default="scope">
                            <span class="code">{{ scope.row.user_code }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="phone" label="Số điện thoại" min-width="152" />
                    <el-table-column header-align="center" align="center" prop="role_name" label="Chức vụ" min-width="152" />
                    <el-table-column header-align="center" align="center" prop="create_at" label="Thời gian" min-width="180">
                        <template #default="scope">
                            <span>{{ this.$moment(scope.row.create_at).format("HH:mm DD/MM/YYYY") }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="" label="Số tiền ứng" min-width="187">
                        <template #default="scope">
                            <span class="price">{{ parseInt(scope.row.amount).toLocaleString('vi') }} VNĐ</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="approve_text" label="Trạng thái giao dịch" width="172">
                        <template #default="scope">
                            <span v-if="scope.row.approve_status == 1" style="color: #00B569;">Đã xác nhận</span>
                            <span v-else-if="scope.row.approve_status == 0" style="color: #F58E16;">Chưa xác nhận</span>
                            <span v-else style="color: #F03030;">Từ chối</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
        <Detail
            :dialogVisible="dialogVisible"
            :dataDetail="dataDetail"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            @reject="reject"
        />
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
import Detail from './dialog/detail_request'
export default {
    components: {
        Pagination,
        Detail
    },
    data() {
        return {
            keySearch: '',
            approve_status: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Tất cả trạng thái',
            status: '',
            id: '',
            dialogVisible: false,
            dataDetail: []
        }
    },
    mounted() {
        this.getListTransaction()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.getListTransaction()
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.getListTransaction()
        },
        listenDialog(status) {
            this.dialogVisible = status
        },
        async getDetail(row) {
            this.startLoading();
            await this.$axios.get('/finance/getDetailWithdrawHistorydetail',{
                params: {
                    id: row.id,
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                this.dialogVisible = true
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirm() {
            const data = {
                id: this.dataDetail.id,
                approve_status: 1
            }
            this.startLoading();
            await this.$axios.put('/finance/updateWithdrawStatus', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Xác nhận duyệt yêu cầu thành công!');
                this.dialogVisible = false
                this.getListTransaction()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async reject(reason) {
            const data = {
                id: this.dataDetail.id,
                reason_reject: reason,
                approve_status: 2
            }
            this.startLoading();
            await this.$axios.put('/finance/updateWithdrawStatus', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Từ chối duyệt yêu cầu thành công!');
                this.dialogVisible = false
                this.getListTransaction()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async getListTransaction() {
            this.startLoading();
            await this.$axios.get('/finance/getListWithdrawHistoryForAdmin',{
                params: {
                    key: this.keySearch,
                    approve_status: this.approve_status,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    span.code {
        color: #2B97E9;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    span.price {
        color: #00B569;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin: 0 16px 0 0;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .btn-approve, .btn-reject {
        width: 81px;
        height: 23px;
        border-radius: 15px;
        font-size: 15px;
        font-weight: 500;
        line-height: 115%;
    }

    .btn-approve {
        color: #FFF;
        background: #F58E16;
        margin-bottom: 5px;
    }

    .btn-reject {
        color:#F13A3A;
        background: #F4F4F4;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }
</style>

<style>
    .history_tbl tbody tr {
        height: 80px;
    }
</style>