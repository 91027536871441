<template>
    <div class="wp-money">
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :span="24">
                <h5>Quản lý tài chính</h5>
                <div class="breadcrumb mb-0">
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Quản lý tài chính</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Doanh thu cá nhân</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Rút tiền</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Lịch sử</span>
                </div>
            </el-col>
        </el-row>

        <div class="wp-content">
            <div style="margin-bottom: 20px;">
                <router-link to="/cong-viec/tai-chinh/rut-tien">
                    <img src="@/assets/icons/arrow.png" alt="">
                </router-link>
                <span class="ttl">Lịch sử rút tiền</span>
            </div>
            <el-scrollbar height="700px">
                <div class="wp-item" v-for="(item, index) in listHistory" :key="index">
                    <div class="item">
                        <div class="d-flex align-items-center">
                            <img src="@/assets/icons/money-out.png" alt="">
                            <div class="txt">
                                <p v-if="item.approve_status == 0">Số tiền rút: <span class="txt--yl">- {{ parseInt(item.amount).toLocaleString('vi') }} VNĐ</span></p>
                                <p v-else-if="item.approve_status == 1">Số tiền rút: <span class="txt--red">- {{ parseInt(item.amount).toLocaleString('vi') }} VNĐ</span></p>
                                <p v-else-if="item.approve_status == 2">Số tiền rút: <span class="txt--green">+ {{ parseInt(item.amount).toLocaleString('vi') }} VNĐ</span></p>
                                <p class="code">#{{ item.id }} 
                                    <span :class="{
                                        'txt--yl':item.approve_status == 0,
                                        'txt--red':item.approve_status == 1,
                                        'txt--green':item.approve_status == 2
                                    }">{{ item.approve_text }}</span>
                                </p>
                                <p v-if="item.approve_status == 2">Lý do: <span class="reason">{{ item.reason_reject }}</span></p>
                            </div>
                        </div>
                        <span class="note">{{ item.content }}</span>
                    </div>
                    <div class="text-end">
                        <span class="time">{{ $moment(item.create_at).format('hh:mm DD/MM/YYYY') }}</span>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listHistory: []
        }
    },
    mounted() {
        this.getListHistory()
    },
    methods: {
        async getListHistory() {
            this.startLoading();
            await this.$axios.get('/finance/getListWithdrawHistoryForPerson', {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listHistory = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 100%;
        max-width: 842px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-content a img {
        rotate: 180deg;
        margin-right: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
    }

    .wp-item {
        margin-bottom: 10px;
    }

    .item {
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        padding: 10px;
        margin: 5px;
    }

    .item img {
        width: 38px;
        height: 38px;
        margin-right: 20px;
    }

    .txt {
        flex-basis: 90%;
    }

    .txt p {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #515151;
        font-size: 15px;
        line-height: 110%;
        margin-bottom: 7px;
    }

    p.code {
        color:#3AB7EC;
        font-size: 15px;
        font-weight: 500;
        line-height: 117%;
    }

    .txt p span {
        font-size: 16px;
        font-weight: 600;
    }

    span.txt--red {
        color: #F13A3A;
    }

    span.txt--green {
        color: #00B569;
    }

    span.txt--yl {
        color: #F58E16;
    }

    span.reason {
        font-size: 15px;
        font-weight: 500;
    }

    span.note, span.time {
        color:#515151;
        font-size: 14px;
        font-weight: 400;
        display: block;
        margin-right: 7px;
    }
</style>